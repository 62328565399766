/* eslint-disable no-unused-vars */
/* eslint-disable no-fallthrough */
/* eslint-disable array-callback-return */
/* eslint-disable no-self-assign */
import Immutable from "seamless-immutable"
import { createSelector } from "reselect"
import {
  getMajorFlightDetails,
  priceItinerary,
  // getAirlineName,
  getAirlinesNameCode,
  getKeyPriceItinerary,
  flightTimings,
  getFlightReturnTime,
  getMomentDate
} from "filterFunctions"
import _ from "underscore"
import loadMax from "lodash.max"
import orderBy from "lodash.orderby"

import moment from "moment"
import {
  getStopsFilter
  // filterData
} from "utilities"
import { cabinData } from "Constants"
// Types
export const types = {
  //
  // RESET_FLIGHT_STATE
  //
  RESET_FLIGHT_STATE: "tripovy/RESET_FLIGHT_STATE",
  //
  // SEARCH_FLIGHTS
  //
  SEARCH_FLIGHTS_REQUEST: "tripovy/SEARCH_FLIGHTS_REQUEST",
  SEARCH_FLIGHTS_SUCCESS: "tripovy/SEARCH_FLIGHTS_SUCCESS",
  SEARCH_FLIGHTS_ERROR: "tripovy/SEARCH_FLIGHTS_ERROR",
  //
  // RESERVE_FLIGHT
  //
  RESERVE_FLIGHT_REQUEST: "tripovy/RESERVE_FLIGHT_REQUEST",
  RESERVE_FLIGHT_SUCCESS: "tripovy/RESERVE_FLIGHT_SUCCESS",
  RESERVE_FLIGHT_ERROR: "tripovy/RESERVE_FLIGHT_ERROR",
  //
  // CHECK_BOOKING_STATUS
  //
  CHECK_BOOKING_STATUS_REQUEST: "tripovy/CHECK_BOOKING_STATUS_REQUEST",
  CHECK_BOOKING_STATUS_SUCCESS: "tripovy/CHECK_BOOKING_STATUS_SUCCESS",
  CHECK_BOOKING_STATUS_ERROR: "tripovy/CHECK_BOOKING_STATUS_ERROR",
  //
  // GET_SINGLE_RESERVATION
  //
  GET_SINGLE_RESERVATION_REQUEST: "tripovy/GET_SINGLE_RESERVATION_REQUEST",
  GET_SINGLE_RESERVATION_SUCCESS: "tripovy/GET_SINGLE_RESERVATION_SUCCESS",
  GET_SINGLE_RESERVATION_ERROR: "tripovy/GET_SINGLE_RESERVATION_ERROR",
  //
  // SEARCH_STOPS
  //
  SEARCH_STOPS: "tripovy/SEARCH_STOPS",
  //
  //  PRICE_RANGE
  //
  PRICE_RANGE: "tripovy/PRICE_RANGE",
  //
  //  PRICE_RANGE
  //
  MIN_MAX_PRICE_RANGE: "tripovy/MIN_MAX_PRICE_RANGE",
  //
  // JOURNEY_DURATION_RANGE
  //
  JOURNEY_DURATION_RANGE: "tripovy/JOURNEY_DURATION_RANGE",
  //
  // JOURNEY_DURATION
  //
  JOURNEY_DURATION: "tripovy/JOURNEY_DURATION",
  //
  // AIRLINES_ARRAY
  //
  AIRLINES_ARRAY: "tripovy/AIRLINES_ARRAY",
  // APPLY_FILTERS
  APPLY_FILTERS: "tripovy/APPLY_FILTERS",
  //
  // RESET FILTERS
  //
  RESET_FILTER: "tripovy/RESET_FILTER",
  //
  // CHEAPEST FLIGHTS
  //
  CHEAPEST_FLIGHTS: "tripovy/CHEAPEST_FLIGHTS",
  //
  // EARLIEST FLIGHTS
  //
  EARLIEST_FLIGHTS: "tripovy/EARLIEST_FLIGHTS",
  //
  // QUICKEST FLIGHTS
  //
  QUICKEST_FLIGHTS: "tripovy/QUICKEST_FLIGHTS",
  //
  // OUTBOUND JOURNEY TIME
  //
  OUTBOUND_JOURNEY_TIME: "tripovy/OUTBOUND_JOURNEY_TIME",
  //
  // RETURN JOURNEY TIME
  RETURN_JOURNEY_TIME: "tripovy/RETURN_JOURNEY_TIME",
  //
  // SELECTED_FLIGHT_DATA
  //
  SELECTED_FLIGHT_DATA: "tripovy/SELECTED_FLIGHT_DATA",
  //
  // RESERVE_FLIGHT_STATUS_FAILED
  //
  RESERVE_FLIGHT_STATUS_FAILED: "tripovy/RESERVE_FLIGHT_STATUS_FAILED",
  //
  // CHECK_BOOKING_STATUS_FAILED
  //
  CHECK_BOOKING_STATUS_FAILED: "tripovy/CHECK_BOOKING_STATUS_FAILED",
  //
  // GET_SINGLE_RESERVATION_FAILED
  //
  GET_SINGLE_RESERVATION_FAILED: "tripovy/GET_SINGLE_RESERVATION_FAILED",
  //
  // MODIFY_SELECTED_FLIGHT_DATA
  //
  MODIFY_SELECTED_FLIGHT_DATA: "tripovy/MODIFY_SELECTED_FLIGHT_DATA",
  //
  // PAGE_REFRESH
  //
  PAGE_REFRESH: "tripovy/PAGE_REFRESH",
  //
  // MODIFY_FARE_BREAKDOWN
  //
  MODIFY_FARE_BREAKDOWN: "tripovy/MODIFY_FARE_BREAKDOWN",
  //
  // TOTAL_PRICE_FARE_BREAKDOWN
  //
  TOTAL_PRICE_FARE_BREAKDOWN_REQUEST:
    "tripovy/TOTAL_PRICE_FARE_BREAKDOWN_REQUEST",
  TOTAL_PRICE_FARE_BREAKDOWN_SUCCESS:
    "tripovy/TOTAL_PRICE_FARE_BREAKDOWN_SUCCESS",
  TOTAL_PRICE_FARE_BREAKDOWN_ERROR: "tripovy/TOTAL_PRICE_FARE_BREAKDOWN_ERROR",
  // TOGGLE_OVERLAY
  TOGGLE_OVERLAY: "tripovy/TOGGLE_OVERLAY",
  // RETURNING_FILTERS
  RETURNING_FILTERS: "tripovy/RETURNING_FILTERS",
  // SELECTED_DEPARTING_FLIGHT
  SELECTED_DEPARTING_FLIGHT: "tripovy/SELECTED_DEPARTING_FLIGHT",
  //
  // SEARCH_FLIGHTS_PROGRESSIVE
  //
  SEARCH_FLIGHTS_PROGRESS_SEARCH_LIST_REQUEST:
    "tripovy/SEARCH_FLIGHTS_PROGRESS_SEARCH_LIST_REQUEST",
  SEARCH_FLIGHTS_PROGRESS_SEARCH_LIST_SUCCESS:
    "tripovy/SEARCH_FLIGHTS_PROGRESS_SEARCH_LIST_SUCCESS",
  SEARCH_FLIGHTS_PROGRESS_SEARCH_LIST_ERROR:
    "tripovy/SEARCH_FLIGHTS_PROGRESS_SEARCH_LIST_ERROR",
  //
  // SEARCH_FLIGHTS_PROGRESS_RESULT
  //
  SEARCH_FLIGHTS_PROGRESS_SEARCH_REQUEST:
    "tripovy/SEARCH_FLIGHTS_PROGRESS_SEARCH_REQUEST",
  SEARCH_FLIGHTS_PROGRESS_SEARCH_SUCCESS:
    "tripovy/SEARCH_FLIGHTS_PROGRESS_SEARCH_SUCCESS",
  SEARCH_FLIGHTS_PROGRESS_SEARCH_ERROR:
    "tripovy/SEARCH_FLIGHTS_PROGRESS_SEARCH_ERROR",
  //
  // SEARCH_FLIGHTS_PROGRESS_RESULT_PGINATION
  //
  SEARCH_FLIGHTS_PROGRESS_SEARCH_PAGINATION_REQUEST:
    "tripovy/SEARCH_FLIGHTS_PROGRESS_SEARCH_PAGINATION_REQUEST",
  SEARCH_FLIGHTS_PROGRESS_SEARCH_PAGINATION_SUCCESS:
    "tripovy/SEARCH_FLIGHTS_PROGRESS_SEARCH_PAGINATION_SUCCESS",
  SEARCH_FLIGHTS_PROGRESS_SEARCH_PAGINATION_ERROR:
    "tripovy/SEARCH_FLIGHTS_PROGRESS_SEARCH_PAGINATION_ERROR"
}

const initialState = Immutable({
  isSearchingFlights: false,
  isReservingFlights: false,
  flights: [],
  flightsB: [],
  flightsC: [],
  flightsD: [],
  depDateForFilters: new Date(),
  returnDateForFilters: new Date(),
  minDateForFilters: new Date(),
  minMaxPriceRange: [],
  minMaxPriceValue: [],
  journeyDurationRange: [],
  airlinesArray: [],
  checkedAirlines: [],
  outBoundJourneyTime: [],
  returnJourneyTime: [],
  filters: {},
  filtersB: {},
  isRefreshFilter: false,
  selectedFlight: {},
  selectedFlightB: {},
  queryDetails: null,
  borders: {
    cheapFlightBorder: true,
    earlyFlightBorder: false,
    quickFlightBorder: false
  },
  identifier: null,
  pnr: null,
  testReserved: null,
  isCheckBookingStatus: false,
  checkBookingStatus: "pending",
  isGetSingleReservation: false,
  overlay: false,
  progressValue: 0,
  isTotalPriceNull: false,
  pageRefresh: true,
  split_view: undefined,
  splitDeparting: [],
  splitReturning: [],
  return_flight: {},
  split_departing_flight: {},
  selected_view: "departing",
  response_trip_type: null,
  // vendors Array from progressive search flight result
  progressList: [],
  progressListSuccess: false,
  isSearchingFlightsProgress: false,
  splitViewCheck: [],
  forSortingOnly: [],
  forSortingOnlyDeparting: [],
  forSortingOnlyReturning: [],
  previousfilters: {},
  filtersApplied: false
})

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // RESET_FLIGHT_STATE
    case types.RESET_FLIGHT_STATE:
      return state.merge({
        flights: [],
        split_view: undefined,
        split_departing_flight: {},
        flightsB: [],
        flightsC: [],
        flightsD: [],
        filters: {},
        filtersB: {},
        splitDeparting: [],
        splitReturning: []
      })

    case types.SEARCH_FLIGHTS_PROGRESS_SEARCH_REQUEST:
      let { depDateForFilters, minDateForFilters, returnDateForFilters } = action.payload
      return state.merge({
        isSearchingFlights: true,
        depDateForFilters: getMomentDate(depDateForFilters),
        returnDateForFilters: getMomentDate(returnDateForFilters),
        minDateForFilters: getMomentDate(minDateForFilters),
        progressValue: 0,
        filters: {},
        filtersB: {},
        flights: [],
        flightsB: [],
        flightsC: [],
        flightsD: [],
        splitDeparting: [],
        splitReturning: [],
        splitViewCheck: [],
        forSortingOnly: [],
        forSortingOnlyDeparting: [],
        forSortingOnlyReturning: [],
        previousfilters: {},
        filtersApplied: false
      })
    case types.SEARCH_FLIGHTS_PROGRESS_SEARCH_SUCCESS: {
      // console.log("SEARCH_FLIGHTS_PROGRESS_SEARCH_SUCCESS", action.payload)
      if (action.payload.success) {
        let trip_type = action.payload && action.payload.trip_type
        let stateFlights = Immutable(state.flights).asMutable({ deep: true })
        let testFlights = stateFlights.length
          ? stateFlights.concat(action.payload.data)
          : action.payload.data
        let myFlightsArr = stateFlights.length
          ? stateFlights.concat(action.payload.data)
          : action.payload.data
        let splitViewCheck = []
        action.payload && action.payload.split_view
          ? splitViewCheck.push(true)
          : splitViewCheck.push(false)
        let splitView =
          action.payload && action.payload.split_view
            ? action.payload && action.payload.split_view
            : splitViewCheck.includes(true)
            ? true
            : undefined
        let stop = { direct: "", oneStop: "", twoPlusStops: "" }
        let airlinesArr = []
        let journeyDuration = { min: "", max: "" }
        let priceRange = { min: "", max: "" }
        if (typeof splitView !== undefined && splitView === true) {
          myFlightsArr = stateFlights.length
            ? stateFlights.concat(action.payload.data.departing)
            : action.payload.data.departing
          testFlights = stateFlights.length
            ? stateFlights.concat(action.payload.data.departing)
            : action.payload.data.departing
      
        }
   
        testFlights.forEach(flight => {
          let departures
          let tripsLength = []
          let arrays
          let departureArr
          let maximumNum
          if (flight.trips) {
            departures = flight.trips.map(i => i.departing_flight_segments)
            arrays = departures.map((i, index) => {
              i.map(items => {
                departureArr = items.stop_airports
              })
              return departureArr
            })
            departures.map((item, index) => {
              tripsLength.push(item.length + arrays[index].length - 1)
            })
            maximumNum = loadMax(tripsLength)
          }

          let departure = flight.trips
            ? flight.trips[0].departing_flight_segments
            : flight.departing_flight_segments
          // STOPS
          let segment = flight.trips
            ? maximumNum
            : departure.length + departure[0].stop_airports.length - 1
          // let segment = maximumNum
          let price = priceItinerary(flight.price_itinerary)
          if (segment === 0) {
            stop.direct = stop.direct
              ? Math.min(stop.direct, price.amount)
              : price.amount
          }
          if (segment === 1) {
            stop.oneStop = stop.oneStop
              ? Math.min(stop.oneStop, price.amount)
              : price.amount
          }
          if (segment >= 2) {
            stop.twoPlusStops = stop.twoPlusStops
              ? Math.min(stop.twoPlusStops, price.amount)
              : price.amount
          }
        
          let durationMinutes = flight.departing_flight_duration
          journeyDuration.min = journeyDuration.min
            ? Math.min(journeyDuration.min, durationMinutes)
            : durationMinutes
          journeyDuration.max = journeyDuration.max
            ? Math.max(journeyDuration.max, durationMinutes)
            : durationMinutes
          // Airlines
          let obj = getAirlinesNameCode(departure[0])
          let airlinesIndex
          if (obj) {
            airlinesIndex = airlinesArr.findIndex(
              airlines => airlines.code === obj.code
            )
            if (airlinesIndex === -1) {
              obj.price = price.amount
              airlinesArr.push(obj)
            } else {
              if (airlinesArr[airlinesIndex].price > price.amount) {
                airlinesArr[airlinesIndex].price = price.amount
              }
            }
          }
          // Price Range
          priceRange.min = priceRange.min
            ? Math.min(priceRange.min, price.amount)
            : price.amount
          priceRange.max = priceRange.max
            ? Math.max(priceRange.min, price.amount)
            : price.amount
        })
        journeyDuration = Object.assign(
          {},
          {
            min: Math.floor(journeyDuration.min / 60),
            max: Math.ceil(journeyDuration.max / 60)
          }
        )
        priceRange = Object.assign(
          {},
          {
            min: Math.floor(priceRange.min / 100) * 100,
            max: Math.ceil(priceRange.max / 100) * 100
          }
        )
        //
        // early flights for sorting
        //
        let earlyFlights = []
        let myFlight = myFlightsArr
        myFlight.map(item => {
          if (item.trips) {
            let {
              departure_date,
              departure_time
            } = item.trips[0].departing_flight_segments[0]
            // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
            let takeOffTime = `${departure_date.year}-${departure_date.month}-${
              departure_date.day
            } ${("0" + departure_time.hour).slice(-2)}:${(
              "0" + departure_time.minute
            ).slice(-2)}:00`
            let obj = { ...item, take_off_time: takeOffTime }
            earlyFlights.push(obj)
          } else {
            let {
              departure_date,
              departure_time
            } = item.departing_flight_segments[0]
            let takeOffTime = `${departure_date.year}-${departure_date.month}-${
              departure_date.day
            } ${("0" + departure_time.hour).slice(-2)}:${(
              "0" + departure_time.minute
            ).slice(-2)}:00`
            // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
            let obj = { ...item, take_off_time: takeOffTime }
            earlyFlights.push(obj)
          }
        })
        // @@@@@@@@@@@@@@@@@
        earlyFlights.sort(function(a, b) {
          let flightA = a.take_off_time
          let flightB = b.take_off_time
          return flightA < flightB ? -1 : flightA > flightB ? 1 : 0
        })
        // earlyFlights = orderBy(earlyFlights, function(o) {
        //   return new moment(o.take_off_time)
        // })

        // cheap flights
        let cheapFlights = myFlightsArr
        let newArr = []
        let priceKey = getKeyPriceItinerary(
          cheapFlights.length && cheapFlights[0].price_itinerary
        )
        cheapFlights.map(items => {
          newArr.push(items.price_itinerary[priceKey].amount)
        })

        cheapFlights.sort(function(a, b) {
          return (
            a.price_itinerary[priceKey].amount -
            b.price_itinerary[priceKey].amount
          )
        })
        let splitDeparting = Immutable(state.splitDeparting).asMutable({
          deep: true
        })
        let splitReturning = Immutable(state.splitReturning).asMutable({
          deep: true
        })
        if (typeof splitView !== undefined && splitView === true) {
          splitDeparting = splitDeparting.concat(action.payload.data.departing)
          splitDeparting = splitDeparting.sort(function(a, b) {
            return (
              a.price_itinerary[priceKey].amount -
              b.price_itinerary[priceKey].amount
            )
          })
          splitReturning = splitReturning.concat(action.payload.data.returning)
          splitReturning = splitReturning.sort(function(a, b) {
            return (
              a.price_itinerary[priceKey].amount -
              b.price_itinerary[priceKey].amount
            )
          })
        } else {
          testFlights = testFlights.sort(function(a, b) {
            return (
              a.price_itinerary[priceKey].amount -
              b.price_itinerary[priceKey].amount
            )
          })
        }
        // let cheapFlight = _.min(newArr)

        // quick flights
        let quickFlights = myFlightsArr
        let testArray = []
        quickFlights.map(item => {
          if (item.trips) {
            let duration = 0
            item.trips.map(trips => {
              trips.departing_flight_segments.map(items => {
                duration = duration + items.flight_duration
              })
            })
            item.totalFlightDuration = duration
            testArray.push(item)
          } else {
            let duration = 0
            item.departing_flight_segments.map(subItem => {
              duration = duration + subItem.flight_duration
            })
            item.totalFlightDuration = duration
            testArray.push(item)
          }
        })
        testArray.sort(function(a, b) {
          return a.departing_flight_duration - b.departing_flight_duration
        })
        // _.sortBy(testArray)
        // Merge filters after progressive api

        let filterState = Immutable(state.filters).asMutable({ deep: true })
        airlinesArr = filterState.airlinesArr
          ? filterState.airlinesArr.concat(airlinesArr)
          : airlinesArr
        airlinesArr = _.uniq(airlinesArr, "name")

        airlinesArr.map((v, i) => {
          airlinesArr[i]["isChecked"] = true
        })
        return state.merge({
          splitViewCheck: splitViewCheck,
          isSearchingFlights: false,
          flights: testFlights,
          flightsB: testFlights,
          flightsC: state.flightsC.concat(action.payload.data),
          flightsD: state.flightsD.concat(action.payload.data),
          splitDeparting: splitDeparting,
          splitReturning: splitReturning,
          forSortingOnly: testFlights,
          forSortingOnlyDeparting: testFlights,
          forSortingOnlyReturning: testFlights,
          split_view: splitView,
          // typeof splitView !== undefined && splitView === true

          response_trip_type:
            typeof splitView !== undefined && splitView === true
              ? "roundtrip"
              : action.payload.data.length && action.payload.data[0].type,
          borders: {
            cheapFlightBorder: true,
            earlyFlightBorder: false,
            quickFlightBorder: false
          },
          filters: {
            stop,
            priceRange,
            airlinesArr,
            journeyDuration,
            earlyFlights: earlyFlights[0],
            cheapFlights: cheapFlights[0],
            quickFlights: testArray[0],
            trip_type
          },
          filtersB: {
            stop,
            priceRange,
            airlinesArr,
            journeyDuration,
            earlyFlights: earlyFlights[0],
            cheapFlights: cheapFlights[0],
            quickFlights: testArray[0],
            trip_type
          }
        })
      }
    }
    case types.SEARCH_FLIGHTS_PROGRESS_SEARCH_ERROR:
      return state.merge({
        isSearchingFlights: false
        // flights: [],
        // flightsB: [],
        // flightsC: [],
        // flightsD: [],
        // splitDeparting: [],
        // splitReturning: [],
        // split_view: undefined,
        // filters: {},
        // filtersB: {}
      })

    //
    // SEARCH_FLIGHTS_PROGRESS_SEARCH_LIST
    //
    case types.SEARCH_FLIGHTS_PROGRESS_SEARCH_LIST_REQUEST:
      return state.merge({
        isSearchingFlightsProgress: true
      })
    case types.SEARCH_FLIGHTS_PROGRESS_SEARCH_LIST_SUCCESS:
      return state.merge({
        isSearchingFlightsProgress: false,
        progressList: action.payload.data,
        progressListSuccess: action.payload.success
      })
    case types.SEARCH_FLIGHTS_PROGRESS_SEARCH_LIST_ERROR:
      return state.merge({
        isSearchingFlightsProgress: false
      })

    //
    // SEARCH_FLIGHTS_PROGRESS_PAGINATION
    //
    case types.SEARCH_FLIGHTS_PROGRESS_SEARCH_PAGINATION_REQUEST:
      let {
        depDateForFilters: depDateForFiltersPagination,
        returnDateForFilters:  returnDateForFiltersPagination,
        minDateForFilters: minDateForFiltersPagination
      } = action.payload
      return state.merge({
        // isSearchingFlights: true,
        depDateForFilters: getMomentDate(depDateForFiltersPagination),
        returnDateForFilters: getMomentDate(returnDateForFiltersPagination),
        minDateForFilters: getMomentDate(minDateForFiltersPagination),
        progressValue: 0
        // filters: {},
        // flights: [],
        // flightsB: [],
        // flightsC: [],
        // flightsD: []
      })

    case types.SEARCH_FLIGHTS_PROGRESS_SEARCH_PAGINATION_SUCCESS: {
      if (action.payload.success) {
        let trip_type = action.payload.trip_type
        // let testFlights = action.payload.data
        let stateFlights = Immutable(state.flightsB).asMutable({ deep: true })

        let myFlightsArr =
          stateFlights.length && action.payload.data.length
            ? stateFlights.concat(action.payload.data)
            : action.payload.data

        let testFlights = stateFlights.length
          ? stateFlights.concat(action.payload.data)
          : action.payload.data
        // myFlightsArr = myFlightsArr
        // let myFlightsArr = action.payload.data

        let splitView = action.payload.split_view

        if (state.splitViewCheck.includes(true)) {
          splitView = true
        } else {
          splitView = action.payload.split_view
        }
        let stop = { direct: "", oneStop: "", twoPlusStops: "" }
        let airlinesArr = []
        let journeyDuration = { min: "", max: "" }
        let priceRange = { min: "", max: "" }

        if (typeof splitView !== undefined && splitView === true) {
          testFlights = stateFlights.length
            ? stateFlights.concat(action.payload.data.departing)
            : action.payload.data.departing

          // myFlightsArr = myFlightsArr
          myFlightsArr = stateFlights.length
            ? stateFlights.concat(action.payload.data.departing)
            : action.payload.data.departing
        }
        testFlights.length &&
          testFlights.forEach(flight => {
            let departures
            let tripsLength = []
            let arrays
            let departureArr
            let maximumNum
            if (flight.trips) {
              departures = flight.trips.map(i => i.departing_flight_segments)
              arrays = departures.map((i, index) => {
                i.map(items => {
                  departureArr = items.stop_airports
                })
                return departureArr
              })
              departures.map((item, index) => {
                tripsLength.push(item.length + arrays[index].length - 1)
              })

              maximumNum = loadMax(tripsLength)
            }

            let departure = flight.trips
              ? flight.trips[0].departing_flight_segments
              : flight.departing_flight_segments
            // STOPS
            let segment = flight.trips
              ? maximumNum
              : departure.length + departure[0].stop_airports.length - 1
            // let segment = maximumNum
            let price = priceItinerary(flight.price_itinerary)
            if (segment === 0) {
              stop.direct = stop.direct
                ? Math.min(stop.direct, price.amount)
                : price.amount
            } else if (segment === 1) {
              stop.oneStop = stop.oneStop
                ? Math.min(stop.oneStop, price.amount)
                : price.amount
            } else if (segment >= 2) {
              stop.twoPlusStops = stop.twoPlusStops
                ? Math.min(stop.twoPlusStops, price.amount)
                : price.amount
            }
            // Journey Duration
            // let { durationMinutes } = getMajorFlightDetails(departure)
            // journeyDuration.min = journeyDuration.min
            //   ? Math.min(journeyDuration.min, durationMinutes)
            //   : durationMinutes
            // journeyDuration.max = journeyDuration.max
            //   ? Math.max(journeyDuration.max, durationMinutes)
            //   : durationMinutes
            // let flightDurationTesting = flight.trips
            //   ? flight.trips[0].departing_flight_duration
            //   : flight.departing_flight_duration
            // let flightDurationTesting = flight.departing_flight_duration
            let durationMinutes = flight.departing_flight_duration
            journeyDuration.min = journeyDuration.min
              ? Math.min(journeyDuration.min, durationMinutes)
              : durationMinutes
            journeyDuration.max = journeyDuration.max
              ? Math.max(journeyDuration.max, durationMinutes)
              : durationMinutes
            // Airlines
            let obj = getAirlinesNameCode(departure[0])
            let airlinesIndex
            if (obj) {
              airlinesIndex = airlinesArr.findIndex(
                airlines => airlines.code === obj.code
              )
              if (airlinesIndex === -1) {
                obj.price = price.amount
                airlinesArr.push(obj)
              } else {
                if (airlinesArr[airlinesIndex].price > price.amount) {
                  airlinesArr[airlinesIndex].price = price.amount
                }
              }
            }
            // Price Range
            priceRange.min = priceRange.min
              ? Math.min(priceRange.min, price.amount)
              : price.amount
            priceRange.max = priceRange.max
              ? Math.max(priceRange.min, price.amount)
              : price.amount
          })
        journeyDuration = Object.assign(
          {},
          {
            min: Math.floor(journeyDuration.min / 60),
            max: Math.ceil(journeyDuration.max / 60)
          }
        )
        priceRange = Object.assign(
          {},
          {
            min: Math.floor(priceRange.min / 100) * 100,
            max: Math.ceil(priceRange.max / 100) * 100
          }
        )
        //
        // early flights for sorting
        //
        let earlyFlights = []
        let myFlight = testFlights
        myFlight.map(item => {
          if (item) {
            if (item.trips) {
              let {
                departure_date,
                departure_time
              } = item.trips[0].departing_flight_segments[0]
              let takeOffTime = `${departure_date.year}-${
                departure_date.month
              }-${departure_date.day} ${("0" + departure_time.hour).slice(
                -2
              )}:${("0" + departure_time.minute).slice(-2)}:00`
              // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
              let obj = { ...item, take_off_time: takeOffTime }
              earlyFlights.push(obj)
            } else {
              let {
                departure_date,
                departure_time
              } = item.departing_flight_segments[0]
              let takeOffTime = `${departure_date.year}-${
                departure_date.month
              }-${departure_date.day} ${("0" + departure_time.hour).slice(
                -2
              )}:${("0" + departure_time.minute).slice(-2)}:00`
              // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
              let obj = { ...item, take_off_time: takeOffTime }
              earlyFlights.push(obj)
            }
          }
        })
        earlyFlights.sort(function(a, b) {
          let flightA = a.take_off_time
          let flightB = b.take_off_time
          return flightA < flightB ? -1 : flightA > flightB ? 1 : 0
        })
        // earlyFlights = orderBy(earlyFlights, function(o) {
        //   return new moment(o.take_off_time)
        // })

        // cheap flights
        let cheapFlights = testFlights
        let newArr = []
        let priceKey = getKeyPriceItinerary(
          cheapFlights.length && cheapFlights[0].price_itinerary
        )
        cheapFlights.map(items => {
          newArr.push(items.price_itinerary[priceKey].amount)
        })
        // cheapFlights.sort(function(a, b) {
        //   return (
        //     a.price_itinerary[priceKey].amount -
        //     b.price_itinerary[priceKey].amount
        //   )
        // })
        let cheapFlight = _.min(newArr)

        // quick flights
        let quickFlights = testFlights

        let testArray = []
        quickFlights.map(item => {
          if (item.trips) {
            let duration = 0
            item.trips.map(trips => {
              trips.departing_flight_segments.map(items => {
                duration = duration + items.flight_duration
              })
            })
            item.totalFlightDuration = duration
            testArray.push(item)
          } else {
            let duration = 0
            item.departing_flight_segments.map(subItem => {
              duration = duration + subItem.flight_duration
            })
            item.totalFlightDuration = duration
            testArray.push(item)
          }
        })
        testArray.sort(function(a, b) {
          return a.departing_flight_duration - b.departing_flight_duration
        })
        let filterState = Immutable(state.filters).asMutable({ deep: true })
        airlinesArr = filterState.airlinesArr
          ? filterState.airlinesArr.concat(airlinesArr)
          : airlinesArr
        airlinesArr = _.uniq(airlinesArr, "name")
        airlinesArr.map((v, i) => {
          airlinesArr[i]["isChecked"] = true
        })
        // priceRange.min =
        //   state.filters.priceRange.min < priceRange.min
        //     ? state.filters.priceRange.min
        //     : priceRange.min
        // priceRange.max =
        //   state.filters.priceRange.max > priceRange.max
        //     ? state.filters.priceRange.max
        //     : priceRange.max
        // journeyDuration.min =
        //   state.filters.journeyDuration.min < journeyDuration.min
        //     ? state.filters.journeyDuration.min
        //     : journeyDuration.min
        // journeyDuration.max =
        //   state.filters.journeyDuration.max > journeyDuration.max
        //     ? state.filters.journeyDuration.max
        //     : journeyDuration.max

        // if (stop.direct && state.filters.stop.direct) {
        //   stop.direct =
        //     state.filters.stop.direct < stop.direct
        //       ? stop.direct
        //       : state.filters.stop.direct
        // } else if (stop.direct && !state.filters.stop.direct) {
        //   stop.direct = stop.direct
        // } else {
        //   stop.direct = state.filters.stop.direct
        // }

        // if (stop.oneStop && state.filters.stop.oneStop) {
        //   stop.oneStop =
        //     state.filters.stop.oneStop < stop.oneStop
        //       ? stop.oneStop
        //       : state.filters.stop.oneStop
        // } else if (stop.oneStop && !state.filters.stop.oneStop) {
        //   stop.oneStop = stop.oneStop
        // } else {
        //   stop.oneStop = state.filters.stop.oneStop
        // }

        // if (stop.twoPlusStops && state.filters.stop.twoPlusStops) {
        //   stop.twoPlusStops =
        //     state.filters.stop.twoPlusStops < stop.twoPlusStops
        //       ? stop.twoPlusStops
        //       : state.filters.stop.twoPlusStops
        // } else if (stop.twoPlusStops && !state.filters.stop.twoPlusStops) {
        //   stop.twoPlusStops = stop.twoPlusStops
        // } else {
        //   stop.twoPlusStops = state.filters.stop.twoPlusStops
        // }
        // stop.direct = stop.direct && state.filters.stop.direct && state.filters.stop.direct < stop.direct ? stop.direct: state.filters.stop.direct;
        // stop.oneStop = stop.oneStop && state.filters.stop.oneStop < stop.oneStop ? stop.oneStop: "";
        // stop.twoPlusStops = stop.twoPlusStops && state.filter.stop.twoPlusStops < stop.twoPlusStops ? stop.twoPlusStops: "";
        // _.sortBy(testArray)
        return state.merge({
          isSearchingFlights: false,
          flights: testFlights,
          // .length
          //   ? state.flights.concat(testFlights)
          //   : state.flights,
          flightsB: testFlights,
          // .length
          //   ? state.flightsB.concat(testFlights)
          //   : state.flightsB,
          flightsC: state.flightsC.concat(action.payload.data),
          flightsD: state.flightsD.concat(action.payload.data),
          splitDeparting: state.splitDeparting.concat(
            action.payload.data.departing
          ),
          splitReturning: state.splitReturning.concat(
            action.payload.data.returning
          ),
          forSortingOnly: testFlights,
          forSortingOnlyDeparting: testFlights,
          split_view: splitView,
          // typeof splitView !== undefined && splitView === true
          response_trip_type:
            typeof splitView !== undefined && splitView === true
              ? "roundtrip"
              : action.payload.data[0].type,
          // borders: {
          // cheapFlightBorder: true,
          // earlyFlightBorder: false,
          // quickFlightBorder: false
          // },
          filters: {
            stop,
            priceRange,
            airlinesArr,
            journeyDuration,
            earlyFlights: earlyFlights[0],
            cheapFlights: cheapFlights[0],
            quickFlights: testArray[0],
            trip_type
          },
          filtersB: {
            stop,
            priceRange,
            airlinesArr,
            journeyDuration,
            earlyFlights: earlyFlights[0],
            cheapFlights: cheapFlights[0],
            quickFlights: testArray[0],
            trip_type
          }
        })
      }
    }

    case types.SEARCH_FLIGHTS_PROGRESS_SEARCH_PAGINATION_ERROR:
      return state.merge({
        isSearchingFlights: false
        // flights: [],
        // flightsB: [],
        // flightsC: [],
        // flightsD: [],
        // splitDeparting: [],
        // splitReturning: [],
        // split_view: undefined,
        // filters: {},
        // filtersB: {}
      })

    // APPLY_FILTERS
    case types.APPLY_FILTERS: {
      // let flightsArr = Immutable(state.flightsB).asMutable({ deep: true })
      let filteredArr = []
      // if(action.payload.data){}
      let {
        stops,
        airlines,
        journeyValue,
        outBoundValues,
        priceValue,
        returnSliderValues,
        onlyAirline
      } = action.payload
      if (outBoundValues && outBoundValues[0] > outBoundValues[1]) {
        outBoundValues = outBoundValues.reverse()
      }
      // STOPS
      // debugger
      if (state.split_view === undefined) {
        // debugger
        let flightsArr = Immutable(state.flightsB).asMutable({ deep: true })
        let filteredArr = []
        filteredArr = flightsArr.filter(flights => {
          let segment
          let allStops
          let departures
          let tripsLength = []
          let arrays
          let departureArr
          if (flights.trips) {
            departures = flights.trips.map(i => i.departing_flight_segments)
            arrays = departures.map((i, index) => {
              i.map(items => {
                departureArr = items.stop_airports
              })
              return departureArr
            })
            departures.map((item, index) => {
              tripsLength.push(item.length + arrays[index].length - 1)
            })

            // segment =
            //   flights.trips[0].departing_flight_segments.length +
            //   flights.trips[0].departing_flight_segments[0].stop_airports.length -
            //   1
            let maximumNum = loadMax(tripsLength)
            segment = maximumNum
          } else {
            segment =
              flights.departing_flight_segments.length +
              flights.departing_flight_segments[0].stop_airports.length -
              1
          }

          if (segment > 2) {
            segment = 2
          }
          return stops.indexOf(segment) !== -1
        })
        // Departure Times : Outbound
        if (outBoundValues !== null) {
          filteredArr = filteredArr.filter(i => {
            let departure = i.trips
              ? i.trips[0].departing_flight_segments[0]
              : i.departing_flight_segments[0]
            let { departureTime } = flightTimings(departure)
            return (
              outBoundValues[0] <= departureTime * 60 &&
              departureTime * 60 <= outBoundValues[1]
            )
          })
        }
        // Departure Times : Return
        if (returnSliderValues !== null) {
          filteredArr = filteredArr.filter(i => {
            if (i.returning_flight_segments) {
              let departure = i.trips
                ? i.trips[0].departing_flight_segments[0]
                : i.returning_flight_segments[0]
              let { departureTime } = flightTimings(departure)
              return (
                returnSliderValues[0] <= departureTime * 60 &&
                departureTime * 60 <= returnSliderValues[1]
              )
            }
          })
        }
        // Journey Duration
        if (journeyValue !== null) {
          filteredArr = filteredArr.filter(i => {
            let durationMinutes = i.departing_flight_duration
            // let departure = i.trips
            //   ? i.trips[0].departing_flight_segments
            //   : i.departing_flight_segments
            // let { durationMinutes } = getMajorFlightDetails(departure)
            return durationMinutes <= journeyValue * 60
          })
        }
        // Price Range
        if (priceValue !== null) {
          filteredArr = filteredArr.filter(i => {
            let { amount: amt } = priceItinerary(i.price_itinerary)

            return amt >= priceValue[0] && amt <= priceValue[1]
          })
        }

        // Airlines
        if (airlines !== null) {
          filteredArr = filteredArr.filter(i => {
            let departure = i.trips
              ? i.trips[0].departing_flight_segments[0]
              : i.departing_flight_segments[0]
            return (
              airlines.indexOf(
                getAirlinesNameCode(departure).code &&
                  getAirlinesNameCode(departure).code
              ) !== -1
            )
          })
        }
        // also for sorting
        //
        // early flights for sorting
        //
        let earlyFlights = []
        let myFlight = filteredArr
        myFlight.map(item => {
          if (item.trips) {
            let {
              departure_date,
              departure_time
            } = item.trips[0].departing_flight_segments[0]
            let takeOffTime = `${departure_date.year}-${departure_date.month}-${
              departure_date.day
            } ${("0" + departure_time.hour).slice(-2)}:${(
              "0" + departure_time.minute
            ).slice(-2)}:00`
            // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
            let obj = { ...item, take_off_time: takeOffTime }
            earlyFlights.push(obj)
          } else {
            let {
              departure_date,
              departure_time
            } = item.departing_flight_segments[0]
            let takeOffTime = `${departure_date.year}-${departure_date.month}-${
              departure_date.day
            } ${("0" + departure_time.hour).slice(-2)}:${(
              "0" + departure_time.minute
            ).slice(-2)}:00`
            // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
            let obj = { ...item, take_off_time: takeOffTime }
            earlyFlights.push(obj)
          }
        })
        earlyFlights.sort(function(a, b) {
          let flightA = a.take_off_time
          let flightB = b.take_off_time
          return flightA < flightB ? -1 : flightA > flightB ? 1 : 0
        })
        // earlyFlights = orderBy(earlyFlights, function(o) {
        //   return new moment(o.take_off_time)
        // })

        // cheap flights
        let cheapFlights = filteredArr
        let newArr = []
        let priceKey = getKeyPriceItinerary(
          cheapFlights.length && cheapFlights[0].price_itinerary
        )
        cheapFlights.map(items => {
          newArr.push(items.price_itinerary[priceKey].amount)
        })
        // cheapFlights.sort(function(a, b) {
        // return (
        // a.price_itinerary[priceKey].amount -
        // b.price_itinerary[priceKey].amount
        // )
        // })
        if (priceValue !== null) {
          filteredArr = filteredArr.sort(function(a, b) {
            return (
              a.price_itinerary[priceKey].amount -
              b.price_itinerary[priceKey].amount
            )
          })
        }
        let cheapFlight = _.min(newArr)
        // quick flights
        let quickFlights = filteredArr
        let testArray = []
        quickFlights.map(item => {
          if (item.trips) {
            let duration = 0
            item.trips.map(trips => {
              trips.departing_flight_segments.map(items => {
                duration = duration + items.flight_duration
              })
            })
            item.totalFlightDuration = duration
            testArray.push(item)
          } else {
            let duration = 0
            item.departing_flight_segments.map(subItem => {
              duration = duration + subItem.flight_duration
            })
            item.totalFlightDuration = duration
            testArray.push(item)
          }
        })
        testArray.sort(function(a, b) {
          return a.departing_flight_duration - b.departing_flight_duration
        })
        return state.merge({
          isSearchingFlights: false,
          flights: filteredArr,
          forSortingOnly: filteredArr,
          filters: {
            ...state.filters,
            earlyFlights: earlyFlights[0],
            cheapFlights: cheapFlights[0],
            quickFlights: testArray[0],
            onlyAirline
          },
          borders: {
            cheapFlightBorder: true,
            earlyFlightBorder: false,
            quickFlightBorder: false
          },
          previousfilters: action.payload,
          filtersApplied: true
        })
      } else {
        if (state.selected_view === "departing") {
          let flightsArr = Immutable(state.flightsD).asMutable({ deep: true })
          let filteredArr = []
          flightsArr = flightsArr.map(item => item.departing)
          flightsArr = [].concat.apply([], flightsArr)
          filteredArr = flightsArr.filter(flights => {
            // console.log("flights -------", flights)
            let segment
            let allStops
            let departures
            let tripsLength = []
            let arrays
            let departureArr
            if (flights.trips) {
              departures = flights.trips.map(i => i.departing_flight_segments)
              arrays = departures.map((i, index) => {
                i.map(items => {
                  departureArr = items.stop_airports
                })
                return departureArr
                // if (i[index]) {

                //  && i[index].stop_airports
              })
              departures.map((item, index) => {
                tripsLength.push(item.length + arrays[index].length - 1)
              })

              // segment =
              //   flights.trips[0].departing_flight_segments.length +
              //   flights.trips[0].departing_flight_segments[0].stop_airports.length -
              //   1
              let maximumNum = loadMax(tripsLength)
              segment = maximumNum
            } else {
              segment =
                flights.departing_flight_segments.length +
                flights.departing_flight_segments[0].stop_airports.length -
                1
            }
            if (segment > 2) {
              segment = 2
            }
            return stops.indexOf(segment) !== -1
          })
          // Departure Times : Outbound
          if (outBoundValues !== null) {
            filteredArr = filteredArr.filter(i => {
              let departure = i.trips
                ? i.trips[0].departing_flight_segments[0]
                : i.departing_flight_segments[0]
              let { departureTime } = flightTimings(departure)
              return (
                outBoundValues[0] <= departureTime * 60 &&
                departureTime * 60 <= outBoundValues[1]
              )
            })
          }

          // Departure Times : Return
          if (returnSliderValues !== null) {
            filteredArr = filteredArr.filter(i => {
              let departure = i.trips
                ? i.trips[0].departing_flight_segments[0]
                : i.departing_flight_segments[0]
              let { departureTime } = flightTimings(departure)
              return (
                returnSliderValues[0] <= departureTime * 60 &&
                departureTime * 60 <= returnSliderValues[1]
              )
            })
          }

          // Journey Duration
          if (journeyValue !== null) {
            filteredArr = filteredArr.filter(i => {
              let durationMinutes = i.departing_flight_duration
              // let departure = i.trips
              //   ? i.trips[0].departing_flight_segments
              //   : i.departing_flight_segments
              // let { durationMinutes } = getMajorFlightDetails(departure)
              return durationMinutes <= journeyValue * 60
            })
          }

          // Price Range
          if (priceValue !== null) {
            filteredArr = filteredArr.filter(i => {
              let { amount: amt } = priceItinerary(i.price_itinerary)
              return amt >= priceValue[0] && amt <= priceValue[1]
            })
          }

          // Airlines

          if (airlines !== null) {
            filteredArr = filteredArr.filter(i => {
              let departure = i.trips
                ? i.trips[0].departing_flight_segments[0]
                : i.departing_flight_segments[0]

              return (
                airlines.indexOf(
                  getAirlinesNameCode(departure).code &&
                    getAirlinesNameCode(departure).code
                ) !== -1
              )
            })
          }

          //
          // early flights for sorting
          //
          let earlyFlights = []
          let myFlight = filteredArr
          myFlight.map(item => {
            if (item.trips) {
              let {
                departure_date,
                departure_time
              } = item.trips[0].departing_flight_segments[0]
              let takeOffTime = `${departure_date.year}-${
                departure_date.month
              }-${departure_date.day} ${("0" + departure_time.hour).slice(
                -2
              )}:${("0" + departure_time.minute).slice(-2)}:00`
              // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
              let obj = { ...item, take_off_time: takeOffTime }
              earlyFlights.push(obj)
            } else {
              let {
                departure_date,
                departure_time
              } = item.departing_flight_segments[0]
              let takeOffTime = `${departure_date.year}-${
                departure_date.month
              }-${departure_date.day} ${("0" + departure_time.hour).slice(
                -2
              )}:${("0" + departure_time.minute).slice(-2)}:00`
              // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
              let obj = { ...item, take_off_time: takeOffTime }
              earlyFlights.push(obj)
            }
          })
          // earlyFlights = orderBy(earlyFlights, function(o) {
          //   return new moment(o.take_off_time)
          // })
          earlyFlights.sort(function(a, b) {
            let flightA = a.take_off_time
            let flightB = b.take_off_time
            return flightA < flightB ? -1 : flightA > flightB ? 1 : 0
          })

          // cheap flights
          let cheapFlights = filteredArr
          let newArr = []
          let priceKey = getKeyPriceItinerary(
            cheapFlights.length && cheapFlights[0].price_itinerary
          )
          cheapFlights.map(items => {
            newArr.push(items.price_itinerary[priceKey].amount)
          })
          // cheapFlights.sort(function(a, b) {
          // return (
          // a.price_itinerary[priceKey].amount -
          // b.price_itinerary[priceKey].amount
          // )
          // })
          if (priceValue !== null) {
            filteredArr = filteredArr.sort(function(a, b) {
              return (
                a.price_itinerary[priceKey].amount -
                b.price_itinerary[priceKey].amount
              )
            })
          }
          let cheapFlight = _.min(newArr)
          // quick flights
          let quickFlights = filteredArr
          let testArray = []
          quickFlights.map(item => {
            if (item.trips) {
              let duration = 0
              item.trips.map(trips => {
                trips.departing_flight_segments.map(items => {
                  duration = duration + items.flight_duration
                })
              })
              item.totalFlightDuration = duration
              testArray.push(item)
            } else {
              let duration = 0
              item.departing_flight_segments.map(subItem => {
                duration = duration + subItem.flight_duration
              })
              item.totalFlightDuration = duration
              testArray.push(item)
            }
          })
          testArray.sort(function(a, b) {
            return a.departing_flight_duration - b.departing_flight_duration
          })

          return state.merge({
            isSearchingFlights: false,
            flights: filteredArr,
            splitDeparting: filteredArr,
            forSortingOnlyDeparting: filteredArr,
            filters: {
              ...state.filters,
              onlyAirline,
              earlyFlights: earlyFlights[0],
              cheapFlights: cheapFlights[0],
              quickFlights: testArray[0]
            },
            borders: {
              cheapFlightBorder: true,
              earlyFlightBorder: false,
              quickFlightBorder: false
            },
            previousfilters: action.payload,
            filtersApplied: true
            // splitReturning: []
          })
        } else {
          // debugger
          // returning
          let flightsArr = Immutable(state.flightsD).asMutable({ deep: true })
          let filteredArr = []
          flightsArr = flightsArr.map(item => item.returning)
          flightsArr = [].concat.apply([], flightsArr)
          filteredArr = flightsArr.filter(flights => {
            let segment
            let allStops
            let departures
            let tripsLength = []
            let arrays
            let departureArr
            if (flights.trips) {
              departures = flights.trips.map(i => i.departing_flight_segments)
              arrays = departures.map((i, index) => {
                i.map(items => {
                  departureArr = items.stop_airports
                })
                return departureArr
                // if (i[index]) {

                //  && i[index].stop_airports
              })
              departures.map((item, index) => {
                tripsLength.push(item.length + arrays[index].length - 1)
              })

              // segment =
              //   flights.trips[0].departing_flight_segments.length +
              //   flights.trips[0].departing_flight_segments[0].stop_airports.length -
              //   1
              let maximumNum = loadMax(tripsLength)
              segment = maximumNum
            } else {
              segment =
                flights.departing_flight_segments.length +
                flights.departing_flight_segments[0].stop_airports.length -
                1
            }
            if (segment > 2) {
              segment = 2
            }
            return stops.indexOf(segment) !== -1
          })
          // Departure Times : Outbound
          if (outBoundValues !== null) {
            filteredArr = filteredArr.filter(i => {
              let departure = i.trips
                ? i.trips[0].departing_flight_segments[0]
                : i.departing_flight_segments[0]
              let { departureTime } = flightTimings(departure)
              return (
                outBoundValues[0] <= departureTime * 60 &&
                departureTime * 60 <= outBoundValues[1]
              )
            })
          }
          // Departure Times : Return
          if (returnSliderValues !== null) {
            filteredArr = filteredArr.filter(i => {
              let departure = i.trips
                ? i.trips[0].departing_flight_segments[0]
                : i.departing_flight_segments[0]
              let { departureTime } = flightTimings(departure)
              return (
                returnSliderValues[0] <= departureTime * 60 &&
                departureTime * 60 <= returnSliderValues[1]
              )
            })
          }
          // Journey Duration
          if (journeyValue !== null) {
            filteredArr = filteredArr.filter(i => {
              let durationMinutes = i.departing_flight_duration
              // let departure = i.trips
              //   ? i.trips[0].departing_flight_segments
              //   : i.departing_flight_segments
              // let { durationMinutes } = getMajorFlightDetails(departure)
              return durationMinutes <= journeyValue * 60
            })
          }
          // Price Range
          if (priceValue !== null) {
            filteredArr = filteredArr.filter(i => {
              let { amount: amt } = priceItinerary(i.price_itinerary)
              return amt >= priceValue[0] && amt <= priceValue[1]
            })
          }

          // Airlines
          if (airlines !== null) {
            filteredArr = filteredArr.filter(i => {
              let departure = i.trips
                ? i.trips[0].departing_flight_segments[0]
                : i.departing_flight_segments[0]
              return (
                airlines.indexOf(
                  getAirlinesNameCode(departure).code &&
                    getAirlinesNameCode(departure).code
                ) !== -1
              )
            })
          }
          //
          // early flights for sorting
          //
          let earlyFlights = []
          let myFlight = filteredArr
          myFlight.map(item => {
            if (item.trips) {
              let {
                departure_date,
                departure_time
              } = item.trips[0].departing_flight_segments[0]
              let takeOffTime = `${departure_date.year}-${
                departure_date.month
              }-${departure_date.day} ${("0" + departure_time.hour).slice(
                -2
              )}:${("0" + departure_time.minute).slice(-2)}:00`
              // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
              let obj = { ...item, take_off_time: takeOffTime }
              earlyFlights.push(obj)
            } else {
              let {
                departure_date,
                departure_time
              } = item.departing_flight_segments[0]
              let takeOffTime = `${departure_date.year}-${
                departure_date.month
              }-${departure_date.day} ${("0" + departure_time.hour).slice(
                -2
              )}:${("0" + departure_time.minute).slice(-2)}:00`
              // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
              let obj = { ...item, take_off_time: takeOffTime }
              earlyFlights.push(obj)
            }
          })
          // earlyFlights = orderBy(earlyFlights, function(o) {
          //   return new moment(o.take_off_time)
          // })
          earlyFlights.sort(function(a, b) {
            let flightA = a.take_off_time
            let flightB = b.take_off_time
            return flightA < flightB ? -1 : flightA > flightB ? 1 : 0
          })

          // cheap flights
          let cheapFlights = filteredArr
          let newArr = []
          let priceKey = getKeyPriceItinerary(
            cheapFlights.length && cheapFlights[0].price_itinerary
          )
          cheapFlights.map(items => {
            newArr.push(items.price_itinerary[priceKey].amount)
          })
          // cheapFlights.sort(function(a, b) {
          // return (
          // a.price_itinerary[priceKey].amount -
          // b.price_itinerary[priceKey].amount
          // )
          // })
          if (priceValue !== null) {
            filteredArr = filteredArr.sort(function(a, b) {
              return (
                a.price_itinerary[priceKey].amount -
                b.price_itinerary[priceKey].amount
              )
            })
          }
          let cheapFlight = _.min(newArr)
          // quick flights
          let quickFlights = filteredArr
          let testArray = []
          quickFlights.map(item => {
            if (item.trips) {
              let duration = 0
              item.trips.map(trips => {
                trips.departing_flight_segments.map(items => {
                  duration = duration + items.flight_duration
                })
              })
              item.totalFlightDuration = duration
              testArray.push(item)
            } else {
              let duration = 0
              item.departing_flight_segments.map(subItem => {
                duration = duration + subItem.flight_duration
              })
              item.totalFlightDuration = duration
              testArray.push(item)
            }
          })
          testArray.sort(function(a, b) {
            return a.departing_flight_duration - b.departing_flight_duration
          })

          return state.merge({
            splitReturning: filteredArr,
            flights: filteredArr,
            forSortingOnlyReturning: filteredArr,
            filters: {
              ...state.filters,
              earlyFlights: earlyFlights[0],
              cheapFlights: cheapFlights[0],
              quickFlights: testArray[0],
              onlyAirline
            },
            borders: {
              cheapFlightBorder: true,
              earlyFlightBorder: false,
              quickFlightBorder: false
            },
            previousfilters: action.payload,
            filtersApplied: true
          })
        }
      }
    }
    // SEARCH_STOPS
    case types.SEARCH_STOPS:
      let { stops, type } = action.payload
      let t = []
      let stopsArr = getStopsFilter(stops)
      let fd = Immutable(state.flightsB).asMutable({ deep: true })
      fd = fd.filter(flights => {
        let segment =
          flights.departing_flight_segments.length +
          flights.departing_flight_segments[0].stop_airports.length -
          1
        if (segment > 2) {
          segment = 2
        }
        return stopsArr.indexOf(segment) !== -1
      })
      return state.merge({
        flights: fd
      })
    // PRICE_RANGE
    case types.PRICE_RANGE:
      let fp = Immutable(state.flights).asMutable({ deep: true })
      let newFp = fp.filter(i => {
        let { amount: amt } = priceItinerary(i.price_itinerary)
        if (amt > action.payload[0] && amt < action.payload[1]) {
          return i
        }
      })
      return state.merge({
        flights: newFp,
        minMaxPriceValue: action.payload
      })

    // MIN_MAX_PRICE_RANGE
    case types.MIN_MAX_PRICE_RANGE:
      let flightP = Immutable(state.flights).asMutable({ deep: true })
      let testArray = []
      flightP.map(i => {
        let { amount } = priceItinerary(i.price_itinerary)
        testArray.push(amount)
      })
      let min = _.min(testArray)
      let max = _.max(testArray)
      return state.merge({
        minMaxPriceValue: [
          Math.floor(min / 100) * 100,
          Math.ceil(max / 100) * 100
        ],
        minMaxPriceRange: [
          Math.floor(min / 100) * 100,
          Math.ceil(max / 100) * 100
        ]
      })

    //
    // JOURNEY_DURATION_RANGE
    //
    case types.JOURNEY_DURATION_RANGE:
      let jdr = Immutable(state.flights).asMutable({ deep: true })
      let jdrArray = []
      jdr.map(i => {
        if (i.trips) {
          let { durationMinutes } = getMajorFlightDetails(
            i.trips[0].departing_flight_segments
          )
          jdrArray.push(durationMinutes)
        } else {
          let { durationMinutes } = getMajorFlightDetails(
            i.departing_flight_segments
          )
          jdrArray.push(durationMinutes)
        }
      })
      let minJDR = _.min(jdrArray)
      let maxJDR = _.max(jdrArray)
      return state.merge({
        journeyDurationRange: [Math.floor(minJDR / 60), Math.ceil(maxJDR / 60)]
      })

    //
    // JOURNEY_DURATION
    //
    case types.JOURNEY_DURATION:
      let jd = Immutable(state.flights).asMutable({ deep: true })
      let jdArray = []
      jd.map(i => {
        if (i.trips) {
          let { durationMinutes } = getMajorFlightDetails(
            i.trips[0].departing_flight_segments
          )
          if (durationMinutes <= action.payload * 60) {
            jdArray.push(i)
          }
        } else {
          let { durationMinutes } = getMajorFlightDetails(
            i.departing_flight_segments
          )
          if (durationMinutes <= action.payload * 60) {
            jdArray.push(i)
          }
        }
      })
      return state.merge({
        flights: jdArray
      })

    // AIRLINES_ARRAY
    case types.AIRLINES_ARRAY:
      let ala = Immutable(state.flights).asMutable({ deep: true })
      let alArr = []
      ala.map(i => {
        if (i.trips) {
          let obj = getAirlinesNameCode(i.trips[0].departing_flight_segments[0])
          if (obj) {
            alArr.push(obj)
          }
        } else {
          let obj = getAirlinesNameCode(i.departing_flight_segments[0])
          if (obj) {
            alArr.push(obj)
          }
        }
      })
      _.uniq(alArr)
      return state.merge({
        airlinesArray: _.uniq(alArr)
      })

    // OUTBOUND JOURNEY_TIME
    case types.OUTBOUND_JOURNEY_TIME:
      let flightJT =
        state.flights && Immutable(state.flights).asMutable({ deep: true })
      let testArrayJT = []
      let filteredArray = []
      flightJT.map(i => {
        if (i.trips) {
          let { departureTime } = flightTimings(
            i.trips[0].departing_flight_segments[0]
          )
          if (
            action.payload &&
            (action.payload.value[0] <= departureTime &&
              departureTime <= action.payload.value[1])
          ) {
            filteredArray.push(i)
          }
          testArrayJT.push(departureTime)
        } else {
          let { departureTime } = flightTimings(i.departing_flight_segments[0])
          if (
            action.payload &&
            (action.payload.value[0] <= departureTime &&
              departureTime <= action.payload.value[1])
          ) {
            filteredArray.push(i)
          }
          testArrayJT.push(departureTime)
        }
      })
      let minTime = _.min(testArrayJT)
      let maxTime = _.max(testArrayJT)
      return state.merge({
        outBoundJourneyTime: [minTime, maxTime],
        flights: filteredArray.length === 0 ? flightJT : filteredArray
      })

    // RETURN JOURNEY_TIME
    case types.RETURN_JOURNEY_TIME:
      let flightRJT = Immutable(state.flights).asMutable({ deep: true })
      let testArrayRJT = []
      let filteredArray1 = []
      flightRJT.map(i => {
        if (i.trips) {
          if (i.trips[0].returning_flight_segments) {
            let { returningTime } = getFlightReturnTime(
              i.trips[0].returning_flight_segments[0]
            )
            if (
              action.payload &&
              (action.payload.value[0] <= returningTime &&
                returningTime <= action.payload.value[1])
            ) {
              filteredArray1.push(i)
            }
            testArrayRJT.push(returningTime)
          }
        } else {
          if (i.returning_flight_segments) {
            let { returningTime } = getFlightReturnTime(
              i.returning_flight_segments[0]
            )
            if (
              action.payload &&
              (action.payload.value[0] <= returningTime &&
                returningTime <= action.payload.value[1])
            ) {
              filteredArray1.push(i)
            }
            testArrayRJT.push(returningTime)
          }
        }
      })

      let minRTime = _.min(testArrayRJT)
      let maxRTime = _.max(testArrayRJT)
      return state.merge({
        returnJourneyTime: [minRTime, maxRTime]
      })
    // RESET_FILTER
    case types.RESET_FILTER:
      // debugger
      if (state.split_view === undefined) {
        return state.merge({
          flights: state.flightsB,
          filters: state.filtersB,
          forSortingOnly: state.flightsB,
          isRefreshFilter: !state.isRefreshFilter,
          borders: {
            cheapFlightBorder: true,
            earlyFlightBorder: false,
            quickFlightBorder: false
          },
          filtersApplied: false
        })
      } else {
        if (state.selected_view === "departing") {
          let departing_flights = Immutable(state.flightsD).asMutable({
            deep: true
          })
          let testFlights = departing_flights.map(item => item["departing"])
          testFlights = [].concat.apply([], testFlights)
          let filters = Immutable(state.filtersB).asMutable({
            deep: true
          })
          return state.merge({
            flights: testFlights,
            splitDeparting: testFlights,
            forSortingOnlyDeparting: testFlights,
            filters,
            isRefreshFilter: !state.isRefreshFilter,
            borders: {
              cheapFlightBorder: true,
              earlyFlightBorder: false,
              quickFlightBorder: false
            },
            filtersApplied: false
          })
        } else {
          let returning_flights = Immutable(state.flightsD).asMutable({
            deep: true
          })
          let testFlights = returning_flights.map(item => item["returning"])

          testFlights = [].concat.apply([], testFlights)
          return state.merge({
            flights: testFlights,
            splitReturning: testFlights,
            filters: state.filtersB,
            forSortingOnlyReturning: testFlights,
            isRefreshFilter: !state.isRefreshFilter,
            borders: {
              cheapFlightBorder: true,
              earlyFlightBorder: false,
              quickFlightBorder: false
            },
            filtersApplied: false
          })
        }
      }

    case types.CHEAPEST_FLIGHTS: {
      let temp
      // const { flights } = state
      // let flightsM = Immutable(flights).asMutable({ deep: true })

      if (state.split_view === undefined) {
        temp = Immutable(state.forSortingOnly).asMutable({ deep: true })
      } else if (
        state.split_view === true &&
        state.selected_view === "departing"
      ) {
        temp = Immutable(state.forSortingOnlyDeparting).asMutable({
          deep: true
        })
      } else {
        temp = Immutable(state.forSortingOnlyReturning).asMutable({
          deep: true
        })
      }

      let priceKey = getKeyPriceItinerary(
        temp.length && temp[0].price_itinerary
      )
      temp.sort(function(a, b) {
        return (
          a.price_itinerary[priceKey].amount -
          b.price_itinerary[priceKey].amount
        )
      })
      if (state.split_view === undefined) {
        return state.merge({
          flights: temp,
          cheapFlights: temp[0],
          borders: {
            cheapFlightBorder: true,
            earlyFlightBorder: false,
            quickFlightBorder: false
          },
          sortBarType: "cheapest"
        })
      } else {
        if (state.selected_view === "departing") {
          return state.merge({
            flights: temp,
            splitDeparting: temp,
            cheapFlights: temp[0],
            borders: {
              cheapFlightBorder: true,
              earlyFlightBorder: false,
              quickFlightBorder: false
            },
            sortBarType: "cheapest"
          })
        } else {
          return state.merge({
            flights: temp,
            splitReturning: temp,
            cheapFlights: temp[0],
            borders: {
              cheapFlightBorder: true,
              earlyFlightBorder: false,
              quickFlightBorder: false
            },
            sortBarType: "cheapest"
          })
        }
      }
    }
    // EARLIEST_FLIGHTS
    case types.EARLIEST_FLIGHTS: {
      let tempArr
      if (state.split_view === undefined) {
        tempArr = Immutable(state.forSortingOnly).asMutable({ deep: true })
      } else if (
        state.split_view === true &&
        state.selected_view === "departing"
      ) {
        tempArr = Immutable(state.forSortingOnlyDeparting).asMutable({
          deep: true
        })
      } else {
        tempArr = Immutable(state.forSortingOnlyReturning).asMutable({
          deep: true
        })
      }

      let flightsArr = []
      tempArr.map(item => {
        if (item.trips) {
          let {
            departure_date,
            departure_time
          } = item.trips[0].departing_flight_segments[0]
          let takeOffTime = `${departure_date.year}-${departure_date.month}-${
            departure_date.day
          } ${("0" + departure_time.hour).slice(-2)}:${(
            "0" + departure_time.minute
          ).slice(-2)}:00`
          // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
          let obj = { ...item, take_off_time: takeOffTime }
          flightsArr.push(obj)
        } else {
          let {
            departure_date,
            departure_time
          } = item.departing_flight_segments[0]
          let takeOffTime = `${departure_date.year}-${departure_date.month}-${
            departure_date.day
          } ${("0" + departure_time.hour).slice(-2)}:${(
            "0" + departure_time.minute
          ).slice(-2)}:00`
          // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
          let obj = { ...item, take_off_time: takeOffTime }
          flightsArr.push(obj)
        }
      })
      flightsArr = orderBy(flightsArr, function(o) {
        return new moment(o.take_off_time)
      })
      if (state.split_view === undefined) {
        return state.merge({
          flights: flightsArr,
          earlyFlights: flightsArr[0],
          // cheapFlights: cheapFlights[0],
          // quickFlights: testArray[0],
          borders: {
            cheapFlightBorder: false,
            earlyFlightBorder: true,
            quickFlightBorder: false
          },
          sortBarType: "earliest"
        })
      } else {
        if (state.selected_view === "departing") {
          return state.merge({
            flights: flightsArr,
            earlyFlights: flightsArr[0],
            splitDeparting: flightsArr,
            borders: {
              cheapFlightBorder: false,
              earlyFlightBorder: true,
              quickFlightBorder: false
            },
            sortBarType: "earliest"
          })
        } else {
          return state.merge({
            flights: flightsArr,
            splitReturning: flightsArr,
            earlyFlights: flightsArr[0],
            borders: {
              cheapFlightBorder: false,
              earlyFlightBorder: true,
              quickFlightBorder: false
            },
            sortBarType: "earliest"
          })
        }
      }
    }
    // QUICKEST_FLIGHTS
    case types.QUICKEST_FLIGHTS:
      // const flightsQ = state.flights
      // let tempQ = Immutable(state.forSortingOnly).asMutable({ deep: true })
      let tempQ
      if (state.split_view === undefined) {
        tempQ = Immutable(state.forSortingOnly).asMutable({ deep: true })
      } else if (
        state.split_view === true &&
        state.selected_view === "departing"
      ) {
        tempQ = Immutable(state.forSortingOnlyDeparting).asMutable({
          deep: true
        })
      } else {
        tempQ = Immutable(state.forSortingOnlyReturning).asMutable({
          deep: true
        })
      }
      tempQ.sort(function(a, b) {
        return a.departing_flight_duration - b.departing_flight_duration
      })

      if (state.split_view === undefined) {
        return state.merge({
          flights: tempQ,
          quickFlights: tempQ[0],
          borders: {
            cheapFlightBorder: false,
            earlyFlightBorder: false,
            quickFlightBorder: true
          },
          sortBarType: "quickest"
        })
      } else {
        if (state.selected_view === "departing") {
          return state.merge({
            flights: tempQ,
            splitDeparting: tempQ,
            quickFlights: tempQ[0],
            borders: {
              cheapFlightBorder: false,
              earlyFlightBorder: false,
              quickFlightBorder: true
            },
            sortBarType: "quickest"
          })
        } else {
          return state.merge({
            flights: tempQ,
            splitReturning: tempQ,
            quickFlights: tempQ[0],
            borders: {
              cheapFlightBorder: false,
              earlyFlightBorder: false,
              quickFlightBorder: true
            },
            sortBarType: "quickest"
          })
        }
      }

    // SELECTED_FLIGHT_DATA
    case types.SELECTED_FLIGHT_DATA:
      return state.merge({
        selectedFlight: action.payload.selectedFlight,
        selectedFlightB: action.payload.selectedFlight,
        queryDetails: action.payload.queryDetails,
        progressValue: state.progressValue + 1
      })
    // MODIFY_SELECTED_FLIGHT_DATA
    case types.MODIFY_SELECTED_FLIGHT_DATA:
      let { selectedPrice, price_mismatch_data } = action.payload
      let { mismatch_type } = price_mismatch_data
      let sfd = Immutable(state.selectedFlight).asMutable({ deep: true })
      
      if (mismatch_type === "leaving") {
        sfd.price_itinerary[selectedPrice.cabin_class].amount =
          selectedPrice.sum

        sfd.price_itinerary[
          selectedPrice.cabin_class
        ].fare_breakdown.adult.price = selectedPrice.sum
        sfd.meta = selectedPrice.meta
      } else if (mismatch_type === "both") {
        sfd.price_itinerary[selectedPrice.cabin_class].amount =
          selectedPrice.sum

        sfd.price_itinerary[
          selectedPrice.cabin_class
        ].fare_breakdown.adult.price = selectedPrice.sum

        sfd.price_itinerary[selectedPrice.cabin_class].departing =
          selectedPrice.price
        sfd.price_itinerary[selectedPrice.cabin_class].returning =
          selectedPrice.return_price
        sfd.meta = selectedPrice.meta
      }
      return state.merge({
        selectedFlight: sfd
      })

    //
    // MODIFY_FARE_BREAKDOWN
    //
    case types.MODIFY_FARE_BREAKDOWN:
      let sfd2 = Immutable(state.selectedFlight).asMutable({ deep: true })
      let cabin
      cabinData.map(item => {
        if (item.value === state.queryDetails.cabin_class) {
          cabin = item.label.toLowerCase()
        }
      })
      sfd2.price_itinerary[cabin].fare_breakdown.total = action.payload.total
      return state.merge({
        selectedFlight: sfd2
      })

    //
    // RESERVE_FLIGHT
    //
    case types.RESERVE_FLIGHT_REQUEST:
      return state.merge({
        isReservingFlights: true,
        overlay: true
      })
    case types.RESERVE_FLIGHT_SUCCESS:
      return state.merge({
        isReservingFlights: false,
        identifier: action.payload.identifier,
        overlay: action.payload.success
      })

    case types.RESERVE_FLIGHT_ERROR:
      return state.merge({
        isReservingFlights: false,
        overlay: false
      })

    //
    // CHECK_BOOKING_STATUS
    //
    case types.CHECK_BOOKING_STATUS_REQUEST:
      return state.merge({
        isCheckBookingStatus: true,
        overlay: true
      })
    case types.CHECK_BOOKING_STATUS_SUCCESS:
      return state.merge({
        isCheckBookingStatus: false,
        pnr: action.payload.pnr,
        checkBookingStatus: action.payload.status,
        overlay: true
      })
    case types.CHECK_BOOKING_STATUS_ERROR:
      return state.merge({
        isCheckBookingStatus: false,
        pnr: null,
        checkBookingStatus: null,
        overlay: false
      })
    //
    // GET_SINGLE_RESERVATION
    //
    case types.GET_SINGLE_RESERVATION_REQUEST:
      return state.merge({
        isGetSingleReservation: true,
        overlay: true
      })
    case types.GET_SINGLE_RESERVATION_SUCCESS: {
      return state.merge({
        isGetSingleReservation: false,
        testReserved: action.payload,
        pnr: null,
        identifier: null,
        checkBookingStatus: null,
        overlay: false
      })
    }
    case types.GET_SINGLE_RESERVATION_ERROR:
      return state.merge({
        isGetSingleReservation: false,
        overlay: false
      })
    case types.RESERVE_FLIGHT_STATUS_FAILED:
      return state.merge({
        isReservingFlights: false,
        overlay: false
      })
    case types.CHECK_BOOKING_STATUS_FAILED:
      return state.merge({
        isCheckBookingStatus: false,
        pnr: null,
        checkBookingStatus: null,
        overlay: false
      })
    case types.GET_SINGLE_RESERVATION_FAILED:
      return state.merge({
        isGetSingleReservation: false,
        overlay: false
      })

    //
    // TOTAL_PRICE_FARE_BREAKDOWN
    //
    case types.TOTAL_PRICE_FARE_BREAKDOWN_REQUEST:
      return state.merge({
        isTotalPriceNull: true
      })
    case types.TOTAL_PRICE_FARE_BREAKDOWN_SUCCESS: {
      let {
        success,
        data,
        mismatch_type: mismatchType,
        prices,
        price_mismatch
      } = action.payload
      let sfd = Immutable(state.selectedFlight).asMutable({ deep: true })

      if (mismatch_type === "leaving") {
        sfd.price_itinerary[selectedPrice.cabin_class].amount =
          selectedPrice.sum

        sfd.price_itinerary[
          selectedPrice.cabin_class
        ].fare_breakdown.adult.price = selectedPrice.sum
      } else if (mismatch_type === "both") {
        sfd.price_itinerary[selectedPrice.cabin_class].amount =
          selectedPrice.sum

        sfd.price_itinerary[
          selectedPrice.cabin_class
        ].fare_breakdown.adult.price = selectedPrice.sum

        sfd.price_itinerary[selectedPrice.cabin_class].departing =
          selectedPrice.price
        sfd.price_itinerary[selectedPrice.cabin_class].returning =
          selectedPrice.return_price
      }

      return state.merge({
        selectedFlight: sfd,
        isTotalPriceNull: false
      })
    }
    case types.TOTAL_PRICE_FARE_BREAKDOWN_ERROR:
      return state.merge({
        isTotalPriceNull: false
      })

    // TOGGLE_OVERLAY
    case types.TOGGLE_OVERLAY:
      return state.merge({
        overlay: false
      })
    // RETURNING_FILTERS
    // RETURNING_FILTERS
    // RETURNING_FILTERS
    // RETURNING_FILTERS
    // RETURNING_FILTERS
    case types.RETURNING_FILTERS: {
      let trip_type = action.payload.query.trip_type
      let flightsArray = Immutable(state.flightsD).asMutable({ deep: true })
      // flightsArray = flightsArray.concat(state.flightsD)
      // let testFlights = flightsArray[action.payload.type]
      let testFlights = flightsArray.map(item => item[action.payload.type])
      testFlights = [].concat.apply([], testFlights)
      // let myFlightsArr = flightsArray[action.payload.type]
      let myFlightsArr = flightsArray.map(item => item[action.payload.type])
      myFlightsArr = [].concat.apply([], testFlights)
      // let splitView = action.payload.split_view
      let stop = { direct: "", oneStop: "", twoPlusStops: "" }
      let airlinesArr = []
      let journeyDuration = { min: "", max: "" }
      let priceRange = { min: "", max: "" }
      if (action.payload.type === "returning") {
        testFlights.length &&
          testFlights.forEach(flight => {
            let departures
            let tripsLength = []
            let arrays
            let departureArr
            let maximumNum
            if (flight.trips) {
              departures = flight.trips.map(i => i.departing_flight_segments)
              arrays = departures.map((i, index) => {
                i.map(items => {
                  departureArr = items.stop_airports
                })
                return departureArr
              })
              departures.map((item, index) => {
                tripsLength.push(item.length + arrays[index].length - 1)
              })

              maximumNum = loadMax(tripsLength)
            }

            let departure = flight.trips
              ? flight.trips[0].departing_flight_segments
              : flight.departing_flight_segments
            // STOPS
            let segment = flight.trips
              ? maximumNum
              : departure.length + departure[0].stop_airports.length - 1
            // let segment = maximumNum
            let price = priceItinerary(flight.price_itinerary)
            if (segment === 0) {
              stop.direct = stop.direct
                ? Math.min(stop.direct, price.amount)
                : price.amount
            } else if (segment === 1) {
              stop.oneStop = stop.oneStop
                ? Math.min(stop.oneStop, price.amount)
                : price.amount
            } else if (segment >= 2) {
              stop.twoPlusStops = stop.twoPlusStops
                ? Math.min(stop.twoPlusStops, price.amount)
                : price.amount
            }
            // Journey Duration
            // let { durationMinutes } = getMajorFlightDetails(departure)
            // journeyDuration.min = journeyDuration.min
            //   ? Math.min(journeyDuration.min, durationMinutes)
            //   : durationMinutes
            // journeyDuration.max = journeyDuration.max
            //   ? Math.max(journeyDuration.max, durationMinutes)
            //   : durationMinutes
            let durationMinutes = flight.departing_flight_duration
            journeyDuration.min = journeyDuration.min
              ? Math.min(journeyDuration.min, durationMinutes)
              : durationMinutes
            journeyDuration.max = journeyDuration.max
              ? Math.max(journeyDuration.max, durationMinutes)
              : durationMinutes
            // Airlines
            let obj = getAirlinesNameCode(departure[0])
            let airlinesIndex
            if (obj) {
              airlinesIndex = airlinesArr.findIndex(
                airlines => airlines.code === obj.code
              )
              if (airlinesIndex === -1) {
                obj.price = price.amount
                airlinesArr.push(obj)
              } else {
                if (airlinesArr[airlinesIndex].price > price.amount) {
                  airlinesArr[airlinesIndex].price = price.amount
                }
              }
            }
            // Price Range
            priceRange.min = priceRange.min
              ? Math.min(priceRange.min, price.amount)
              : price.amount
            priceRange.max = priceRange.max
              ? Math.max(priceRange.min, price.amount)
              : price.amount
          })
        journeyDuration = Object.assign(
          {},
          {
            min: Math.floor(journeyDuration.min / 60),
            max: Math.ceil(journeyDuration.max / 60)
          }
        )
        priceRange = Object.assign(
          {},
          {
            min: Math.floor(priceRange.min / 100) * 100,
            max: Math.ceil(priceRange.max / 100) * 100
          }
        )
        //
        // early flights for sorting
        //
        let earlyFlights = []
        let myFlight = myFlightsArr
        myFlight.map(item => {
          if (item.trips) {
            let {
              departure_date,
              departure_time
            } = item.trips[0].departing_flight_segments[0]
            let takeOffTime = `${departure_date.year}-${departure_date.month}-${
              departure_date.day
            } ${("0" + departure_time.hour).slice(-2)}:${(
              "0" + departure_time.minute
            ).slice(-2)}:00`
            // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
            let obj = { ...item, take_off_time: takeOffTime }
            earlyFlights.push(obj)
          } else {
            let {
              departure_date,
              departure_time
            } = item.departing_flight_segments[0]
            let takeOffTime = `${departure_date.year}-${departure_date.month}-${
              departure_date.day
            } ${("0" + departure_time.hour).slice(-2)}:${(
              "0" + departure_time.minute
            ).slice(-2)}:00`
            // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
            let obj = { ...item, take_off_time: takeOffTime }
            earlyFlights.push(obj)
          }
        })
        // earlyFlights = orderBy(earlyFlights, function(o) {
        //   return new moment(o.take_off_time)
        // })
        earlyFlights.sort(function(a, b) {
          let flightA = a.take_off_time
          let flightB = b.take_off_time
          return flightA < flightB ? -1 : flightA > flightB ? 1 : 0
        })

        // cheap flights
        let cheapFlights = myFlightsArr
        let newArr = []
        let priceKey = getKeyPriceItinerary(
          cheapFlights.length && cheapFlights[0].price_itinerary
        )
        cheapFlights.map(items => {
          newArr.push(items.price_itinerary[priceKey].amount)
        })
        cheapFlights.sort(function(a, b) {
          return (
            a.price_itinerary[priceKey].amount -
            b.price_itinerary[priceKey].amount
          )
        })
        testFlights = cheapFlights.sort(function(a, b) {
          return (
            a.price_itinerary[priceKey].amount -
            b.price_itinerary[priceKey].amount
          )
        })
        let cheapFlight = _.min(newArr)
        // quick flights
        let quickFlights = myFlightsArr
        let testArray = []
        quickFlights.map(item => {
          if (item.trips) {
            let duration = 0
            item.trips.map(trips => {
              trips.departing_flight_segments.map(items => {
                duration = duration + items.flight_duration
              })
            })
            item.totalFlightDuration = duration
            testArray.push(item)
          } else {
            let duration = 0
            item.departing_flight_segments.map(subItem => {
              duration = duration + subItem.flight_duration
            })
            item.totalFlightDuration = duration
            testArray.push(item)
          }
        })

        testArray.sort(function(a, b) {
          return a.departing_flight_duration - b.departing_flight_duration
        })
        let result
        if (state.sortBarType === "cheapest") {
          result = testFlights
        }
        if (state.sortBarType === "earliest") {
          result = earlyFlights
        }
        if (state.sortBarType === "quickest") {
          result = testArray
        }
        return state.merge({
          flights: result,
          // flightsB: testFlights,
          splitReturning: result,
          forSortingOnlyReturning: result,
          selected_view: "returning",
          filters: {
            stop,
            priceRange,
            airlinesArr,
            journeyDuration,
            earlyFlights: earlyFlights[0],
            cheapFlights: cheapFlights[0],
            quickFlights: testArray[0],
            trip_type
          }
          // borders: {
          //   cheapFlightBorder: true,
          //   earlyFlightBorder: false,
          //   quickFlightBorder: false
          // }
        })
      } else {
        testFlights.forEach(flight => {
          let departures
          let tripsLength = []
          let arrays
          let departureArr
          let maximumNum
          if (flight.trips) {
            departures = flight.trips.map(i => i.departing_flight_segments)
            arrays = departures.map((i, index) => {
              i.map(items => {
                departureArr = items.stop_airports
              })
              return departureArr
            })
            departures.map((item, index) => {
              tripsLength.push(item.length + arrays[index].length - 1)
            })

            maximumNum = loadMax(tripsLength)
          }

          let departure = flight.trips
            ? flight.trips[0].departing_flight_segments
            : flight.departing_flight_segments
          // STOPS
          let segment = flight.trips
            ? maximumNum
            : departure.length + departure[0].stop_airports.length - 1
          // let segment = maximumNum
          let price = priceItinerary(flight.price_itinerary)
          if (segment === 0) {
            stop.direct = stop.direct
              ? Math.min(stop.direct, price.amount)
              : price.amount
          } else if (segment === 1) {
            stop.oneStop = stop.oneStop
              ? Math.min(stop.oneStop, price.amount)
              : price.amount
          } else if (segment >= 2) {
            stop.twoPlusStops = stop.twoPlusStops
              ? Math.min(stop.twoPlusStops, price.amount)
              : price.amount
          }
          // Journey Duration
          // let { durationMinutes } = getMajorFlightDetails(departure)
          // journeyDuration.min = journeyDuration.min
          //   ? Math.min(journeyDuration.min, durationMinutes)
          //   : durationMinutes
          // journeyDuration.max = journeyDuration.max
          //   ? Math.max(journeyDuration.max, durationMinutes)
          //   : durationMinutes
          let durationMinutes = flight.departing_flight_duration
          journeyDuration.min = journeyDuration.min
            ? Math.min(journeyDuration.min, durationMinutes)
            : durationMinutes
          journeyDuration.max = journeyDuration.max
            ? Math.max(journeyDuration.max, durationMinutes)
            : durationMinutes
          // Airlines
          let obj = getAirlinesNameCode(departure[0])
          let airlinesIndex
          if (obj) {
            airlinesIndex = airlinesArr.findIndex(
              airlines => airlines.code === obj.code
            )
            if (airlinesIndex === -1) {
              obj.price = price.amount
              airlinesArr.push(obj)
            } else {
              if (airlinesArr[airlinesIndex].price > price.amount) {
                airlinesArr[airlinesIndex].price = price.amount
              }
            }
          }
          // Price Range
          priceRange.min = priceRange.min
            ? Math.min(priceRange.min, price.amount)
            : price.amount
          priceRange.max = priceRange.max
            ? Math.max(priceRange.min, price.amount)
            : price.amount
        })
        journeyDuration = Object.assign(
          {},
          {
            min: Math.floor(journeyDuration.min / 60),
            max: Math.ceil(journeyDuration.max / 60)
          }
        )
        priceRange = Object.assign(
          {},
          {
            min: Math.floor(priceRange.min / 100) * 100,
            max: Math.ceil(priceRange.max / 100) * 100
          }
        )
        //
        // early flights for sorting
        //
        let earlyFlights = []
        let myFlight = myFlightsArr
        myFlight.map(item => {
          if (item.trips) {
            let {
              departure_date,
              departure_time
            } = item.trips[0].departing_flight_segments[0]
            let takeOffTime = `${departure_date.year}-${departure_date.month}-${
              departure_date.day
            } ${("0" + departure_time.hour).slice(-2)}:${(
              "0" + departure_time.minute
            ).slice(-2)}:00`
            // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
            let obj = { ...item, take_off_time: takeOffTime }
            earlyFlights.push(obj)
          } else {
            let {
              departure_date,
              departure_time
            } = item.departing_flight_segments[0]
            let takeOffTime = `${departure_date.year}-${departure_date.month}-${
              departure_date.day
            } ${("0" + departure_time.hour).slice(-2)}:${(
              "0" + departure_time.minute
            ).slice(-2)}:00`
            // let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
            let obj = { ...item, take_off_time: takeOffTime }
            earlyFlights.push(obj)
          }
        })
        // earlyFlights = orderBy(earlyFlights, function(o) {
        //   return new moment(o.take_off_time)
        // })
        earlyFlights.sort(function(a, b) {
          let flightA = a.take_off_time
          let flightB = b.take_off_time
          return flightA < flightB ? -1 : flightA > flightB ? 1 : 0
        })

        // cheap flights
        let cheapFlights = myFlightsArr
        let newArr = []
        let priceKey = getKeyPriceItinerary(
          cheapFlights.length && cheapFlights[0].price_itinerary
        )
        cheapFlights.map(items => {
          newArr.push(items.price_itinerary[priceKey].amount)
        })
        cheapFlights.sort(function(a, b) {
          return (
            a.price_itinerary[priceKey].amount -
            b.price_itinerary[priceKey].amount
          )
        })
        testFlights = cheapFlights.sort(function(a, b) {
          return (
            a.price_itinerary[priceKey].amount -
            b.price_itinerary[priceKey].amount
          )
        })
        let cheapFlight = _.min(newArr)
        // quick flights
        let quickFlights = myFlightsArr
        let testArray = []
        quickFlights.map(item => {
          if (item.trips) {
            let duration = 0
            item.trips.map(trips => {
              trips.departing_flight_segments.map(items => {
                duration = duration + items.flight_duration
              })
            })
            item.totalFlightDuration = duration
            testArray.push(item)
          } else {
            let duration = 0
            item.departing_flight_segments.map(subItem => {
              duration = duration + subItem.flight_duration
            })
            item.totalFlightDuration = duration
            testArray.push(item)
          }
        })
        testArray.sort(function(a, b) {
          return a.departing_flight_duration - b.departing_flight_duration
        })
        let result
        if (state.sortBarType === "cheapest") {
          result = testFlights
        }
        if (state.sortBarType === "earliest") {
          result = earlyFlights
        }
        if (state.sortBarType === "quickest") {
          result = testArray
        }
        return state.merge({
          flights: result,
          // flightsB: testFlights,
          forSortingOnlyDeparting: result,
          splitDeparting: result,
          selected_view: "departing",
          filters: {
            stop,
            priceRange,
            airlinesArr,
            journeyDuration,
            earlyFlights: earlyFlights[0],
            cheapFlights: cheapFlights[0],
            quickFlights: testArray[0],
            trip_type
          }
          // borders: {
          //   cheapFlightBorder: true,
          //   earlyFlightBorder: false,
          //   quickFlightBorder: false
          // }
        })
      }
    }
    case types.SELECTED_DEPARTING_FLIGHT:
      return state.merge({
        split_departing_flight: action.payload.departing_flight.selectedFlight
      })

    default:
      return state
  }
}

// Actions
export const actions = {
  // RESET_FLIGHT_STATE
  resetFlightState: () => ({
    type: types.RESET_FLIGHT_STATE
  }),

  // SEARCH_FLIGHTS
  //
  searchFlightsRequest: ({ depDateForFilters, minDateForFilters, returnDateForFilters }) => ({
    type: types.SEARCH_FLIGHTS_REQUEST,
    payload: { depDateForFilters, minDateForFilters, returnDateForFilters }
  }),
  searchFlightsSuccess: ({ data, trip_type, split_view }) => ({
    type: types.SEARCH_FLIGHTS_SUCCESS,
    payload: { data, trip_type, split_view }
  }),
  searchFlightsError: () => ({
    type: types.SEARCH_FLIGHTS_ERROR
  }),

  // SEARCH_FLIGHTS_PROGRESS_SEARCH_LIST_VENDORS_ARRAY
  //
  searchFlightsProgressSearchRequest: () => ({
    type: types.SEARCH_FLIGHTS_PROGRESS_SEARCH_LIST_REQUEST
  }),
  searchFlightsProgressSearchSuccess: ({ data, trip_type, split_view }) => ({
    type: types.SEARCH_FLIGHTS_PROGRESS_SEARCH_LIST_SUCCESS,
    payload: { data, trip_type, split_view }
  }),
  searchFlightsProgressSearchError: () => ({
    type: types.SEARCH_FLIGHTS_PROGRESS_SEARCH_LIST_ERROR
  }),

  // SEARCH_FLIGHTS_PROGRESS_RESULTS
  //
  searchFlightsProgressRequest: ({ depDateForFilters, minDateForFilters, returnDateForFilters }) => ({
    type: types.SEARCH_FLIGHTS_PROGRESS_SEARCH_REQUEST,
    payload: { depDateForFilters, minDateForFilters, returnDateForFilters }
  }),
  searchFlightsProgressSuccess: ({
    data,
    trip_type,
    split_view,
    success,
    pagination
  }) => ({
    type: types.SEARCH_FLIGHTS_PROGRESS_SEARCH_SUCCESS,
    payload: { data, trip_type, split_view, success, pagination }
  }),
  searchFlightsProgressError: () => ({
    type: types.SEARCH_FLIGHTS_PROGRESS_SEARCH_ERROR
  }),

  // SEARCH_FLIGHTS_PROGRESS_RESULTS_FOR_PAGINATION
  //
  searchFlightsProgressPaginationRequest: ({
    depDateForFilters,
    minDateForFilters,
    returnDateForFilters
  }) => ({
    type: types.SEARCH_FLIGHTS_PROGRESS_SEARCH_PAGINATION_REQUEST,
    payload: { depDateForFilters, minDateForFilters, returnDateForFilters }
  }),
  searchFlightsProgressPaginationSuccess: ({
    data,
    trip_type,
    split_view,
    success,
    pagination
  }) => ({
    type: types.SEARCH_FLIGHTS_PROGRESS_SEARCH_PAGINATION_SUCCESS,
    payload: { data, trip_type, split_view, success, pagination }
  }),
  searchFlightsProgressPaginationError: () => ({
    type: types.SEARCH_FLIGHTS_PROGRESS_SEARCH_PAGINATION_ERROR
  }),

  // SEARCH_STOPS
  searchStops: ({ stops, type }) => ({
    type: types.SEARCH_STOPS,
    payload: { stops, type }
  }),
  // PRICE_RANGE
  priceRange: value => ({
    type: types.PRICE_RANGE,
    payload: value
  }),
  // MIN_MAX_PRICE_RANGE
  minMaxPriceRange: () => ({
    type: types.MIN_MAX_PRICE_RANGE
  }),
  // JOURNEY_DURATION_RANGE
  journeyDurationsRange: () => ({
    type: types.JOURNEY_DURATION_RANGE
  }),
  // JOURNEY_DURATION
  journeyDuration: value => ({
    type: types.JOURNEY_DURATION,
    payload: value
  }),
  // AIRLINES_ARRAY
  getAirlinesArray: () => ({
    type: types.AIRLINES_ARRAY
  }),
  // APPLY_FILTERS
  applyFiltersRequest: ({
    stops,
    journeyValue,
    airlines,
    priceValue,
    outBoundValues,
    returnSliderValues,
    applyFilter,
    onlyAirline
  }) => ({
    type: types.APPLY_FILTERS,
    payload: {
      stops,
      journeyValue,
      airlines,
      priceValue,
      outBoundValues,
      returnSliderValues,
      applyFilter,
      onlyAirline
    }
  }),
  // OUTBOUND_JOURNEY_TIME
  outBoundJourneyTime: value => ({
    type: types.OUTBOUND_JOURNEY_TIME,
    payload: value
  }),
  // RETURN_JOURNEY_TIME
  returnJourneyTime: value => ({
    type: types.RETURN_JOURNEY_TIME,
    payload: value
  }),
  // RESET FILTER
  resetFilters: () => ({
    type: types.RESET_FILTER
  }),
  // CHEAPEST FILTER
  cheapestFlights: () => ({
    type: types.CHEAPEST_FLIGHTS
  }),
  // EARLIEST FILTER
  earliestFlights: () => ({
    type: types.EARLIEST_FLIGHTS
  }),
  // QUICKEST FILTER
  quickestFlights: () => ({
    type: types.QUICKEST_FLIGHTS
  }),
  // SELECTED_FLIGHT_DATA
  selectedFlight: ({ selectedFlight, queryDetails }) => ({
    type: types.SELECTED_FLIGHT_DATA,
    payload: { selectedFlight, queryDetails }
  }),

  // MODIFY_SELECTED_FLIGHT_DATA
  modifySelectedFlightData: ({ selectedPrice, price_mismatch_data }) => ({
    type: types.MODIFY_SELECTED_FLIGHT_DATA,
    payload: { selectedPrice, price_mismatch_data }
  }),
  // PAGE_REFRESH
  pageRefresh: () => ({
    type: types.PAGE_REFRESH
  }),
  // MODIFY_FARE_BREAKDOWN
  modifyFareBreakDown: data => ({
    type: types.MODIFY_FARE_BREAKDOWN,
    payload: data
  }),
  // RESERVE_FLIGHT
  reserveFlightRequest: () => ({
    type: types.RESERVE_FLIGHT_REQUEST
  }),
  reserveFlightSuccess: ({ identifier, success }) => ({
    type: types.RESERVE_FLIGHT_SUCCESS,
    payload: { identifier, success }
  }),
  reserveFlightError: () => ({
    type: types.RESERVE_FLIGHT_ERROR
  }),
  // CHECK_BOOKING_STATUS
  checkBookingStatusRequest: () => ({
    type: types.CHECK_BOOKING_STATUS_REQUEST
  }),
  checkBookingStatusSuccess: ({
    pnr,
    status,
    price_mismatch,
    price_mismatch_data
  }) => ({
    type: types.CHECK_BOOKING_STATUS_SUCCESS,
    payload: { pnr, status, price_mismatch, price_mismatch_data }
  }),
  checkBookingStatusError: () => ({
    type: types.CHECK_BOOKING_STATUS_ERROR
  }),
  // GET_SINGLE_RESERVATION
  getSingleReservationRequest: () => ({
    type: types.GET_SINGLE_RESERVATION_REQUEST
  }),
  getSingleReservationSuccess: ({ data }) => ({
    type: types.GET_SINGLE_RESERVATION_SUCCESS,
    payload: data
  }),
  getSingleReservationError: () => ({
    type: types.GET_SINGLE_RESERVATION_ERROR
  }),
  reserveFlightStatusFailed: () => ({
    type: types.RESERVE_FLIGHT_STATUS_FAILED
  }),
  checkBookingStatusFailed: () => ({
    type: types.CHECK_BOOKING_STATUS_FAILED
  }),
  getSingleReservationFailed: () => ({
    type: types.GET_SINGLE_RESERVATION_FAILED
  }),
  //
  // TOTAL_PRICE_FARE_BREAKDOWN
  //
  totalPriceFareBreakDownRequest: () => ({
    type: types.TOTAL_PRICE_FARE_BREAKDOWN_REQUEST
  }),
  totalPriceFareBreakDownSuccess: data => ({
    type: types.TOTAL_PRICE_FARE_BREAKDOWN_SUCCESS,
    payload: data
  }),
  totalPriceFareBreakDownError: () => ({
    type: types.TOTAL_PRICE_FARE_BREAKDOWN_ERROR
  }),
  // TOGGLE_OVERLAY
  toggleOverlay: () => ({
    type: types.TOGGLE_OVERLAY
  }),
  // RETURNING_FILTERS
  returningFilters: ({ type, query }) => ({
    type: types.RETURNING_FILTERS,
    payload: { type, query }
  }),
  selectedDepartingFlight: ({ departing_flight }) => ({
    type: types.SELECTED_DEPARTING_FLIGHT,
    payload: { departing_flight }
  })
}

// Selectors
const searchResultSelector = () => state => {
  return state.searchResult
}

const selectIsSearchingFlights = () =>
  createSelector(
    searchResultSelector(),
    state => {
      return state.isSearchingFlights
    }
  )

const selectFlights = () =>
  createSelector(
    searchResultSelector(),
    state => state.flights
  )
const selectMinMaxPriceRange = () =>
  createSelector(
    searchResultSelector(),
    state => state.minMaxPriceRange
  )
const selectMinMaxPriceValue = () =>
  createSelector(
    searchResultSelector(),
    state => state.minMaxPriceValue
  )

const getBorders = () =>
  createSelector(
    searchResultSelector(),
    state => state.borders
  )

const selectJourneyDurationRange = () =>
  createSelector(
    searchResultSelector(),
    state => state.journeyDurationRange
  )
const selectAirlinesArray = () =>
  createSelector(
    searchResultSelector(),
    state => state.airlinesArray
  )
const selectAppliedFilters = () =>
  createSelector(
    searchResultSelector(),
    state => state.filters
  )
const selectJourneyTime = () =>
  createSelector(
    searchResultSelector(),
    state => state.outBoundJourneyTime
  )
const returnJourneyTime = () =>
  createSelector(
    searchResultSelector(),
    state => state.returnJourneyTime
  )

const selectFilterData = () =>
  createSelector(
    searchResultSelector(),
    state => state.filters
  )

const selectSelectedFlight = () =>
  createSelector(
    searchResultSelector(),
    state => {
      let sf = Immutable(state.selectedFlight).asMutable({ deep: true })
      return sf
    }
  )
const selectSelectedFlightB = () =>
  createSelector(
    searchResultSelector(),
    state => {
      let sf = Immutable(state.selectedFlightB).asMutable({ deep: true })
      return sf
    }
  )

const selectQueryDetails = () =>
  createSelector(
    searchResultSelector(),
    state => {
      let qd = Immutable(state.queryDetails).asMutable({ deep: true })
      return qd
    }
  )
const selectDepDateForFilters = () => 
  createSelector(
    searchResultSelector(),
    state => {
      if (state.depDateForFilters) {
      let dd = Immutable(state.depDateForFilters).asMutable({ deep: true })
      return dd
      }
    }
  )
  const selectReturnDateForFilters = () =>
  createSelector(
    searchResultSelector(),
    state => {
      if (state.depDateForFilters) {
        console.log(state);
        let dd = Immutable(state.returnDateForFilters).asMutable({ deep: true })
        return dd
      }
    }
  )
const selectMinDateForFilters = () =>
  createSelector(
    searchResultSelector(),
    state => {
      if (state.minDateForFilters) {
        let dd = Immutable(state.minDateForFilters).asMutable({ deep: true })
        return dd
      }
    }
  )
const selectIsReservingFlights = () =>
  createSelector(
    searchResultSelector(),
    state => state.isReservingFlights
  )
const selectIdentifier = () =>
  createSelector(
    searchResultSelector(),
    state => state.identifier
  )
const selectPNR = () =>
  createSelector(
    searchResultSelector(),
    state => state.pnr
  )
const selectIsCheckBookingStatus = () =>
  createSelector(
    searchResultSelector(),
    state => state.isCheckBookingStatus
  )
const selectIsGetSingleReservation = () =>
  createSelector(
    searchResultSelector(),
    state => state.isGetSingleReservation
  )
const selectCheckBookingStatus = () =>
  createSelector(
    searchResultSelector(),
    state => state.checkBookingStatus
  )

const selectShowOverlay = () =>
  createSelector(
    searchResultSelector(),
    state => state.overlay
  )
const selectProgressValue = () =>
  createSelector(
    searchResultSelector(),
    state => state.progressValue
  )
const selectIsTotalPriceNull = () =>
  createSelector(
    searchResultSelector(),
    state => state.isTotalPriceNull
  )

const selectSplitView = () =>
  createSelector(
    searchResultSelector(),
    state => {
      return state.split_view
    }
  )
const selectFlightsC = () =>
  createSelector(
    searchResultSelector(),
    state => state.flightsC
  )
const selectSplitReturning = () =>
  createSelector(
    searchResultSelector(),
    state => state.splitReturning
  )
const selectSplitDeparting = () =>
  createSelector(
    searchResultSelector(),
    state => state.splitDeparting
  )
const selectDeparting_flight = () =>
  createSelector(
    searchResultSelector(),
    state => {
      let sdf = Immutable(state.split_departing_flight).asMutable({
        deep: true
      })
      return sdf
    }
  )

const selectSelectedView = () =>
  createSelector(
    searchResultSelector(),
    state => state.selected_view
  )
const selectisRefreshFilter = () =>
  createSelector(
    searchResultSelector(),
    state => state.isRefreshFilter
  )
const selectResponseTripType = () =>
  createSelector(
    searchResultSelector(),
    state => state.response_trip_type
  )

const selectProgressListSearchResult = () =>
  createSelector(
    searchResultSelector(),
    state => state.progressList
  )

const previousAppliedFilters = () =>
  createSelector(
    searchResultSelector(),
    state => state.previousfilters
  )

const filtersSelected = () =>
  createSelector(
    searchResultSelector(),
    state => state.filtersApplied
  )

export const selectors = {
  selectIsSearchingFlights,
  selectFlights,
  selectMinMaxPriceRange,
  selectJourneyDurationRange,
  selectAirlinesArray,
  selectAppliedFilters,
  selectJourneyTime,
  selectMinMaxPriceValue,
  returnJourneyTime,
  selectFilterData,
  selectSelectedFlight,
  selectDepDateForFilters,
  selectReturnDateForFilters,
  selectMinDateForFilters,
  getBorders,
  selectQueryDetails,
  selectIsReservingFlights,
  selectIdentifier,
  selectPNR,
  selectIsCheckBookingStatus,
  selectIsGetSingleReservation,
  selectCheckBookingStatus,
  selectShowOverlay,
  selectProgressValue,
  selectIsTotalPriceNull,
  selectSelectedFlightB,
  selectSplitView,
  selectFlightsC,
  selectSplitDeparting,
  selectSplitReturning,
  selectDeparting_flight,
  selectSelectedView,
  selectisRefreshFilter,
  selectResponseTripType,
  selectProgressListSearchResult,
  previousAppliedFilters,
  filtersSelected
}
