import React from "react"
import { Progress } from "reactstrap"
import { ContentContainer, Filter, FilterText, MobileNone } from "../elements"
import { ResetFilterBtn } from "../FilterButton"
import { Result } from "../FilterButton"
import Filters from "../Filter"
import { Row, Col } from "reactstrap"
import FiltersDate from "../FilterDate"
import ReturnDate from "../FilterDate/returnDate"
import Accordion from "../LeftBar"
import FlightCard from "../FlightCard"
import FilterModal from "../ResponsiveAccordion"
import {
  SearchResultLoader,
  PaginationResultLoader
} from "base_components/Loaders"
import { compose, pure } from "recompose"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import { withRouter } from "react-router-dom"
import { selectors } from "../ducks"
import { NoDataFound } from "base_components/NoDataFound"
import Immutable from "seamless-immutable"
import { createQuery } from "utilities"
import { returningFilters } from "../thunks"
import {
  resetFilter,
  cheapestFlights,
  earliestFlights,
  quickestFlights,
  journeyTime
} from "../thunks"
import { RightBar } from "../RightBar"
import styled from "styled-components"
import DatePicker from "base_components/DatePicker"
import { Arrowleft } from "../../SearchResult/FilterDate/elements"
import {
  // FaSortAmountUp,
  FaAngleLeft,
  FaAngleRight
} from "react-icons/fa"

let percentValInterval
let timeout

class SelectFlights extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      modal: false,
      isClose: false,
      cheapestBorder: true,
      earliestBorder: false,
      quickestBorder: false,
      refreshCount: 1,
      updateFilterComponent: false,
      resetPressed: false,
      pageNo: 1,
      loadMore: true,
      percentVal: 0,
      progressBarDivision: null,
      isLoading: true,
      loader: false,
      noLength: false,
      progressDone: false,
      progressBar: "block",
      hideLoader: false,
      bottom: false,
      flightNotFound: false
    }
  }

  componentDidMount = () => {
    this.startInterval()
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { isSearchingFlights, flights } = this.props
    let flightsM = Immutable(flights).asMutable({ deep: true })
    if (prevProps !== this.props) {
      this.props.selectProgressListSearchResult.length &&
        this.setState({
          progressBarDivision:
            100 / this.props.selectProgressListSearchResult.length
        })

      let tempArr = []
      let loadArr = []
      this.props.called &&
        this.props.called.length &&
        this.props.called.map(item => {
          if (item.received && item.dataLength === 0) {
            tempArr.push(item)
          }
          if (item.received) {
            loadArr.push(item)
          }
        })
      this.props.called && this.props.called.length === tempArr.length
        ? this.setState({ isLoading: false })
        : this.setState({ isLoading: true })
    }
    this.handlebottom()
    // let el = document.getElementById("searchResults")
    // if (el && flightsM.length <= 4) {
    //   this.props.nextPageResult && this.props.nextPageResult()
    // }
  }

  handlebottom = () => {
    if (
      document.getElementById("searchResults") &&
      window.outerHeight > document.getElementById("searchResults").offsetHeight
    ) {
      this.setState({ bottom: false })
    } else {
      this.setState({ bottom: true })
    }
  }

  startInterval = () => {
    clearTimeout(timeout)

    percentValInterval = setInterval(() => {
      this.setState({ percentVal: this.state.percentVal + 0.5 }, () => {
        if (this.state.percentVal >= 100) {
          this.setState({ percentVal: 0 })
          clearInterval(percentValInterval)
        }
        // if (this.state.progressDone) {
        //   setTimeout(() => {
        //     this.setState({ percentVal: 97 }, () => {
        //       clearInterval(percentValInterval)
        //     })
        //   }, 1000)
        // }
      })
    }, 1000)
  }

  handleLoader = called => {
    if (called && called.length) {
      let loaderArr = []
      let progArr = []
      called.map(item => {
        if (item.called === true && item.received === false) {
          loaderArr.push(item)
        }
        if (item.called === true && item.received === true) {
          progArr.push(item)
        }
      })

      if (
        progArr.length === 1 &&
        this.state.percentVal < this.state.progressBarDivision
      ) {
        this.setState({ percentVal: this.state.progressBarDivision })
      }
      if (
        progArr.length > 1 &&
        called.length !== progArr.length &&
        this.state.percentVal < progArr.length * this.state.progressBarDivision
      ) {
        this.setState({
          percentVal: progArr.length * this.state.progressBarDivision
        })
      }
      if (called.length === progArr.length) {
        this.setState({ progressDone: true })
        clearInterval(percentValInterval)
      }
      if (loaderArr.length) {
        this.setState({ loader: true })
      } else {
        this.setState({ loader: false })
      }
      let lengthArr = []
      called.map(item => {
        if (item.received === true && item.dataLength === 0) {
          lengthArr.push(item)
        }
      })
      if (called.length === lengthArr.length) {
        this.setState({ noLength: true })
      }
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  onCheapestFlightsPressed = () => {
    this.props.cheapestFlights()
    this.setState({
      cheapestBorder: true,
      earliestBorder: false,
      quickestBorder: false
    })
  }

  onEarliestFlightsPressed = () => {
    this.props.earliestFlights()
    this.setState({
      cheapestBorder: false,
      earliestBorder: true,
      quickestBorder: false
    })
  }

  onQuickestFlightsPressed = () => {
    this.props.quickestFlights()
    this.setState({
      cheapestBorder: false,
      earliestBorder: false,
      quickestBorder: true
    })
  }

  onResetPressed = () => {
    this.props.resetFilter()
    this.setState({ resetPressed: !this.state.resetPressed })
    this.props.journeyTime()
    this.setState({
      cheapestBorder: true,
      earliestBorder: false,
      quickestBorder: false
    })
  }

  progressHide = () => {
    timeout = setTimeout(() => {
      this.setState({ progressBar: "none" })
      clearInterval(percentValInterval)
      this.setState({ percentVal: 0 })
    }, 749)

    return this.state.progressBar === "block" ? (
      <CustomProgress>
        <Progress value={100}></Progress>
      </CustomProgress>
    ) : null
  }

  updateFilterComponent = type => {
    let { returningFilters } = this.props
    this.setState({ updateFilterComponent: !this.state.updateFilterComponent })
    returningFilters({
      type,
      query: createQuery(this.props.location.search)
    })
  }

  updateQuery = data => {
    this.props.callApi(data)
    this.onResetPressed()
    this.setState({ progressDone: false, progressBar: "block" }, () => {
      this.startInterval()
    })
  }

  returnNoFlightFound = () => {
    this.setState({
      flightNotFound: true
    })
    return <NoDataFound>No Flights Found</NoDataFound>
  }

  setMaxDate(newReturnDate) {
    // console.log(newReturnDate);
  }

  render() {
    let { loader, noLength, progressDone } = this.state
    let {
      updateProgress,
      isSearchingFlights,
      flights,
      date,
      splitView,
      handleLoader,
      hasMore,
      execution,
      called,
      noFound,
      getScrollLocations,
      return_date
    } = this.props
    this.handleLoader(this.props.called)
    let flightsM = Immutable(flights).asMutable({ deep: true })

    let received =
      called && called.length && called.every(e => e.received === true)
    // let received = this.state.called.every(e => e.received === true)
    let receivedSome =
      called && called.length && called.some(e => e.received === true)
      const temp = `"${return_date.month.length > 9 ? return_date.month : '0'+return_date.month}/${return_date.day}/${return_date.year}"`;
    return (
      <div>
        {!progressDone ? (
          <CustomProgress>
            <Progress value={this.state.percentVal}></Progress>
          </CustomProgress>
        ) : (
          this.progressHide()
        )}
        <ContentContainer ref={this.testRef} id="testtest">
          <Row>
            <Col md="2" sm="2">
              <ResetFilterBtn resetFilters={() => this.onResetPressed()} />
            </Col>
            <Col sm="5" md="5" className="Fliter">
              <Filters
                onCheapestPressed={() => this.onCheapestFlightsPressed()}
                onEarliestPressed={() => this.onEarliestFlightsPressed()}
                onQuickestPressed={() => this.onQuickestFlightsPressed()}
              />
            </Col>
            
            <Col
              sm="1"
              md="1"
              className="FliterDate"
              style={{ padding: "0px" }}
            >
              <FiltersDate sendDate={this.updateQuery} maxDate={return_date} selectedDate={date}  />
              <Filter>
                {/* <FaSlidersH /> */}
                <FilterModal
                  toggleState={this.state.modal}
                  toggleHandler={this.toggleModal}
                />
                <FilterText onClick={this.toggleModal}>
                  {this.props.buttonLabel}Filters
                </FilterText>
              </Filter>
            </Col>

            &nbsp; &nbsp;
            { this.props.responseTripType !== 'oneway' && (
            <Col
              sm="1"
              md="1"
              className="FliterDate"
              style={{ padding: "0px" }}
            >
              <ReturnDate sendDate={this.updateQuery} checkMinDate={date} selectedDate={return_date} changeMaxDate={this.setMaxDate} />
              <Filter>
                <FilterModal
                  toggleState={this.state.modal}
                  toggleHandler={this.toggleModal}
                />
                <FilterText onClick={this.toggleModal}>
                  {this.props.buttonLabel}Filters
                </FilterText>
              </Filter>
            </Col>)}
        



          </Row>
          <Row style={{ marginTop: "12px" }}>
            <Col
              sm="12"
              md="2"
              className="MobileNone"
              style={{ zIndex: "999" }}
            >
              <MobileNone>
                <Accordion
                  updateFilterComponent={this.state.updateFilterComponent}
                  resetPressed={this.state.resetPressed}
                  pageNo={this.props.pageNo}
                  resetOnPrevBtn={this.props.resetOnPrevBtn}
                />
              </MobileNone>
            </Col>
            <Col sm="12" md="7" className="CardCol">
              {isSearchingFlights && !noFound ? (
                <SearchResultLoader />
              ) : (
                <div>
                  {/* {splitView === true ? <FlightCard
                      data={flightsM}
                      updateProgress={updateProgress}
                      updateFilterComponent={this.updateFilterComponent}
                      responseTripType={this.props.responseTripType}
                    /> : } */}
                  {flightsM.length ? (
                    <FlightCard
                      data={flightsM}
                      updateProgress={updateProgress}
                      updateFilterComponent={this.updateFilterComponent}
                      responseTripType={this.props.responseTripType}
                      getScrollLocations={getScrollLocations}
                    />
                  ) : !flightsM.length && !handleLoader ? (
                    this.returnNoFlightFound()
                  ) : null}
                </div>
              )}
              {!noLength &&
              !flightsM.length &&
              !received &&
              !isSearchingFlights ? (
                <SearchResultLoader />
              ) : null
              // !flightsM.length ? (
              //   <NoDataFound>No flights found</NoDataFound>
              // ) : null
              }
              {/* {!isSearchingFlights && !flightsM.length ? null : loader ? (
                <PaginationResultLoader />
              ) : null} */}
              {/* {this.props.hasMore &&
              this.props.hasMore.next &&
              flightsM.length &&
              !loader &&
              this.state.bottom ? (
                <PaginationResultLoader />
              ) : null} */}
              {hasMore && hasMore.next && handleLoader ? (
                <PaginationResultLoader />
              ) : // <p>aaaaaaaaaaa</p>
              null}
              {flightsM.length && !isSearchingFlights && !received ? (
                <PaginationResultLoader />
              ) : null}
              {/* {noFound ? <NoDataFound>No flights found</NoDataFound> : null} */}
              {!noFound &&
              !flightsM.length &&
              this.props.hasMore &&
              !this.props.hasMore.next &&
              !this.state.flightNotFound &&
              received ? (
                <NoDataFound>No Flights Found</NoDataFound>
              ) : null}
              {/* {this.handleNextPage()} */}
              {/* {this.props.hasMore &&
              this.props.hasMore.next &&
              !flightsM.length &&
              loader &&
              !this.state.bottom ? null : document.getElementById(
                  "searchResults"
                ) &&
                window.outerHeight >
                  document.getElementById("searchResults").offsetHeight &&
                flightsM.length &&
                this.props.hasMore.next ? (
                <SelectBtn onClick={() => this.props.nextPageResult()}>
                  Load More
                </SelectBtn>
              ) : null} */}
            </Col>
            <Col sm="12" md="3" className="RightCol">
              <RightBar />
            </Col>
          </Row>
        </ContentContainer>
      </div>
    )
  }
}
const mapState = () =>
  createStructuredSelector({
    isSearchingFlights: selectors.selectIsSearchingFlights(),
    flights: selectors.selectFlights(),
    filterData: selectors.selectFilterData(),
    splitView: selectors.selectSplitView(),
    responseTripType: selectors.selectResponseTripType()
    // selectProgressListSearchResult: selectors.selectProgressListSearchResult()
  })

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      resetFilter,
      cheapestFlights,
      earliestFlights,
      quickestFlights,
      journeyTime,
      returningFilters
    },
    dispatch
  )

export default compose(
  withRouter,
  component =>
    connect(
      mapState,
      mapDispatch
    )(component),
  pure
)(SelectFlights)

export const CustomProgress = styled.div`
  .progress-bar {
    background-color: #ff7b47;
  }
  .progress {
    height: 5px;
  }
`
export const SelectBtn = styled.button`
  border: none;
  background-color: #00b4e2;
  color: #fff;
  padding: 7px 27px;
  font-weight: bold;
  border-radius: 20px;
  &:focus {
    outline: none;
  }
  svg {
    font-size: 14px;
    margin-left: 7px;
  }
  @media only screen and (min-width: 200px) and (max-width: 1200px) {
    margin-left: 26px;
  }
`
