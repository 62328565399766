import styled from "styled-components"
import { Nav, NavLink } from "reactstrap"
export const StyleNav = styled(Nav)`
  padding: 0px 36px;
  border-bottom: 1px solid #00b4e2 !important;
  padding-top: 20px;
  @media only screen and (min-width: 200px) and (max-width: 1024px) {
    display: block;
  }
`
export const TabLinks = styled(NavLink)`
  font-weight: 600;
  padding: 9px 12px;
  cursor: pointer;

  &&.active {
    background-color: #00b4e2;
    color: #fff;
    font-weight: 600;
    border-bottom: 1px solid #00b4e2 !important;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    @media only screen and (min-width: 200px) and (max-width: 767px) {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    &:hover {
      color: #fff !important;
    }
  }
  &:hover {
    border-color: #fff !important;
    border-bottom: 1px solid #00b4e2 !important;
  }
`
