import React from "react"
import styled from "styled-components"
export const NoDataFound = ({ children }) => {
  return <Empty>{children}</Empty>
}

export const Empty = styled.div`
  text-align: center;
  font-size: 16px;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-bottom: 20px;
`
