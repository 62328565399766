import styled from "styled-components"

export const PaymentContainer = styled.div`
  margin-top: 20px;
  @media only screen and (max-width: 990px) {
    padding: 0px 15px;
    margin-bottom: 20px;
  }
  .nav-link {
    color: #000 !important;
    font-size: 17px;
    border: 1px solid #ededed !important;
    border-radius: 0px !important;
    padding: 14px 1rem;
    font-weight: 500;
    border-top: none !important;
    border-right: none !important;
    @media only screen and (max-width: 990px) {
      border-top: 1px solid #ededed !important;
      border-left: none !important;
    }
  }
  .active.nav-link {
    background-color: #eaf9ff !important;
    border: 1px solid #84cae8 !important;
    box-shadow: 0px 0px 10px -5px #84cae8;
  }
  .nav-tabs .nav-item {
    margin-bottom: 0px;
    @media only screen and (max-width: 990px) {
      width: 100%;
    }
  }
  .nav-tabs {
    height: 100%;
    border: none;
    border-right: 1px solid #ededed !important;
    border-top: 1px solid #ededed !important;
    @media only screen and (max-width: 990px) {
      border-left: 1px solid #ededed !important;
    }
  }
`
export const RightContainer = styled.div`
  padding-left: 30px;
  @media only screen and (min-width: 991px) and (max-width: 1100px) {
    padding-left: 0px;
  }
`
export const List = styled.ul`
  list-style-type: none;
  padding: 0px;
  display: flex;
  margin-bottom: 5rem;
  @media only screen and (max-width: 990px) {
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 3rem;
    margin-top: 2rem;
    justify-content: space-between;
    padding: 0px 27px;
  }
  @media only screen and (max-width: 440px) {
    display: block;
  }
`
export const ListItem = styled.li`
  margin-right: 3rem;
  @media only screen and (min-width: 991px) and (max-width: 1150px) {
    margin-right: 2rem;
  }
  @media only screen and (max-width: 990px) {
    margin-right: 1rem;
    width: 42%;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 440px) {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  p {
    margin: 0px;
    margin-top: 15px;
    font-size: 14px;
    color: #313131;
    text-align: center;
  }
`
export const ListImage = styled.div`
  max-width: 100px;
  border: 1px solid #dcdcdc;
  padding: 11px 6px;
  border-radius: 3px;
  background-color: #fff;
  min-width: 100px;
  text-align: center;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 70px;
  cursor: pointer;
  position: relative;
  &.active {
    border: 1px solid #8bd1ef;
    box-shadow: 0px 0px 19px -2px #84cae8;
    .check-blue {
      display: block;
    }
  }
  @media only screen and (max-width: 1024px) {
    margin: 0 auto;
  }
  .check-blue {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 24px;
    display: none;
  }
`
export const BankText = styled.h3`
  margin: 0px;
  font-size: 16px;
  color: #313131;
  font-weight: 400;
  @media only screen and (max-width: 990px) {
    margin-left: 15px;
  }
`
export const CardBtn = styled.button`
  background-color: #ff7b47;
  color: #fff;
  font-weight: 500;
  border: none;
  padding: 8px 0px;
  width: 280px;
  text-transform: capitalize;
  margin-top: 20px;
  border-radius: 3px;
  letter-spacing: 1.2px;
  font-size: 20px;
  margin-bottom: 2rem;
  @media only screen and (max-width: 990px) {
    margin-left: 15px;
  }
  @media only screen and (max-width: 440px) {
    max-width: 200px;
  }
`
