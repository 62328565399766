import React, { Component, lazy, Suspense } from "react"
import TopSearchBar from "scenes/SearchResult/TopSearchBar"
import { NavItem, TabContent, TabPane } from "reactstrap"
import { StyledNav, NavLinks } from "./elements.js"
import classnames from "classnames"
// import Tickets from "../TicketsTab"
import { FaMoneyBill, FaSuitcase, FaUserAlt } from "react-icons/fa"
// import Profile from "../Profile"
// import ErrorBoundry from "routes/ErrorBoundry"
const renderLoader = () => <p />
// const TopSearchBar = lazy(() => import("../../SearchResult/TopSearchBar"))
const Tickets = lazy(() => import("../TicketsTab"))
const Profile = lazy(() => import("../Profile"))
const Payment = lazy(() => import("../../MobileAppPayment"))
class TicketsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1"
    }
  }
  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }
  render() {
    const { activeTab } = this.state

    return (
      <React.Fragment>
        <TopSearchBar />
        <Suspense fallback={renderLoader()}>
          <StyledNav tabs>
            <NavItem>
              <NavLinks
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  this.toggle("1")
                }}
              >
                <FaSuitcase />
                Trips
              </NavLinks>
            </NavItem>
            {/* <NavItem>
              <NavLinks
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  this.toggle("2")
                }}
              >
                <FaComment /> Chat with us
              </NavLinks>
            </NavItem> */}
            <NavItem>
              <NavLinks
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  this.toggle("3")
                }}
              >
                <FaUserAlt /> Profile
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                className={classnames({ active: activeTab === "4" })}
                onClick={() => {
                  this.toggle("4")
                }}
              >
                <FaMoneyBill /> Payment
              </NavLinks>
            </NavItem>
            {/* <NavItem>
              <NavLinks
                className={classnames({ active: activeTab === "4" })}
                onClick={() => {
                  this.toggle("4")
                }}
              >
                <FaBlackTie /> Reward Points
              </NavLinks>
            </NavItem> */}
          </StyledNav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Tickets />
            </TabPane>
            <TabPane tabId="2">chats</TabPane>
            <TabPane tabId="3">
              <Profile />
            </TabPane>
            <TabPane tabId="4">
              <Payment />
            </TabPane>
            <TabPane tabId="5">Reward points</TabPane>
          </TabContent>
        </Suspense>
      </React.Fragment>
    )
  }
}

export default TicketsPage
