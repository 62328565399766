import React, { Component, Fragment } from "react"
import {
  TopCart,
  InnerHeading,
  SmallHeading,
  FlexContainer,
  Date,
  Time,
  Charges,
  BottomCart,
  Person,
  Divider,
  Total,
  CardBtn,
  StickyCart
} from "./elements"
import { compose, pure } from "recompose"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import {
  getMajorFlightDetails,
  headerDetails,
  getCityName,
  getPassengersPriceAndTax,
  getCabinFromQueryDetails
} from "filterFunctions"
import { types, selectors } from "../ducks"
import { totalPriceFareBreakDown, modifyFareBreakDown } from "../thunks"
import features from "features"
import { priceItinerary } from "filterFunctions"
import { TimeConvert24to12 } from "shared/ticketFuctions"

class SplitViewCart extends Component {
  continueHandler = () => {
    // this.props.formikRef[1].current.submitForm()
    for (var key in this.props.formikRef) {
      if (this.props.formikRef.hasOwnProperty(key)) {
        this.props.formikRef[key].current.submitForm()
      }
    }
  }
  state = {
    success: false,
    price_mismatch: false,
    passenger_data: {},
    totalNull: false
  }
  divider2 = false
  divider = false
  componentDidMount() {
    this.props.toggleUpdate()
    let {
      selectedFlightData,
      selectedFlightB,
      totalPriceFareBreakDown,
      api_token,
      selectQueryDetails,
      modifyFareBreakDown,
      selectDeparting_flight
    } = this.props
    let { fare_breakdown } = priceItinerary(selectedFlightB.price_itinerary)
    this.showPriceConatiner = false
    if (fare_breakdown && fare_breakdown.total === null) {
      let query = {
        flight: selectDeparting_flight,
        query: selectQueryDetails,
        return_flight: selectedFlightData
      }
      totalPriceFareBreakDown(query).then(action => {
        let {
          success,
          data,
          mismatch_type,
          prices,
          price_mismatch
        } = action.payload
        // this.setState({ success: action.payload.success })
        if (success == true) {
          if (fare_breakdown.adult !== null) {
            if (!data.adult) {
              data["adult"] = { quantity: fare_breakdown.adult.quantity }
            } else {
              data.adult.quantity = fare_breakdown.adult.quantity
            }
          }

          if (fare_breakdown.child !== null) {
            // data["child"]["quantity"] = fare_breakdown.child.quantity
            if (!data.child) {
              data["child"] = { quantity: fare_breakdown.child.quantity }
            } else {
              data.child.quantity = fare_breakdown.child.quantity
            }
          } else {
            // data.child = null
          }
          if (fare_breakdown.infant !== null) {
            // data["infant"]["quantity"] = fare_breakdown.infant.quantity
            if (!data.infant) {
              data["infant"] = { quantity: fare_breakdown.infant.quantity }
            } else {
              data.infant.quantity = fare_breakdown.infant.quantity
            }
          } else {
            // data.infant = null
          }

          modifyFareBreakDown(data)
          this.setState({
            passenger_data: data,
            success: action.payload.success,
            totalNull: true
          })
          // let price_mismatch_data = {
          //   mismatch_type: "leaving",
          //   prices: values
          // }
          // this.props.openPricesMOdal(price_mismatch_data)
        } else {
          if (price_mismatch && price_mismatch === true) {
            this.setState({
              price_mismatch: true,
              totalNull: true,
              success: action.payload.success
            })

            let price_mismatch_data = {
              mismatch_type,
              prices
            }
            this.props.openPricesMOdal(price_mismatch_data)
          }
        }
      })
    } else {
      // do something here
      this.setState({ totalNull: false })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { totalPriceApiAgain } = this.props
    if (
      totalPriceApiAgain &&
      prevProps.totalPriceApiAgain !== totalPriceApiAgain
    ) {
      // this.callApiAgain({})
    }
  }
  // callApiAgain = ({}) => {
  //   console.log("in callPAiAgian ////////////////// split view")
  //   let {
  //     selectedFlightData,
  //     selectedFlightB,
  //     totalPriceFareBreakDown,
  //     api_token,
  //     selectQueryDetails,
  //     modifyFareBreakDown,
  //     selectDeparting_flight,
  //     totalPriceApiAgainToggle
  //   } = this.props
  //   let query = {
  //     flight: selectDeparting_flight,
  //     query: selectQueryDetails,
  //     return_flight: selectedFlightData
  //   }
  //   totalPriceFareBreakDown(query).then(action => {
  //     let { success, data } = action.payload
  //     console.log("action =====", action)
  //     let { fare_breakdown } = priceItinerary(
  //       selectedFlightData.price_itinerary
  //     )

  //     if (success == true) {
  //       if (fare_breakdown.adult !== null) {
  //         data.adult.quantity = fare_breakdown.adult.quantity
  //       } else {
  //         data.adult.quantity =
  //           selectQueryDetails.passengers &&
  //           selectQueryDetails.passengers.adult_count &&
  //           selectQueryDetails.passengers.adult_count
  //       }

  //       if (fare_breakdown.child !== null) {
  //         data.child.quantity = fare_breakdown.child.quantity
  //       } else {
  //         // data.child = null
  //         data.child.quantity =
  //           selectQueryDetails.passengers &&
  //           selectQueryDetails.passengers.child_count &&
  //           selectQueryDetails.passengers.child_count
  //       }
  //       if (fare_breakdown.infant !== null) {
  //         data.infant.quantity = fare_breakdown.infant.quantity
  //       } else {
  //         // data.infant = null
  //         data.infant.quantity =
  //           selectQueryDetails.passengers &&
  //           selectQueryDetails.passengers.infant_count &&
  //           selectQueryDetails.passengers.infant_count
  //       }

  //       this.setState({
  //         passenger_data: data,
  //         success: action.payload.success,
  //         totalNull: true,
  //         price_mismatch: false
  //       })
  //       totalPriceApiAgainToggle()
  //     }
  //   })
  // }

  render() {
    let {
      selectedFlightData,
      selectQueryDetails,
      airports,
      cabinName,
      currency,
      isTotalPriceNull,
      selectDeparting_flight
    } = this.props
    let { success, price_mismatch, passenger_data, totalNull } = this.state
    let {
      type,
      depAirport,
      arrAirport,
      shortDepDate,
      ppp,
      adult,
      child,
      infant,
      total,
      depDate,
      stopInt,
      depTime,
      tripTypeName,
      totalPassengerCount
    } = headerDetails(selectDeparting_flight, selectQueryDetails)
    let departure = ""
    let arrival = ""
    airports.map(item => {
      if (item.code === depAirport) {
        departure = item.value
      } else if (item.code === arrAirport) {
        arrival = item.value
      }
    })
    let departureCity = getCityName(departure)
    let arrivalCity = getCityName(arrival)

    let returnData = {}
    let dataReturn = getMajorFlightDetails(
      selectedFlightData.departing_flight_segments
    )
    returnData = dataReturn

    let { total: totalT } = headerDetails(
      selectedFlightData,
      selectQueryDetails
    )
    let totalTotal = total + totalT
    let { cabinFromSearch } = getCabinFromQueryDetails({ selectQueryDetails })
    return (
      <StickyCart>
        <TopCart>
          <InnerHeading>
            {departureCity.city} - {arrivalCity.city}
          </InnerHeading>
          <SmallHeading>
            "Round Trip", {cabinFromSearch}, {totalPassengerCount}
          </SmallHeading>
          <FlexContainer>
            <Date>
              <h4>{depDate}</h4>
              <p>
                {depAirport} - {arrAirport}
              </p>
            </Date>
            <Time>
              <h4>{TimeConvert24to12(depTime)}</h4>
              <p>
                {stopInt} {stopInt > 1 ? "Stops" : "Stop"}
              </p>
            </Time>
          </FlexContainer>

          <FlexContainer style={{ marginTop: "3px" }}>
            <Date>
              <h4>{returnData.depDate}</h4>
              <p>
                {returnData.depAirport} - {returnData.arrAirport}
              </p>
            </Date>
            <Time>
              <h4>{TimeConvert24to12(returnData.depTime)}</h4>
              <p>
                {returnData.stopInt} {returnData.stopInt > 1 ? "Stops" : "Stop"}
              </p>
            </Time>
          </FlexContainer>
        </TopCart>
        {totalT === 0 ? (
          <Fragment>
            {/* <Charges>Charges</Charges> */}
            <BottomCart>
              <CardBtn onClick={this.continueHandler}>continue</CardBtn>
            </BottomCart>
          </Fragment>
        ) : (
          <Fragment>
            <Charges>Charges</Charges>
            <BottomCart>
              <InnerHeading>Departing</InnerHeading>
              <PassengerData
                flight={selectDeparting_flight}
                selectQueryDetails={selectQueryDetails}
                currency={currency}
              />
              <InnerHeading style={{ marginTop: "15px" }}>
                Returning
              </InnerHeading>
              <PassengerData
                flight={selectedFlightData}
                selectQueryDetails={selectQueryDetails}
                currency={currency}
              />
              <Divider />
              <FlexContainer>
                <Date>
                  <Total>Total</Total>
                </Date>
                <Time>
                  <Total>
                    {currency} {totalTotal.toLocaleString("en-IN")}
                  </Total>
                </Time>
              </FlexContainer>
              <CardBtn onClick={this.continueHandler}>continue</CardBtn>
            </BottomCart>
          </Fragment>
        )}
      </StickyCart>
    )
  }
}

const mapState = () =>
  createStructuredSelector({
    selectedFlightData: selectors.selectSelectedFlight(),
    selectedFlightB: selectors.selectSelectedFlightB(),
    selectQueryDetails: selectors.selectQueryDetails(),
    airports: features.landing.selectors.selectAirportList(),
    api_token: features.login.selectors.selectApiToken(),
    isTotalPriceNull: selectors.selectIsTotalPriceNull(),
    split_view: selectors.selectSplitView(),
    selectDeparting_flight: selectors.selectDeparting_flight()
  })

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      showLoginModal: features.login.actions.showLoginModal,
      totalPriceFareBreakDown,
      modifyFareBreakDown
    },
    dispatch
  )

export default compose(
  component =>
    connect(
      mapState,
      mapDispatch
    )(component),
  pure
)(SplitViewCart)

export const PassengerData = ({ flight, selectQueryDetails, currency }) => {
  let { adult, child, infant, total } = headerDetails(
    flight,
    selectQueryDetails
  )

  let arrayOfPassengers = []
  let types = ""

  if (adult !== null && child === null && infant !== null) {
    arrayOfPassengers.push(adult, infant)
    types = "Adult&Infant"
  }
  if (adult !== null && child !== null && infant === null) {
    arrayOfPassengers.push(adult, child)
    types = "Adults&Child"
  }
  if (adult !== null && child !== null && infant !== null) {
    arrayOfPassengers.push(adult, child, infant)
    types = "All"
  }
  if (adult !== null && child === null && infant === null) {
    arrayOfPassengers.push(adult)
    types = "Adult"
  }
  let { totalPassengerData } = getPassengersPriceAndTax(
    arrayOfPassengers,
    types
  )
  return (
    <Fragment>
      {totalPassengerData.map((item, index) => {
        return (
          <React.Fragment>
            <FlexContainer style={{ marginTop: "10px" }} key={index}>
              <Date>
                <Person>
                  {item.quantity} {item.type}
                </Person>
              </Date>
              <Time>
                <Person>
                  {currency}{" "}
                  {item.price === null
                    ? 0
                    : (item.price * item.quantity).toLocaleString("en-IN")}
                </Person>
              </Time>
            </FlexContainer>
            <FlexContainer style={{ marginTop: "4px" }}>
              <Date>
                <Person>Tax & Fees</Person>
              </Date>
              <Time>
                <Person>
                  {currency}{" "}
                  {item.tax === null
                    ? 0
                    : (item.tax * item.quantity).toLocaleString("en-IN")}
                </Person>
              </Time>
            </FlexContainer>
          </React.Fragment>
        )
      })}
    </Fragment>
  )
}
