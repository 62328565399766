import React, { useState } from "react"
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert,
  InputGroup,
  Button
} from "reactstrap"
import { fetchService } from "services/API"
import { ContactContainer, UserContactForm, CenteredDiv } from "./elements"

const CustomPayment = () => {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [totalAmount, setTotalAmount] = useState(null)
  const [currency, setCurrency] = useState("BDT")
  const [errorTexts, setErrorTexts] = useState([])
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [paymentLink, setPaymentLink] = useState("")
  const [copied, setCopied] = useState(false)

  return (
    <ContactContainer>
      {formSubmitted && !paymentLink && (
        <CenteredDiv>
          <Alert color="info">
            Please wait. We're processing your request...
          </Alert>
        </CenteredDiv>
      )}

      {paymentLink && (
        <CenteredDiv>
          <Alert color="info">
            Succesfully generated the payment link. Please copy or go to the
            payment page.
          </Alert>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="primary"
              style={{ width: "40%" }}
              onClick={async () => {
                if (navigator.clipboard) {
                  await navigator.clipboard.writeText(paymentLink)
                } else {
                  const input = document.createElement("input")
                  document.body.appendChild(input)
                  input.value = paymentLink
                  input.focus()
                  input.select()
                  const result = document.execCommand("copy")
                  document.body.removeChild(input)
                  if (result === "unsuccessful") {
                    alert("Failed to copy.")
                  }
                }

                setCopied(true)
              }}
            >
              Copy Link
            </Button>

            <Button
              color="primary"
              outline={true}
              style={{ width: "40%", marginLeft: "10px" }}
              onClick={() => {
                window.open(paymentLink, "_self")
              }}
            >
              Go to Payment
            </Button>
          </div>
          {copied && (
            <div style={{ marginTop: "20px" }}>
              <Alert color="info">Copied to your clipboard.</Alert>
            </div>
          )}
        </CenteredDiv>
      )}

      {!formSubmitted && (
        <UserContactForm>
          <Form
            onSubmit={async event => {
              event.preventDefault()

              setFormSubmitted(true)

              try {
                const result = await fetchService({
                  method: "POST",
                  url: `api/v2/custom-invoice/create`,
                  body: {
                    total_amount: totalAmount,
                    currency: currency,
                    cus_name: name,
                    cus_email: email,
                    cus_phone: phone
                  }
                })

                console.log({ result })

                if (!result) {
                  setFormSubmitted(false)
                }

                const { data, success, errors, message } = result

                if (success === false && errors) {
                  console.log(Object.values(errors))
                  setFormSubmitted(false)
                  setErrorTexts(Object.values(errors))
                  return
                } else if (success === false && message) {
                  setFormSubmitted(false)
                  setErrorTexts([message])
                  return
                }

                if (data) {
                  const { status, pay_url, error_reason } = data

                  if (status === "success" && pay_url) {
                    setPaymentLink(pay_url)
                  } else if (error_reason && error_reason.length) {
                    setFormSubmitted(false)
                    setErrorTexts([error_reason])
                    return
                  }
                }
              } catch (error) {
                console.log(error)
                setFormSubmitted(false)
              }
            }}
          >
            <FormGroup>
              <FormText>
                Please fill up the form below with the proper amount you need to
                pay and complete the payment in the next page.
              </FormText>
            </FormGroup>

            <FormGroup>
              <Label for="name">Name:</Label>
              <Input
                required={true}
                type="text"
                maxLength="35"
                name="name"
                id="name"
                placeholder="Enter your name"
                value={name || ""}
                onChange={value => {
                  setName(value.currentTarget.value)
                }}
              />
            </FormGroup>

            <FormGroup>
              <Label for="email">Email:</Label>
              <Input
                required={true}
                type="email"
                maxLength="35"
                name="email"
                id="email"
                placeholder="Enter your email address"
                value={email || ""}
                onChange={value => {
                  setEmail(value.currentTarget.value)
                }}
              />
            </FormGroup>

            <FormGroup>
              <Label for="phone">Phone:</Label>
              <Input
                required={true}
                type="tel"
                name="phone"
                id="phone"
                placeholder="Enter your phone number"
                value={phone || ""}
                onChange={value => {
                  setPhone(value.currentTarget.value)
                }}
              />
            </FormGroup>

            <FormGroup>
              <Label for="totalamount">Amount:</Label>
              <InputGroup>
                <Input
                  required={true}
                  type="number"
                  name="totalamount"
                  id="totalamount"
                  placeholder="Enter the total amount"
                  value={totalAmount || ""}
                  onChange={value => {
                    setTotalAmount(+value.currentTarget.value)
                  }}
                />
                <div style={{ marginLeft: "3px", maxWidth: "35vw" }}>
                  <Input
                    required={true}
                    type="select"
                    name="currency"
                    id="currency"
                    onChange={event => {
                      const value = event.currentTarget.value
                      setCurrency(value)
                    }}
                  >
                    <option value={"BDT"} defaultValue={currency}>
                      Taka (BDT)
                    </option>
                    <option value={"USD"}>US Dollar (USD)</option>
                  </Input>
                </div>
              </InputGroup>
            </FormGroup>

            {(errorTexts && errorTexts.length && errorTexts.length > 0 && (
              <FormGroup>
                {errorTexts.map(error => {
                  if (error.length === 1) {
                    return (
                      <div key={error[0]}>
                        <Alert color="danger">{error[0]}</Alert>
                      </div>
                    )
                  } else {
                    return (
                      <div key={error[0]}>
                        {error.map(e => (
                          <Alert key={e} color="danger">
                            {e}
                          </Alert>
                        ))}
                      </div>
                    )
                  }
                })}
              </FormGroup>
            )) ||
              ""}

            {(name &&
              email &&
              phone &&
              totalAmount &&
              totalAmount > 0 &&
              currency && (
                <FormGroup>
                  <Input
                    type="submit"
                    name="submit"
                    id="submit"
                    value="Submit"
                  ></Input>
                </FormGroup>
              )) ||
              ""}
          </Form>

          <div style={{ height: "300px" }}></div>
        </UserContactForm>
      )}
    </ContactContainer>
  )
}

export default CustomPayment
