import React, { Component, Fragment } from "react"
// import { tripType } from "utilities"
import OnewayResults from "../OnewayResults"
import RoundTripResults from "../RoundTripResults"
import MultiCityResults from "../MultiCityResults"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { createStructuredSelector } from "reselect"
import features from "features"
class FlightCard extends Component {
  tripTypeContent = () => {
    const {
      data,
      updateProgress,
      updateFilterComponent,
      responseTripType,
      airports,
      getScrollLocations,
    } = this.props
    // switch (tripType(this.props.location.search)) {
    switch (responseTripType) {
      case "oneway":
        return (
          <OnewayResults
            allAirports={airports}
            updateProgress={updateProgress}
            data={data && data}
          />
        )
      case "roundtrip":
        return (
          <RoundTripResults
            allAirports={airports}
            updateProgress={updateProgress}
            data={data && data}
            updateFilterComponent={updateFilterComponent}
            getScrollLocations={getScrollLocations}
          />
        )
      case "multicity":
        return (
          <MultiCityResults
            allAirports={airports}
            updateProgress={updateProgress}
            data={data && data}
          />
        )

      default:
        return (
          <OnewayResults
            allAirports={airports}
            updateProgress={updateProgress}
          />
        )
    }
  }

  render() {
    return <Fragment>{this.tripTypeContent()}</Fragment>
  }
}

const mapState = () =>
  createStructuredSelector({
    airports: features.landing.selectors.selectAirportList()
  })

export default connect(mapState)(withRouter(FlightCard))
