import styled from "styled-components"

export const MainRightBar = styled.div`
  background-image: url(../images/rightback.png);
  border-radius: 10px;
  padding: 20px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 210px;
  position: relative;
  margin-bottom: 20px;
  @media only screen and (min-width: 1700px) and (max-width: 2500px) {
    height: 260px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    height: 150px;
  }
`
export const Searchbtn = styled.button`
  background-color: #fff;
  border: none;
  border-radius: 5px;
  color: #000;
  padding: 6px 25px;
  position: absolute;
  bottom: 9px;
  right: 21px;
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 2px 25px;
    bottom: 6px;
    right: 9px;
  }
`
export const SecondRightbar = styled.div`
  background-image: ${p => p.img};
  border-radius: 10px;
  padding: 20px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 210px;
  position: relative;
  margin-bottom: 20px;
  @media only screen and (min-width: 1700px) and (max-width: 2500px) {
    height: 260px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    height: 150px;
  }
`
export const Hotelbtn = styled.button`
  background-color: #f47c48;
  border: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
  padding: 6px 30px;
  position: absolute;
  bottom: 0px;
  right: 30px;
`
