import React from "react"
import { SecondRightbar } from "./elements"
import { SliderAnchor } from "scenes/Landing/DealSlider/elements"
export const RightBar = props => (
  <div>
    <SliderAnchor href="https://tripovy.com/" target="_blank">
      <SecondRightbar img={`url("./images/slide-bottom-3.jpg")`} />
    </SliderAnchor>
    <SliderAnchor href="https://tripovy.com/" target="_blank">
      <SecondRightbar img={`url("./images/slide-bottom-1.jpg")`} />
    </SliderAnchor>
    <SliderAnchor href="https://tripovy.com/" target="_blank">
      <SecondRightbar img={`url("./images/slide-bottom-2.jpg")`} />
    </SliderAnchor>
  </div>
)
