import React, { Component, Fragment } from "react"
import { BpkAccordion } from "bpk-component-accordion"
import BpkSlider from "bpk-component-slider"
import { compose, pure } from "recompose"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import { withRouter } from "react-router-dom"
import Immutable from "seamless-immutable"
import {
  SBpkAccordionItem,
  AccordionBody,
  CustomInputs,
  RangeText,
  RangeTime
} from "./elements"
import {
  searchStops,
  priceRange,
  journeyDuration,
  applyFilters,
  journeyTime,
  returnJourneyTime
} from "../thunks"
import { createQuery, timeConvert, manipulateArr } from "utilities"
import { selectors } from "../ducks"
import { checkArrayEquality } from "filterFunctions"
import isEqual from "lodash.isequal"
// const StatefulAccordionItem = withAccordionItemState(BpkAccordionItem)
import { TimeConvert24to12 } from "shared/ticketFuctions"

class Accordion extends Component {
  state = {
    outBoundText: ["00:00", "23:59"],
    outBoundValues: [0, 1440],
    returnSliderText: ["00:00", "23:59"],
    returnSliderValues: [0, 1440],
    showState: false,
    tripType: "",
    stops: [0, 1, 2],
    journeyValue: null,
    airlines: [],
    priceValue: null,
    resetFlight: false,
    appliedAirlines: [],
    only: false,
    onlyAirline: []
  }

  componentDidUpdate(prevProps, prevState) {
    let { appliedFilters } = this.props
    let { only } = this.state
    let resetAirlineArr = []
    // console.log(
    //   "prevProps.onlyailines ===",
    //   prevProps.onlyAirline,
    //   "this.props ---",
    //   appliedFilters.onlyAirline
    // )
    // console.log(
    //   "!isEqual(prevProps.onlyAirline, appliedFilters.onlyAirline)",
    //   !isEqual(prevProps.onlyAirline, appliedFilters.onlyAirline)
    // )
    appliedFilters.airlinesArr &&
      appliedFilters.airlinesArr.length &&
      appliedFilters.airlinesArr.map(item => {
        resetAirlineArr.push(item.code)
      })
    if (this.props.selectSplitView !== undefined) {
      // console.log(
      //   "in 1111111111111111111 prevState",
      //   prevState.only,
      //   "this.state",
      //   only
      // )
      if (
        this.props.appliedFilters.airlinesArr !== undefined &&
        !isEqual(
          prevProps.appliedFilters.airlinesArr,
          this.props.appliedFilters.airlinesArr
        )
      ) {
        // console.log(
        //   "in 222222222222 prevState",
        //   prevState.only,
        //   "this.state",
        //   only
        // )
        this.setState({
          outBoundText: ["00:00", "23:59"],
          outBoundValues: [0, 1440],
          returnSliderText: ["00:00", "23:59"],
          returnSliderValues: [0, 1440],
          showState: false,
          tripType: "",
          // stops: [0, 1, 2],
          journeyValue: null,
          airlines: this.state.appliedAirlines.length
            ? this.state.appliedAirlines
            : resetAirlineArr,
          priceValue: null,
          // appliedAirlines: resetAirlineArr
          only: false,
          onlyAirline: []
        })
      } else if (prevProps.resetPressed !== this.props.resetPressed) {
        // console.log(
        //   "in 3333333333333333333 prevState",
        //   prevState.only,
        //   "this.state",
        //   only
        // )
        this.setState({
          outBoundText: ["00:00", "23:59"],
          outBoundValues: [0, 1440],
          returnSliderText: ["00:00", "23:59"],
          returnSliderValues: [0, 1440],
          showState: false,
          tripType: "",
          stops: [0, 1, 2],
          journeyValue: null,
          airlines: resetAirlineArr,
          priceValue: null,
          resetFlight: true,
          // appliedAirlines: resetAirlineArr,
          only: false,
          onlyAirline: []
        })
      }
    } else if (prevProps.resetPressed !== this.props.resetPressed) {
      // console.log(
      //   "in 4444444444444444444444 prevState",
      //   prevState.only,
      //   "this.state",
      //   only
      // )
      this.setState({
        outBoundText: ["00:00", "23:59"],
        outBoundValues: [0, 1440],
        returnSliderText: ["00:00", "23:59"],
        returnSliderValues: [0, 1440],
        showState: false,
        tripType: "",
        stops: [0, 1, 2],
        journeyValue: null,
        airlines: resetAirlineArr,
        priceValue: null,
        resetFlight: true,
        // appliedAirlines: resetAirlineArr,
        only: false,
        onlyAirline: []
      })
    } else if (
      this.props.appliedFilters.airlinesArr !== undefined &&
      !isEqual(
        prevProps.appliedFilters.airlinesArr,
        this.props.appliedFilters.airlinesArr
      )
    ) {
      // console.log(
      //   "in 555555555555555 prevState",
      //   prevState.only,
      //   "this.state",
      //   only
      // )
      // console.log("this.props.appliedFilters ======", this.props.appliedFilters)
      this.setState({
        outBoundText: ["00:00", "23:59"],
        outBoundValues: [0, 1440],
        returnSliderText: ["00:00", "23:59"],
        returnSliderValues: [0, 1440],
        showState: false,
        tripType: "",
        // stops: [0, 1, 2],
        journeyValue: null,
        airlines: this.state.appliedAirlines.length
          ? this.state.appliedAirlines
          : resetAirlineArr,
        priceValue: null,
        // appliedAirlines: resetAirlineArr,
        only: false,
        onlyAirline: []
      })
    } else if (
      this.props.appliedFilters.onlyAirline !== undefined &&
      this.props.appliedFilters.onlyAirline.length &&
      !isEqual(prevProps.appliedFilters.onlyAirline, appliedFilters.onlyAirline)
    ) {
      // console.log("in 6666666")
      this.setState({
        only: true,
        onlyAirline: appliedFilters.onlyAirline
      })
    }
  }

  componentDidMount = () => {
    let isRoundTrip = createQuery(this.props.location.search)
    let tripType = isRoundTrip.trip_type
    this.setState({ tripType })
    let { journeyTiming } = this.props
    let journeyTimesM = Immutable(journeyTiming).asMutable({
      deep: true
    })
    // this.setState({ outBoundValues: journeyTimesM })
  }

  handleStopFilter = (e, type) => {
    let stops = this.state.stops.slice(0)
    let {
      journeyValue,
      airlines,
      priceValue,
      outBoundValues,
      returnSliderValues,
      onlyAirline
    } = this.state
    let outBound = checkArrayEquality(outBoundValues)
    let returnVal = checkArrayEquality(returnSliderValues)
    stops = manipulateArr(stops, type, e.target.checked)

    this.props.applyFilters({
      stops,
      journeyValue,
      airlines,
      priceValue,
      outBoundValues: outBound ? null : outBoundValues,
      returnSliderValues: returnVal ? null : returnSliderValues,
      onlyAirline
    })
    this.setState({ stops })
  }

  handleOutBoundValues = value => {
    let {
      journeyValue,
      airlines,
      priceValue,
      stops,
      returnSliderValues,
      onlyAirline
    } = this.state
    let returnVal = checkArrayEquality(returnSliderValues)
    let startingHours = timeConvert(value[0])

    let endingHours = timeConvert(value[1])
    this.props.applyFilters({
      stops,
      journeyValue,
      airlines,
      priceValue,
      outBoundValues: value,
      returnSliderValues: returnVal ? null : returnSliderValues,
      onlyAirline
    })
    this.setState({
      outBoundText: [startingHours, endingHours],
      outBoundValues: value
    })
  }

  handleReturnValues = value => {
    let {
      journeyValue,
      airlines,
      priceValue,
      stops,
      outBoundValues,
      returnSliderValues,
      onlyAirline
    } = this.state
    let outBound = checkArrayEquality(outBoundValues)
    let startingHours = timeConvert(value[0])
    let endingHours = timeConvert(value[1])
    this.props.applyFilters({
      stops,
      journeyValue,
      airlines,
      priceValue,
      outBoundValues: outBound ? null : outBoundValues,
      returnSliderValues: value,
      onlyAirline
    })
    this.setState({
      returnSliderText: [startingHours, endingHours],
      returnSliderValues: value
    })
  }

  handlePriceRange = value => {
    let {
      journeyValue,
      airlines,
      outBoundValues,
      returnSliderValues,
      stops,
      onlyAirline
    } = this.state
    let outBound = checkArrayEquality(outBoundValues)
    let returnVal = checkArrayEquality(returnSliderValues)
    this.props.applyFilters({
      stops,
      journeyValue,
      airlines,
      priceValue: value,
      outBoundValues: outBound ? null : outBoundValues,
      returnSliderValues: returnVal ? null : returnSliderValues,
      onlyAirline
    })
    this.setState({ priceValue: value })
    // this.props.priceRange(value)
  }

  handleJourneyDuration = value => {
    let {
      priceValue,
      airlines,
      outBoundValues,
      stops,
      returnSliderValues,
      onlyAirline
    } = this.state
    this.setState({ journeyValue: value })
    let outBound = checkArrayEquality(outBoundValues)
    let returnVal = checkArrayEquality(returnSliderValues)
    this.props.applyFilters({
      stops,
      journeyValue: value,
      airlines,
      priceValue,
      outBoundValues: outBound ? null : outBoundValues,
      returnSliderValues: returnVal ? null : returnSliderValues,
      onlyAirline
    })

    // this.props.journeyDuration(value)
    // this.props.applyFilters({ key: "journeyDuration", value })
  }

  handleAirlineFilter = e => {
    let {
      priceValue,
      airlines,
      outBoundValues,
      stops,
      journeyValue,
      returnSliderValues
    } = this.state
    let outBound = checkArrayEquality(outBoundValues)
    let returnVal = checkArrayEquality(returnSliderValues)
    if (airlines) {
      airlines = [].concat(airlines)
      airlines = manipulateArr(airlines, e.target.name, e.target.checked)
    } else {
      let filtersData = Immutable(this.props.filtersData).asMutable({
        deep: true
      })
      airlines = filtersData.airlinesArr.map(airline => airline.code)
      airlines = manipulateArr(airlines, e.target.name, e.target.checked)
    }
    // console.log("airlines in handleFilterrrr", airlines)
    this.props.applyFilters({
      stops,
      journeyValue,
      airlines,
      priceValue,
      outBoundValues: outBound ? null : outBoundValues,
      returnSliderValues: returnVal ? null : returnSliderValues,
      onlyAirline: []
    })
    this.setState({
      airlines,
      only: false,
      onlyAirline: [],
      appliedAirlines: airlines
    })
  }

  handleOnly = (e, ar) => {
    let {
      priceValue,
      airlines,
      outBoundValues,
      stops,
      journeyValue,
      returnSliderValues
    } = this.state
    let outBound = checkArrayEquality(outBoundValues)
    let returnVal = checkArrayEquality(returnSliderValues)

    let onlyAirline = [ar.code]
    this.props.applyFilters({
      stops,
      journeyValue,
      airlines: onlyAirline,
      priceValue,
      outBoundValues: outBound ? null : outBoundValues,
      returnSliderValues: returnVal ? null : returnSliderValues,
      onlyAirline
    })
    this.setState({ airlines: onlyAirline, only: true, onlyAirline })
  }

  render() {
    let { testHandler, filtersData, selectSplitView } = this.props
    let {
      outBoundValues,
      showState,
      stops,
      outBoundText,
      journeyValue,
      priceValue,
      returnSliderValues,
      returnSliderText,
      only,
      onlyAirline
    } = this.state
    filtersData = Immutable(filtersData).asMutable({
      deep: true
    })

    let isRoundTrip = createQuery(this.props.location.search)
    let tripType = isRoundTrip.trip_type

    let { stop, priceRange, airlinesArr, journeyDuration } = filtersData
    // console.log("onlyAirline in renderrrr", onlyAirline)
    // console.log("airlinesArr ======= in renderrrr", airlinesArr)
    // console.log("this.state.airlines ===== in render", this.state.airlines)
    return (
      <Fragment>
        <BpkAccordion>
          <SBpkAccordionItem
            id="stops"
            title="Stops"
            initiallyExpanded={true}
            onClick={testHandler}
          >
            <AccordionBody>
              <CustomInputs
                type="checkbox"
                id="direct"
                checked={stops.indexOf(0) !== -1}
                disabled={stop && !stop.direct}
                // value={this.state.}
                onChange={e => this.handleStopFilter(e, 0)}
              >
                <span className="MainText">Direct</span>
                <span className="subtext">
                  {stop && stop.direct ? `BDT ${stop.direct}` : "NONE"}
                </span>
              </CustomInputs>
              <CustomInputs
                type="checkbox"
                id="oneStop"
                checked={stops.indexOf(1) !== -1}
                disabled={stop && !stop.oneStop}
                onChange={e => this.handleStopFilter(e, 1)}
              >
                <span className="MainText">1 Stop</span>
                <span className="subtext">
                  {stop && stop.oneStop ? `BDT ${stop.oneStop}` : "NONE"}
                </span>
              </CustomInputs>
              <CustomInputs
                type="checkbox"
                id="twoPlusStops"
                disabled={stop && !stop.twoPlusStops}
                checked={stops.indexOf(2) !== -1}
                onChange={e => this.handleStopFilter(e, 2)}
              >
                <span className="MainText">2+ stop</span>
                <span className="subtext">
                  {stop && stop.twoPlusStops
                    ? `BDT ${stop.twoPlusStops}`
                    : "NONE"}
                </span>
              </CustomInputs>
            </AccordionBody>
          </SBpkAccordionItem>
          <SBpkAccordionItem
            id="stops"
            title="Departure Times"
            initiallyExpanded={true}
            onClick={testHandler}
          >
            <AccordionBody>
              <RangeText>Outbound</RangeText>
              <RangeTime>
                <Fragment>
                  {" "}
                  {TimeConvert24to12(outBoundText[0])} {" - "}{" "}
                  {TimeConvert24to12(outBoundText[1])}
                </Fragment>
              </RangeTime>
              <BpkSlider
                min={0}
                max={1440}
                value={outBoundValues}
                step={30}
                className={"my-class-name"}
                onAfterChange={this.handleOutBoundValues}
              />
              {tripType === "roundtrip" && selectSplitView === undefined ? (
                <Fragment>
                  <RangeText style={{ marginTop: "20px" }}>Return</RangeText>
                  <RangeTime>
                    {TimeConvert24to12(returnSliderText[0])} {" - "}{" "}
                    {TimeConvert24to12(returnSliderText[1])}
                  </RangeTime>
                  <BpkSlider
                    min={0}
                    max={1440}
                    value={returnSliderValues}
                    step={30}
                    className={"my-class-name"}
                    onAfterChange={this.handleReturnValues}
                  />
                </Fragment>
              ) : tripType === "roundtrip" &&
                selectSplitView !== undefined ? null : null}
            </AccordionBody>
          </SBpkAccordionItem>
          <SBpkAccordionItem
            id="stops"
            title="Journey Duration"
            initiallyExpanded={true}
            onClick={testHandler}
          >
            <AccordionBody>
              <RangeTime>
                {journeyDuration && journeyDuration.min} hours -{" "}
                {journeyValue
                  ? journeyValue
                  : journeyDuration && journeyDuration.max}{" "}
                hours
              </RangeTime>
              <BpkSlider
                min={journeyDuration && journeyDuration.min}
                max={journeyDuration && journeyDuration.max}
                value={
                  journeyValue !== null
                    ? journeyValue
                    : journeyDuration && journeyDuration.max
                }
                step={1}
                className={"my-class-name1111"}
                onAfterChange={this.handleJourneyDuration}
              />
            </AccordionBody>
          </SBpkAccordionItem>
          <SBpkAccordionItem
            id="stops"
            title="Airlines"
            initiallyExpanded={true}
            onClick={testHandler}
          >
            <AccordionBody>
              {airlinesArr && airlinesArr.length
                ? airlinesArr.map((ar, index) => {
                    return (
                      <CustomInputs
                        // onMouseOver={}
                        only={
                          // this.state.airlines.length &&
                          onlyAirline.length && onlyAirline.includes(ar.code)
                            ? "yes"
                            : "no"
                        }
                        key={index}
                        type="checkbox"
                        name={ar.code}
                        id={`airlines${index}`}
                        defaultChecked={true}
                        checked={
                          this.state.airlines.length && onlyAirline.length
                            ? onlyAirline.includes(ar.code)
                            : this.state.airlines.includes(ar.code)
                        }
                        onChange={e => {
                          this.handleAirlineFilter(e, ar)
                        }}
                      >
                        <span className="MainText">
                          {ar.name}{" "}
                          <span
                            className="only"
                            onClick={e => this.handleOnly(e, ar)}
                          >
                            only
                          </span>
                        </span>

                        <p className="subtext">{`BDT ${ar.price}`}</p>
                      </CustomInputs>
                    )
                  })
                : null}
            </AccordionBody>
          </SBpkAccordionItem>
          <SBpkAccordionItem
            id="stops"
            title="Price"
            initiallyExpanded={true}
            onClick={testHandler}
          >
            <AccordionBody>
              <RangeText>Price - Range</RangeText>
              <RangeTime>
                {priceValue
                  ? `${priceValue[0]} - ${priceValue[1]}`
                  : priceRange && `${priceRange.min} - ${priceRange.max}`}
              </RangeTime>
              <BpkSlider
                min={priceRange && priceRange.min}
                max={priceRange && priceRange.max}
                value={
                  priceValue
                    ? priceValue
                    : priceRange && [priceRange.min, priceRange.max]
                }
                step={100}
                className={"my-class-name"}
                onAfterChange={this.handlePriceRange}
              />
            </AccordionBody>
          </SBpkAccordionItem>
        </BpkAccordion>
      </Fragment>
    )
  }
}

const mapState = () =>
  createStructuredSelector({
    minMaxPriceRange: selectors.selectMinMaxPriceRange(),
    minMaxPriceValue: selectors.selectMinMaxPriceValue(),
    journeyDurationRange: selectors.selectJourneyDurationRange(),
    airlinesArray: selectors.selectAirlinesArray(),
    appliedFilters: selectors.selectAppliedFilters(),
    journeyTiming: selectors.selectJourneyTime(),
    returnJourneyTiming: selectors.returnJourneyTime(),
    filtersData: selectors.selectFilterData(),
    selectedView: selectors.selectSelectedView(),
    selectSplitView: selectors.selectSplitView(),
    isRefreshFilter: selectors.selectisRefreshFilter(),
    isSearchingFlights: selectors.selectIsSearchingFlights()
  })

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      searchStops,
      priceRange,
      journeyDuration,
      applyFilters,
      journeyTime,
      returnJourneyTime
    },
    dispatch
  )

export default compose(
  withRouter,
  component =>
    connect(
      mapState,
      mapDispatch
    )(component),
  pure
)(Accordion)
