import React, { Component } from "react"
// import FromToCities from "base_components/FromToCities"
import DatePicker from "base_components/DatePicker"
import InputPassengersNumber from "base_components/InputPassengersNumber"
import CabinClass from "base_components/CabinClass"
// import Immutable from "seamless-immutable"
import { withRouter } from "react-router-dom"
import {
  Stylebtn,
  FlexRow,
  DepartureDate,
  PassengersNumber,
  TabContainer,
  CabinClassFull
} from "../elements.js"
// import qs from "query-string"
import { compose, pure } from "recompose"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import {
  getStateFromProps
  // customQuery
} from "utilities"
// import { getStoredState, createPersistor } from "redux-persist"
import { searchFlights, progressListSearch } from "../thunks"
import { validateJoi, onewaySearchSchema } from "shared/validations.js"
import Alert from "base_components/BannerAlert/index.js"
import features from "features.js"
import InputAutoComplete from "base_components/InputAutoComplete/index.js"
// import { getCityName } from "filterFunctions.js"

class Oneway extends Component {
  constructor(props) {
    super(props)
    this.destination = React.createRef()
    this.depDate = React.createRef()
    this.state = {
      ...getStateFromProps({ props }),
      message: "",
      alertVisible: false,
      originObj: [],
      destinationObj: []
    }
  }

  componentDidMount = () => {
    let originObj = this.props.airports.filter(
      i => i.code === this.state.origin
    )
    let destinationObj = this.props.airports.filter(
      i => i.code === this.state.destination
    )
    this.setState({ originObj, destinationObj })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.query !== this.props.query) {
      let data = getStateFromProps({ props: this.props })
      let filteredOrigin = this.props.airports.filter(
        i => i.code === data.origin
      )
      let filteredDestination = this.props.airports.filter(
        i => i.code === data.destination
      )
      this.setState({
        origin: data.origin,
        originObj: filteredOrigin,
        destinationObj: filteredDestination,
        departure_date: data.customDDate,
        customDDate: data.customDDate,
        passengers: data.passengers,
        destination: data.destination,
        cabin_class: data.cabin_class
      })
    }
  }

  getPassengers = (type, value, index) => {
    let passengers = Object.assign({}, this.state.passengers)
    let { departure_date } = this.state
    passengers[type] = value
    // let test = Object.values(passengers)
    // let sum = test.reduce((a, b) => a + b)
    this.setState(
      { passengers, departure_date, customDDate: departure_date },
      () => {
        this.props.history.replace({
          search: `?trip_type=oneway&departure_date=${
            this.state.departure_date
          }&origin=${this.state.origin}&destination=${
            this.state.destination
          }&cabin_class=${this.state.cabin_class.value}&adults=${
            this.state.passengers.adults
          }&children=${this.state.passengers.children}&infants=${
            this.state.passengers.infants
          }&customDDate=${this.state.customDDate ? this.state.customDDate : ""}`
        })
      }
    )
  }
  handleDateSelect = date => {
    this.setState(
      {
        departure_date: date,
        customDDate: `${("0" + (date.getMonth() + 1)).slice(-2)}/${(
          "0" + date.getDate()
        ).slice(-2)}/${date.getFullYear()}`
      },
      () => {
        this.props.history.replace({
          search: `?trip_type=oneway&departure_date=${this.state.departure_date}&origin=${this.state.origin}&destination=${this.state.destination}&cabin_class=${this.state.cabin_class.value}&adults=${this.state.passengers.adults}&children=${this.state.passengers.children}&infants=${this.state.passengers.infants}&customDDate=${this.state.customDDate}`
        })
      }
    )
  }

  handleSelect = cabin_class => {
    let { departure_date, customDDate } = this.state
    this.setState(
      { cabin_class, departure_date: customDDate, customDDate },
      () => {
        this.props.history.replace({
          search: `?trip_type=oneway&departure_date=${
            this.state.departure_date
          }&origin=${this.state.origin}&destination=${
            this.state.destination
          }&cabin_class=${this.state.cabin_class.value}&adults=${
            this.state.passengers.adults
          }&children=${this.state.passengers.children}&infants=${
            this.state.passengers.infants
          }&customDDate=${customDDate ? customDDate : ""}`
        })
      }
    )
  }
  // handleSamePlace = () => {
  //   let message = "Origin and Destination should not be same."
  //   this.setState(
  //     {
  //       alertVisible: true,
  //       message
  //     },
  //     () => {
  //       setTimeout(() => {
  //         this.setState({ alertVisible: false })
  //       }, 2000)
  //     }
  //   )
  // }

  getTo = value => {
    let {
      departure_date,
      customDDate,
      passengers,
      cabin_class,
      destination
    } = this.state
    if (typeof value === "object") {
      // if (value.code !== this.state.origin) {
      let filteredDesti = this.props.airports.filter(i => i.code === value.code)
      // let filteredOrigin = this.props.airports.filter(i => i.code === origin)
      this.setState(
        {
          origin: this.state.origin,
          destinationObj: filteredDesti,
          destination: value.code,
          originObj: this.state.originObj,
          departure_date: customDDate,
          customDDate
        },
        () => {
          this.props.history.replace({
            search: `?trip_type=oneway&departure_date=${departure_date}&origin=${
              this.state.origin
            }&destination=${this.state.destination}&cabin_class=${
              cabin_class.value
            }&adults=${passengers.adults}&children=${
              passengers.children
            }&infants=${passengers.infants}&customDDate=${
              customDDate ? customDDate : ""
            }`
          })
        }
      )
      this.depDate.current.childNodes[0].click()
      // } else {
      //   this.handleSamePlace()
      // }
    }
    // else if (typeof value === "string" && !value.includes(",")) {
    //   this.setState({
    //     origin: this.state.origin,
    //     destinationObj: [],
    //     destination: "",
    //     originObj: this.state.originObj,
    //     departure_date: customDDate,
    //     customDDate
    //   })
    // }
  }

  getFrom = value => {
    let { origin: oldOrigin } = getStateFromProps({ props: this.props })
    let {
      departure_date,
      customDDate,
      passengers,
      cabin_class,
      destination
    } = this.state
    if (typeof value === "object") {
      // if (value.code !== this.state.destination) {

      let filteredOrigin = this.props.airports.filter(
        i => i.code === value.code
      )
      this.setState(
        {
          origin: value.code,
          originObj: filteredOrigin,
          destination,
          destinationObj: this.state.destinationObj,
          departure_date: customDDate,
          customDDate
        },
        () => {
          this.props.history.replace({
            search: `?trip_type=oneway&departure_date=${departure_date}&origin=${
              this.state.origin
            }&destination=${this.state.destination}&cabin_class=${
              cabin_class.value
            }&adults=${passengers.adults}&children=${
              passengers.children
            }&infants=${passengers.infants}&customDDate=${
              customDDate ? customDDate : ""
            }`
          })
        }
      )
      this.destination.current.focus()
      // } else {
      //   this.handleSamePlace()
      // }
    }
    // else if (typeof value === "string" && !value.includes(",")) {
    //   this.setState({
    //     origin: "",
    //     originObj: [],
    //     destination,
    //     // destinationObj: this.state.destinationObj,
    //     departure_date: customDDate,
    //     customDDate
    //   })
    // }
  }

  search = e => {
    let {
      departure_date,
      passengers,
      origin,
      destination,
      cabin_class,
      customDDate
    } = this.state
    validateJoi(onewaySearchSchema, {
      passengers,
      origin: origin == "undefined" ? "" : origin,
      destination: destination == "undefined" ? "" : destination,
      cabin_class,
      customDate: customDDate
    })
      .then(() => {
        let pathnam =
          this.props.location.pathname === "/search" ? "/search2" : "/search"
        // debugger
        this.props.history.push({
          pathname: pathnam,
          search: `?trip_type=oneway&departure_date=${departure_date}&origin=${origin}&destination=${destination}&cabin_class=${cabin_class.value}&adults=${passengers.adults}&children=${passengers.children}&infants=${passengers.infants}&customDDate=${customDDate}`
        })
      })
      .catch(e => {
        const message = e.params.label
        this.setState(
          {
            alertVisible: true,
            message
          },
          () => {
            setTimeout(() => {
              this.setState({ alertVisible: false })
            }, 2000)
          }
        )
      })
  }

  closeAlert = () => {
    this.setState({ alertVisible: false })
  }

  render() {
    const { query } = this.props
    let {
      // origin,
      // destination,
      originObj,
      destinationObj,
      message,
      alertVisible
    } = this.state
    return (
      <TabContainer>
        <FlexRow>
          <InputAutoComplete
            placeholder="From"
            onChange={this.getFrom}
            arrow
            cities={this.props.airports}
            initialValue={
              originObj.length
                ? `${originObj[0].value} (${originObj[0].code})`
                : ``
            }
          />
          <InputAutoComplete
            placeholder="To"
            inputRef={this.destination}
            cities={this.props.airports}
            onChange={this.getTo}
            initialValue={
              destinationObj.length
                ? `${destinationObj[0].value} (${destinationObj[0].code})`
                : ``
            }
          />
        </FlexRow>
        <DepartureDate ref={this.depDate}>
          <DatePicker
            // dateRef={r => {
            //   this.depDate = r
            // }}
            date={this.state.departure_date}
            handleChange={this.handleDateSelect}
          />
        </DepartureDate>
        <PassengersNumber className="dummy">
          <InputPassengersNumber
            inputName="Passengers"
            initialValue={this.state.passengers}
            onChange={this.getPassengers}
          />
        </PassengersNumber>
        <CabinClassFull>
          <CabinClass
            flex
            height="60px"
            fontSize="16px"
            value={this.state.cabin_class}
            handleSelect={this.handleSelect}
          />
        </CabinClassFull>
        <Stylebtn onClick={this.search}>
          SEARCH{" "}
          <img
            alt="img"
            src="/images/plane.png"
            style={{ maxWidth: "18px", marginLeft: "9px" }}
          />
        </Stylebtn>
        <Alert
          message={message}
          visible={alertVisible}
          close={this.closeAlert}
        />
      </TabContainer>
    )
  }
}

const mapToState = () =>
  createStructuredSelector({
    airports: features.landing.selectors.selectAirportList()
  })

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      searchFlights,
      progressListSearch
    },
    dispatch
  )

// Oneway.contextTypes = {
//   router: propTypes.func.isRequired
// }

export default compose(
  withRouter,
  component =>
    connect(
      mapToState,
      mapDispatch
    )(component),
  pure
)(Oneway)
