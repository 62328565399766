// import React from "react"
// import { InputsLoaders } from "base_components/Loaders"
// const InputPassengersNumber = React.lazy(() =>
//   import("./InputPassengersNumber")
// )

// class index extends React.PureComponent {
//   render() {
//     return (
//       <React.Suspense fallback={InputsLoaders()}>
//         <InputPassengersNumber {...this.props} />
//       </React.Suspense>
//     )
//   }
// }
import InputPassengersNumber from "./InputPassengersNumber"
export default InputPassengersNumber
