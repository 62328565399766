import React from "react"
import Downshift from "downshift"
import Immutable from "seamless-immutable"
import styled from "styled-components"

const InputAutoComplete = ({
  placeholder,
  inputRef,
  disabled,
  onChange,
  arrow,
  cities,
  initialValue
}) => {
  let citiesM = Immutable(cities).asMutable({ deep: true })
  return (
    <Downshift
      onChange={selection => onChange(selection)}
      onInputValueChange={a => onChange(a)}
      itemToString={item => (item ? item.value : "")}
      initialHighlightedIndex={0}
      defaultHighlightedIndex={0}
      initialSelectedItem={0}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        selectHighlightedItem,
        closeMenu,
        defaultHighlightedIndex,
        itemToString,
        highlightedItem
      }) => {
        return (
          <div style={{ position: "relative", flex: "1" }}>
            <input
              ref={inputRef}
              {...getInputProps({
                className: arrow
                  ? `search-form-input form-input testClass arrow`
                  : "search-form-input form-input testClass",
                placeholder: placeholder,
                disabled: disabled && disabled,
                style: {
                  backgroundColor: "#fff"
                },
                onKeyDown: event => {
                  if (event.key === "Tab" || event.key === "Enter") {
                    event.preventDefault()
                    event.stopPropagation()
                    selectHighlightedItem()
                  } else if (event.key === "Escape") {
                    closeMenu()
                    event.nativeEvent.preventDownshiftDefault = true
                  } else if (
                    event.key === "ArrowDown" ||
                    event.key === "ArrowUp"
                  ) {
                    // highlightedItem =
                  } else if (event.key === "Backspace") {
                    // event.nativeEvent.preventDownshiftDefault = true
                  } else {
                    event.nativeEvent.preventDownshiftDefault = true
                  }
                }
              })}
              onFocus={e => {
                e.target.select()
              }}
              // onChange={e => onChange(e)}
              value={isOpen ? inputValue : initialValue ? initialValue : ""}
            />
            {inputValue && isOpen ? (
              <ul
                {...getMenuProps({
                  style: {
                    backgroundColor: "#fff",
                    zIndex: "9999",
                    width: 400
                  }
                })}
              >
                {citiesM
                  .filter(
                    item =>
                      item.value
                        .trim()
                        .toLowerCase()
                        .includes(inputValue.toLowerCase()) ||
                      item.code
                        .toLowerCase()
                        .includes(inputValue.toLowerCase()) ||
                      item.airport_name
                        .toLowerCase()
                        .includes(inputValue.toLowerCase()) ||
                      item.country_code
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                  )
                  // .sort((a, b) =>
                  //   a.value > b.value ? 1 : b.value > a.value ? -1 : 0
                  // )
                  .slice(0, 5)
                  .map((item, index) => {
                    return (
                      <li
                        {...getItemProps({
                          key: index,
                          index,
                          item,
                          style: {
                            backgroundColor:
                              highlightedIndex === index ? "#f7f7f7" : "white",
                            fontWeight:
                              selectedItem === item ? "bold" : "normal",
                            cursor: "pointer",
                            backgroundImage: "url(../images/listicon.png)",
                            backgroundRepeat: "no-repeat",
                            borderBottom: "1px solid #e6e4eb",
                            padding: "20px",
                            paddingLeft: "35px",
                            backgroundPosition: "center left 8px",
                            textTransform: "capitalize"
                          }
                        })}
                      >
                        <DropDownContainer>
                          <CityAirportCountryName>
                            <CityNames>
                              {item.value}, {item.country_code}
                            </CityNames>
                            <div>{item.airport_name}</div>
                          </CityAirportCountryName>

                          <CodeContainer>{item.code}</CodeContainer>
                        </DropDownContainer>

                        {/* {item.value} ({item.code}) */}
                      </li>
                    )
                  })}
              </ul>
            ) : null}
          </div>
        )
      }}
    </Downshift>
  )
}

export default InputAutoComplete

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`
const CityAirportCountryName = styled.div`
  width: 80%;
  padding-left: 8px;
  padding-right: 8px;
`
const CityNames = styled.div`
  font-weight: bolder;
`
const CodeContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`
