import React, { Component, Fragment } from "react"
import SearchBar from "./SearchBar"
import { Stylebtn, TabContainer, StyleBtnAdd, Blank } from "../elements"
import { getStateFromProps } from "utilities"
import { compose, pure } from "recompose"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import { searchFlights } from "../thunks"
import { withRouter } from "react-router-dom"
import {
  multiTripSearchSchema,
  validateJoi,
  multiTripSearchSchema2
} from "shared/validations"
import Alert from "base_components/BannerAlert"
import moment from "moment"
const compareDates = (date1, date2) => {
  return moment(date1).isSameOrAfter(date2, "day")
}
class MultiCitySearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...getStateFromProps({ props }),
      message: "",
      alertVisible: false
    }
  }

  componentDidMount = () => {
    if (this.state.multiCities.length === 1) {
      this.addMultiCities()
    }
  }

  addMultiCities = () => {
    let { multiCities } = this.state
    multiCities.push({
      id: new Date(),
      origin: "",
      destination: "",
      departure_date: "",
      minDate: `${
        multiCities[multiCities.length - 1].departure_date === ""
          ? multiCities[0].departure_date
          : multiCities[multiCities.length - 1].departure_date
      }`
    })
    this.setState({ multiCities })
  }
  deleteMultiCities = data => {
    let { multiCities } = this.state
    let index = multiCities.findIndex(t => t.id === data.id)
    multiCities.splice(index, 1)
    this.setState({ multiCities })
  }

  handleSelect = selectedOption => {
    this.setState({ cabin_class: selectedOption })
  }

  getPassengers = (type, value, index) => {
    let passengers = Object.assign({}, this.state.passengers)
    passengers[type] = value
    let test = Object.values(passengers)
    let sum = test.reduce((a, b) => a + b)
    this.setState({ passengerSum: sum })
    this.setState({ passengers })
  }

  getValuesFromChild = (index, values, type, subType) => {
    let { multiCities } = this.state
    let test = multiCities[index]
    if (type === "cities") {
      test.origin = values.origin
      if (subType === "origin" && values.origin) {
        this[`destination${index}`].focus()
      } else if (subType === "destination" && values.destination) {
        this[`dates${index}`].childNodes[0].click()
      }
      multiCities[index] = test
      test.destination = values.destination
      multiCities[index] = test
      this.setState({ multiCities })
    } else if (type === "departure_date") {
      setTimeout(() => {
        this[`origin${index + 1}`] && this[`origin${index + 1}`].focus()
      }, 200)
      test.departure_date = values
      multiCities[index] = test
      let length = multiCities.length
      this.setState({ multiCities })
      if (index > 0) {
        for (let i = index; i < length; i++) {
          let m = multiCities[i]
          if (m.departure_date !== "") {
            if (compareDates(values, m.departure_date)) {
              m.minDate = values
              multiCities[i] = m
              m.departure_date = values
              this.setState({ multiCities })
            } else {
              m.departure_date = values
              multiCities[i] = m
              this.setState({ multiCities })
            }
          } else {
            m.minDate = values
            multiCities[i] = m
            this.setState({ multiCities })
          }
        }
      }
      if (index === 0 || index < length - 1) {
        for (let i = index; i < length; i++) {
          let trip = multiCities[i]
          if (compareDates(values, trip.departure_date)) {
            let trip2 = multiCities[i]
            trip2.minDate = values
            trip2.departure_date = values
            multiCities[i] = trip2
            this.setState({ multiCities })
          } else {
            let trip2 = multiCities[i]
            trip2.minDate = values
            multiCities[i] = trip2
            this.setState({ multiCities })
          }
        }
      }
    }
  }
  search = () => {
    let {
      passengers,
      cabin_class,
      return_date,
      customDDate,
      customRDate,
      departure_date,
      multiCities
    } = this.state
    this.setState({ return_date, customDDate, customRDate, departure_date })
    let errors = []
    // setLocalStorage(this.state, "multicitySearchKey")
    multiCities.map((item, index) => {
      if (index === 0) {
        validateJoi(multiTripSearchSchema2, item)
          .then(res => {})
          .catch(e => {
            errors.push(e.message)
          })
      } else {
        validateJoi(multiTripSearchSchema, item)
          .then(res => {})
          .catch(e => {
            errors.push(e.params.label)
          })
      }
    })
    setTimeout(() => {
      if (errors.length) {
        const message = errors[0]
        this.setState(
          {
            alertVisible: true,
            message
          },
          () => {
            setTimeout(() => {
              this.setState({ alertVisible: false })
            }, 2000)
          }
        )
      } else {
        let myString = JSON.stringify(this.state)
        let pathname =
          this.props.location.pathname === "/search" ? "/search2" : "/search"
        this.props.history.push({
          pathname,
          search: `?trip_type=multicity&adults=${passengers.adults}&children=${passengers.children}&infants=${passengers.infants}&cabin_class=${cabin_class.value}&multicitySearchKey=${myString}`
        })
      }
    }, 300)
  }

  closeAlert = () => {
    this.setState({ alertVisible: false })
  }

  render() {
    let {
      multiCities,
      origin,
      destination,
      departure_date,
      message,
      alertVisible
    } = this.state

    return (
      <Fragment>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {multiCities && multiCities.length
            ? multiCities.map((i, j) => {
                return (
                  <TabContainer key={j}>
                    <SearchBar
                      key={j}
                      data={i}
                      added={j}
                      originRef={r => {
                        this[`origin${j}`] = r
                      }}
                      destinationRef={r => {
                        this[`destination${j}`] = r
                      }}
                      datesRef={r => {
                        this[`dates${j}`] = r
                      }}
                      passengers={this.state.passengers}
                      getPassengers={this.getPassengers}
                      handleSelect={this.handleSelect}
                      cabin_class={this.state.cabin_class}
                      deleteMultiCities={this.deleteMultiCities}
                      getValuesFromChild={(j, values, type, subType) =>
                        this.getValuesFromChild(j, values, type, subType)
                      }
                    />
                    {j > 0 ? <Blank /> : null}
                    {j === 1 ? (
                      <Blank style={{ padding: "21px 17px" }} />
                    ) : null}
                    {j === 0 ? (
                      <Stylebtn onClick={this.search}>
                        SEARCH{" "}
                        <img
                          alt="plane"
                          src="/images/plane.png"
                          style={{ maxWidth: "18px", marginLeft: "9px" }}
                        />
                      </Stylebtn>
                    ) : null}
                  </TabContainer>
                )
              })
            : null}
        </div>
        <StyleBtnAdd
          onClick={this.addMultiCities}
          disabled={multiCities && multiCities.length > 3 ? true : false}
        >
          <i className="fa fa-plus" /> Add
        </StyleBtnAdd>
        <Alert
          message={message}
          visible={alertVisible}
          close={this.closeAlert}
        />
      </Fragment>
    )
  }
}

const mapState = () => createStructuredSelector({})

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      searchFlights
    },
    dispatch
  )

export default compose(
  withRouter,
  component =>
    connect(
      mapState,
      mapDispatch
    )(component),
  pure
)(MultiCitySearchBar)
