import React, { useEffect, useState } from "react"
import localforage from "localforage"
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert,
  InputGroup
} from "reactstrap"
import { fetchService } from "services/API"
import { ContactContainer, UserContactForm, CenteredDiv } from "./elements"

const MobileAppPayment = ({ apiToken = null }) => {
  const [token, setToken] = useState(apiToken)
  const [user, setUser] = useState(null)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [totalAmount, setTotalAmount] = useState(null)
  const [currency, setCurrency] = useState("BDT")
  const [errorTexts, setErrorTexts] = useState([])
  const [formSubmitted, setFormSubmitted] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (user) {
        return
      }

      let tokenInUrl

      if (!token) {
        const searchParams = new URLSearchParams(window.location.search)
        tokenInUrl = searchParams.get("token") || null

        if (tokenInUrl) {
          setToken(tokenInUrl)
        }
      }

      let storedToken

      if (!token && !tokenInUrl) {
        try {
          const data = await localforage.getItem("persist:tripovy")
          storedToken = JSON.parse(JSON.parse(data).login).user.api_token

          setToken(storedToken)
        } catch (error) {
          console.log(error)
        }
      }

      if (!token && !storedToken && !tokenInUrl) {
        window.open(`/?redirect=%2F`, "_self")
        return
      }

      if (!token) {
        return
      }

      try {
        const fetchedUser = (
          await fetchService({
            method: "GET",
            url: `api/v1/user?api_token=${token}`
          })
        ).data

        if (fetchedUser) {
          setUser(fetchedUser)
          setName(fetchedUser.profile.name || "")
          setEmail(fetchedUser.profile.email || "")
          setPhone(fetchedUser.profile.phone || "")
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [user, token])

  return (
    <ContactContainer>
      {formSubmitted && (
        <CenteredDiv>
          <Alert color="info">
            Please wait. We're redirecting you to the payment page...
          </Alert>
        </CenteredDiv>
      )}

      {user &&
        !formSubmitted &&
        (() => {
          console.log({ user })
        }) && (
          <UserContactForm>
            <Form
              onSubmit={async event => {
                event.preventDefault()

                setFormSubmitted(true)

                try {
                  const result = await fetchService({
                    method: "POST",
                    url: `api/v2/invoice-by-customer/create`,
                    body: {
                      api_token: token,
                      total_amount: totalAmount,
                      currency: currency,
                      cus_name: name,
                      cus_email: email,
                      cus_phone: phone
                    }
                  })

                  console.log({ result })

                  if (!result) {
                    setFormSubmitted(false)
                  }

                  // data: {
                  //   "status": "success",
                  //   "error_reason": "",
                  //   "invoice_refer": "5B1F9DE4D82B6",
                  //   "pay_url": "https://sandbox.sslcommerz.com/gwprocess/v4/pay.php?refer=TEST-INV628F5BD4E719E",
                  //   "qr_image_url": "",
                  //   "qr_image_pay_url": "https://merchant.sslcommerz.com/web/images/generateQR.php?QR=https://sandbox.sslcommerz.com/gwprocess/v4/pay.php?refer=TEST-INV628F5BD4E719E",
                  //   "invoice_id": "TEST-INV628F5BD4E719E",
                  //   "email_sending_status": "SUCCESS"
                  // }

                  const { data, success, errors, message } = result

                  if (success === false && errors) {
                    console.log(Object.values(errors))
                    setFormSubmitted(false)
                    setErrorTexts(Object.values(errors))
                    return
                  } else if (success === false && message) {
                    setFormSubmitted(false)
                    setErrorTexts([message])
                    return
                  }

                  if (data) {
                    const { status, pay_url, error_reason } = data

                    if (status === "success" && pay_url) {
                      window.open(pay_url, "_self")
                    } else if (error_reason && error_reason.length) {
                      setFormSubmitted(false)
                      setErrorTexts([error_reason])
                      return
                    }
                  }
                } catch (error) {
                  console.log(error)
                  setFormSubmitted(false)
                }
              }}
            >
              <FormGroup>
                <FormText>
                  Please fill up the form below with the proper amount you need
                  to pay and complete the payment in the next page.
                </FormText>
              </FormGroup>

              <FormGroup>
                <Label for="name">Name:</Label>
                <Input
                  required={true}
                  type="text"
                  maxLength="35"
                  name="name"
                  id="name"
                  placeholder="Enter your name"
                  value={name || ""}
                  onChange={value => {
                    setName(value.currentTarget.value)
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label for="email">Email:</Label>
                <Input
                  required={true}
                  type="email"
                  maxLength="35"
                  name="email"
                  id="email"
                  placeholder="Enter your email address"
                  value={email || ""}
                  onChange={value => {
                    setEmail(value.currentTarget.value)
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label for="phone">Phone:</Label>
                <Input
                  required={true}
                  type="tel"
                  name="phone"
                  id="phone"
                  placeholder="Enter your phone number"
                  value={phone || ""}
                  onChange={value => {
                    setPhone(value.currentTarget.value)
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label for="totalamount">Amount:</Label>
                <InputGroup>
                  <Input
                    required={true}
                    type="number"
                    name="totalamount"
                    id="totalamount"
                    placeholder="Enter the total amount"
                    value={totalAmount || ""}
                    onChange={value => {
                      setTotalAmount(+value.currentTarget.value)
                    }}
                  />
                  <div style={{ marginLeft: "3px", maxWidth: "35vw" }}>
                    <Input
                      required={true}
                      type="select"
                      name="currency"
                      id="currency"
                      onChange={event => {
                        const value = event.currentTarget.value
                        setCurrency(value)
                      }}
                    >
                      <option value={"BDT"} defaultValue={currency}>
                        Taka (BDT)
                      </option>
                      <option value={"USD"}>US Dollar (USD)</option>
                    </Input>
                  </div>
                </InputGroup>
              </FormGroup>

              {(errorTexts && errorTexts.length && errorTexts.length > 0 && (
                <FormGroup>
                  {errorTexts.map(error => {
                    if (error.length === 1) {
                      return (
                        <div key={error[0]}>
                          <Alert color="danger">{error[0]}</Alert>
                        </div>
                      )
                    } else {
                      return (
                        <div key={error[0]}>
                          {error.map(e => (
                            <Alert key={e} color="danger">
                              {e}
                            </Alert>
                          ))}
                        </div>
                      )
                    }
                  })}
                </FormGroup>
              )) ||
                ""}

              {(name &&
                email &&
                phone &&
                totalAmount &&
                totalAmount > 0 &&
                currency && (
                  <FormGroup>
                    <Input
                      type="submit"
                      name="submit"
                      id="submit"
                      value="Submit"
                    ></Input>
                  </FormGroup>
                )) ||
                ""}
            </Form>

            <div style={{ height: "300px" }}></div>
          </UserContactForm>
        )}
    </ContactContainer>
  )
}

export default MobileAppPayment
