import React, { useEffect, useState } from "react"
import localforage from "localforage"
import { Form, FormGroup, Label, Input, FormText, Alert } from "reactstrap"
import { fetchService } from "services/API"
import { ContactContainer, UserContactForm, CenteredDiv } from "./elements"

const NOTHING = "nothing"

const ContactUs = () => {
  const [token, setToken] = useState(null)
  const [user, setUser] = useState(null)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [havePNR, setHavePNR] = useState(null)
  const [PNR, setPNR] = useState("")
  const [pnrs, setPNRs] = useState([])
  const [bookings, setBookings] = useState([])
  const [flightSegments, setFlightSegments] = useState([])
  const [oldFlight, setOldFlight] = useState(null)
  const [newDate, setNewDate] = useState("")
  const [newTime, setNewTime] = useState("")
  const [purpose, setPurpose] = useState(null)
  const [freeText, setFreeText] = useState("")
  const [formSubmitted, setFormSubmitted] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (user) {
        return
      }

      let tokenInUrl

      if (!token) {
        const searchParams = new URLSearchParams(window.location.search)
        tokenInUrl = searchParams.get("token") || null

        if (tokenInUrl) {
          setToken(tokenInUrl)
        }
      }

      let storedToken

      if (!token && !tokenInUrl) {
        try {
          const data = await localforage.getItem("persist:tripovy")
          storedToken = JSON.parse(JSON.parse(data).login).user.api_token

          setToken(storedToken)
        } catch (error) {
          console.log(error)
        }
      }

      if (!token) {
        return
      }

      try {
        const fetchedUser = (
          await fetchService({
            method: "GET",
            url: `api/v1/user?api_token=${token}`
          })
        ).data

        if (fetchedUser) {
          setUser(fetchedUser)
          setName(fetchedUser.profile.name || "")
          setEmail(fetchedUser.profile.email || "")
          setPhone(fetchedUser.profile.phone || "")
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [user, token])

  useEffect(() => {
    console.log({ havePNR })
  }, [havePNR])

  useEffect(() => {
    ;(async () => {
      if (!token) {
        return
      }

      try {
        const { data } = await fetchService({
          method: "POST",
          url: "v2/flight/bookings",
          body: {
            api_token: token
          }
        })

        setBookings(data)
        setPNRs(data.map(d => d.pnr))
      } catch (error) {
        console.log(error)
      }
    })()
  }, [token])

  return (
    <ContactContainer>
      {formSubmitted && (
        <CenteredDiv>
          <Alert color="info">
            Thank you! We have received your query. We'll contact you shortly.
          </Alert>
        </CenteredDiv>
      )}

      {!formSubmitted && (
        <UserContactForm>
          <div
            className="call-container"
            style={{
              position: "relative",
              padding: "5px 10px",
              maxWidth: "360px",
              background: "linear-gradient(to right,#0dafb7, #82d6da)",
              height: token ? "96px" : "90px",
              borderRadius: "10px",
              margin: "auto",
              marginBottom: "10px"
            }}
          >
            <div
              className="inner-circle-white"
              style={{
                zIndex: 1000,
                display: "flex",
                position: "absolute",
                width: "80px",
                height: "80px",
                borderRadius: "45px",
                backgroundColor: "white"
              }}
            >
              <div
                className="inner-circle-white"
                style={{
                  zIndex: 1000,
                  position: "relative",
                  width: "65px",
                  height: "65px",
                  borderRadius: "35px",
                  backgroundColor: "white",
                  border: "1px solid #00aab2",
                  alignContent: "center",
                  justifyContent: "center",
                  left: "10%",
                  top: "10%"
                }}
              >
                <img
                  src="/images/support-call-icon.png"
                  alt="call"
                  style={{
                    position: "absolute",
                    left: "50%",
                    transform: "translate(-50%)",
                    bottom: "20%"
                  }}
                />
              </div>
            </div>
            <div
              className="text"
              style={{
                borderRadius: "15px 15px 0px 15px",
                height: "60px",
                paddingLeft: "40px",
                top: "4px",
                marginLeft: "10%",
                backgroundColor: "#00aab2",
                maxWidth: "300px",
                color: "white"
              }}
            >
              <p
                style={{
                  margin: "auto 0px",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bold",
                  marginTop: "10px"
                }}
              >
                <a
                  href="tel:09610991199"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  09610991199
                </a>
              </p>
              <p
                style={{
                  margin: "auto 0px",
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: "bold"
                }}
              >
                Saturday to Thursday 9AM to 10PM
              </p>
            </div>

            {!token && (
              <p
                style={{
                  margin: "auto 10px",
                  textAlign: "right",
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "white"
                }}
              >
                <span>While calling from abroad: </span>
                <a
                  href="tel:+8809610991199"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  +8809610991199
                </a>
              </p>
            )}
          </div>

          <Form
            onSubmit={async event => {
              event.preventDefault()

              setFormSubmitted(true)

              const message = `Name: ${name},  Phone: ${phone},  Email: ${email},  ${
                !havePNR ? "Message: " : ""
              }${freeText},  PNR: ${PNR || "Not Given"}`

              try {
                const result = await fetchService({
                  method: "POST",
                  url: token
                    ? `api/v1/contact?api_token=${token}`
                    : `api/v1/contact`,
                  body: {
                    email: email,
                    name: name,
                    message: message
                  }
                })

                console.log({ result })
              } catch (error) {
                console.log(error)
              }
            }}
            style={{ maxWidth: "600px", margin: "auto" }}
          >
            <FormGroup>
              {token && (
                <FormText>
                  <b>To change date:</b> Please fill up the form with your
                  booking ID (PNR) and new flight date and time. We will contact
                  you mentioning any change fee and/or fare difference. We will
                  provide a payment link to pay the date-change fee.
                  <br />
                  <b>To refund/cancel:</b> Please fill up the form mentioning
                  that you wish to refund or cancel the ticket. We will mention
                  the refund charge and refund the balance back to your payment
                  method (card/bkash/internet banking). The process normally
                  takes 7-14 days to complete.
                </FormText>
              )}

              {!token && <h2>Contact Tripovy</h2>}
            </FormGroup>

            <FormGroup>
              <Label for="name">Name:</Label>
              <Input
                required={true}
                type="text"
                maxLength="35"
                name="name"
                id="name"
                placeholder="Enter your name"
                value={name || ""}
                onChange={value => {
                  setName(value.currentTarget.value)
                }}
              />
            </FormGroup>

            <FormGroup>
              <Label for="email">Email:</Label>
              <Input
                required={true}
                type="email"
                maxLength="35"
                name="email"
                id="email"
                placeholder="Enter your email address"
                value={email || ""}
                onChange={value => {
                  setEmail(value.currentTarget.value)
                }}
              />
            </FormGroup>

            <FormGroup>
              <Label for="phone">Phone:</Label>
              <Input
                required={true}
                type="tel"
                name="phone"
                id="phone"
                placeholder="Enter your phone number"
                value={phone || ""}
                onChange={value => {
                  setPhone(value.currentTarget.value)
                }}
              />
            </FormGroup>

            {token && (
              <FormGroup>
                <Label for="havePNR">Choose an option:</Label>
                <Input
                  required={true}
                  type="select"
                  name="havePNR"
                  id="havePNR"
                  onChange={event => {
                    setHavePNR(null)
                    setFreeText("")

                    const value = event.currentTarget.value

                    if (value === NOTHING) {
                      return
                    }

                    setHavePNR(value === "true" ? true : false)
                  }}
                >
                  <option value={NOTHING} defaultValue={NOTHING}>
                    --- SELECT ---
                  </option>
                  <option value={true}>
                    I have booking confirmation (PNR)
                  </option>
                  <option value={false}>
                    I don't have booking confirmation (PNR)
                  </option>
                </Input>
              </FormGroup>
            )}

            {havePNR === true && (
              <FormGroup>
                <Label for="PNR">Choose an option:</Label>
                <Input
                  type="select"
                  name="PNR"
                  id="PNR"
                  onChange={event => {
                    const value = event.currentTarget.value

                    if (value === NOTHING) {
                      return
                    }

                    setPNR(value)

                    const selectedPNR = value

                    const booking = bookings.find(b => b.pnr === selectedPNR)

                    /** @type {Array} */
                    let allFlightSegments =
                      booking.flight_details.departing_flight_segments

                    if (booking.flight_details.returning_flight_segments) {
                      allFlightSegments = allFlightSegments.concat(
                        booking.flight_details.returning_flight_segments
                      )
                    }

                    setFlightSegments(
                      allFlightSegments.map(
                        f =>
                          `${f.departure_airport.code} - ${f.arrival_airport.code}, ${f.departure_date.day}/${f.departure_date.month}/${f.departure_date.year} ${f.departure_time.hour}:${f.departure_time.minute}`
                      )
                    )
                  }}
                >
                  <option value={NOTHING} defaultValue={NOTHING}>
                    --- SELECT PNR ---
                  </option>

                  {pnrs.map(p => {
                    return (
                      <option key={p} value={p}>
                        {p}
                      </option>
                    )
                  })}
                </Input>
              </FormGroup>
            )}

            {PNR && (
              <FormGroup>
                <Label for="purpose">Choose an option:</Label>
                <Input
                  type="select"
                  name="purpose"
                  id="purpose"
                  onChange={event => {
                    const value = event.currentTarget.value

                    if (value === NOTHING) {
                      return
                    }

                    setPurpose(value)

                    if (value === "REFUND") {
                      setFreeText(
                        `${value}, ${
                          event.currentTarget.options[
                            event.currentTarget.selectedIndex
                          ].textContent
                        }`
                      )
                    }
                  }}
                >
                  <option value={NOTHING} defaultValue={NOTHING}>
                    --- SELECT PURPOSE ---
                  </option>

                  <option value={"REFUND"}>
                    I want to refund/cancel the booking
                  </option>
                  <option value={"REISSUE"}>
                    I want to change date and time of the flight
                  </option>
                  <option value={"OTHER"}>Other</option>
                </Input>
              </FormGroup>
            )}

            {(havePNR === false ||
              (havePNR === null && !token) ||
              (havePNR === true && purpose === "OTHER")) && (
              <FormGroup>
                <Label for="freetext">Your Message:</Label>

                <Input
                  required={true}
                  type="textarea"
                  name="freetext"
                  id="freetext"
                  placeholder="Type your query here"
                  rows={5}
                  maxLength={500}
                  onChange={event => {
                    setFreeText(event.currentTarget.value)
                  }}
                ></Input>
              </FormGroup>
            )}

            {havePNR === true &&
              purpose === "REISSUE" &&
              flightSegments.length > 0 && (
                <>
                  <FormGroup>
                    <Label for="flight">Choose old flight:</Label>
                    <Input
                      type="select"
                      name="flight"
                      id="flight"
                      onChange={event => {
                        const value = event.currentTarget.value

                        if (value === NOTHING) {
                          return
                        }

                        setOldFlight(value)
                      }}
                    >
                      <option value={NOTHING} defaultValue={NOTHING}>
                        --- SELECT OLD FLIGHT ---
                      </option>

                      {flightSegments.map(flight => (
                        <option key={flight} value={flight}>
                          {flight}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>

                  {oldFlight && (
                    <>
                      <FormGroup>
                        <Label for="newdate">New Flight Date:</Label>

                        <Input
                          required={true}
                          type="date"
                          name="newdate"
                          id="newdate"
                          onChange={event => {
                            setFreeText("")

                            const value = event.currentTarget.value
                            setNewDate(value)

                            if (newTime) {
                              setFreeText(
                                `Old flight to change: ${oldFlight},  New Date-Time: ${value} ${newTime}`
                              )
                            }
                          }}
                          value={newDate}
                        ></Input>
                      </FormGroup>

                      <FormGroup>
                        <Label for="newtime">New Flight Time:</Label>

                        <Input
                          required={true}
                          type="time"
                          name="newtime"
                          id="newtime"
                          onChange={event => {
                            setFreeText("")

                            const value = event.currentTarget.value
                            setNewTime(value)

                            if (newDate) {
                              setFreeText(
                                `Old flight to change: ${oldFlight},  New Date-Time: ${newDate} ${value}`
                              )
                            }
                          }}
                          value={newTime}
                        ></Input>
                      </FormGroup>
                    </>
                  )}
                </>
              )}

            {((havePNR === false && freeText) ||
              (havePNR === null && !token) ||
              (havePNR === true && purpose === "REFUND") ||
              (havePNR === true && purpose === "REISSUE" && freeText) ||
              (havePNR === true && purpose === "OTHER" && freeText)) && (
              <FormGroup>
                <Input
                  type="submit"
                  name="submit"
                  id="submit"
                  value="Submit"
                ></Input>
              </FormGroup>
            )}

            <FormGroup>
              <FormText
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                  marginTop: "50px"
                }}
              >
                <div>
                  <div style={{ fontWeight: "bold" }}>Head Office</div>

                  <div>
                    32-34 Zakir Hossain Society,
                    <br />
                    South Khulshi, Road no. 3,
                    <br />
                    Chittagong, Bangladesh
                    <br />
                    <a
                      href="mailto:support@tripovy.com"
                      style={{ textDecoration: "none", color: "unset" }}
                    >
                      support@tripovy.com
                    </a>
                  </div>
                </div>

                <div>
                  <div style={{ fontWeight: "bold" }}>UK Office</div>

                  <div>
                    71-75 Shelton Street,
                    <br />
                    Covent Garden, London,
                    <br />
                    United Kingdom, WC2H 9JQ
                    <br />
                    <a
                      href="mailto:support@tripovy.com"
                      style={{ textDecoration: "none", color: "unset" }}
                    >
                      support@tripovy.com
                    </a>
                  </div>
                </div>

                <div>
                  <div style={{ fontWeight: "bold" }}>UAE Office</div>

                  <div>
                    Unit 505, Al Maya Building,
                    <br />
                    Near Al Nahda Park,
                    <br />
                    Sharjah, UAE
                    <br />
                    <a
                      href="mailto:support@tripovy.com"
                      style={{ textDecoration: "none", color: "unset" }}
                    >
                      support@tripovy.com
                    </a>
                  </div>
                </div>
              </FormText>
            </FormGroup>
          </Form>

          <div style={{ height: "300px" }}></div>
        </UserContactForm>
      )}
    </ContactContainer>
  )
}

export default ContactUs
