import React, { Component, Fragment } from "react"
import {
  BigCard,
  CardImg,
  Fromtext,
  DirectFlight,
  Flightdetail,
  SmallCard,
  TotalHeading,
  TotalPrice,
  SelectBtn,
  MainCard
} from "./elements"
import { FaArrowRight, FaPlane } from "react-icons/fa"
import CardDetailT from "./CardDetail"
import {
  priceItinerary,
  // getAirlineName,
  // flightTimings,
  // getFlightCardData,
  // cabinClassArray,
  getMajorFlightDetails,
  getNewFlightDuration
} from "filterFunctions"
// import { tripType } from "utilities"
import { compose, pure } from "recompose"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import { withRouter } from "react-router-dom"
import { selectors } from "../ducks"
import SplitView from "./splitView"
import CardDetail from "../OnewayResults/CardDetail"
import { TimeConvert24to12 } from "shared/ticketFuctions"

const RoundTripContainer = ({
  splitView,
  data,
  updateProgress,
  updateFilterComponent,
  allAirports,
  getScrollLocations
}) => {

  if (splitView === true) {
    return (
      <SplitView
        id="split"
        allAirports={allAirports}
        data={data}
        splitView={splitView}
        updateProgress={updateProgress}
        updateFilterComponent={updateFilterComponent}
        getScrollLocations={getScrollLocations}
      />
    )
  } else {
    return (
      <Fragment>
        {data.map((item, i) => (
          <RoundTripResults
            allAirports={allAirports}
            key={i}
            data={item}
            splitView={splitView}
            updateProgress={updateProgress}
            updateFilterComponent={updateFilterComponent}
            getScrollLocations={getScrollLocations}
          />
        ))}
      </Fragment>
    )
  }
}

const mapState = () =>
  createStructuredSelector({
    splitView: selectors.selectSplitView()
  })

const mapDispatch = dispatch => bindActionCreators({}, dispatch)

export default compose(
  withRouter,
  component =>
    connect(
      mapState,
      mapDispatch
    )(component),
  pure
)(RoundTripContainer)

export class RoundTripResults extends Component {
  state = {
    showdetails: false
  }
 
  showDetails = () => {
    this.setState({ showdetails: !this.state.showdetails })
 
  }
  selectFlight = (data) =>{
    this.props.updateProgress({ selectedFlight: data })
    this.props.updateFilterComponent("returning")
    this.props.getScrollLocations()
  }
  render() {
    let { showdetails } = this.state
    let { updateProgress, data, splitView, allAirports,updateFilterComponent } = this.props

    let {
      departing_flight_segments,
      price_itinerary,
      // seats_remaining,
      returning_flight_segments,
      departing_flight_duration,
      returning_flight_duration
    } = data
    let { amount, currency, cabin_code } = priceItinerary(price_itinerary)
    let { DTime, RTime } = getNewFlightDuration(
      departing_flight_duration,
      returning_flight_duration
    )
    return (
      <div>
        <MainCard>
          <div style={styleD} className="big-card" onClick={this.showDetails}>
            <FlightSegments
              data={departing_flight_segments}
              DTime={DTime}
              allAirports={allAirports}
            />
            {splitView === true ? null : (
              <FlightSegments
                data={returning_flight_segments}
                DTime={RTime}
                allAirports={allAirports}
              />
            )}
            <Flightdetail onClick={this.showDetails}>
              Flight details
            </Flightdetail>
          </div>
          <SmallCard>
            <div>
              <TotalHeading>{currency}</TotalHeading>
              <TotalPrice>{amount.toLocaleString("en-IN")}</TotalPrice>
            </div>
            <SelectBtn onClick={() => this.selectFlight(data)}>
              Select <FaArrowRight />
            </SelectBtn>
          </SmallCard>
        </MainCard>
        {showdetails ? (
          splitView === true ? (
            <CardDetail
              hideDetails={this.showDetails}
              data={data}
              cabinCodes={cabin_code}
            />
          ) : (
            <CardDetailT
              hideDetails={this.showDetails}
              data={data}
              cabinCodes={cabin_code}
              splitView={splitView}
            />
          )
        ) : null}
      </div>
    )
  }
}

const FlightSegments = ({ data, DTime, allAirports }) => {
  let {
    depTime,
    arrTime,
    depAirport,
    arrAirport,
    stopInt,
    sAValue,
    airlineName,
    totalDuration,
    airlineCode,
    arrivalCity,
    depCity
  } = getMajorFlightDetails(data, allAirports)
  return (
    <BigCard>
      <CardImg>
        <img
          src={`${process.env.REACT_APP_API_URL}v2/flight/airlines/logos/${airlineCode}`}
          alt="N/A"
        />
        <p>{airlineName}</p>
      </CardImg>
      <Fromtext title={`${depCity} (${depAirport})`}>
        <h4>{TimeConvert24to12(depTime)}</h4>
        <h5>
          {depCity}
          <br />({depAirport})
        </h5>
      </Fromtext>
      <DirectFlight>
        <h4>{DTime}</h4>
        {stopInt ? (
          <Fragment>
            <StopsImage stops={stopInt} />
            <h5 className="stop">
              {stopInt} Stop{" "}
              <span style={{ color: "#7c7b84" }}>({sAValue})</span>
            </h5>
          </Fragment>
        ) : (
          <Fragment>
            <StopsImage />
            <h5 className="direct">Direct</h5>
          </Fragment>
        )}
      </DirectFlight>
      <Fromtext title={`${arrivalCity} (${arrAirport})`}>
        <h4>{TimeConvert24to12(arrTime)}</h4>
        <h5>
          {arrivalCity} <br /> ({arrAirport})
        </h5>
      </Fromtext>
    </BigCard>
  )
}

const styleD = {
  borderRadius: "10px",
  background: "#fff",
  display: "flex",
  flexDirection: "column",
  width: "67%"
}

export const StopsImage = ({ stops }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: "3px" }}>
      <div
        className="flight duration"
        style={{
          borderBottom: "2px solid #ccc",
          flex: 9,
          position: "relative",
          display: "flex",
          justifyContent: "center"
        }}
      >
        {stops ? StopDots(stops) : null}
      </div>
      <FaPlane style={{ flex: 1, marginLeft: "5px" }} />
      {/* <i className="fa fa-plane" style={{ flex: 1, marginLeft: "5px" }} /> */}
    </div>
  )
}

const StopDots = stops => {
  let data = []
  for (let i = 1; i <= stops; i++) {
    data.push(
      <span
        key={i}
        style={{
          color: "red",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "0px",
          marginRight: "5px"
        }}
      >
        &#9679;
      </span>
    )
  }
  return data
}

// amount.toLocaleString("en-IN", {
//   style: "currency",
//   currency: currency,
//   currencyDisplay: "symbol"
// }
