import React from "react"
import { withRouter } from "react-router-dom"
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
    // else {
    //   window.scrollTo(0, 0)
    // }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)

// export let detectBrowser = () => {
//   let ua = navigator.userAgent.match(
//     /(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i
//   )
//   let browser
//   if (
//     navigator.userAgent.match(/Edge/i) ||
//     navigator.userAgent.match(/Trident.*rv[ :]*11\./i)
//   ) {
//     browser = "msie"
//   } else {
//     browser = ua[1].toLowerCase()
//   }
//   return { browser }
// }

// export let topFunction = () => {
//   debugger
//   let { browser } = detectBrowser()
//   if (browser !== "safari") {
//     return (document.documentElement.scrollTop = 0)
//   } else {
//     return (document.body.scrollTop = 0)
//   }
//   // return (document.body.scrollTop = 0); // For Safari
//   // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
// }
