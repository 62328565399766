// import joi from "joi"
import { string, object, ref, date } from "yup"

export const passengerInfoFormSchema = (type, passport, idx, country_code) => {
  if (type === "adult") {
    return object().shape({
      title: string(),
      first_name: string().required("First name is required"),
      last_name: string().required("Last name is required"),
      birth_date: passport
        ? string().required("Date of birth is required")
        : string(),
      email: idx
        ? string().email()
        : string()
            .email()
            .required("Email is required"),
      country_code: string(),
      phone: idx
        ? string()
        : string()
            .required("Phone is required")
            .test(
              "phone validate",
              "Phone number length must be 10 digits",
              value =>
                country_code.toString() === "880"
                  ? value.toString().indexOf(0) === 0
                    ? value.length <= 11
                      ? true
                      : false
                    : value.length <= 10
                    ? true
                    : false
                  : true
            ),
      passport_number: passport
        ? string().required("Passport number is required")
        : string(),
      passport_country_of_issue: string(),
      passport_nationality: string(),
      passport_expiry_date: string(),
      country: string().required("Country is required")
    })
  } else {
    return object().shape({
      title: string(),
      first_name: string().required("First name is required"),
      last_name: string().required("Last name is required"),
      birth_date: string().required("Date of birth is required"),
      country_code: string(),
      passport_number: passport
        ? string().required("Passport number is required")
        : string(),
      passport_country_of_issue: string(),
      passport_nationality: string(),
      passport_expiry_date: string(),
      country: string().required("Country is required")
    })
  }
}

export const onewaySearchSchema = object().shape({
  origin: string()
    .required()
    .label("Origin is missing"),
  destination: string()
    .required()
    .label("Destination is missing"),
  customDate: string()
    .required()
    .label("Date is missing")
})

export const roundtripSearchSchema = object().shape({
  origin: string()
    .required()
    .label("Origin is missing"),
  destination: string()
    .required()
    .label("Destination is missing"),
  customDDate: string()
    .required()
    .label("Departure Date is missing"),
  customRDate: string()
    .required()
    .label("Return Date is missing")
})

export const multiTripSearchSchema = object().shape({
  origin: object()
    .required()
    .label("Origin is missing"),
  destination: object()
    .required()
    .label("Destination is missing"),
  departure_date: date()
    .required()
    .label("Departure Date is missing")
})

export const multiTripSearchSchema2 = object().shape({
  origin: string()
    .test("test", "Origin is missing", val => {
      if (val) {
        if (typeof val === "string" || typeof val === "object") {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    })
    .required(),
  destination: string()
    .required()
    .label("Destination is missing"),
  departure_date: date()
    .required()
    .label("Departure Date is missing")
})

export const validate = (schema, values) =>
  schema
    .isValid(values)
    .then(valid => {
      // valid // => true
    })
    .catch(err => {
      //   err.name // => 'ValidationError'
      //   err.errors // => ['Deve ser maior que 18']
    })

export const validateJoi = (schema, fields) => {
  return schema.validate(fields)
}

export const loginSchema = object().shape({
  email: string()
    .email("Invalid Email")
    .required("Email is required"),
  password: string().required("Password is Required")
})

export const emailSchema = object().shape({
  email: string()
    .email("Invalid Email")
    .required("Email is required")
})

export const registerSchema = object().shape({
  email: string()
    .email("Invalid Email")
    .required("Email is required"),
  title: string(),
  fname: string()
    .min(3, "Must be greater than 3 digit!")
    .required("First name is required"),
  sname: string()
    .min(3, "Must be greater than 3 digit!")
    .required("Last name is required"),
  phone: string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .required("Phone number is required"),
  password: string()
    .min(6, "Must be greater than 6 digit!")
    .required("Password is Required"),
  password_confirmation: string()
    .oneOf([ref("password")], "Password did not matched")
    .required("Password is required")
})

export const passengerFormValidate = object().shape({
  title: string(),
  first_name: string().required("First name is required"),
  last_name: string().required("Last name is required"),
  birth_date: string().required("Birth date is required"),
  email: string()
    .email()
    .required("Email is required"),
  country_code: string(),
  phone: string().required("Phone is required"),
  passport_number: string().required("Passport number is required"),
  passport_country_of_issue: string().required(
    "Passport issue country is required"
  ),
  passport_nationality: string().required("Passport nationality is required"),
  passport_expiry_date: string().required("Passport expiry date is required")
})

export const passengerWOPassValidate = object().shape({
  title: string(),
  first_name: string().required("First name is required"),
  last_name: string().required("Last name is required"),
  birth_date: string(),
  email: string()
    .email()
    .required("Email is required"),
  country_code: string(),
  phone: string().required("Phone is required"),
  passport_number: string(),
  passport_country_of_issue: string(),
  passport_nationality: string(),
  passport_expiry_date: string()
})

export const otherPassengerValidate = object().shape({
  title: string(),
  first_name: string().required("First name is required"),
  last_name: string().required("Last name is required"),
  birth_date: string().required("Birth date is required"),
  email: string().email(),
  country_code: string(),
  phone: string(),
  passport_number: string().required("Passport number is required"),
  passport_country_of_issue: string().required(
    "Passport issue country is required"
  ),
  passport_nationality: string().required("Passport nationality is required"),
  passport_expiry_date: string().required("Passport expiry date is required")
})

export const otherPassengerWOPassValidate = object().shape({
  title: string(),
  first_name: string().required("First name is required"),
  last_name: string().required("Last name is required"),
  birth_date: string(),
  email: string().email(),
  country_code: string(),
  phone: string(),
  passport_number: string(),
  passport_country_of_issue: string(),
  passport_nationality: string(),
  passport_expiry_date: string()
})
