import Immutable from "seamless-immutable"
import { createSelector } from "reselect"
import { REHYDRATE } from "redux-persist/lib/constants"

// TYPES
export const types = {
  //
  // LOGIN
  //
  LOGIN_REQUEST: "tripovy/LOGIN_REQUEST",
  LOGIN_SUCCESS: "tripovy/LOGIN_SUCCESS",
  LOGIN_ERROR: "tripovy/LOGIN_ERROR",
  //
  // REGISTER
  //
  REGISTER_REQUEST: "tripovy/REGISTER_REQUEST",
  REGISTER_SUCCESS: "tripovy/REGISTER_SUCCESS",
  REGISTER_ERROR: "tripovy/REGISTER_ERROR",
  // MODALS
  SHOW_LOGIN_MODAL: "TRIPOVY/SHOW_LOGIN_MODAL",
  SHOW_REGISTER_MODAL: "TRIPOVY/SHOW_REGISTER_MODAL",
  // LOGINMODAL
  ACTIVE_LOGIN_MODAL: "tripovy/ACTIVE_LOGIN_MODAL",
  DISABLE_LOGIN_MODAL: "tripovy/DISABLE_LOGIN_MODAL",
  // LOGOUT
  LOGOUT: "tripovy/LOGOUT",
  // TOGGLE_IS_LOGGINING
  TOGGLE_IS_LOGGINING: "tripovy/TOGGLE_IS_LOGGINING",
  // FORGET_PASSWORD
  FORGET_PASSWORD_REQUEST: "tripovy/FORGET_PASSWORD_REQUEST",
  FORGET_PASSWORD_SUCCESS: "tripovy/FORGET_PASSWORD_SUCCESS",
  FORGET_PASSWORD_ERROR: "tripovy/FORGET_PASSWORD_ERROR",

  SOCIAL_LOGIN_SUCCESS: "tripovy/SOCIAL_LOGIN_SUCCESS",
  //
  //UPDATE_PROFILE
  //
  UPDATE_PROFILE_REQUEST: "tripovy/UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "tripovy/UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_ERROR: "tripovy/UPDATE_PROFILE_ERROR",
  //
  //UPDATE_PASSWORD
  //
  UPDATE_PASSWORD_REQUEST: "tripovy/UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "tripovy/UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_ERROR: "tripovy/UPDATE_PASSWORD_ERROR"
}

const initialState = Immutable({
  user: null,
  loginModal: false,
  registerModal: false,
  reservations: [],
  wallet: null,
  isLogging: false,
  isLoggedIn: false,
  isRegistering: false,
  isForget: false,
  previousPath: "",
  showLoaderEdit: false,
  showLoaderChangePass: false
})

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    //
    // LOGIN
    //
    case types.LOGIN_REQUEST:
      return state.merge({
        isLogging: true
      })
    case types.LOGIN_SUCCESS:
      let { profile, reservations, wallet } =
        action.payload.success && action.payload.data
      return state.merge({
        isLogging: false,
        user: profile,
        reservations: reservations,
        wallet: wallet,
        isLoggedIn: true
      })
    case types.LOGIN_ERROR:
      return state.merge({
        isLogging: false,
        isLoggedIn: false,
        user: null,
        reservations: [],
        wallet: null
      })
    //
    // REGISTER
    //
    case types.REGISTER_REQUEST:
      return state.merge({
        isLogging: true
      })
    case types.REGISTER_SUCCESS:
      let {
        profile: responseProfile,
        reservations: responseReservations,
        wallet: responseWallet
      } = action.payload.success && action.payload.data

      return state.merge({
        user: responseProfile,
        reservations: responseReservations,
        wallet: responseWallet,
        isLogging: false,
        isLoggedIn: true
      })
    case types.REGISTER_ERROR:
      return state.merge({
        user: null,
        reservations: [],
        wallet: null,
        isLogging: false,
        isLoggedIn: false
      })
    //
    case types.SOCIAL_LOGIN_SUCCESS:
      return state.merge({
        previousPath: action.payload
      })

    case types.LOGOUT:
      return state.merge({
        isLogging: false,
        isLoggedIn: false,
        user: null,
        reservations: [],
        wallet: null
      })
    case types.SHOW_REGISTER_MODAL:
      return state.merge({
        registerModal: !state.registerModal,
        loginModal: false
      })
    case types.SHOW_LOGIN_MODAL:
      return state.merge({
        loginModal: !state.loginModal,
        registerModal: false
      })
    case types.ACTIVE_LOGIN_MODAL:
      return state.merge({
        loginModal: true,
        registerModal: false
      })
    case types.DISABLE_LOGIN_MODAL:
      return state.merge({
        loginModal: false,
        registerModal: false
      })
    case types.TOGGLE_IS_LOGGINING:
      return state.merge({
        isLogging: false
      })
    // FORGET_PASSWORD
    case types.FORGET_PASSWORD_REQUEST:
      return state.merge({
        isForget: true
      })
    case types.FORGET_PASSWORD_SUCCESS:
      return state.merge({
        isForget: false
      })
    case types.FORGET_PASSWORD_ERROR:
      return state.merge({
        isForget: false
      })

    // Do not show infinite loading when reading from localstorage to redux state
    // if user closed website when login/logout was in pending state
    case REHYDRATE: {
      return state.merge({
        isLogging: false
      })
    }
    //
    // UPDATE_PROFILE
    //
    case types.UPDATE_PROFILE_REQUEST:
      return state.merge({
        showLoaderEdit: true
      })
    case types.UPDATE_PROFILE_SUCCESS:
      let user = Immutable(state.user).asMutable({ deep: true })
      user[
        "name"
      ] = `${action.payload.profile.fname} ${action.payload.profile.sname}`
      user["phone"] = action.payload.profile.phone
      user["title"] = action.payload.profile.title
      return state.merge({
        user: user,
        showLoaderEdit: false
      })
    case types.UPDATE_PROFILE_ERROR:
      return state.merge({
        showLoaderEdit: false
      })
    //
    // UPDATE_PASSWORD
    //
    case types.UPDATE_PASSWORD_REQUEST:
      return state.merge({
        showLoaderChangePass: true
      })
    case types.UPDATE_PASSWORD_SUCCESS:
      return state.merge({
        showLoaderChangePass: false
      })
    case types.UPDATE_PASSWORD_ERROR:
      return state.merge({
        showLoaderChangePass: false
      })

    default:
      return state
  }
}

export const actions = {
  //
  // LOGIN
  //
  loginRequest: () => ({
    type: types.LOGIN_REQUEST
  }),
  loginSuccess: ({ data }) => ({
    type: types.LOGIN_SUCCESS,
    payload: data
  }),
  loginError: () => ({
    type: types.LOGIN_ERROR
  }),
  // LOGOUT
  logoutRequest: () => ({
    type: types.LOGOUT
  }),
  socialLoginSuccess: ({ data }) => ({
    type: types.SOCIAL_LOGIN_SUCCESS,
    payload: data
  }),
  //
  // REGISTER
  //
  registerRequest: () => ({
    type: types.REGISTER_REQUEST
  }),
  registerSuccess: ({ data }) => ({
    type: types.REGISTER_SUCCESS,
    payload: data
  }),
  registerError: () => ({
    type: types.REGISTER_ERROR
  }),
  // SHOW_LOGIN_MODAL
  showLoginModal: () => ({
    type: types.SHOW_LOGIN_MODAL
  }),
  // SHOW_REGISTER_MODAL
  showRegisterModal: () => ({
    type: types.SHOW_REGISTER_MODAL
  }),
  activeLoginModal: () => ({
    type: types.ACTIVE_LOGIN_MODAL
  }),
  disableLoginModal: () => ({
    type: types.DISABLE_LOGIN_MODAL
  }),
  // TOGGLE_IS_LOGGINING
  toggleIsLogging: () => ({
    type: types.TOGGLE_IS_LOGGINING
  }),
  forgetPasswordRequest: () => ({
    type: types.FORGET_PASSWORD_REQUEST
  }),
  forgetPasswordSuccess: ({ message }) => ({
    type: types.FORGET_PASSWORD_SUCCESS,
    payload: { message }
  }),
  forgetPasswordError: ({ message }) => ({
    type: types.FORGET_PASSWORD_ERROR,
    payload: { message }
  }),
  //
  // UPDATE_PROFILE
  //
  updateProfileRequest: () => ({
    type: types.UPDATE_PROFILE_REQUEST
  }),
  updateProfileSuccess: data => ({
    type: types.UPDATE_PROFILE_SUCCESS,
    payload: data
  }),
  updateProfileError: ({ errors, message }) => ({
    type: types.UPDATE_PROFILE_ERROR,
    payload: { errors, message }
  }),
  //
  // UPDATE_PASSWORD
  //
  updatePasswordRequest: () => ({
    type: types.UPDATE_PASSWORD_REQUEST
  }),
  updatePasswordSuccess: data => ({
    type: types.UPDATE_PASSWORD_SUCCESS,
    payload: data
  }),
  updatePasswordError: ({ errors, message }) => ({
    type: types.UPDATE_PASSWORD_ERROR,
    payload: { errors, message }
  })
}

// Selectors
export const loginSelector = () => state => state.login

export const selectLoggedUser = () =>
  createSelector(
    loginSelector(),
    state => state.user
  )
export const selectIsLogging = () =>
  createSelector(
    loginSelector(),
    state => state.isLogging
  )
export const selectApiToken = () =>
  createSelector(
    selectLoggedUser(),
    user => user && user.api_token
  )

export const selectLoginState = () =>
  createSelector(
    loginSelector(),
    state => state.loginModal
  )
export const selectRegisterState = () =>
  createSelector(
    loginSelector(),
    state => state.registerModal
  )
export const selectIsLoggedIn = () =>
  createSelector(
    loginSelector(),
    state => state.isLoggedIn
  )

export const selectWallet = () =>
  createSelector(
    loginSelector(),
    state => state.wallet
  )

export const selectIsForget = () =>
  createSelector(
    loginSelector(),
    state => state.isForget
  )
export const selectIsSocial = () =>
  createSelector(
    loginSelector(),
    state => state.previousPath
  )
export const selectShowLoaderEdit = () =>
  createSelector(
    loginSelector(),
    state => state.showLoaderEdit
  )
export const selectShowLoaderChangePass = () =>
  createSelector(
    loginSelector(),
    state => state.showLoaderChangePass
  )
export const selectors = {
  selectLoggedUser,
  selectIsLogging,
  selectApiToken,
  selectLoginState,
  selectRegisterState,
  selectIsLoggedIn,
  selectWallet,
  selectIsForget,
  selectIsSocial,
  selectShowLoaderEdit,
  selectShowLoaderChangePass
}
