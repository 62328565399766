import React, { Component } from "react"
import styled from "styled-components"
export const ChangePlanesUI = ({ transitAirportName, transitTime }) => {
  return (
    <ContainerTest>
      <AirportName>Transit in {transitAirportName}</AirportName>
      <Duration>{transitTime}</Duration>
    </ContainerTest>
  )
}

export const ContainerTest = styled.div`
  display: flex;
  border-bottom: 1px dashed #d60808;
  border-top: 1px dashed #d60808;
  padding: 10px 0px;
  margin: 0px 10px 10px 15px;
  color: #d60808;
`
export const AirportName = styled.div`
  text-align: left;
`

export const Duration = styled.div`
  text-align: right;
  font-weight: bold;
  flex: 1;
`
