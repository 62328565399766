import React, { Component } from "react"
import {
  Filter,
  Arrowleft
} from "./elements"
import BpkDatepicker from "bpk-component-datepicker"
import format from "date-fns/format"
import moment from "moment"
import { compose, pure } from "recompose"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import { withRouter } from "react-router-dom"
import { searchFlights } from "../thunks"
import {
  // customQuery,
  tripType
} from "utilities"
import {
  // FaSortAmountUp,
  FaAngleLeft,
  FaAngleRight
} from "react-icons/fa"
import { selectors } from "../ducks"
// import Immutable from "seamless-immutable"
const formatDate = date => format(date, "DD/MM/YYYY")
const formatDateFull = date => format(date, "dddd, Do MMMM YYYY")
const formatMonth = date => format(date, "MMMM YYYY")

const daysOfWeek = [
  {
    name: "Sunday",
    nameAbbr: "Sun",
    index: 0,
    isWeekend: true
  },
  {
    name: "Monday",
    nameAbbr: "Mon",
    index: 1,
    isWeekend: false
  },
  {
    name: "Tuesday",
    nameAbbr: "Tues",
    index: 2,
    isWeekend: false
  },
  {
    name: "wednesday",
    nameAbbr: "Wed",
    index: 3,
    isWeekend: false
  },
  {
    name: "Thursday",
    nameAbbr: "Thurs",
    index: 4,
    isWeekend: false
  },
  {
    name: "Friday",
    nameAbbr: "Fri",
    index: 5,
    isWeekend: false
  },
  {
    name: "Saturday",
    nameAbbr: "Sat",
    index: 6,
    isWeekend: true
  }
  // ...
]
class FiltersDate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedDate: props.depDateForFilters,
    }
  }

  componentDidMount = () => {
    setTimeout(() => {
      let {
        state: { selectedDate },
        props: { depDateForFilters },
      } = this
      this.setState(
        { 
          selectedDate: depDateForFilters,
        }
      )
    }, 500)
  }

  handleDateSelect = date => {
    let { depDateForFilters, returnDateForFilters } = this.props
    this.setState({ selectedDate: date })
    this.props.sendDate(date)
  }

  nextDate = e => {
    if (!this.props.isSearchingFlights) {
      let trip = tripType(this.props.location.search)
      let { depDateForFilters, minDateForFilters, returnDateForFilters } = this.props
      let new_date = moment(depDateForFilters, "DD-MM-YYYY").add(1, "days")
      if (trip === "oneway") {
        this.setState({ selectedDate: new_date })
        this.props.sendDate(new_date)
      } else {
        let next = moment(minDateForFilters).diff(new_date, "days")
        if (next >= 0) {
          this.setState({ selectedDate: new_date })
          this.props.sendDate(new_date)
        }
      }
    }
  }

  previousDate = () => {
    if (!this.props.isSearchingFlights) {
      let { depDateForFilters, returnDateForFilters } = this.props
      let new_date = moment(depDateForFilters).subtract(1, "days")
      let date = moment(new_date).format("M/D/YYYY")
      let date2 = new Date(date)
      let date4 = new Date()
      let diffDays = Math.floor(
        (Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) -
          Date.UTC(date4.getFullYear(), date4.getMonth(), date4.getDate())) /
          (1000 * 60 * 60 * 24)
      )

      const diffTime = Math.abs(date2.getTime() - date4.getTime())
      if (diffDays >= 0) {
        this.setState({ selectedDate: new_date })
        this.props.sendDate(new_date)
      }
    }
  }
  render() {
    const {
      isSearchingFlights,
      depDateForFilters,
      minDateForFilters,
      returnDateForFilters
    } = this.props
    let trip = tripType(this.props.location.search)
    return (
      <Filter>
        <div style={{ display: "flex", width: "100%" }}>
          <Arrowleft onClick={this.previousDate} disabled={isSearchingFlights}>
            <FaAngleLeft />
          </Arrowleft>
          <BpkDatepicker
            id="datepicker"
            daysOfWeek={daysOfWeek}
            weekStartsOn={1}
            changeMonthLabel="Change month"
            closeButtonText="Close"
            title="Departure date"
            getApplicationElement={() => document.getElementById("pagewrap")}
            formatDate={formatDate}
            formatMonth={formatMonth}
            formatDateFull={formatDateFull}
            onDateSelect={this.handleDateSelect}
            // onReturnDateSelect={this.handleReturnDateSelect}
            date={this.state.selectedDate}
            inputProps={{
              disabled: isSearchingFlights
            }}
            // minDate={minDateForFilters}
            maxDate={minDateForFilters}
          />
          <Arrowleft onClick={this.nextDate} disabled={isSearchingFlights}>
            <FaAngleRight />
          </Arrowleft>
        </div>
      </Filter>
    )
  }
}

const mapState = () =>
  createStructuredSelector({
    isSearchingFlights: selectors.selectIsSearchingFlights(),
    depDateForFilters:  selectors.selectDepDateForFilters(),
    minDateForFilters: selectors.selectMinDateForFilters()
  })

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      searchFlights
    },
    dispatch
  )

export default compose(
  withRouter,
  component =>
    connect(
      mapState,
      mapDispatch
    )(component),
  pure
)(FiltersDate)
