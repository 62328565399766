import React, { Component, Fragment } from "react"
import {
  TopCart,
  InnerHeading,
  SmallHeading,
  FlexContainer,
  Date,
  Time,
  Charges,
  BottomCart,
  Person,
  Divider,
  Total,
  CardBtn,
  StickyCart
} from "./elements"
import { compose, pure } from "recompose"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import {
  getMajorFlightDetails,
  // baggageAllowance,
  headerDetails,
  getCityName,
  getPassengersPriceAndTax,
  getCabinFromQueryDetails
} from "filterFunctions"
import { selectors } from "../ducks"
import { totalPriceFareBreakDown, modifyFareBreakDown } from "../thunks"
import features from "features"
import { priceItinerary } from "filterFunctions"
import { OrbitSpinner } from "react-epic-spinners"
import { TimeConvert24to12 } from "shared/ticketFuctions"
// let data = {
//   mismatch_type: "both",
//   price_mismatch: true,
//   prices: [
//     { cabin_class: "economy", price: 14420, return_price: 13028, sum: 27448 }
//   ]
// }
class TotalCart extends Component {
  continueHandler = () => {
    // debugger
    // this.props.formikRef[1].current.submitForm()
    for (var key in this.props.formikRef) {
      if (this.props.formikRef.hasOwnProperty(key)) {
        this.props.formikRef[key].current.submitForm()
      }
    }
  }
  state = {
    success: false,
    price_mismatch: false,
    passenger_data: {},
    totalNull: false
  }
  divider2 = false
  divider = false
  componentDidMount() {
    this.props.toggleUpdate()
    let {
      selectedFlightData,
      selectedFlightB,
      totalPriceFareBreakDown,
      api_token,
      selectQueryDetails,
      modifyFareBreakDown
    } = this.props
    let { fare_breakdown } = priceItinerary(selectedFlightB.price_itinerary)
    // this.showPriceConatiner = false
    if (fare_breakdown && fare_breakdown.total === null) {
      let query = {
        flight: selectedFlightData,
        query: selectQueryDetails
      }
      totalPriceFareBreakDown(query).then(action => {
        let {
          success,
          data,
          mismatch_type,
          prices,
          price_mismatch
        } = action.payload
        // this.setState({ success: action.payload.success })
        if (success == true) {
          if (fare_breakdown.adult !== null) {
            data.adult.quantity = fare_breakdown.adult.quantity
          } else {
            data.adult.quantity =
              selectQueryDetails.passengers &&
              selectQueryDetails.passengers.adult_count &&
              selectQueryDetails.passengers.adult_count
          }

          if (fare_breakdown.child !== null) {
            data.child.quantity = fare_breakdown.child.quantity
          } else {
            // data.child = null
            data.child.quantity =
              selectQueryDetails.passengers &&
              selectQueryDetails.passengers.child_count &&
              selectQueryDetails.passengers.child_count
          }
          if (fare_breakdown.infant !== null) {
            data.infant.quantity = fare_breakdown.infant.quantity
          } else {
            // data.infant = null
            data.infant.quantity =
              selectQueryDetails.passengers &&
              selectQueryDetails.passengers.infant_count &&
              selectQueryDetails.passengers.infant_count
          }

          modifyFareBreakDown(data)
          this.setState({
            passenger_data: data,
            success: action.payload.success,
            totalNull: true
          })
          // let price_mismatch_data = {
          //   mismatch_type: "leaving",
          //   prices: values
          // }
          // this.props.openPricesMOdal(price_mismatch_data)
        } else {
          if (price_mismatch && price_mismatch === true) {
            this.setState({
              price_mismatch: true,
              totalNull: true,
              success: action.payload.success
            })

            let price_mismatch_data = {
              mismatch_type,
              prices
            }
            this.props.openPricesMOdal(price_mismatch_data)
          }
        }
      })
    } else {
      // do something here
      this.setState({ totalNull: false })
    }
  }

  callApiAgain = ({}) => {
    // console.log("in callPAiAgian //////////////////")
    let {
      selectedFlightData,
      selectedFlightB,
      totalPriceFareBreakDown,
      api_token,
      selectQueryDetails,
      modifyFareBreakDown,
      totalPriceApiAgainToggle
    } = this.props
    let query = {
      flight: selectedFlightData,
      query: selectQueryDetails
    }
    totalPriceFareBreakDown(query).then(action => {
      let { success, data } = action.payload
      // console.log("action =====", action)
      let { fare_breakdown } = priceItinerary(
        selectedFlightData.price_itinerary
      )

      if (success == true) {
        if (fare_breakdown.adult !== null) {
          data.adult.quantity = fare_breakdown.adult.quantity
        } else {
          data.adult.quantity =
            selectQueryDetails.passengers &&
            selectQueryDetails.passengers.adult_count &&
            selectQueryDetails.passengers.adult_count
        }

        if (fare_breakdown.child !== null) {
          data.child.quantity = fare_breakdown.child.quantity
        } else {
          // data.child = null
          data.child.quantity =
            selectQueryDetails.passengers &&
            selectQueryDetails.passengers.child_count &&
            selectQueryDetails.passengers.child_count
        }
        if (fare_breakdown.infant !== null) {
          data.infant.quantity = fare_breakdown.infant.quantity
        } else {
          // data.infant = null
          data.infant.quantity =
            selectQueryDetails.passengers &&
            selectQueryDetails.passengers.infant_count &&
            selectQueryDetails.passengers.infant_count
        }

        this.setState({
          passenger_data: data,
          success: action.payload.success,
          totalNull: true,
          price_mismatch: false
        })
        totalPriceApiAgainToggle()
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    let { totalPriceApiAgain } = this.props
    if (
      totalPriceApiAgain &&
      prevProps.totalPriceApiAgain !== totalPriceApiAgain
    ) {
      this.callApiAgain({})
    }
  }
  render() {
    let {
      selectedFlightData,
      selectQueryDetails,
      airports,
      cabinName,
      currency,
      isTotalPriceNull
    } = this.props
    let { success, price_mismatch, passenger_data, totalNull } = this.state
    let {
      type,
      depAirport,
      arrAirport,
      shortDepDate,
      ppp,
      // adult,
      // child,
      // infant,
      // total,
      depDate,
      stopInt,
      depTime,
      tripTypeName,
      totalPassengerCount
    } = headerDetails(selectedFlightData, selectQueryDetails)
    let departure = ""
    let arrival = ""
    airports.map(item => {
      if (item.code === depAirport) {
        departure = item.value
      } else if (item.code === arrAirport) {
        arrival = item.value
      }
    })
    let departureCity = getCityName(departure)
    let arrivalCity = getCityName(arrival)
    let arrayOfPassengers = []
    let types = ""
    let adult, child, infant, total
    if (success) {
      let {
        adult: adultT,
        child: childT,
        infant: infantT,
        total: totalT
      } = passenger_data
      adult = adultT
      child = childT
      infant = infantT
      total = totalT
    } else {
      let {
        adult: adultTT,
        child: childTT,
        infant: infantTT,
        total: totalTT
      } = headerDetails(selectedFlightData, selectQueryDetails)
      adult = adultTT
      child = childTT
      infant = infantTT
      total = totalTT
    }
    if (adult !== null && child === null && infant !== null) {
      arrayOfPassengers.push(adult, infant)
      types = "Adult&Infant"
    }
    if (adult !== null && child !== null && infant === null) {
      arrayOfPassengers.push(adult, child)
      types = "Adults&Child"
    }
    if (adult !== null && child !== null && infant !== null) {
      arrayOfPassengers.push(adult, child, infant)
      types = "All"
    }
    if (adult !== null && child === null && infant === null) {
      arrayOfPassengers.push(adult)
      types = "Adult"
    }
    let { totalPassengerData } = getPassengersPriceAndTax(
      arrayOfPassengers,
      types
    )

    let returnData = {}
    let multiData = []
    if (type === "ROUND-TRIP") {
      let data = getMajorFlightDetails(
        selectedFlightData.returning_flight_segments
      )
      returnData = data
    } else if (type === "MULTI-CITY") {
      selectedFlightData.trips.map(i => {
        let data = getMajorFlightDetails(i.departing_flight_segments)
        multiData.push(data)
      })
    }
    let { cabinFromSearch } = getCabinFromQueryDetails({ selectQueryDetails })
    // console.log(
    //   "in renderrrr checked values === values success",
    //   success,
    //   "price_mismatch",
    //   price_mismatch,
    //   "passenger_data",
    //   passenger_data,
    //   "totalNull",
    //   totalNull
    // )
    return (
      <StickyCart>
        <TopCart>
          <InnerHeading>
            {departureCity.city} - {arrivalCity.city}
          </InnerHeading>
          <SmallHeading>
            {tripTypeName},{" "}
            {cabinName === "" || cabinName === "N/A"
              ? cabinFromSearch
              : cabinName}
            , {totalPassengerCount}
          </SmallHeading>
          <FlexContainer>
            <Date>
              <h4>{depDate}</h4>
              <p>
                {depAirport} - {arrAirport}
              </p>
            </Date>
            <Time>
              <h4>{TimeConvert24to12(depTime)}</h4>
              <p>
                {stopInt} {stopInt > 1 ? "Stops" : "Stop"}
              </p>
            </Time>
          </FlexContainer>
          {type === "ROUND-TRIP" && (
            <FlexContainer style={{ marginTop: "3px" }}>
              <Date>
                <h4>{returnData.depDate}</h4>
                <p>
                  {returnData.depAirport} - {returnData.arrAirport}
                </p>
              </Date>
              <Time>
                <h4>{TimeConvert24to12(returnData.depTime)}</h4>
                <p>
                  {returnData.stopInt}{" "}
                  {returnData.stopInt > 1 ? "Stops" : "Stop"}
                </p>
              </Time>
            </FlexContainer>
          )}
          {type === "MULTI-CITY" &&
            multiData.map((item, index) =>
              index === 0 ? null : (
                <FlexContainer style={{ marginTop: "3px" }} key={index}>
                  <Date>
                    <h4>{item.depDate}</h4>
                    <p>
                      {item.depAirport} - {item.arrAirport}
                    </p>
                  </Date>
                  <Time>
                    <h4>{TimeConvert24to12(item.depTime)}</h4>
                    <p>
                      {item.stopInt} {item.stopInt > 1 ? "Stops" : "Stop"}
                    </p>
                  </Time>
                </FlexContainer>
              )
            )}
        </TopCart>
        {isTotalPriceNull ? (
          <Fragment>
            <Charges>Charges</Charges>
            <BottomCart>
              <FlexContainer style={{ alignItems: "center" }}>
                <Date>
                  <Total>Total</Total>
                </Date>
                <OrbitSpinner color="#0d90e0" style={{ fontSize: "20px" }} />
              </FlexContainer>
              <CardBtn onClick={this.continueHandler}>continue</CardBtn>
            </BottomCart>
          </Fragment>
        ) : totalNull ? (
          price_mismatch ? (
            <BottomCart>
              <CardBtn onClick={this.continueHandler}>continue</CardBtn>
            </BottomCart>
          ) : success ? (
            <Fragment>
              <Charges>Charges</Charges>
              <BottomCart>
                {totalPassengerData.map((item, index) => {
                  if (item.price !== null) {
                    if (item.price !== 0) {
                      this.divider = true
                      return (
                        <React.Fragment>
                          <FlexContainer
                            style={{ marginTop: "10px" }}
                            key={index}
                          >
                            <Date>
                              <Person>
                                {item.quantity} {item.type}
                              </Person>
                            </Date>
                            <Time>
                              <Person>
                                {currency}{" "}
                                {item.price === null
                                  ? 0
                                  : (item.price * item.quantity).toLocaleString(
                                      "en-IN"
                                    )}
                              </Person>
                            </Time>
                          </FlexContainer>
                          <FlexContainer style={{ marginTop: "4px" }}>
                            <Date>
                              <Person>Tax & Fees</Person>
                            </Date>
                            <Time>
                              <Person>
                                {currency}{" "}
                                {item.tax === null
                                  ? 0
                                  : (item.tax * item.quantity).toLocaleString(
                                      "en-IN"
                                    )}
                              </Person>
                            </Time>
                          </FlexContainer>
                        </React.Fragment>
                      )
                    }
                  }
                })}
                {this.divider ? <Divider /> : null}
                <FlexContainer>
                  <Date>
                    <Total>Total</Total>
                  </Date>
                  <Time>
                    <Total>
                      {currency} {total.toLocaleString("en-IN")}
                    </Total>
                  </Time>
                </FlexContainer>
                <CardBtn onClick={this.continueHandler}>continue</CardBtn>
              </BottomCart>
            </Fragment>
          ) : (
            <Fragment>
              <Charges>Charges</Charges>
              <BottomCart>
                <FlexContainer>
                  second
                  <Date>
                    <Total>Total</Total>
                  </Date>
                  <Time>
                    <Total>
                      {currency} {total.toLocaleString("en-IN")}
                    </Total>
                  </Time>
                </FlexContainer>
                <CardBtn onClick={this.continueHandler}>continue</CardBtn>
              </BottomCart>
            </Fragment>
          )
        ) : (
          <Fragment>
            <Charges>Charges</Charges>
            <BottomCart>
              {totalPassengerData.map((item, index) => {
                if (item.price !== null) {
                  if (item.price !== 0) {
                    this.divider2 = true
                    return (
                      <React.Fragment key={index}>
                        <FlexContainer
                          style={{ marginTop: "10px" }}
                          key={index}
                        >
                          <Date>
                            <Person>
                              {item.quantity} {item.type}
                            </Person>
                          </Date>
                          <Time>
                            <Person>
                              {currency}{" "}
                              {item.price === null
                                ? 0
                                : (item.price * item.quantity).toLocaleString(
                                    "en-IN"
                                  )}
                            </Person>
                          </Time>
                        </FlexContainer>
                        <FlexContainer style={{ marginTop: "4px" }}>
                          <Date>
                            <Person>Tax & Fees</Person>
                          </Date>
                          <Time>
                            <Person>
                              {currency}{" "}
                              {item.tax === null
                                ? 0
                                : (item.tax * item.quantity).toLocaleString(
                                    "en-IN"
                                  )}
                            </Person>
                          </Time>
                        </FlexContainer>
                      </React.Fragment>
                    )
                  }
                }
              })}
              {this.divider2 ? <Divider /> : null}
              <FlexContainer>
                <Date>
                  <Total>Total</Total>
                </Date>
                <Time>
                  <Total>
                    {currency} {total.toLocaleString("en-IN")}
                  </Total>
                </Time>
              </FlexContainer>
              <CardBtn onClick={this.continueHandler}>continue</CardBtn>
            </BottomCart>
          </Fragment>
        )}
      </StickyCart>
    )
  }
}

const mapState = () =>
  createStructuredSelector({
    selectedFlightData: selectors.selectSelectedFlight(),
    selectedFlightB: selectors.selectSelectedFlightB(),
    selectQueryDetails: selectors.selectQueryDetails(),
    airports: features.landing.selectors.selectAirportList(),
    api_token: features.login.selectors.selectApiToken(),
    isTotalPriceNull: selectors.selectIsTotalPriceNull(),
    split_view: selectors.selectSplitView(),
    selectDeparting_flight: selectors.selectDeparting_flight()
  })

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      showLoginModal: features.login.actions.showLoginModal,
      totalPriceFareBreakDown,
      modifyFareBreakDown
    },
    dispatch
  )

export default compose(
  component =>
    connect(
      mapState,
      mapDispatch
    )(component),
  pure
)(TotalCart)
