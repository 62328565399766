import styled from "styled-components"
import { Nav, NavLink, Button, FormGroup, Input } from "reactstrap"
import ReactSelect from "react-select"

export const StyledNav = styled(Nav)`
  border-bottom: none !important;
  margin-bottom: 0px !important;
  margin-top: 10px;
  margin-left: 5em;
  @media screen and (min-width: 1025px) and (max-width: 1400px) {
    margin-left: 2rem;
  }
  @media screen and (min-width: 200px) and (max-width: 767px) {
    margin-left: 0px;
    justify-content: center;
  }
`
export const NavLinks = styled(NavLink)`
  &:hover {
    border: 1px solid transparent !important;
  }
  &.active {
    background-color: transparent !important;
    border: none !important;
    border-bottom: 2px solid #ff7b47 !important;
  }
  font-size: 19px;
  color: #fff !important;
  padding-left: 0px !important;
  font-weight: 600;
  background-color: ${props =>
    props.active ? "transparent !important" : "transparent"};
  border: ${props => (props.active ? "none !important" : "none")};
  border-bottom: ${props =>
    props.active ? "2px solid #ff7b47 !important" : "none"};
  cursor: pointer;
  @media screen and (min-width: 1100px) and (max-width: 1400px) {
    font-size: 19px;
  }
`
export const ReactSelectS = styled(ReactSelect)`
  border: none;
  margin-top: ${props => (props.top ? "0px" : "10px")};
  > div:nth-of-type(1) {
    height: 100%;
    min-height: 60px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    background-image: ${props =>
      props.cabin ? "url(../images/cabin.png)" : "url(../images/user.png)"};
    background-repeat: no-repeat;
    background-position: center left 10px;
    padding: 0px 30px;
    background-size: 15px;
    border-radius: 0px;
    padding-right: 4px;
    border: none;
  }
`

export const FlexRow = styled.div`
  display: flex;
  margin: 10px 0px;
  align-items: flex-start;
  height: 100%;
  max-height: 60px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  flex: 1;
  @media screen and (min-width: 200px) and (max-width: 767px) {
    flex: 100%;
    margin-bottom: 0px;
  }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    flex: 100%;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .testClass {
    border: none;
    border-radius: 0px;
    padding: 0px 10px;
    // background-image: url(../images/date.png);
    background-repeat: no-repeat;
    background-position: center left 10px;
    height: 100%;
    min-height: 60px;
    background-size: 15px;
    width: 100%;
    font-family: "Roboto", sans-serif !important;
  }
  .arrow:nth-of-type(1) {
    background-image: url(/images/arrow.png);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 19px;
    padding-right: 21px;
  }
  input {
    text-transform: capitalize;
    &:focus {
      outline: unset;
    }
  }

  ul {
    position: absolute;
    z-index: 999;
    padding-left: 0px;
    list-style: none;
    width: 100%;
  }
`
export const SFormGroup = styled(FormGroup)`
  flex: 1;
  border: none;
  margin-bottom: 0px !important;
`
export const Stylebtn = styled(Button)`
  display: none;
  background-color: #ff7b47 !important;
  color: #fff !important;
  font-weight: 700 !important;
  border: none !important;
  padding: 16px 28px !important;
  margin-left: 20px;
  font-size: 20px;
  &:nth-last-of-type(1) {
    display: block;
  }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    margin-left: 0px;
    margin-top: 15px;
  }
  @media screen and (min-width: 200px) and (max-width: 767px) {
    margin-left: 0px;
    width: 100%;
    margin-top: 10px;
  }
`
export const StyleBtnAdd = styled(Button)`
  .fa {
    padding-right: 7px;
  }
  background-color: #ff7b47 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  padding: 8px 40px !important;
  width: 100%;
  max-width: 219px;
  margin-bottom: 10px;
  margin-left: 81px;
  @media screen and (min-width: 200px) and (max-width: 1400px) {
    margin-left: 31px;
  }
  @media screen and (min-width: 200px) and (max-width: 350px) {
    max-width: 120px;
  }
`
export const MainDate = styled(FormGroup)`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }
  margin-bottom: 0px !important;
  flex: 1;
`
export const InputText = styled(Input)`
  &&:focus {
    box-shadow: unset;
  }
  background-image: ${props =>
    props.arrows ? "unset" : "url(/images/arrow.png)"};
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 19px;
  border: none !important;
  border-radius: 0px !important;
  padding: 0px 32px;
  height: 100%;
  min-height: 60px;
  background-size: 15px;
  width: 100%;
`
export const PopoverButton = styled.button`
  border: none;
  border-radius: 0px;
  padding: 0px 32px;
  background-image: url(../images/user.png);
  background-repeat: no-repeat;
  background-position: center left 10px;
  height: 100%;
  min-height: 60px;
  background-size: 15px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
  text-align: left;
  position: relative;
  img {
    position: absolute;
    right: 12px;
    top: 6px;
  }
`

export const DepartureDate = styled.div`
  margin-left: 20px;
  flex: 0.5;
  @media screen and (min-width: 200px) and (max-width: 767px) {
    margin-left: 0px;
    flex: 100%;
  }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    flex: 50%;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 20px;
    margin-top: 15px;
  }
  @media screen and (min-width: 1750px) and (max-width: 2560px) {
    flex: 0.6;
  }
  input[type="text"] {
    border: none;
    border-radius: 0px;
    padding: 0px 32px;
    background-image: url(../images/date.png);
    background-repeat: no-repeat;
    background-position: center left 10px;
    min-height: 60px;
    background-size: 15px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    font-family: "Roboto", sans-serif !important;
    margin: 10px 0px;
    @media screen and (min-width: 200px) and (max-width: 1200px) {
      margin-bottom: 0px;
    }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      margin-top: 0px;
    }
  }
`
export const CabinClassFull = styled.div`
  margin-left: 0px;
  flex: 0.5;
  @media screen and (min-width: 200px) and (max-width: 767px) {
    margin-left: 0px;
    flex: 100%;
  }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    flex: unset;
    width: 50%;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-top: 15px;
    padding-right: ${p => (p.fromRoundTRip ? "0px" : "20px")};
  }
  @media screen and (min-width: 1750px) and (max-width: 2560px) {
    flex: 0.6;
  }
`

export const PassengersNumber = styled.div`
  margin: 10px 20px;
  flex: 0.5;
  @media screen and (min-width: 200px) and (max-width: 767px) {
    margin: 0px;
    flex: 100%;
    margin-bottom: 10px;
  }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    flex: 50%;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: ${p => (p.fromRoundTRip ? "20px" : "0px")};
    margin-top: 15px;
  }
  @media screen and (min-width: 1750px) and (max-width: 2560px) {
    flex: 0.6;
  }
  input[type="text"] {
    border: none;
    border-radius: 0px;
    padding: 0px 32px;
    background-image: url(../images/date.png);
    background-repeat: no-repeat;
    background-position: center left 10px;
    height: 100%;
    min-height: 60px;
    background-size: 15px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    font-family: "Roboto", sans-serif !important;
    @media screen and (min-width: 200px) and (max-width: 767px) {
      margin-top: 10px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1399px) {
      padding-right: 10px;
    }
  }
`
export const TabContainer = styled.div`
  flex-direction:row;
  display:flex;
  justify-content-space-around;
  padding:0px 5rem;
  align-items:center;
  padding-bottom: 15px;
  @media screen and (min-width:1025px) and (max-width:1400px) {
    padding:0px 2rem;
    
  }
  .RoundtripCol{
    @media screen and (min-width:1681px) and (max-width:2560px) {
      flex:unset;
    max-width:400px;
    }
    @media screen and (min-width:1481px) and (max-width:1680px) {
      flex:unset;
    max-width:350px;
    }
    @media screen and (min-width:1300px) and (max-width:1480px) {
      flex:unset;
    max-width:310px;
    }
    
    @media screen and (min-width:1200px) and (max-width:1299px) {
      flex:unset;
    max-width:250px;
    }
    @media screen and (min-width:200px) and (max-width:1200px) {
      max-width:100%;
      
    }
  }
  @media screen and (min-width:200px) and (max-width:1200px) {
    flex-wrap:wrap;
    padding:0px 2rem;
    padding-bottom: 10px;
    
  }
`
export const Blank = styled.div`
  padding: 21px 56px;
  margin-left: 20px;
  @media screen and (min-width: 200px) and (max-width: 1024px) {
    display: none;
  }
`
export const ContentContainer = styled.div`
  background-color: #efefef;
  padding: 0px 2rem;
  height: 100%;
  .MobileNone {
    @media screen and (min-width: 200px) and (max-width: 1024px) {
      display: none;
    }
  }
  .CardCol {
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
  }
  .RightCol {
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }
`
export const Filter = styled.div`
  color: #fff;
  background-color: #47b2e6;
  margin-bottom: 0px;
  font-size: 18px;
  display: flex;
  padding: 6px 6px;
  // width: 100%;
  //   max-width: 141px;
  flex: 1;
  display: none;
  margin-left: 60px;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 10px;
  }
  @media screen and (min-width: 200px) and (max-width: 1024px) {
    display: block;
  }
  @media screen and (min-width: 200px) and (max-width: 768px) {
    margin-left: 20px;
  }
  @media screen and (min-width: 200px) and (max-width: 479px) {
    margin-left: 0px;
    margin-top: 10px;
  }
`
export const FilterText = styled.h3`
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
export const MobileNone = styled.div`
  @media only screen and (min-width: 200px) and (max-width: 1024px) {
    display: none;
  }
`
export const Maincontainer = styled.div`
  padding: 0px 0em !important;
  border-bottom: 1px solid #fff;
  background-image: url(../images/header_back.png);
  background-size: 100% 100%;
`
export const SearchContainer = styled.div`
  height: 100vh;
  overflow: auto;
  // overflow-scroll: auto;
  .Fliter {
    @media only screen and (min-width: 560px) and (max-width: 1024px) {
      max-width: 100%;
      flex: unset;
    }
  }
  .FliterDate {
    width: 100%;
    flex: unset;
    max-width: 165px;
    @media only screen and (min-width: 200px) and (max-width: 1024px) {
      margin: 0 auto;
      flex: unset;
      max-width: unset;
      padding: 0px 15px !important;
      display: flex;
    }
    @media only screen and (min-width: 200px) and (max-width: 479px) {
      display: block;
    }
  }
  .InfiniteScroll {
    overflow: unset !important;
    height: unset !important;
  }
`
