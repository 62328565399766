import styled from "styled-components"
import {
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap"
export const StyleFormGroup = styled(FormGroup)`
  flex: 1;
  padding: 0px 15px;
  label {
    color: #353535;
    font-size: 15px;
  }
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    padding: 12px 6px;
    border-radius: 0px;
    border: 1px solid #eaeaea;
    &:focus {
      outline: none;
      box-shadow: unset;
    }
  }
  .form-control {
    padding: 12px 6px;
    border-radius: 0px;
    border: 1px solid #eaeaea;
    height: unset !important;
    &:focus {
      outline: none;
      box-shadow: unset;
    }
  }
  .custom-control-label {
    margin-top: 3px;
    color: #545454;
    &:before {
      background: transparent;
      border: 1px solid #787878;
    }
  }
`
export const FlexContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  @media screen and (min-width: 200px) and (max-width: 767px) {
    display: block;
  }
`
export const Divider = styled.div`
  border-bottom: 1px solid #eaeaea;
  margin: 20px 0px;
`
export const Heading = styled.h3`
  color: #323232;
  font-size: 20px;
`
export const FormBtn = styled.button`
  background-color: #ff7b47;
  color: #fff;
  font-weight: 600;
  border: none;
  padding: 11px 60px;
  border-radius: 5px;
`
