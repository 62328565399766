import { client, fetchService } from "./API"
const pdfDownloadLink = process.env.REACT_APP_API_URL.concat(
  "v2/flight/downloadbookingpdf"
)

export const getBookingsList = api_token =>
  fetchService({
    method: "POST",
    url: "v2/flight/bookings",
    body: { api_token }
  })

export const getBooking = (api_token, pnr) =>
  fetchService({
    method: "POST",
    url: `v2/flight/bookings/${pnr}`,
    body: { api_token }
  })

export const cardsPayment = (api_token, pnr, payment_type) =>
  fetchService({
    method: "POST",
    url: `api/v1/bookings/${pnr}/payment/card`,
    body: { api_token, payment_type },
    card: "card"
  })

export const walletsPayment = (api_token, pnr) =>
  fetchService({
    method: "POST",
    url: `api/v1/bookings/${pnr}/payment/wallet`,
    body: { api_token }
  })

export const flightIssueTicket = (api_token, pnr) =>
  fetchService({
    method: "POST",
    url: `v2/flight/bookings/${pnr}/issue`,
    body: { api_token }
  })
// v2/flight/bookings/%3CPNR%3E

export const downloadBookingPdfApi = ({ pnr, api_token }) =>
  fetch(pdfDownloadLink, {
    method: "POST",
    body: JSON.stringify({ pnr, api_token }),
    headers: {
      "Content-Type": "application/json"
    }
  })

export const sendBookingEmailAPI = ({ pnr, api_token, email_address }) =>
  fetchService({
    method: "POST",
    url: `v2/flight/sendbookingemail`,
    body: { pnr, api_token, email_address }
  })

export const changeBookingRequest = ({ api_token, booking_key, user_query }) =>
  fetchService({
    method: "POST",
    url: `api/v1/airtickets/changerequest`,
    body: { api_token, booking_key, message: user_query }
  })

export const editProfile = values =>
  fetchService({
    method: "POST",
    url: `api/v1/user/edit`,
    body: values
  })

export const changePassword = values =>
  fetchService({
    method: "POST",
    url: `api/v1/user/change-password`,
    body: values
  })
