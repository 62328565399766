import Immutable from "seamless-immutable"
import { createSelector } from "reselect"
// import { REHYDRATE } from "redux-persist/lib/constants"

// TYPES
export const types = {
  //
  // AIRPORTS_LIST
  //
  AIRPORTS_LIST_REQUEST: "tripovy/AIRPORTS_LIST_REQUEST",
  AIRPORTS_LIST_SUCCESS: "tripovy/AIRPORTS_LIST_SUCCESS",
  AIRPORTS_LIST_ERROR: "tripovy/AIRPORTS_LIST_ERROR",
  // SET_AIRPORTS_STATE
  SET_AIRPORTS_STATE: "tripovy/SET_AIRPORTS_STATE",
  //
  // SELECTED_ORIGIN
  //
  SELECTED_ORIGIN: "tripovy/SELECTED_ORIGIN",
  //
  // SELECT_FROM_ROUNDTRIP
  //
  SELECT_FROM_ROUNDTRIP: "tripovy/SELECT_FROM_ROUNDTRIP"
}

const initialState = Immutable({
  airportList: [],
  isAirportList: false,
  airportListLastModified: null,
  selectedOrigin: {},
  selectedFromRoundTrip: {}
})

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    //
    // AIRPORTS_LIST
    //
    case types.AIRPORTS_LIST_REQUEST:
      return state.merge({
        isAirportList: true
      })
    case types.AIRPORTS_LIST_SUCCESS:
      let a = []
      if (state.airportListLastModified !== action.payload.last_modified) {
        Object.entries(action.payload.data).map(item => {
          a.push({
            code: item[0],
            airport_name: item[1].split(",")[0],
            value: item[1].split(",")[2],
            country_code: item[1].split(",")[3],
            score: Number(item[1].split(",")[4])
          })
        })
      } else {
        a = state.airportList
      }
      return state.merge({
        isAirportList: false,
        airportList: a,
        airportListLastModified: action.payload.last_modified
      })
    case types.AIRPORTS_LIST_ERROR:
      alert("in error")
      return state.merge({
        isAirportList: false
      })

    // SET_AIRPORTS_STATE
    case types.SET_AIRPORTS_STATE:
      let staticAirports = []
      Object.entries(action.payload.data).map(item => {
        staticAirports.push({
          code: item[0],
          airport_name: item[1].split(",")[0],
          value: item[1].split(",")[2],
          country_code: item[1].split(",")[3],
          score: Number(item[1].split(",")[4])
        })
      })
      return state.merge({
        isAirportList: false,
        airportList: staticAirports,
        airportListLastModified: action.payload.last_modified
      })
    case types.SELECTED_ORIGIN:
      return state.merge({
        selectedOrigin: action.payload.data
      })
    case types.SELECT_FROM_ROUNDTRIP:
      return state.merge({
        selectedFromRoundTrip: action.payload.data
      })
    // Do not show infinite loading when reading from localstorage to redux state
    // if user closed website when login/logout was in pending state
    // case REHYDRATE: {
    //   return state.merge({
    //     airportList: state.airportList
    //   })
    // }

    default:
      return state
  }
}

export const actions = {
  //
  // AIRPORTS_LIST
  //
  airportListRequest: () => ({
    type: types.AIRPORTS_LIST_REQUEST
  }),
  airportListSuccess: ({ data, last_modified }) => ({
    type: types.AIRPORTS_LIST_SUCCESS,
    payload: { data, last_modified }
  }),
  airportListError: () => ({
    type: types.AIRPORTS_LIST_ERROR
  }),
  // SET_AIRPORTS_STATE
  setAirportsState: ({ data, last_modified }) => ({
    type: types.SET_AIRPORTS_STATE,
    payload: { data, last_modified }
  }),
  // SELECTED_ORIGIN
  selectedOrigin: ({ data }) => ({
    type: types.SELECTED_ORIGIN,
    payload: { data }
  }),
  // SELECT_FROM_ROUNDTRIP
  selectedFromRoundTrip: ({ data }) => ({
    type: types.SELECT_FROM_ROUNDTRIP,
    payload: { data }
  })
}

// Selectors
export const landingSelector = () => state => state.landing

export const selectAirportList = () =>
  createSelector(landingSelector(), state => state.airportList)

export const selectedOrigin = () =>
  createSelector(landingSelector(), state => state.selectedOrigin)

export const selectedFromRoundTrip = () =>
  createSelector(
    landingSelector(),
    state =>
      // {
      //   let r = Immutable(state.selectedFromRoundTrip).asMutable({ deep: true })
      // return r
      // }
      state.selectedFromRoundTrip
  )

export const selectors = {
  selectAirportList,
  selectedOrigin,
  selectedFromRoundTrip
}
