import React, { Component, Fragment } from "react"
import { daysOfWeek, dates } from "Constants"
import BpkDatepicker from "bpk-component-datepicker"
import format from "date-fns/format"
import DatePickers from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"

const formatDate = date => format(date, "DD/MM/YYYY")
const formatDateFull = date => format(date, "dddd, Do MMMM YYYY")
const formatMonth = date => format(date, "MMMM YYYY")

const Input = ({
  onChange,
  placeholder,
  handleChange,
  value,
  isSecure,
  id,
  onClick
}) => {
  return (
    <Fragment>
      {/* <DatePickers
        selected={value}
        placeholderText={item.placeholder}
        onChange={handleChange}
      /> */}
      <input
        // key={index}
        style={{ width: "70%" }}
        onChange={onChange}
        // placeholder={item.placeholder}
        value={value}
        isSecure={isSecure}
        id={id}
        onClick={onClick}
      />
      {/* {index === 2 ? null : <span style={{ marginLeft: "5px" }}>/</span>} */}
    </Fragment>
  )
}

class DatePicker extends Component {
  render() {
    const { date, handleChange, placeholder, dateRef } = this.props
    return (
      <BpkDatepicker
        ref={dateRef}
        id="datepicker"
        daysOfWeek={daysOfWeek}
        weekStartsOn={1}
        changeMonthLabel="Change month"
        closeButtonText="Close"
        title="Departure date"
        getApplicationElement={() => document.getElementById("root")}
        formatDate={formatDate}
        formatMonth={formatMonth}
        formatDateFull={formatDateFull}
        onDateSelect={handleChange}
        date={date ? new Date(date) : null}
        inputProps={{
          placeholder: placeholder ? placeholder : "Departure Date",
          onChange: e => console.log("on change", e)
        }}
        {...this.props}
      />
    )
  }
}

export default DatePicker

{
  /* <DatePickers
        selected={date ? new Date(date) : null}
        onChange={handleChange}
        dateFormat="dd/MM/yyyy"
        // placeholderText={placeholder ? placeholder : "DD / MM / YYYY"}
        ref={dateRef}
        customInput={
          <Input
            handleChange={handleChange}
            placeholder={placeholder}
            onChange={val => console.log("value ----------", val)}
          />
        }
        // id="datepicker"
        {...this.props}
        maxDate={new Date("01/01/2030")}
        scrollableMonthYearDropdown
        // showYearDropdown
        onInputClick={e => console.log("on click -----------", e)}
        yearDropdownItemNumber={15}
      /> */
}
