import { client, fetchService } from "./API"

// export const login = formData => {
//   return client
//     .post(`api/v1/login`, {
//       formData
//     })
//     .then(response => response.data)
// }

export const login = formData =>
  fetchService({ method: "POST", url: "api/v1/login", body: formData })

export const register = formData =>
  fetchService({ method: "POST", url: "api/v1/register", body: formData })

export const forgetPassword = formData =>
  fetchService({ method: "POST", url: "api/v1/password/email", body: formData })
