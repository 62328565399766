import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import "react-datepicker/dist/react-datepicker-cssmodules.min.css"
// import "bootstrap/dist/css/bootstrap.min.css"
import App from "./App"
// const rootElement = document.getElementById("root")
ReactDOM.render(<App />, document.getElementById("root"))
// if (rootElement.hasChildNodes()) {
//   ReactDOM.hydrate(<App />, rootElement)
// } else {
//   ReactDOM.render(<App />, rootElement)
// }
