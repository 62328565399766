import React, { Component } from "react"
import InputAutoComplete from "base_components/InputAutoComplete"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import features from "features"
// import("../../static_data/cities").then(item => {
//
// })
// import { cities } from "static_data/cities"

class FromToCities extends Component {
  constructor(props) {
    super(props)
    this.state = {
      origin: this.props.origin ? this.getCityData(this.props.origin) : "",
      destination: this.props.destination
        ? this.getCityData(this.props.destination)
        : ""
    }
  }
  getTo = value => {
    if (typeof value === "object") {
      this.setState({ destination: value })
      this.props.getCitiesValue(
        {
          origin: this.state.origin,
          destination: value
        },
        "destination"
      )
    }
    // else if (!value.includes(",")) {
    //   this.setState({ destination: "" })
    //   this.props.getCitiesValue(
    //     {
    //       origin: this.state.origin,
    //       destination: "",
    //       type: "destination"
    //     },
    //     "destination"
    //   )
    // }
  }
  getFrom = value => {
    // console.log("value in getFrom", value)
    if (typeof value === "object") {
      this.setState({ origin: value })
      this.props.getCitiesValue(
        {
          origin: value,
          destination: this.state.destination
        },
        "origin"
      )
    }
    // else if (typeof value === "string" && !value.includes(",")) {
    //   this.setState({ origin: "" })
    //   this.props.getCitiesValue(
    //     {
    //       origin: "",
    //       destination: this.state.destination
    //     },
    //     "origin"
    //   )
    // }
  }
  // componentDidMount() {
  //   this.cities().then(item => {
  //     this.setState({ citiesState: item.cities })
  //     this.props.airportList().then()
  //   })
  // }

  // cities = async () => {
  //   return await import("../../static_data/cities")
  // }

  getCityData = value => {
    if (typeof value === "string") {
      let cityObj = this.props.airports.find(city => city.code === value)
      return cityObj
    } else {
      return value
    }
  }

  render() {
    let { origin, destination } = this.state
    let { airports, destinationRef, originRef } = this.props
    if (typeof origin === "string") {
      airports.find
    }
    return (
      <React.Fragment>
        <InputAutoComplete
          inputRef={originRef}
          placeholder="From"
          onChange={this.getFrom}
          arrow
          cities={airports}
          initialValue={
            typeof origin === "object" ? `${origin.value} (${origin.code})` : ""
          }
        />
        <InputAutoComplete
          inputRef={destinationRef}
          placeholder="To"
          cities={airports}
          onChange={this.getTo}
          initialValue={
            typeof destination === "object"
              ? `${destination.value} (${destination.code})`
              : ""
          }
          // cities={airports.filter(
          //   item => item.value !== this.state.origin && this.state.origin.code
          // )}
        />
      </React.Fragment>
    )
  }
}

// export default FromToCities
const mapToState = () =>
  createStructuredSelector({
    airports: features.landing.selectors.selectAirportList()
  })

const mapDispatch = dispatch => bindActionCreators({}, dispatch)

export default connect(
  mapToState,
  mapDispatch
)(FromToCities)
