import React, { lazy, Suspense } from "react"
const Logo = lazy(() => import("./Logo"))
class index extends React.PureComponent {
  render() {
    return (
      <Suspense fallback={<span></span>}>
        <Logo {...this.props} />
      </Suspense>
    )
  }
}

export default index
