import React, {
  Component,
  Fragment
  // lazy,
  // Suspense
} from "react"
import * as API from "services/API"
import { SearchContainer } from "../elements.js"
import { tripType, createQuery, extractDepDate, customQuery, extractReturnDate } from "utilities"
import { compose, pure } from "recompose"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import { withRouter } from "react-router-dom"
import Immutable from "seamless-immutable"
// import * as moment from "moment"
import {
  searchFlights,
  selectedFlight,
  progressListSearch,
  searchFlightsProgress,
  resetFilter,
  // searchFlightsProgressPagination,
  applyFilters,
  saveOuterPromiseFlightsRequest,
  saveOuterPromiseFlightsSuccess,
  searchFlightsProgressPaginationSuccess,
  cheapestFlights,
  searchFlightsProgressPaginationRequest,
  earliestFlights,
  quickestFlights
} from "../thunks"
// import { PaginationResultLoader } from "base_components/Loaders"
import { types, selectors } from "../ducks"
import features from "features.js"
// import ErrorBoundary from "routes/ErrorBoundry"
import Test from "scenes/Airports"
// import qs from "query-string"
import InfiniteScroll from "react-infinite-scroll-component"
import * as localForage from "localforage"
// import { fetchService } from "./API"
import { progressListSearch as progressArrayApi } from "services/SearchFlights"
// const TopSearchBar = lazy(() =>
//   import(/*webpackChunkName: "TopSearchBar-searchpage"  */ "../TopSearchBar")
// )
import TopSearchBar from "../TopSearchBar"
// const SelectFlights = lazy(() =>
//   import(/*webpackChunkName: "SelectFlights-searchpage"  */ "../SelectFlights")
// )
import SelectFlights from "../SelectFlights"
// const Progress = lazy(() =>
//   import(
//     /*webpackChunkName: "ProgressBar-searchpage"  */ "../../../base_components/ProgressBar"
//   )
// )
import Progress from "../../../base_components/ProgressBar"

class SearchResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      progressValue: 0,
      page: 1,
      ProgressResult: [],
      pagination: {},
      initialValueProgressBar: 0,
      divisionValueProgress: 0,
      pageNo: 1,
      called: [],
      DateNew: new Date(),
      button: false,
      execution: false,
      pageLoader: true,
      resetFlightsOnPrevBtn: false,
      noFound: false,
      bottom: false
    }
    this.testRef = React.createRef()
  }
  globalCount = 0
  componentDidMount() {
    let { setAirportsState, airportList, airportListSelector } = this.props
    if (airportListSelector.length === 0) {
      Test().then(storage => {
        let { data, last_modified } = storage
        setAirportsState({ data, last_modified }).then(() => airportList())
      })
    }
    this.setData()
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { isSearchingFlights, flights } = this.props
    let flightsM = Immutable(flights).asMutable({ deep: true })
    let el = document.getElementById("searchResults")

    // below commented for @new aldo for the timmings
    if (
      el &&
      // received &&
      flightsM.length < 6 &&
      this.state.pagination.next !== null &&
      this.state.execution
    ) {
      // console.log("in didUpdate ############")
      this.setState({ pageNo: this.state.pageNo + 1, execution: false }, () => {
        // this.getNextPage(this.state.pageNo + 1)
        // call getMoreFlights
        this.getMoreFlights()
      })
    }
  }

  setData = async val => {
    this.setState({ ProgressResult: [], called: [] })
    this.globalCount = 0
    if (val) {
      this.setState({ button: true, DateNew: new Date(val) }, () => {
        this.setState({ pageNo: 1 })
      })
    }
    let data = tripType(this.props.location.search)
    if (data === "oneway") {
      this.setState({ activeTab: "1" })
    } else if (data === "roundtrip") {
      this.setState({ activeTab: "2" })
    } else {
      this.setState({ activeTab: "3" })
    }
    let localForageData
    await localForage.getItem("progressResult").then(data => {
      localForageData = data
    })
    if (localForageData && localForageData.length) {
      // debugger
      let {
        state: { called }
      } = this
      localForageData.map(e => {
        called.push({
          progress: e,
          called: false,
          received: false,
          dataLength: 0
        })
      })
      this.setState({ called, ProgressResult: localForageData })
      if (val) {
        this.getFlights(val)
        // spell corrected
        this.setState({ button: true })
      } else {
        this.getFlights()
      }
      // this.props.progressListSearch().then(action => {})
      progressArrayApi().then(data => {
        if (data.success) {
          localForage.setItem("progressResult", data.data)
        }
      })
    } else {
      this.props.progressListSearch().then(action => {
        if (action.type === types.SEARCH_FLIGHTS_PROGRESS_SEARCH_LIST_SUCCESS) {
          this.setState({ ProgressResult: action.payload.data })
          localForage.setItem("progressResult", action.payload.data)
          // this.called = []
          let {
            state: { called }
          } = this
          action.payload.data.map(e => {
            called.push({
              progress: e,
              called: false,
              received: false,
              dataLength: 0
            })
          })
          this.setState({ called })
          if (val) {
            this.getFlights(val)
            this.setState({ buton: true })
          } else {
            this.getFlights()
          }
        }
      })
    }
  }

  getFlights = async val => {
    let { ProgressResult, pageNo, called } = this.state
    let {
      saveOuterPromiseFlightsSuccess,
      saveOuterPromiseFlightsRequest,
      searchFlightsProgressPaginationSuccess
    } = this.props
    ProgressResult.map(async (vender, index) => {
      let query

      if (this.state.button) {
        query = customQuery(
          "departure_date",
          this.props.location.search,
          this.state.DateNew
        )
        query["progress"] = vender
      } else {
        query = {
          ...createQuery(this.props.location.search),
          progress: vender
        }
      }
      if (this.state.pageNo > 1) {
        this.setState({ pageLoader: true })
      }

      called[index]["called"] = true
      this.setState({ called })
      saveOuterPromiseFlightsRequest(1, query)

      API.progressSearchFlights(query, 1).then(async res => {
        // console.log("mydataaaaaa vender page 1", vender, res)

        let { data, split_view, success, pagination } = res
        if (success) {
          // save data to state
          saveOuterPromiseFlightsSuccess({
            data,
            split_view,
            success,
            pagination,
            query
          })
          // this.globalCount = this.globalCount + 1
          if (this.globalCount === ProgressResult.length) {
            this.props.cheapestFlights()
          }
          if (this.props.filtersSelected) {
            let filters = Immutable(
              this.props.previousAppliedFilters
            ).asMutable({
              deep: true
            })
            this.props.applyFilters(filters)
          }

          if (pagination && pagination.pages && pagination.pages.length) {
            // update pagination state here
            if (!Object.keys(this.state.pagination).length) {
              // pagination is already saved in state
              this.setState({
                pagination
              })
            } else {
              if (
                this.state.pagination.pages.length <= pagination.pages.length
              ) {
                this.setState({
                  pagination
                })
              }
            }
            let { pages } = pagination
            pages.splice(pages.indexOf(1), 1)
            if (pages.length) {
              // let myPages = pages.slice(0, 2)
              // console.log("myPages", myPages, "pages", pages)
              let innerPromises = pages.slice(0, 2).map(async page => {
                // console.log("in pages map @@@@", page, "vender", vender)
                if (this.state.pageNo < page) {
                  this.setState({ pageNo: page })
                }
                return API.progressSearchFlights(query, page)
              })
              Promise.all(innerPromises).then(res => {
                // console.log("res @@@@ promis.all vender", vender, res)
                // save all response array in state
                res.map(allItem => {
                  let { data, split_view, success, pagination } = allItem
                  // filter state if filters already aplied

                  searchFlightsProgressPaginationSuccess({
                    data,
                    split_view,
                    success,
                    pagination,
                    query
                  })
                  if (this.props.filtersSelected) {
                    let filters = Immutable(
                      this.props.previousAppliedFilters
                    ).asMutable({
                      deep: true
                    })
                    this.props.applyFilters(filters)
                  }

                  // update pagination state here
                  if (!Object.keys(this.state.pagination).length) {
                    // pagination is already saved in state
                    this.setState({
                      pagination
                    })
                  } else {
                    if (
                      this.state.pagination.pages.length <=
                      pagination.pages.length
                    ) {
                      this.setState({
                        pagination
                      })
                    }
                  }
                })
                this.globalCount = this.globalCount + 1
                if (this.globalCount === ProgressResult.length) {
                  this.props.cheapestFlights()
                }
                called.map(item => {
                  if (item.progress === vender) {
                    if (data && data.departing) {
                      item.dataLength =
                        data && data.departing && data.departing.length ? 1 : 0
                    } else {
                      item.dataLength = data && data.length ? 1 : 0
                    }
                    item.received = true
                  }
                })
                this.setState({ called })
                let received = this.state.called.every(e => e.received === true)
                if (received) {
                  this.setState({ execution: true, pageLoader: false })
                }
                if (
                  called.length == this.state.ProgressResult.length &&
                  received &&
                  called.every(e => e.dataLength === 0)
                ) {
                  this.setState({ noFound: true })
                } else if (this.state.noFound === true) {
                  this.setState({ noFound: false })
                }
                this.setState({ resetFlightsOnPrevBtn: false })
              })
            } else {
              this.globalCount = this.globalCount + 1
              called.map(item => {
                if (item.progress === vender) {
                  if (data && data.departing) {
                    item.dataLength =
                      data && data.departing && data.departing.length ? 1 : 0
                  } else {
                    item.dataLength = data && data.length ? 1 : 0
                  }
                  item.received = true
                }
              })
              this.setState({ called })
              let received = this.state.called.every(e => e.received === true)
              if (received) {
                this.setState({ execution: true, pageLoader: false })
              }
              if (
                called.length == this.state.ProgressResult.length &&
                received &&
                called.every(e => e.dataLength === 0)
              ) {
                this.setState({ noFound: true })
              } else if (this.state.noFound === true) {
                this.setState({ noFound: false })
              }
              this.setState({ resetFlightsOnPrevBtn: false })
              if (this.globalCount === ProgressResult.length) {
                this.props.cheapestFlights()
              }
            }
          } else {
            // no pagination found in page1 vender call
            this.globalCount = this.globalCount + 1
            called.map(item => {
              if (item.progress === vender) {
                if (data && data.departing) {
                  item.dataLength =
                    data && data.departing && data.departing.length ? 1 : 0
                } else {
                  item.dataLength = data && data.length ? 1 : 0
                }
                item.received = true
              }
            })
            this.setState({ called })
            let received = this.state.called.every(e => e.received === true)
            if (received) {
              this.setState({ execution: true })
            }
            if (
              called.length == this.state.ProgressResult.length &&
              received &&
              called.every(e => e.dataLength === 0)
            ) {
              this.setState({ noFound: true })
            } else if (this.state.noFound === true) {
              this.setState({ noFound: false })
            }
            this.setState({ resetFlightsOnPrevBtn: false })
            if (this.globalCount === ProgressResult.length) {
              this.props.cheapestFlights()
            }
          }
        } else {
          this.globalCount = this.globalCount + 1
          called.map(item => {
            if (item.progress === vender) {
              if (data && data.departing) {
                item.dataLength =
                  data && data.departing && data.departing.length ? 1 : 0
              } else {
                item.dataLength = data && data.length ? 1 : 0
              }
              item.received = true
            }
          })
          this.setState({ called })
          let received = this.state.called.every(e => e.received === true)
          if (received) {
            this.setState({ execution: true, pageLoader: false })
          }
          if (
            called.length == this.state.ProgressResult.length &&
            received &&
            called.every(e => e.dataLength === 0)
          ) {
            this.setState({ noFound: true })
          } else if (this.state.noFound === true) {
            this.setState({ noFound: false })
          }
          this.setState({ resetFlightsOnPrevBtn: false })
          if (this.globalCount === ProgressResult.length) {
            this.props.cheapestFlights()
          }
        }
      })
    })
  }

  updateApi = data => {
    // debugger
    this.setData(data)
    this.setState({ resetFlightsOnPrevBtn: true })
  }

  getPageNumber = page => {
    this.setState({ pageNo: this.state.pageNo + 1 }, () => {
      // this.getFlights()
      this.getMoreFlights()
    })
  }

  getMoreFlights = () => {
    // console.log("in getMoreFlights 22222", this.state.pageNo)
    let { ProgressResult, pageNo, called } = this.state
    let {
      searchFlightsProgressPaginationSuccess,
      searchFlightsProgressPaginationRequest
    } = this.props
    let tempArry = []
    ProgressResult.map(async (vender, index) => {
      let query
      // console.log("venderrrrrrr inside map ====", vender)

      if (this.state.button) {
        query = customQuery(
          "departure_date",
          this.props.location.search,
          this.state.DateNew
        )
        query["progress"] = vender
      } else {
        query = {
          ...createQuery(this.props.location.search),
          progress: vender
        }
      }

      called[index]["called"] = true
      this.setState({ called })
      searchFlightsProgressPaginationRequest(pageNo, query)
      API.progressSearchFlights(query, this.state.pageNo).then(async res => {
        // console.log("mydataaaaaa vender page 1", vender, "pageNo", pageNo, res)

        let { data, split_view, success, pagination } = res
        // save data to state
        searchFlightsProgressPaginationSuccess({
          data,
          split_view,
          success,
          pagination,
          query
        })
        this.applySelectedSort(this.getSelectedSort())
        // this.props.cheapestFlights()
        if (this.props.filtersSelected) {
          // debugger
          let filters = Immutable(this.props.previousAppliedFilters).asMutable({
            deep: true
          })
          this.props.applyFilters(filters)
        }
        let json = {
          keys: vender,
          data:
            data.payload && data.payload.data && data.payload.data.length
              ? 1
              : 0
        }
        tempArry.push(json)

        if (
          tempArry.length == this.state.ProgressResult.length &&
          tempArry.some(e => e.data === 1)
        ) {
          this.setState({ execution: true, pageLoader: false })
        }

        if (pagination && pagination.pages && pagination.pages.length) {
          // update pagination state here
          if (!Object.keys(this.state.pagination).length) {
            // pagination is already saved in state
            this.setState({
              pagination
            })
          } else {
            if (this.state.pagination.pages.length <= pagination.pages.length) {
              this.setState({
                pagination
              })
            }
          }
          let received = this.state.called.every(e => e.received === true)
          if (received) {
            this.setState({ execution: true })
          }
          if (
            called.length == this.state.ProgressResult.length &&
            received &&
            called.every(e => e.dataLength === 0)
          ) {
            this.setState({ noFound: true })
          } else if (this.state.noFound === true) {
            this.setState({ noFound: false })
          }
          this.setState({ resetFlightsOnPrevBtn: false })
        }
      })
    })
  }

  // getNextPage = () => {
  //   this.getFlights()
  // }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  updateProgress = ({ selectedFlight }) => {
    this.setState({ progressValue: this.state.progressValue + 1 })
    if (selectedFlight) {
      // window.scrollTo(0, 0)
      this.props.history.push({
        pathname: "/passengers",
        search: this.props.location.search
      })
      this.props.selectedFlight({
        selectedFlight: selectedFlight,
        queryDetails: createQuery(this.props.location.search)
      })
    }
  }
  handleScroll = e => {
    // this.getSelectedSort()
    // console.log("this.getSelectedSort", this.getSelectedSort())
    let {
      getBorders: { cheapFlightBorder, earlyFlightBorder, quickFlightBorder }
    } = this.props
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      console.log("at bottom #####################")
      this.setState({ bottom: true })
    }
    if (
      e.target.scrollTop >= 0 &&
      e.target.scrollTop <= 250 &&
      this.state.bottom
    ) {
      console.log("moved from bottom to top #####################")
      this.applySelectedSort(this.getSelectedSort())
      this.setState({ bottom: false })
    }
  }

  getSelectedSort = () => {
    let { getBorders } = this.props
    let selectedSort
    Object.keys(getBorders).map(item => {
      if (getBorders[item] === true) {
        selectedSort = item
      }
    })
    return selectedSort
  }

  applySelectedSort = selectedSort => {
    switch (selectedSort) {
      case "cheapFlightBorder":
        this.props.cheapestFlights()
        break
      case "earlyFlightBorder":
        this.props.earliestFlights()
        break
      case "quickFlightBorder":
        this.props.quickestFlights()
        break
      default:
        this.props.cheapestFlights()
    }
  }
  getScrollLocations = () => {
    let topCol = document.getElementById("searchBar")
    console.log("topCol",topCol)
    topCol &&
      topCol.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "end",
      })
  }

  render() {
    let { progressValue, airportList, flights } = this.props
    let { pagination } = this.state
    let data2 = extractDepDate(this.props.location.search)
    let return_date = extractReturnDate(this.props.location.search)
    let flightsM =
      flights && flights.length && Immutable(flights).asMutable({ deep: true })
    // console.log(" this.globalCount = this.globalCount + 1", this.globalCount)
    return (
      <Fragment>
        <SearchContainer
          ref={this.testRef}
          id="searchResult"
          onScroll={this.handleScroll}
        >
          <div style={{ position: "relative" }}>
            <InfiniteScroll
              dataLength={flightsM && flightsM.length}
              next={flightsM && flightsM.length && this.getPageNumber}
              hasMore={pagination && !!pagination.next}
              scrollableTarget="searchResult"
              className="InfiniteScroll"
              // loader={<PaginationResultLoader />}
            >
              <TopSearchBar fromPassengerPage={true} />
              <Progress min={0} max={4} progressValue={progressValue} />
              <SelectFlights
                callApi={this.updateApi}
                hasMore={this.state.pagination}
                called={this.state.called}
                selectProgressListSearchResult={this.state.ProgressResult}
                handleLoadMore={this.getFlights}
                handleNextPageflights={
                  flightsM && flightsM.length && this.getPageNumber
                }
                date={data2}
                updateProgress={this.updateProgress}
                pageNo={this.state.pageNo}
                handleLoader={this.state.pageLoader}
                execution={this.state.execution}
                resetOnPrevBtn={this.state.resetFlightsOnPrevBtn}
                noFound={this.state.noFound}
                getScrollLocations={this.getScrollLocations}
                return_date={return_date}
              />
            </InfiniteScroll>
          </div>
        </SearchContainer>
      </Fragment>
    )
  }
}

const mapState = () =>
  createStructuredSelector({
    isReservingFlights: selectors.selectIsReservingFlights(),
    isCheckBookingStatus: selectors.selectIsCheckBookingStatus(),
    isGetSingleReservation: selectors.selectIsGetSingleReservation(),
    showOverlay: selectors.selectShowOverlay(),
    progressValue: selectors.selectProgressValue(),
    airportListSelector: features.landing.selectors.selectAirportList(),
    progressListSearchValue: selectors.selectProgressListSearchResult(),
    flights: selectors.selectFlights(),
    previousAppliedFilters: selectors.previousAppliedFilters(),
    filtersSelected: selectors.filtersSelected(),
    airports: features.landing.selectors.selectAirportList(),
    getBorders: selectors.getBorders()
  })

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      searchFlights,
      setAirportsState: features.landing.actions.setAirportsState,
      airportList: features.landing.actions.airportList,
      resetFilter,
      selectedFlight,
      progressListSearch,
      searchFlightsProgress,
      // searchFlightsProgressPagination,
      applyFilters,
      saveOuterPromiseFlightsRequest,
      searchFlightsProgressPaginationRequest,
      saveOuterPromiseFlightsSuccess,
      searchFlightsProgressPaginationSuccess,
      cheapestFlights,
      earliestFlights,
      quickestFlights
    },
    dispatch
  )

export default compose(
  withRouter,
  component => 
     connect(
       mapState,
       mapDispatch
       )(component),
  pure
)(SearchResult)
