import styled from "styled-components"
import { NavLink, Nav } from "reactstrap"
export const BigCard = styled.div`
  width: 100%;
  // background-color: #fff;
  padding: 22px 10px;
  margin-top: 0px;
  // border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 0px;
  align-items: center;
  @media only screen and (min-width: 1200px) and (max-width: 1500px) {
    width: 100%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    // flex-wrap:wrap;
    position: relative;
    width: 100%;
    padding-bottom: 26px;
  }
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    width: 100%;
    position: relative;
    padding: 30px 10px;
    justify-content: space-between;
  }
`
export const MainCard = styled.div`
  display: flex;
  margin-bottom: 20px;
  position: relative;
  &:after {
    border: ${props => (props.active ? "1px solid #8ccee7" : "none")};
    content: "";
    width: 100%;
    position: absolute;
    height: 100%;
    border-radius: 11px;
    opacity: 0.7;
    display: ${props => (props.active ? "block" : "none")};
    top: 0;
  }
  &:before {
    content: " ";
    background-color: ${props => (props.active ? "skyblue" : "transparent")};
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    border-radius: 11px;
    z-index: 9999;
    border: 2px solid #00b4e2;
    display: ${props => (props.active ? "block" : "none")};
  }
  .big-card {
    @media only screen and (min-width: 200px) and (max-width: 1200px) {
      width: 100% !important;
    }
  }
  @media only screen and (min-width: 200px) and (max-width: 1200px) {
    display: block;
  }
`
export const CardImg = styled.div`
  margin-right: 10px;
  text-align: center;
  @media only screen and (min-width: 1681px) and (max-width: 2000px) {
    margin-left: 30px;
  }
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    position: absolute;
    left: 11px;
    top: 0px;
  }
  img {
    width: 100%;
    max-width: 70px;
    @media only screen and (min-width: 200px) and (max-width: 767px) {
      display: none;
    }
  }
  p {
    color: #000;
    font-size: 10px;
    margin-top: 5px;
    margin-bottom: 0px;
    font-weight: 500;
    margin: 0 auto;
    width: 55px;
    // text-transform: capitalize;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
`
export const Fromtext = styled.div`
  width: 100%;
  // max-width: 77px;
  text-align: center;
  margin-right: 10px;
  @media only screen and (min-width: 1501px) and (max-width: 2560px) {
    max-width: 100%;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1500px) {
    margin-right: 10px;
  }
  h4 {
    color: #343434;
    font-weight: 600;
    margin-bottom: 3px;
    font-size: 19px;
  }
  h5 {
    font-weight: 400;
    margin-bottom: 0px;
    font-size: 14px;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
export const DirectFlight = styled.div`
  width: 100%;
  max-width: 200px;
  text-align: center;
  margin-right: 0px;
  @media only screen and (min-width: 1200px) and (max-width: 1500px) {
    max-width: 100%;
  }
  h4 {
    color: #343434;
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 18px;
  }
  .direct {
    color: #00d563;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 0.6;
    @media only screen and (min-width: 200px) and (max-width: 1024px) {
      font-size: 11px;
    }
  }
  .stop {
    color: red;
    margin-bottom: 0px;
    font-size: 16px;
    @media only screen and (min-width: 1201px) and (max-width: 1500px) {
      font-size: 13px;
      line-height: 1;
    }
    @media only screen and (min-width: 200px) and (max-width: 1200px) {
      font-size: 11px;
      line-height: 1;
    }
  }
`
export const Flightdetail = styled.p`
  color: red;
  text-transform: uppercase;
  font-size: 10px;
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;
  margin-right: 10px;
  cursor: pointer;
  @media only screen and (min-width: 1200px) and (max-width: 1500px) {
    font-size: 9px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    position: absolute;
    bottom: 54px;
    display: block;
    text-align: center;
    width: 100%;
  }
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    position: absolute;
    bottom: 55px;
    display: block;
    text-align: center;
    width: 100%;
  }
`
export const SmallCard = styled.div`
  width: 33%;
  background-color: #fff;
  padding: 20px 10px;
  margin-top: 0px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  border-left: 1px dashed #e6e6e6;
  align-items: center;
  @media only screen and (min-width: 1200px) and (max-width: 1500px) {
    width: 35%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 0px;
    border: none;
    border-top: 1px dashed #e6e6e6;
    padding: 8px 10px;
    justify-content: flex-end;
  }
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    width: 100%;
    margin-top: 0px;
    border: none;
    border-top: 1px dashed #e6e6e6;
    padding: 8px 10px;
    justify-content: flex-end;
  }
`
export const TotalHeading = styled.h5`
  color: #343434;
  font-weight: 400;
  margin-bottom: 0px;
  font-size: 19px;
`
export const TotalPrice = styled.h4`
  color: #343434;
  font-weight: 600;
  margin-bottom: 3px;
  font-size: 20px;
  @media only screen and (min-width: 1000px) and (max-width: 1300px) {
    font-size: 19px;
  }
`
export const SelectBtn = styled.button`
  border: none;
  background-color: #00b4e2;
  color: #fff;
  padding: 7px 27px;
  font-weight: bold;
  border-radius: 20px;
  &:focus {
    outline: none;
  }
  svg {
    font-size: 14px;
    margin-left: 7px;
  }
  @media only screen and (min-width: 200px) and (max-width: 1200px) {
    margin-left: 26px;
  }
`
export const StyleNavLink = styled(NavLink)`
  border-color: transparent !important;
  cursor: pointer !important;
  text-align: center !important;
  font-weight: 600 !important;
  color: #343434 !important;
  &.active {
    background-color: #00b4e2 !important;
    color: #fff !important;
    border-radius: 5px;
  }
`
export const StyledNav = styled(Nav)`
  margin-bottom: 10px;
  box-shadow: rgb(5, 5, 5) 0px 0px 6px -3px;
  border-radius: 5px;
  background-color: #fff;
`
