import React, { Component, Fragment } from "react"
import {
  BigCard,
  CardImg,
  Fromtext,
  DirectFlight,
  Flightdetail,
  MainCard,
  Detail,
  DetailText,
  Date,
  DetailBottomText,
  CardTopHeading,
  AirCraftName
} from "./elements"
import {
  // priceItinerary,
  getAirlineName,
  flightTimings,
  getFlightCardData,
  baggageAllowance,
  // getMyCabinClass,
  // getStopAirportsData,
  getMajorFlightDetails,
  // cabinClassArray,
  getNewFlightDuration,
  getTransitDurationText,
  baggageAllowanceBreakdowns,
  getCabinFromSearchQuery,
  getStopAirportsDataImproved
} from "filterFunctions"
import { ChangePlanesUI } from "base_components/ChangePlanesUI"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import features from "features"
import { LuggageIcon } from "base_components/Icons"
import { withRouter } from "react-router-dom"
import { FaTimes } from "react-icons/fa"
import { TimeConvert24to12 } from "shared/ticketFuctions"

class CardDetail extends Component {
  render() {
    let { data, hideDetails, cabinCodes, airports } = this.props
    let {
      baggage_allowance,
      departing_flight_segments,
      price_itinerary,
      seats_remaining,
      returning_flight_segments,
      departing_flight_duration,
      returning_flight_duration
    } = data
    let { weight, passenger_type, unit, pieces } = baggageAllowance(
      baggage_allowance
    )
    let {
      depAirport: depAirportD,
      arrAirport: arrAirportD,
      totalDuration: totalDurationD
    } = getMajorFlightDetails(departing_flight_segments)
    let {
      depAirport: depAirportR,
      arrAirport: arrAirportR,
      totalDuration: totalDurationR
    } = getMajorFlightDetails(returning_flight_segments)
    let { DTime, RTime } = getNewFlightDuration(
      departing_flight_duration,
      returning_flight_duration
    )
    let { departBagArr, returnBagArr } = baggageAllowanceBreakdowns({
      baggage_allowance,
      tripType: "oneway"
    })
    let { cabinFromSearch } = getCabinFromSearchQuery(
      this.props.location.search
    )
    return (
      <MainCard>
        <Detail>
          <DetailText>Details</DetailText>
          <FaTimes style={style} onClick={() => hideDetails()} />
        </Detail>
        <CardTopHeading>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                color: "#00b4e2",
                marginRight: "10px",
                fontSize: "20px"
              }}
            >
              Depart
            </p>
            <p style={{ color: "#939297", fontSize: "18px" }}>
              {depAirportD}-{arrAirportD}
            </p>
          </div>
          <p style={{ fontSize: "20px" }}>{DTime}</p>
        </CardTopHeading>
        {departing_flight_segments.map((datat, i) => (
          <BigCardT
            key={i}
            data={datat}
            majorData={data}
            index={i}
            cabinClassArr={cabinCodes.departing_segments}
            type="Depart"
            airports={airports}
            cabinFromSearch={cabinFromSearch}
          />
        ))}
        <DetailBottomText title="Baggage allowance">
          <LuggageIcon />
          {departBagArr}{" "}
        </DetailBottomText>

        <CardTopHeading>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                color: "#00b4e2",
                marginRight: "10px",
                fontSize: "20px"
              }}
            >
              Return
            </p>
            <p style={{ color: "#939297", fontSize: "18px" }}>
              {depAirportR}-{arrAirportR}
            </p>
          </div>
          <p style={{ fontSize: "20px" }}>{RTime}</p>
        </CardTopHeading>
        {returning_flight_segments.map((datat, i) => (
          <BigCardT
            key={i}
            data={datat}
            majorData={data}
            index={i}
            cabinClassArr={cabinCodes.returning_segments}
            type="Return"
            airports={airports}
            cabinFromSearch={cabinFromSearch}
          />
        ))}
        <DetailBottomText title="Baggage allowance">
          <LuggageIcon />
          {returnBagArr}{" "}
        </DetailBottomText>
      </MainCard>
    )
  }
}
const mapState = () =>
  createStructuredSelector({
    airports: features.landing.selectors.selectAirportList()
  })

export default connect(mapState)(withRouter(CardDetail))
// export default CardDetail

export const BigCardT = ({
  data,
  majorData,
  index,
  cabinClassArr,
  type,
  airports,
  cabinFromSearch
}) => {
  let {
    arivalTime,
    depTime,
    duration,
    depDate,
    flightNumber,
    aircraft,
    is_flight_duration_null
  } = flightTimings(data)
  let {
    arivalAirport,
    depAirport,
    stops,
    stopAirports,
    airlineCode,
    cabinNew,
    arrivalCity,
    departCity
  } = getFlightCardData(data, airports)
  let d = getStopAirportsDataImproved(data, airports)
  let { transitTime, transitAirportCode } = getTransitDurationText(data)
  let transitAirportName
  airports.map(item => {
    if (item.code === transitAirportCode) {
      transitAirportName = item.value
    }
  })
  return stops ? (
    <div style={{ backgroundColor: "#fff",padding:"2px" }}>
      {d.map((d, i) => {
        let { transitTimeD, transitAirport } = d.stayAirportData
        let transitAirportName
        airports.map(item => {
          if (item.code === d.depAirport) {
            transitAirportName = item.value
          }
        })
        return (
          <Fragment>
              {transitTimeD !== null || transitAirport !== null  ? (
              <div style={{marginTop:"10px"}}>
                <ChangePlanesUI
                  transitAirportName={`${transitAirportName} (${transitAirport})`}
                  transitTime={transitTimeD}
                />
              </div>
              ) : null}
            <BigCard noMargin key={i} transitTimeD={transitTimeD}>
              <Date>
                {d.depDate}
                <p style={{ marginTop: "20px", fontSize: "15px" }}>
                  {flightNumber}
                </p>
              </Date>
              <CardImg>
                <img
                  src={`${process.env.REACT_APP_API_URL}v2/flight/airlines/logos/${airlineCode}`}
                  alt="N/A"
                />
                <p>{getAirlineName(data)}</p>
              </CardImg>
              <Fromtext title={`${d.departCity} (${d.depAirport})`}>
                <h4>{TimeConvert24to12(d.depTime)}</h4>
                <h5>
                  {d.departCity} <br /> ({d.depAirport})
                </h5>
              </Fromtext>
              <DirectFlight>
                <img
                  src="images/air.png"
                  style={{ width: "100%" }}
                  alt="plane"
                />
                <h4>{d.duration}</h4>
              </DirectFlight>
              <Fromtext title={`${d.arrivalCity} (${d.arivalAirport})`}>
                <h4>{TimeConvert24to12(d.arrTime)}</h4>
                <h5>
                  {d.arrivalCity} <br /> ({d.arivalAirport})
                </h5>
              </Fromtext>
              <Flightdetail>
                {cabinNew
                  ? cabinNew.replace("_", " ")
                  : cabinFromSearch.replace("_", " ")}
                {/* {getMyCabinClass(index, cabinClassArr)} */}
              </Flightdetail>
              <AirCraftName>
                <img
                  src="images/listicon.png"
                  style={{ width: "15px" }}
                  alt="icon"
                />
                <div style={{ alignItems: "flex-end", marginLeft: "3px" }}>
                  {aircraft}
                </div>
              </AirCraftName>
            </BigCard>
          </Fragment>
        )
      })}
    </div>
  ) : (
    <div style={{ backgroundColor: "#fff" }}>
      {transitTime !== null ? (
        <ChangePlanesUI
          transitAirportName={`${transitAirportName} (${transitAirportCode})`}
          transitTime={transitTime}
        />
      ) : null}
      <BigCard transitTimeD={transitTime}>
        <Date>
          {depDate}
          <p style={{ marginTop: "20px", fontSize: "15px" }}>{flightNumber}</p>
        </Date>
        <CardImg>
          <img
            src={`${process.env.REACT_APP_API_URL}v2/flight/airlines/logos/${airlineCode}`}
            alt="N/A"
          />
          <p>{getAirlineName(data)}</p>
        </CardImg>
        <Fromtext title={`${departCity} (${depAirport})`}>
          <h4>{TimeConvert24to12(depTime)}</h4>
          <h5>
            {departCity} <br /> ({depAirport})
          </h5>
        </Fromtext>
        <DirectFlight>
          <img src="images/air.png" style={{ width: "100%" }} alt="plane" />
          <h4>{is_flight_duration_null ? null : duration}</h4>
        </DirectFlight>
        <Fromtext title={`${arrivalCity} (${arivalAirport})`}>
          <h4>{TimeConvert24to12(arivalTime)}</h4>
          <h5>
            {arrivalCity} <br /> ({arivalAirport})
          </h5>
        </Fromtext>
        <Flightdetail>
          {cabinNew
            ? cabinNew.replace("_", " ")
            : cabinFromSearch.replace("_", " ")}
          {/* {getMyCabinClass(index, cabinClassArr)} */}
        </Flightdetail>
        <AirCraftName>
          <img src="images/listicon.png" style={{ width: "15px" }} alt="icon" />
          <div style={{ alignItems: "flex-end", marginLeft: "3px" }}>
            {aircraft}
          </div>
        </AirCraftName>
      </BigCard>
    </div>
  )
}

const style = {
  position: "absolute",
  right: "17px",
  top: "17px",
  color: "#fff",
  cursor: "pointer",
  background: "#939297",
  borderRadius: "50%",
  height: "20px",
  width: "20px",
  fontSize: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "2px"
}
