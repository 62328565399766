import React, { Fragment } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import features from "features"
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect"
import { history } from "../store"
import ScrollToTop from "./ScrollToTop"
import CustomerLogin from "scenes/Login/CustomerLogin"
import Tickets from "scenes/Tickets/TicketsPage"
import SearchResult from "scenes/SearchResult/SearchResultsPage"
import Passengers from "scenes/SearchResult/PassengerDetails"
import Landing from "scenes/Landing/LandingPage"
// import ErrorBoundary from "./ErrorBoundry"
import CommingSoon from "scenes/Hotel/CommingSoon"
import ContactUs from "scenes/ContactUs"
import MobileAppPayment from "scenes/MobileAppPayment"
import CustomPayment from "scenes/CustomPayment"
// const Landing = asyncComponent(() => import("../scenes/Landing/LandingPage"))
// const Tickets = asyncComponent(() => import("../scenes/Tickets/TicketsPage"))
// const SearchResult = asyncComponent(() =>
//   import("../scenes/SearchResult/SearchResultsPage")
// )
// const Passengers = asyncComponent(() =>
//   import("../scenes/SearchResult/PassengerDetails")
// )

const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: "/",
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: features.login.selectors.selectIsLoggedIn()
})

// const handlePageChange = () => {
//   debugger
//   window.scrollTo(0, 0)
//   if ("scrollRestoration" in history) {
//     history.scrollRestoration = "manual"
//   }
// }

history.listen((location, action) => {
  window.scrollTo(0, 0)
})
// listen()
// const scrollPositionsHistory = {}
// if (window.history && "scrollRestoration" in window.history) {
//   window.history.scrollRestoration = "manual"
// }

// let currentLocation = history.location
// function onLocationChange(location, action) {
//   // Remember the latest scroll position for the previous location
//   scrollPositionsHistory[currentLocation.key] = {
//     scrollX: window.pageXOffset,
//     scrollY: window.pageYOffset
//   }
//   // Delete stored scroll position for next page if any
//   if (action === "PUSH") {
//     delete scrollPositionsHistory[location.key]
//   }
//   currentLocation = location
//   // render page here and then...

//   let scrollX = 0
//   let scrollY = 0
//   const pos = scrollPositionsHistory[location.key]
//   if (pos) {
//     scrollX = pos.scrollX
//     scrollY = pos.scrollY
//   } else {
//     const targetHash = location.hash.substr(1)
//     if (targetHash) {
//       const target = document.getElementById(targetHash)
//       if (target) {
//         scrollY = window.pageYOffset + target.getBoundingClientRect().top
//       }
//     }
//   }

//   // Restore the scroll position if it was saved into the state
//   // or scroll to the given #hash anchor
//   // or scroll to top of the page
//   window.scrollTo(scrollX, scrollY)
// }
// history.listen(onLocationChange)
// onLocationChange(currentLocation)
const Routes = () => (
  <Fragment>
    <Router
      history={history}
      primary={false}
      onUpdate={() => window.scrollTo(0, 0)}
    >
      <ScrollToTop>
        <Switch>
          <div>
            {/* <Route path="" exact component={Header} /> */}
            <Route path="/" exact component={Landing} ignoreScrollBehavior />
            <Route
              key="search-1"
              path="/search"
              exact
              component={SearchResult}
              ignoreScrollBehavior={true}
            />
            <Route
              key="search-2"
              path="/search2"
              exact
              component={SearchResult}
              ignoreScrollBehavior={true}
            />
            <Route
              key="passenger"
              path="/passengers"
              exact
              component={Passengers}
              ignoreScrollBehavior={true}
            />
            <Route
              path="/home"
              exact
              component={userIsAuthenticated(Tickets)}
              ignoreScrollBehavior={true}
            />
            <Route
              path="/bookings/:pnr"
              exact
              component={userIsAuthenticated(Tickets)}
              ignoreScrollBehavior={true}
            />
            <Route
              path="/customer-login"
              exact
              component={CustomerLogin}
              ignoreScrollBehavior={true}
            />
            <Route
              path="/hotels"
              exact
              component={CommingSoon}
              ignoreScrollBehavior={true}
            />
            <Route
              path="/contact"
              exact
              component={ContactUs}
              ignoreScrollBehavior={true}
            />
            <Route
              path="/payment/mobileapp"
              exact
              component={MobileAppPayment}
              ignoreScrollBehavior={true}
            />
            <Route
              path="/payment"
              exact
              component={CustomPayment}
              ignoreScrollBehavior={true}
            />
          </div>
        </Switch>
      </ScrollToTop>
    </Router>
  </Fragment>
)

Routes.propTypes = {}

export default Routes
