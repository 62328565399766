import * as API from "services/API"
import { actions as ticketActions } from "./ducks"
import { sleep } from "../../utilities"

export const getBookingsList = (api_token, check) => {
  return async dispatch => {
    dispatch(ticketActions.getBookingsListRequest(check))
    try {
      const { data } = await API.getBookingsList(api_token)
      if (data) {
        return dispatch(ticketActions.getBookingsListSuccess({ data, check }))
      } else {
        return dispatch(ticketActions.getBookingsListError())
      }
    } catch (error) {
      return dispatch(ticketActions.getBookingsListError())
    }
  }
}

export const getABooking = ({ api_token, pnr }) => {
  return async dispatch => {
    dispatch(ticketActions.getBookingRequest())
    try {
      const { data } = await API.getBooking(api_token, pnr)
      if (data) {
        return dispatch(ticketActions.getBookingSuccess({ data }))
      } else {
        return dispatch(ticketActions.getBookingError())
      }
    } catch (error) {
      return dispatch(ticketActions.getBookingError())
    }
  }
}

export const cardPayment = ({ api_token, pnr, payment_type }) => {
  return async dispatch => {
    dispatch(ticketActions.cardPaymentRequest())
    try {
      // let message, success, url
      const { success, message, url } = await API.cardsPayment(
        api_token,
        pnr,
        payment_type
      )
      return dispatch(
        ticketActions.cardPaymentSuccess({ success, message, url })
      )
    } catch (error) {
      return dispatch(ticketActions.cardPaymentError())
    }
  }
}

export const walletPayment = ({ api_token, pnr }) => {
  return async dispatch => {
    dispatch(ticketActions.walletPaymentRequest({ pnr }))
    try {
      // let message, success
      const { message, success } = await API.walletsPayment(api_token, pnr)
      return dispatch(
        ticketActions.walletPaymentSuccess({ message, success, pnr })
      )
    } catch (error) {
      return dispatch(ticketActions.walletPaymentError())
    }
  }
}

export const downloadPdf = ({ api_token, pnr }) => {
  return async dispatch => {
    dispatch(ticketActions.downloadPdfRequest())
    try {
      const { message, success } = await API.downloadBookingPdfApi({
        api_token,
        pnr
      })
      return dispatch(ticketActions.downloadPdfSuccess({ success, message }))
    } catch (error) {
      return dispatch(ticketActions.downloadPdfError())
    }
  }
}

export const resendEmail = ({ pnr, api_token, email_address }) => {
  return async dispatch => {
    dispatch(ticketActions.resendEmailRequest())
    try {
      const { message, success } = await API.sendBookingEmailAPI({
        pnr,
        api_token,
        email_address
      })
      return dispatch(ticketActions.resendEmailSuccess({ success, message }))
    } catch (error) {
      return dispatch(ticketActions.resendEmailError())
    }
  }
}

export const requestRefund = ({ api_token, booking_key, user_query }) => {
  return async dispatch => {
    dispatch(ticketActions.requestRefundRequest())
    try {
      const { message, success } = await API.changeBookingRequest({
        api_token,
        booking_key,
        user_query
      })
      return dispatch(ticketActions.requestRefundSuccess({ success, message }))
    } catch (error) {
      return dispatch(ticketActions.requestRefundError())
    }
  }
}

export const flightIssueTicket = (
  { api_token, pnr },
  callAPIFlightIssueTicket = API.flightIssueTicket
) => {
  return async dispatch => {
    dispatch(ticketActions.flightIssueTicketRequest())
    try {
      let success, message

      while (true) {
        const response = await callAPIFlightIssueTicket(api_token, pnr).catch(
          e => {
            console.log("1. Flight Issue Ticket Error: ", e)

            return {
              success: false
            }
          }
        )

        if (response && response.success) {
          success = response.success
          message = response.message

          break
        } else {
          await sleep(2000)

          console.log("\n Retrying Flight Issue Ticket... \n")
        }
      }

      return dispatch(
        ticketActions.flightIssueTicketSuccess({ success, message })
      )
    } catch (error) {
      console.log("2. Flight Issue Ticket Error: ", error)

      return dispatch(ticketActions.flightIssueTicketError())
    }
  }
}

export const selectTicket = ({ ticket, change }) => {
  return async dispatch => {
    dispatch(ticketActions.selectTicket({ ticket, change }))
  }
}
