// import React from "react"
// import { InputsLoadersHalf } from "../Loaders"
import InputAutoComplete from "./InputAutoComplete"
// const InputAutoComplete = React.lazy(() => import("./InputAutoComplete"))
// class index extends React.PureComponent {
//   render() {
//     return (
//       <React.Suspense fallback={InputsLoadersHalf()}>
//         <InputAutoComplete {...this.props} />
//       </React.Suspense>
//     )
//   }
// }

export default InputAutoComplete
