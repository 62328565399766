import unpack from "./unpack-lib"

// export const airportsList = () => {
//   // debugger
//   return client.get(`flight/airports`).then(response => response.data)
// }
export const airportsList = async () => {
  return JSON.parse(
    unpack(
      await fetch(
        `${process.env.REACT_APP_API_URL}v2/flight/new-airports-packed`
      )
        .then(response => response.text())
        .catch(error => {
          return error
        })
    )
  )
}
