import Immutable from "seamless-immutable"
import { createSelector } from "reselect"

// TYPES
export const types = {
  //
  // GET_BOOKINGS_LIST
  //
  GET_BOOKINGS_LIST_REQUEST: "tripovy/GET_BOOKINGS_LIST_REQUEST",
  GET_BOOKINGS_LIST_SUCCESS: "tripovy/GET_BOOKINGS_LIST_SUCCESS",
  GET_BOOKINGS_LIST_ERROR: "tripovy/GET_BOOKINGS_LIST_ERROR",

  //
  // GET_BOOKINGS_LIST
  //
  GET_BOOKING_REQUEST: "tripovy/GET_BOOKING_REQUEST",
  GET_BOOKING_SUCCESS: "tripovy/GET_BOOKING_SUCCESS",
  GET_BOOKING_ERROR: "tripovy/GET_BOOKING_ERROR",
  //
  // CARD_PAYMENT
  //
  CARD_PAYMENT_REQUEST: "tripovy/CARD_PAYMENT_REQUEST",
  CARD_PAYMENT_SUCCESS: "tripovy/CARD_PAYMENT_SUCCESS",
  CARD_PAYMENT_ERROR: "tripovy/CARD_PAYMENT_ERROR",
  //
  // WALLET_PAYMENT
  //
  WALLET_PAYMENT_REQUEST: "tripovy/WALLET_PAYMENT_REQUEST",
  WALLET_PAYMENT_SUCCESS: "tripovy/WALLET_PAYMENT_SUCCESS",
  WALLET_PAYMENT_ERROR: "tripovy/WALLET_PAYMENT_ERROR",
  //
  // FLIGHT_ISSUE_TICKET
  //
  FLIGHT_ISSUE_TICKET_REQUEST: "tripovy/FLIGHT_ISSUE_TICKET_REQUEST",
  FLIGHT_ISSUE_TICKET_SUCCESS: "tripovy/FLIGHT_ISSUE_TICKET_SUCCESS",
  FLIGHT_ISSUE_TICKET_ERROR: "tripovy/FLIGHT_ISSUE_TICKET_ERROR",
  // SELECT_TICKET
  SELECT_TICKET: "tripovy/SELECT_TICKET",
  // DOWNLOAD_PDF
  DOWNLOAD_PDF_REQUEST: "tripovy/DOWNLOAD_PDF_REQUEST",
  DOWNLOAD_PDF_SUCCESS: "tripovy/DOWNLOAD_PDF_SUCCESS",
  DOWNLOAD_PDF_ERROR: "tripovy/DOWNLOAD_PDF_ERROR",
  // RESEND_EMAIL
  RESEND_EMAIL_REQUEST: "tripovy/RESEND_EMAIL_REQUEST",
  RESEND_EMAIL_SUCCESS: "tripovy/RESEND_EMAIL_SUCCESS",
  RESEND_EMAIL_ERROR: "tripovy/RESEND_EMAIL_ERROR",
  // REQUEST_REFUND
  REQUEST_REFUND_REQUEST: "tripovy/REQUEST_REFUND_REQUEST",
  REQUEST_REFUND_SUCCESS: "tripovy/REQUEST_REFUND_SUCCESS",
  REQUEST_REFUND_ERROR: "tripovy/REQUEST_REFUND_ERROR"
}

const initialState = Immutable({
  reservations: [],
  isBookingList: false,
  selectingCard: false,
  selectingWallet: false,
  walletMessage: null,
  walletSuccess: null,
  selectedTicket: null,
  isDownloadingPdf: false,
  isResendingEmail: false,
  isRequestingRefund: false
})

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    //
    // GET_BOOKINGS_LIST
    //
    case types.GET_BOOKINGS_LIST_REQUEST:
      return state.merge({
        isBookingList: action.payload.check ? false : true
      })
    case types.GET_BOOKINGS_LIST_SUCCESS:
      if (action.check) {
        return state.merge({
          isBookingList: false,
          reservations: action.payload
        })
      } else {
        return state.merge({
          isBookingList: false,
          reservations: action.payload,
          selectedTicket: action.payload[0]
        })
      }
    case types.GET_BOOKINGS_LIST_ERROR:
      return state.merge({
        reservations: [],
        isBookingList: false
      })
    //
    // GET_BOOKING
    //
    case types.GET_BOOKING_REQUEST:
      return state.merge({})
    case types.GET_BOOKING_SUCCESS:
      let ticketArray = Immutable(state.reservations).asMutable({ deep: true })
      let i = ticketArray.findIndex(e => e.pnr == action.payload.data.pnr)
      ticketArray.splice(i, 1, action.payload.data)
      return state.merge({
        reservations: ticketArray
        // selectedTicket: ticketArray[0]
      })
    case types.GET_BOOKING_ERROR:
      return state.merge({})
    //
    // CARD_PAYMENT
    //
    case types.CARD_PAYMENT_REQUEST:
      return state.merge({
        selectingCard: true
      })
    case types.CARD_PAYMENT_SUCCESS:
      return state.merge({
        selectingCard: false,
        cardSuccess: action.payload.success,
        cardMessage: action.payload.message,
        cardUrl: action.payload.url
      })
    case types.CARD_PAYMENT_ERROR:
      return state.merge({
        selectingCard: false
      })
    //
    // WALLET_PAYMENT
    //
    case types.WALLET_PAYMENT_REQUEST:
      let { pnr } = action.payload
      let res = Immutable(state.reservations).asMutable({ deep: true })
      let index = res.find(item => item.pnr === pnr)
      // res[index].paid
      // paid === 1 && is_issued === 0
      return state.merge({
        selectingWallet: true
      })
    case types.WALLET_PAYMENT_SUCCESS:
      return state.merge({
        // isBookingList: true,
        selectingWallet: false,
        walletMessage: action.payload.message,
        walletSuccess: action.payload.success
      })
    case types.WALLET_PAYMENT_ERROR:
      return state.merge({
        selectingWallet: false,
        walletMessage: null,
        walletSuccess: null
      })
    //
    // FLIGHT_ISSUE_TICKET
    //
    case types.FLIGHT_ISSUE_TICKET_REQUEST:
      return state.merge({
        isBookingList: false
      })
    case types.FLIGHT_ISSUE_TICKET_SUCCESS:
      return state.merge({
        isBookingList: false
      })
    case types.FLIGHT_ISSUE_TICKET_ERROR:
      return state.merge({})

    case types.SELECT_TICKET:
      let reserv = Immutable(state.reservations).asMutable({ deep: true })
      if (action.change === true) {
        let indx = reserv.findIndex(p => p.pnr === action.payload.pnr)
        if (indx) {
          reserv[indx].is_issued = 1
          reserv[indx].paid = 1
          reserv[indx].is_expired = false
        }
      }
      return state.merge({
        selectedTicket: action.payload,
        reservations: reserv
      })
    //
    // DOWNLOAD_PDF
    //
    case types.DOWNLOAD_PDF_REQUEST:
      return state.merge({
        isDownloadingPdf: true
      })
    case types.DOWNLOAD_PDF_SUCCESS:
      return state.merge({
        isDownloadingPdf: false
      })
    case types.DOWNLOAD_PDF_ERROR:
      return state.merge({
        isDownloadingPdf: false
      })

    //
    // RESEND_EMAIL
    //
    case types.RESEND_EMAIL_REQUEST:
      return state.merge({
        isResendingEmail: true
      })
    case types.RESEND_EMAIL_SUCCESS:
      return state.merge({
        isResendingEmail: false
      })
    case types.RESEND_EMAIL_ERROR:
      return state.merge({
        isResendingEmail: false
      })

    //
    // REQUEST_REFUND
    //
    case types.REQUEST_REFUND_REQUEST:
      return state.merge({
        isRequestingRefund: true
      })
    case types.REQUEST_REFUND_SUCCESS:
      return state.merge({
        isRequestingRefund: false
      })
    case types.REQUEST_REFUND_ERROR:
      return state.merge({
        isRequestingRefund: false
      })

    default:
      return state
  }
}

export const actions = {
  //
  // GET_BOOKINGS_LIST
  //
  getBookingsListRequest: check => ({
    type: types.GET_BOOKINGS_LIST_REQUEST,
    payload: { check }
  }),
  getBookingsListSuccess: ({ data, check }) => ({
    type: types.GET_BOOKINGS_LIST_SUCCESS,
    payload: data,
    check
  }),
  getBookingsListError: () => ({
    type: types.GET_BOOKINGS_LIST_ERROR
  }),
  //
  // GET_A_BOOKING
  //
  getBookingRequest: () => ({
    type: types.GET_BOOKING_REQUEST
  }),
  getBookingSuccess: data => ({
    type: types.GET_BOOKING_SUCCESS,
    payload: data
  }),
  getBookingError: () => ({
    type: types.GET_BOOKING_ERROR
  }),
  //
  // CARD_PAYMENT
  //
  cardPaymentRequest: () => ({
    type: types.CARD_PAYMENT_REQUEST
  }),
  cardPaymentSuccess: ({ success, message, url }) => ({
    type: types.CARD_PAYMENT_SUCCESS,
    payload: { success, message, url }
  }),
  cardPaymentError: () => ({
    type: types.CARD_PAYMENT_ERROR
  }),
  //
  // WALLET_PAYMENT
  //
  walletPaymentRequest: ({ pnr }) => ({
    type: types.WALLET_PAYMENT_REQUEST,
    payload: { pnr }
  }),
  walletPaymentSuccess: ({ message, success, pnr }) => ({
    type: types.WALLET_PAYMENT_SUCCESS,
    payload: { message, success, pnr }
  }),
  walletPaymentError: () => ({
    type: types.WALLET_PAYMENT_ERROR
  }),
  //
  // FLIGHT_ISSUE_TICKET
  //
  flightIssueTicketRequest: () => ({
    type: types.FLIGHT_ISSUE_TICKET_REQUEST
  }),
  flightIssueTicketSuccess: ({ success, message }) => ({
    type: types.FLIGHT_ISSUE_TICKET_SUCCESS,
    payload: { success, message }
  }),
  flightIssueTicketError: () => ({
    type: types.FLIGHT_ISSUE_TICKET_ERROR
  }),
  // SELECT_TICKET
  selectTicket: ({ ticket, change }) => ({
    type: types.SELECT_TICKET,
    payload: ticket,
    change
  }),
  //
  // DOWNLOAD_PDF
  //
  downloadPdfRequest: () => ({
    type: types.DOWNLOAD_PDF_REQUEST
  }),
  downloadPdfSuccess: ({ success, message }) => ({
    type: types.DOWNLOAD_PDF_SUCCESS,
    payload: { success, message }
  }),
  downloadPdfError: () => ({
    type: types.DOWNLOAD_PDF_ERROR
  }),
  //
  // RESEND_EMAIL
  //
  resendEmailRequest: () => ({
    type: types.RESEND_EMAIL_REQUEST
  }),
  resendEmailSuccess: ({ success, message }) => ({
    type: types.RESEND_EMAIL_SUCCESS,
    payload: { success, message }
  }),
  resendEmailError: () => ({
    type: types.RESEND_EMAIL_ERROR
  }),
  //
  // REQUEST_REFUND
  //
  requestRefundRequest: () => ({
    type: types.REQUEST_REFUND_REQUEST
  }),
  requestRefundSuccess: ({ success, message }) => ({
    type: types.REQUEST_REFUND_SUCCESS,
    payload: { success, message }
  }),
  requestRefundError: () => ({
    type: types.REQUEST_REFUND_ERROR
  }),
  //
  // UPDATE_PROFILE
  //
  updateProfileRequest: () => ({
    type: types.UPDATE_PROFILE_REQUEST
  }),
  updateProfileSuccess: ({ data }) => ({
    type: types.UPDATE_PROFILE_SUCCESS,
    payload: data
  }),
  updateProfileError: () => ({
    type: types.UPDATE_PROFILE_ERROR
  })
}

// Selectors
export const ticketsSelector = () => state => state.tickets

export const selectReservations = () =>
  createSelector(
    ticketsSelector(),
    state => {
      let r = Immutable(state.reservations).asMutable({ deep: true })
      return r
    }
  )
export const selectIsBookingList = () =>
  createSelector(
    ticketsSelector(),
    state => state.isBookingList
  )

export const isSelectingWallet = () =>
  createSelector(
    ticketsSelector(),
    state => state.selectingWallet
  )
export const selectSelectedTicket = () =>
  createSelector(
    ticketsSelector(),
    state => state.selectedTicket
  )

export const selectIsDownloadingPdf = () =>
  createSelector(
    ticketsSelector(),
    state => state.isDownloadingPdf
  )

export const selectIsResendingEmail = () =>
  createSelector(
    ticketsSelector(),
    state => state.isResendingEmail
  )

export const selectIsRequestingRefund = () =>
  createSelector(
    ticketsSelector(),
    state => state.isRequestingRefund
  )

export const selectors = {
  selectReservations,
  selectIsBookingList,
  isSelectingWallet,
  selectSelectedTicket,
  selectIsDownloadingPdf,
  selectIsResendingEmail,
  selectIsRequestingRefund
}
