import styled from "styled-components"

export const ContactContainer = styled.div`
  // background-color: white;

  background-color: #efefef;
  padding: 20px;

  min-height: calc(100vh - 283px);
  max-height: 100vh;
  height: 100vh;
  // @media only screen and (min-width: 1400px) and (max-width: 2000px) {
  //   height: calc(100vh - 249px);
  // }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 20px 10px;
  }
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    height: 100%;
  }
`

export const UserContactForm = styled.div`
  width: 90vw;
  margin: auto;
  margin-top: 20px;
`
export const CenteredDiv = styled.div`
  margin: auto;
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);

  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
