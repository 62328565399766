import React, { Component, Fragment } from "react"
import {
  BigCard,
  CardImg,
  Fromtext,
  DirectFlight,
  Flightdetail,
  MainCard,
  Detail,
  DetailText,
  Date,
  DetailBottomText,
  CardTopHeading,
  AirCraftName
} from "./elements"
import {
  getAirlineName,
  flightTimings,
  getStopAirportsDataImproved,
  getMajorFlightDetails,
  getFlightCardDataMulti,
  getNewFlightDuration,
  getTransitDurationText,
  multicityBaggageAllowance,
  getCabinFromSearchQuery
} from "filterFunctions"
import { ChangePlanesUI } from "base_components/ChangePlanesUI"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { withRouter } from "react-router-dom"
import features from "features"
import { LuggageIcon } from "base_components/Icons"
import { FaTimes } from "react-icons/fa"
import { TimeConvert24to12 } from "shared/ticketFuctions"

class CardDetail extends Component {
  state = {
    array: []
  }
  componentDidMount = () => {
    let myArr = []
    this.props.data.trips.map(items => {
      myArr.push(items.departing_flight_segments)
    })

    myArr.map(i => {
      i.map(item => {
        item.cabin = ""
      })
    })
    let newArr = [].concat.apply([], myArr)

    newArr.map((items, indexes) => {
      this.props.cabinClassArr.map((item, index) => {
        if (indexes === index) {
          items.cabin = item
        }
      })
    })
    this.setState({ array: newArr })
  }

  render() {
    let { data, hideDetails, cabinClassArr, airports } = this.props
    let { baggage_allowance, trips } = data

    let { multiBagArr } = multicityBaggageAllowance({
      baggage_allowance,
      trips
    })
    let { cabinFromSearch } = getCabinFromSearchQuery(
      this.props.location.search
    )
    return (
      <MainCard>
        <Detail>
          <DetailText>Details</DetailText>
          <FaTimes style={style} onClick={() => hideDetails()} />
        </Detail>
        {trips.map((datat, i) => (
          <Fragment>
            <BigCardT
              data={datat}
              majorData={data}
              tripIndex={i}
              key={i}
              myData={this.state.array}
              cabinClassArr={cabinClassArr}
              departing_flight_duration={datat.departing_flight_duration}
              airports={airports}
              cabinFromSearch={cabinFromSearch}
            />
            <DetailBottomText title="Baggage allowance">
              <LuggageIcon /> {multiBagArr[i].join(", ")}
            </DetailBottomText>
          </Fragment>
        ))}
      </MainCard>
    )
  }
}

const mapState = () =>
  createStructuredSelector({
    airports: features.landing.selectors.selectAirportList()
  })

export default connect(mapState)(withRouter(CardDetail))

export const BigCardT = ({
  myData,
  data,
  majorData,
  tripIndex,
  cabinClassArr,
  departing_flight_duration,
  airports,
  cabinFromSearch
}) => {
  let {
    depTime,
    depAirport,
    arrTime,
    arrAirport,
    totalDuration
  } = getMajorFlightDetails(data.departing_flight_segments)
  let { DTime } = getNewFlightDuration(departing_flight_duration)

  return (
    <Fragment>
      <CardTopHeading>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{ color: "#00b4e2", marginRight: "10px", fontSize: "20px" }}
          >
            Flight {tripIndex + 1}
          </p>
          <p style={{ color: "#939297", fontSize: "18px" }}>
            {depAirport}-{arrAirport}
          </p>
        </div>
        <p style={{ fontSize: "20px" }}>{DTime}</p>
      </CardTopHeading>
      {data.departing_flight_segments.map((d, i) => (
        <BigCardTT
          data={d}
          myData={myData}
          majorData={majorData}
          key={i}
          flightSegmentsIndex={i}
          tripIndex={tripIndex}
          cabinClassArr={cabinClassArr}
          airports={airports}
          cabinFromSearch={cabinFromSearch}
        />
      ))}
    </Fragment>
  )
}

export const BigCardTT = ({ data, airports, cabinFromSearch }) => {
  let {
    arivalTime,
    depTime,
    duration,
    depDate,
    flightNumber,
    aircraft,
    is_flight_duration_null
  } = flightTimings(data)
  let {
    arivalAirport,
    depAirport,
    stops,
    cabin,
    airlineCode,
    cabinNew,
    arrivalCity,
    departCity
  } = getFlightCardDataMulti(data, airports)
  let d = getStopAirportsDataImproved(data, airports)
  let { transitTime, transitAirportCode } = getTransitDurationText(data)
  let transitAirportName
  airports.map(item => {
    if (item.code === transitAirportCode) {
      transitAirportName = item.value
    }
  })

  return stops ? (
    <Fragment>
      {d.map((d, i) => {
        let { transitTimeD, transitAirport } = d.stayAirportData

        return (
          <div style={{ backgroundColor: "#fff" }}>
            {transitTimeD !== null ? (
              <ChangePlanesUI
                transitAirportName={transitAirport}
                transitTime={transitTimeD}
              />
            ) : null}
            <BigCard noMargin transitTimeD={transitTimeD}>
              <Date>
                {d.depDate}
                <p style={{ marginTop: "20px", fontSize: "15px" }}>
                  {flightNumber}
                </p>
              </Date>
              <CardImg>
                <img
                  src={`${process.env.REACT_APP_API_URL}v2/flight/airlines/logos/${airlineCode}`}
                  alt="N/A"
                />
                <p>{getAirlineName(data)}</p>
              </CardImg>
              <Fromtext title={`${d.departCity} (${d.depAirport})`}>
                <h4>{TimeConvert24to12(d.depTime)}</h4>
                <h5>
                  {d.departCity} <br /> ({d.depAirport})
                </h5>
              </Fromtext>
              <DirectFlight>
                <img
                  src="images/air.png"
                  style={{ width: "100%" }}
                  alt="plane"
                />
                {/* <h4>{d.duration}</h4> */}
              </DirectFlight>
              <Fromtext title={`${d.arrivalCity} (${d.arivalAirport})`}>
                <h4>{TimeConvert24to12(d.arivalTime)}</h4>
                <h5>
                  {d.arrivalCity} <br /> ({d.arivalAirport})
                </h5>
              </Fromtext>
              <Flightdetail>
                {cabinNew && cabinNew !== "N/A"
                  ? cabinNew.replace("_", " ")
                  : cabinFromSearch.replace("_", " ")}
              </Flightdetail>
              <AirCraftName>
                <img
                  src="images/listicon.png"
                  style={{ width: "15px" }}
                  alt="icon"
                />
                <div style={{ alignItems: "flex-end", marginLeft: "3px" }}>
                  {aircraft}
                </div>
              </AirCraftName>
            </BigCard>
          </div>
        )
      })}
    </Fragment>
  ) : (
    <div style={{ backgroundColor: "#fff" }}>
      {transitTime !== null ? (
        <ChangePlanesUI
          transitAirportName={`${transitAirportName} (${transitAirportCode})`}
          transitTime={transitTime}
          style={{ background: "#fff" }}
        />
      ) : null}
      <BigCard noMargin transitTimeD={transitTime}>
        <Date>
          {depDate}
          <p style={{ marginTop: "20px", fontSize: "15px" }}>{flightNumber}</p>
        </Date>
        <CardImg>
          <img
            src={`${process.env.REACT_APP_API_URL}v2/flight/airlines/logos/${airlineCode}`}
            alt="N/A"
          />
          <p>{getAirlineName(data)}</p>
        </CardImg>
        <Fromtext title={`${departCity} (${depAirport})`}>
          <h4>{TimeConvert24to12(depTime)}</h4>
          <h5>
            {departCity} <br />({depAirport})
          </h5>
        </Fromtext>
        <DirectFlight>
          <img src="images/air.png" style={{ width: "100%" }} alt="plane" />
          <h4>{is_flight_duration_null ? null : duration}</h4>
        </DirectFlight>
        <Fromtext title={`${arrivalCity} (${arivalAirport})`}>
          <h4>{TimeConvert24to12(arivalTime)}</h4>
          <h5>
            {arrivalCity} <br />({arivalAirport})
          </h5>
        </Fromtext>
        <Flightdetail>
          {cabinNew && cabinNew !== "N/A"
            ? cabinNew.replace("_", " ")
            : cabinFromSearch.replace("_", " ")}
        </Flightdetail>
        <AirCraftName>
          <img src="images/listicon.png" style={{ width: "15px" }} alt="icon" />
          <div style={{ alignItems: "flex-end", marginLeft: "3px" }}>
            {aircraft}
          </div>
        </AirCraftName>
      </BigCard>
    </div>
  )
}

const style = {
  position: "absolute",
  right: "17px",
  top: "17px",
  color: "#fff",
  cursor: "pointer",
  background: "#939297",
  borderRadius: "50%",
  height: "20px",
  width: "20px",
  fontSize: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "2px"
}
