import styled from "styled-components"
export const Filter = styled.div`
  display: flex;
  margin-top: 10px;
  background-color: #fff;
  padding: 5px 10px;
  padding-bottom: 0px;
  align-items: center;
  justify-content: space-between;
  #datepicker {
    max-width: 120px;
    height: 2.6rem;
    &:focus {
      outline: none;
    }
    @media only screen and (min-width: 200px) and (max-width: 767px) {
      max-width: 100%;
    }
  }
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 10px;
  }
`
export const FilterBtn = styled.div`
  background-color: #fff;
  padding: 3px 3px;
  cursor: pointer;
  border-bottom: 2px solid #47b0e5;
  padding-bottom: 10px;
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    width: 50%;
  }
  h4 {
    color: #4e4e4e;
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 400;
    @media only screen and (min-width: 1025px) and (max-width: 1400px) {
      font-size: 13px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 13px;
    }
  }
  p {
    color: #8d8c95;
    margin-bottom: 0px;
    font-size: 13px;
    margin-top: 4px;
    font-weight: 500;
    @media only screen and (min-width: 1025px) and (max-width: 1500px) {
      font-size: 10px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 12px;
    }
  }
`
export const Divider = styled.div`
  border-left: 1px solid #eee;
  height: 44px;
  margin: 0px 10px;
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    display: none;
  }
`
export const Arroleft = styled.span`
  background-color: #47b2e6;
  color: #fff;
  padding: 8px 2px;
  cursor: pointer;
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    margin-bottom: 10px;
  }
`
