import React, { Component, Fragment } from "react"
import {
  BigCard,
  CardImg,
  Fromtext,
  DirectFlight,
  Flightdetail,
  SmallCard,
  TotalHeading,
  TotalPrice,
  SelectBtn,
  MainCard
} from "./elements"
import { FaArrowRight, FaPlane } from "react-icons/fa"
import CardDetail from "./CardDetail"
import {
  priceItinerary,
  cabinClassArray,
  getMajorFlightDetails,
  getNewFlightDuration
} from "filterFunctions"
import { TimeConvert24to12 } from "shared/ticketFuctions"
import { withRouter } from "react-router-dom"
// import { createStructuredSelector } from "reselect"
// import features from "features"

const OnewayContainer = ({ data, allAirports, updateProgress }) => {
  return (
    <Fragment>
      {data &&
        data.length &&
        data.map((item, i) => (
          <OnewayResults
            key={i}
            data={item}
            updateProgress={updateProgress}
            allAirports={allAirports}
          />
        ))}
    </Fragment>
  )
}

export default OnewayContainer

class OnewayResults extends Component {
  state = {
    showdetails: false
  }

  showDetails = () => {
    this.setState({ showdetails: !this.state.showdetails })
  }
  render() {
    let { showdetails } = this.state
    let { updateProgress, data, allAirports } = this.props
    let {
      baggage_allowance,
      departing_flight_segments,
      price_itinerary,
      seats_remaining,
      departing_flight_duration
    } = data
    let { amount, currency, cabin_code } = priceItinerary(price_itinerary)
    let cabinClassArr = cabinClassArray(cabin_code)
    let { DTime } = getNewFlightDuration(departing_flight_duration)
    return (
      <div>
        <MainCard>
          <div style={styleD} className="big-card" onClick={this.showDetails}>
            <FlightSegments
              allAirports={allAirports}
              data={departing_flight_segments}
              DTime={DTime}
            />
            <Flightdetail onClick={this.showDetails}>
              Flight details
            </Flightdetail>
          </div>
          <SmallCard>
            <div>
              <TotalHeading>{currency}</TotalHeading>
              <TotalPrice>{amount.toLocaleString("en-IN")}</TotalPrice>
            </div>
            <SelectBtn onClick={() => updateProgress({ selectedFlight: data })}>
              Select <FaArrowRight />
            </SelectBtn>
          </SmallCard>
        </MainCard>
        {showdetails ? (
          <CardDetail
            hideDetails={this.showDetails}
            data={data}
            cabinClassArr={cabinClassArr}
          />
        ) : null}
      </div>
    )
  }
}

withRouter(OnewayResults)

const FlightSegments = ({ data, DTime, allAirports }) => {
  let {
    depTime,
    arrTime,
    depAirport,
    arrAirport,
    flightDuration,
    stopInt,
    sAValue,
    airlineName,
    totalDuration,
    airlineCode,
    arrivalCity,
    depCity
  } = getMajorFlightDetails(data, allAirports)
  return (
    <BigCard>
      <CardImg>
        <img
          src={`${process.env.REACT_APP_API_URL}v2/flight/airlines/logos/${airlineCode}`}
          alt="N/A"
        />
        <p>{airlineName}</p>
      </CardImg>
      <Fromtext title={`${depCity} (${depAirport})`}>
        <h4>{TimeConvert24to12(depTime)}</h4>
        <h5>
          {depCity}
          <br />({depAirport})
        </h5>
      </Fromtext>
      <DirectFlight>
        <h4>{DTime}</h4>
        {stopInt ? (
          <Fragment>
            <StopsImage stops={stopInt} />
            <h5 className="stop">
              {stopInt} Stop{" "}
              <span style={{ color: "#7c7b84" }}>({sAValue})</span>
            </h5>
          </Fragment>
        ) : (
          <Fragment>
            <StopsImage />
            <h5 className="direct">Direct</h5>
          </Fragment>
        )}
      </DirectFlight>
      <Fromtext
        title={`${arrivalCity} (${arrAirport})`}
        style={{ marginRight: "0px" }}
      >
        <h4>{TimeConvert24to12(arrTime)}</h4>
        <h5>
          {arrivalCity} <br />({arrAirport})
        </h5>
      </Fromtext>
    </BigCard>
  )
}

const styleD = {
  borderRadius: "10px",
  background: "#fff",
  display: "flex",
  flexDirection: "column",
  width: "67%"
}

export const StopsImage = ({ stops }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: "3px" }}>
      <div
        className="flight duration"
        style={{
          borderBottom: "2px solid #ccc",
          flex: 9,
          position: "relative",
          display: "flex",
          justifyContent: "center"
        }}
      >
        {stops ? StopDots(stops) : null}
      </div>
      {/* <i className="fa fa-plane" style={{ flex: 1, marginLeft: "5px" }} /> */}
      <FaPlane
        style={{
          // flex: 1,
          marginLeft: "5px",
          fontSize: "14px",
          color: "#7a7688"
        }}
      />
    </div>
  )
}

const StopDots = stops => {
  let data = []
  for (let i = 1; i <= stops; i++) {
    data.push(
      <span
        key={i}
        style={{
          color: "red",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "0px",
          marginRight: "5px"
        }}
      >
        &#9679;
      </span>
    )
  }
  return data
}
