import { combineReducers } from "redux"
import { routerReducer as routing } from "react-router-redux"
import features from "features"
// this is the root reducers, we will combine all reducers that we are using.
export default combineReducers({
  searchResult: features.searchResult.reducer,
  landing: features.landing.reducer,
  login: features.login.reducer,
  tickets: features.tickets.reducer,
  routing
})
