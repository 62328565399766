import React from "react"
import { Modal, ModalBody } from "reactstrap"
import { SidebarModal } from "./elements"
import Accordion from "../LeftBar"
class FilterModal extends React.Component {
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.toggleState}
          toggle={this.props.toggleHandler}
          className={this.props.className}
        >
          <SidebarModal>
            <ModalBody>
              <button
                type="button"
                className="close"
                aria-label="Close"
                style={{
                  border: "1px solid #00b4e2",
                  opacity: "1",
                  color: "#00b4e2",
                  fontSize: "25px",
                  padding: "2px 10px",
                  marginBottom: "18px"
                }}
              >
                <span aria-hidden="true" onClick={this.props.toggleHandler}>
                  {this.props.buttonLabel}×
                </span>
              </button>
              <Accordion />
            </ModalBody>
          </SidebarModal>
        </Modal>
      </div>
    )
  }
}

export default FilterModal
