import styled from "styled-components"
export const BackWhite = styled.div`
  background-color: #fff;
  margin-top: 15rem;
  @media only screen and (min-width: 1025px) and (max-width: 1400px) {
    margin-top: 10rem;
  }
  @media only screen and (min-width: 200px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`

export const Main = styled.div`
  // background-image: url(/images/back1.svg);
  // height: calc(100vh - 89px);
  // max-height: 100%;
  // background-size: cover;
  // width: 100%;
  // background-repeat: no-repeat;
  // background-position: left bottom;
  // background-attachment: fixed;
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    height: 100%;
  }
`
export const Full = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 20px;
  @media only screen and (min-width: 1366px) and (max-width: 3000px) {
    max-width: 1365px;
  }
  @media only screen and (min-width: 1500px) and (max-width: 1699px) {
    // max-width: 100%;
    .slick-slide img {
      // width: 230px !important;
      margin-top: 10px;
    }
  }
  @media only screen and (min-width: 1700px) and (max-width: 1950px) {
    .main_Slider {
      background-size: 101% 100%;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .main_Slider {
      height: 195px;
    }
  }
  @media only screen and (min-width: 280px) and (max-width: 1023px) {
    .main_Slider {
      height: 262px !important;
    }
    .main_Slider h3 {
      margin-bottom: 9px;
      font-size: 22px;
    }
    .slick-initialized .slick-arrow {
      top: 50% !important;
    }
    .slick-slide img {
      width: 100% !important;
    }
    .video {
      margin-left: 5px;
    }
    .row {
      display: unset !important;
    }
    .col-sm-6,
    .col-sm-7,
    .col-sm-5 {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
`
export const FullTabs = styled.div``

export const FullBack = styled.div`
  background-image: ${hotel =>
    hotel.hotel === "2"
      ? "url(../images/comingSoon.png)"
      : "url(/images/back1.svg)"};
  height: 100vh;
  max-height: 100%;
  background-size: ${hotel => (hotel.hotel === "2" ? "100% 100%" : "cover")};
  width: 100%;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-attachment: fixed;
  position: fixed;
  overflow-y: auto;
  ::-webkit-input-placeholder {
    font-style: normal !important;
  }
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    background-image: ${hotel =>
      hotel.hotel === "2"
        ? "url(../images/comingSoonMobile.png)"
        : "url(/images/flights-mobile.png)"};
    background-position: center;
  }
`
