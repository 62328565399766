import React from "react"
import styled from "styled-components"
import { OrbitSpinner } from "react-epic-spinners"

const Loading = styled(({ className, fullheight }) => (
  <div className={className} fullheight={fullheight}>
    <OrbitSpinner color="#0d90e0" />
  </div>
))`
  display: flex;
  justify-content: center;
  margin: 10px;
  height: ${props => (props.fullheight ? "100vh" : "")};
  ${props => props.styles};
`

export default Loading
