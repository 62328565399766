export const fetchService = ({ method, url, body, card }) => {
  return fetch(`${process.env.REACT_APP_API_URL2}${url}`, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Basic dHJpcG92eTpjb3dib3k="
    },
    body: JSON.stringify(body) // body data type must match "Content-Type" header
  })
    .then(res => res.json())
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw response
      }
    })
    .then(response => {
      return response
    })
    .catch(error => {
      return error
    })
}
export const fetchGlobalService = ({ method, url, body }) => {
  return fetch(url, {
  method: method, // *GET, POST, PUT, DELETE, etc.
  headers: {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: "Basic dHJpcG92eTpjb3dib3k="
  },
  body: JSON.stringify(body) // body data type must match "Content-Type" header
  })
  .then(res => res.json())
  .then(response => {
  if (response.ok) {
  return response.json()
  } else {
  throw response
  }
  })
  .then(response => {
  return response
  })
  .catch(error => {
  return error
  })
 }

 
export * from "./SearchFlights"
export * from "./shared"
export * from "./Auth"
export * from "./Reservations"
export * from "./tickets"
