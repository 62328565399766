import React, {
  Component
  //  Fragment
} from "react"
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Collapse
} from "reactstrap"
import {
  PaymentContainer,
  RightContainer,
  List,
  ListItem,
  ListImage,
  BankText,
  CardBtn
} from "./elements"
import classnames from "classnames"
class PaymentUI extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      payment_type: "other_cards",
      profiles: []
    }
  }

  componentDidMount() {
    // this.props.toggleUpdate()
  }
  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }
  handlePayment = value => {
    this.setState({ payment_type: value })
  }

  testSavePaymentOption = value => {
    this.props.savePaymentOption(value)
    this.props.toggleUpdate()
  }

  render() {
    let { activeTab, payment_type } = this.state
    let { savePaymentOption } = this.props
    return (
      <PaymentContainer>
        <Row>
          <Col xs="12" sm="12" lg="3">
            <Nav tabs vertical>
              <NavItem>
                <NavLink
                  href="#"
                  onClick={() => {
                    this.toggle("1")
                    this.handlePayment("visa")
                  }}
                  className={classnames({
                    active: this.state.activeTab === "1"
                  })}
                >
                  Credit/Debit Cards
                </NavLink>
              </NavItem>
              <Collapse
                isOpen={
                  window.innerWidth <= 990 && activeTab === "1" ? true : false
                }
              >
                <CardsTab
                  paymentMode={e => this.handlePayment(e)}
                  payment_type={payment_type}
                  savePaymentOption={this.testSavePaymentOption}
                ></CardsTab>
              </Collapse>

              <NavItem>
                <NavLink
                  href="#"
                  onClick={() => {
                    this.toggle("2")
                    this.handlePayment("bkash")
                  }}
                  className={classnames({
                    active: this.state.activeTab === "2"
                  })}
                >
                  Mobile Banking
                </NavLink>
              </NavItem>
              <Collapse
                isOpen={
                  window.innerWidth <= 990 && activeTab === "2" ? true : false
                }
              >
                <MobileBankingTab
                  paymentMode={e => this.handlePayment(e)}
                  payment_type={payment_type}
                  savePaymentOption={this.testSavePaymentOption}
                ></MobileBankingTab>
              </Collapse>
              <NavItem>
                <NavLink
                  href="#"
                  onClick={() => {
                    this.toggle("3")
                    this.handlePayment("wallet")
                  }}
                  className={classnames({
                    active: this.state.activeTab === "3"
                  })}
                >
                  Wallet
                </NavLink>
              </NavItem>
              <Collapse
                isOpen={
                  window.innerWidth <= 990 && activeTab === "3" ? true : false
                }
              >
                <WalletTab
                  paymentMode={e => this.handlePayment(e)}
                  payment_type={payment_type}
                  savePaymentOption={this.testSavePaymentOption}
                ></WalletTab>
              </Collapse>
              <NavItem>
                <NavLink
                  href="#"
                  onClick={() => {
                    this.toggle("4")
                    this.handlePayment("online_banking")
                  }}
                  className={classnames({
                    active: this.state.activeTab === "4"
                  })}
                >
                  Online Banking
                </NavLink>
              </NavItem>
              <Collapse
                isOpen={
                  window.innerWidth <= 990 && activeTab === "4" ? true : false
                }
              >
                <OnlineBanking
                  paymentMode={e => this.handlePayment(e)}
                  payment_type={payment_type}
                  savePaymentOption={this.testSavePaymentOption}
                ></OnlineBanking>
              </Collapse>
            </Nav>
          </Col>
          <Col xs="12" sm="12" lg="8">
            <RightContainer>
              <TabContent activeTab={window.innerWidth > 990 && activeTab}>
                <TabPane tabId="1">
                  <CardsTab
                    paymentMode={e => this.handlePayment(e)}
                    payment_type={payment_type}
                    savePaymentOption={this.testSavePaymentOption}
                  ></CardsTab>
                </TabPane>
                <TabPane tabId="2">
                  <MobileBankingTab
                    paymentMode={e => this.handlePayment(e)}
                    payment_type={payment_type}
                    savePaymentOption={this.testSavePaymentOption}
                  ></MobileBankingTab>
                </TabPane>
                <TabPane tabId="3">
                  <WalletTab
                    paymentMode={e => this.handlePayment(e)}
                    payment_type={payment_type}
                    savePaymentOption={this.testSavePaymentOption}
                  ></WalletTab>
                </TabPane>
                <TabPane tabId="4">
                  <OnlineBanking
                    paymentMode={e => this.handlePayment(e)}
                    payment_type={payment_type}
                    savePaymentOption={this.testSavePaymentOption}
                  ></OnlineBanking>
                </TabPane>
              </TabContent>
            </RightContainer>
          </Col>
        </Row>
      </PaymentContainer>
    )
  }
}

export default PaymentUI

export const CardsTab = ({ paymentMode, payment_type, savePaymentOption }) => {
  return (
    <div>
      {/* Credits/debit card */}
      <List>
        <ListItem onClick={() => paymentMode("visa")}>
          <ListImage className={payment_type == "visa" ? "active" : null}>
            <img style={{ width: "100%" }} src="images/visa.jpg" alt="" />
            <img src="images/check-blue.png" alt="" className="check-blue" />
          </ListImage>
          <p>Visa</p>
        </ListItem>
        <ListItem onClick={() => paymentMode("mastercard")}>
          <ListImage className={payment_type == "mastercard" ? "active" : null}>
            <img src="images/mastercard.jpg" alt="" />
            <img src="images/check-blue.png" alt="" className="check-blue" />
          </ListImage>
          <p>Mastercard</p>
        </ListItem>
        <ListItem onClick={() => paymentMode("amex")}>
          <ListImage className={payment_type == "amex" ? "active" : null}>
            <img src="images/american-express.jpg" alt="" />
            <img src="images/check-blue.png" alt="" className="check-blue" />
          </ListImage>
          <p>American Express</p>
        </ListItem>
        <ListItem onClick={() => paymentMode("other_cards")}>
          <ListImage
            className={payment_type == "other_cards" ? "active" : null}
          >
            <img src="images/other.png" alt="" />
            <img src="images/check-blue.png" alt="" className="check-blue" />
          </ListImage>
          <p>Other</p>
        </ListItem>
      </List>
      <BankText>
        We will redirect you to bank website to authorize the payment.
      </BankText>
      <CardBtn
        onClick={() => {
          savePaymentOption(payment_type)
        }}
      >
        Place Order
      </CardBtn>
    </div>
  )
}

export const MobileBankingTab = ({
  paymentMode,
  payment_type,
  savePaymentOption
}) => {
  // paymentMode("bkash")
  return (
    <div>
      <List>
        <ListItem onClick={() => paymentMode("bkash")}>
          <ListImage className={payment_type == "bkash" ? "active" : null}>
            <img src="images/bkash.jpg" alt="" />
            <img src="images/check-blue.png" alt="" className="check-blue" />
          </ListImage>
          <p>Bkash</p>
        </ListItem>
        <ListItem onClick={() => paymentMode("rocket")}>
          <ListImage className={payment_type == "rocket" ? "active" : null}>
            <img src="images/rocket.jpg" alt="" />
            <img src="images/check-blue.png" alt="" className="check-blue" />
          </ListImage>
          <p>Rocket</p>
        </ListItem>
        <ListItem onClick={() => paymentMode("other_mobile_banking")}>
          <ListImage
            className={payment_type == "other_mobile_banking" ? "active" : null}
          >
            <img src="images/online-bank.jpg" alt="" />
            <img src="images/check-blue.png" alt="" className="check-blue" />
          </ListImage>
          <p>Other</p>
        </ListItem>
      </List>
      <BankText>
        We will redirect you to bank website to authorize the payment.
      </BankText>
      <CardBtn onClick={() => savePaymentOption(payment_type)}>
        Place Order
      </CardBtn>
    </div>
  )
}

export const WalletTab = ({ paymentMode, payment_type, savePaymentOption }) => {
  // paymentMode("wallet")
  return (
    <div>
      <List>
        <ListItem onClick={() => paymentMode("wallet")}>
          <ListImage className={payment_type == "wallet" ? "active" : null}>
            <img src="images/wallet.jpg" alt="" />
            <img src="images/check-blue.png" alt="" className="check-blue" />
          </ListImage>
          <p>Wallet</p>
        </ListItem>
      </List>
      <BankText>
        We will redirect you to bank website to authorize the payment.
      </BankText>
      <CardBtn onClick={() => savePaymentOption(payment_type)}>
        Place Order
      </CardBtn>
    </div>
  )
}
export const OnlineBanking = ({
  paymentMode,
  payment_type,
  savePaymentOption
}) => {
  // paymentMode("online_banking")
  return (
    <div>
      <List>
        <ListItem onClick={() => paymentMode("online_banking")}>
          <ListImage
            className={payment_type == "online_banking" ? "active" : null}
          >
            <img src="images/online-bank.jpg" alt="" />
            <img src="images/check-blue.png" alt="" className="check-blue" />
          </ListImage>
          <p>Online Banking</p>
        </ListItem>
      </List>
      <BankText>
        We will redirect you to bank website to authorize the payment.
      </BankText>
      <CardBtn onClick={() => savePaymentOption(payment_type)}>
        Place Order
      </CardBtn>
    </div>
  )
}
