import React, { Component } from "react"
import { NavItem, TabContent, TabPane } from "reactstrap"
import Oneway from "../OnewayTab"
import RoundTrip from "../RoundTripTab"
import MultiCitySearchBar from "../MultiCityTab"
import { StyledNav, NavLinks, Maincontainer } from "../elements.js"
import Header from "scenes/Header/Header"
import classnames from "classnames"
import { tripType } from "utilities"
import { compose, pure } from "recompose"
import { withRouter } from "react-router-dom"
class TopSearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1"
    }
  }
  componentDidMount() {
    let data = tripType(this.props.location.search)
    if (data === "oneway") {
      this.setState({ activeTab: "1" })
    } else if (data === "roundtrip") {
      this.setState({ activeTab: "2" })
    } else if (data === "multicity") {
      this.setState({ activeTab: "3" })
    } else {
      this.setState({ activeTab: "1" })
    }
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }
  render() {
    const { activeTab } = this.state

    return (
      <Maincontainer id="searchBar">
        <Header />
        <StyledNav tabs>
          <NavItem>
            <NavLinks
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                this.toggle("1")
              }}
            >
              Oneway
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                this.toggle("2")
              }}
            >
              Round Trip
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                this.toggle("3")
              }}
            >
              Multicity
            </NavLinks>
          </NavItem>
        </StyledNav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Oneway query={this.props.location.search} />
          </TabPane>
          <TabPane tabId="2">
            <RoundTrip query={this.props.location.search} />
          </TabPane>
          <TabPane tabId="3">
            <MultiCitySearchBar query={this.props.location.search} />
          </TabPane>
        </TabContent>
      </Maincontainer>
    )
  }
}

export default compose(
  withRouter,
  pure
)(TopSearchBar)
