import React, { Component, Fragment } from "react"
import FromToCities from "base_components/FromToCities"
import DatePicker from "base_components/DatePicker"
import InputPassengersNumber from "base_components/InputPassengersNumber"
import CabinClass from "base_components/CabinClass"
import {
  FlexRow,
  DepartureDate,
  PassengersNumber,
  CabinClassFull
} from "../elements"
import { multiChildState } from "utilities"
import { FaTimes } from "react-icons/fa"

class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = multiChildState(props)
  }

  handleDateSelect = date => {
    this.setState({
      departure_date: date
    })
    this.props.getValuesFromChild(this.props.added, date, "departure_date")
  }
  getCitiesValue = (value, type) => {
    if (typeof value[type] === "object") {
      this.setState({ origin: value.origin, destination: value.destination })
      this.props.getValuesFromChild(this.props.added, value, "cities", type)
    } else if (!value[type]) {
      value[type] = ""
      this.props.getValuesFromChild(this.props.added, value, "cities", type)
    }
  }

  render() {
    let {
      added,
      deleteMultiCities,
      data,
      originRef,
      destinationRef,
      datesRef
    } = this.props
    let { origin, destination } = data
    return (
      <Fragment>
        <FlexRow>
          <FromToCities
            originRef={originRef}
            destinationRef={destinationRef}
            origin={origin}
            destination={destination}
            getCitiesValue={this.getCitiesValue}
          />
        </FlexRow>
        <DepartureDate ref={datesRef}>
          <DatePicker
            // dateRef={datesRef}
            date={this.props.data.departure_date}
            handleChange={this.handleDateSelect}
            minDate={
              added === 0
                ? new Date()
                : this.props.data.minDate !== ""
                ? new Date(this.props.data.minDate)
                : new Date()
            }
          />
        </DepartureDate>
        <PassengersNumber className="dummy">
          <InputPassengersNumber
            inputName="Passengers"
            initialValue={this.props.passengers}
            onChange={this.props.getPassengers}
          />
        </PassengersNumber>
        <CabinClassFull>
          <CabinClass
            flex
            height="60px"
            fontSize="16px"
            defaultClass={this.props.cabin_class}
            handleSelect={this.props.handleSelect}
            value={this.props.cabin_class}
          />
        </CabinClassFull>
        {added > 1 ? (
          <span style={style}>
            <FaTimes onClick={() => deleteMultiCities(data)} />
          </span>
        ) : null}
      </Fragment>
    )
  }
}

export default SearchBar
const style = {
  // position: "absolute",
  right: "9px",
  top: "22px",
  color: "#fff",
  cursor: "pointer",
  background: "#f33f40",
  borderRadius: "50%",
  height: "23px",
  width: "23px",
  marginLeft: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}
