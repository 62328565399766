import React from "react"
import BpkDialog from "bpk-component-dialog"
import styled from "styled-components"
import { IoMdClose } from "react-icons/io"

const Alert = ({ visible, close, message, title }) => {
  return (
    <div id="dialog-container">
      <BpkDialog
        closeLabel="Close dialog"
        linkColor="yellow"
        id="my-dialog"
        className="my-classname"
        isOpen={visible}
        dismissible={false}
        onClose={close}
        getApplicationElement={() => document.getElementById("pagewrap")}
        renderTarget={() => document.getElementById("dialog-container")}
      >
        {title && 
        <DialogBoxHeader>
          <DialogTitle>{title}</DialogTitle>
          <CloseButton onClick={close}>
            <IoMdClose color="black" size={20} />
          </CloseButton>
        </DialogBoxHeader>
        } 
        <DialogBox>
          <div>{message}</div>
          {!title && 
          <CloseButton onClick={close}>
            <IoMdClose color="black" size={20} />
          </CloseButton>
          }
        </DialogBox>
      </BpkDialog>
    </div>
  )
}

export default Alert

export const DialogBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const CloseButton = styled.button`
  outline: none;
  text-transform: none;
  background-color: #001fff29;
  border-width: inherit;
  border-radius: 4;
  &:focus {
    outline: none;
  }
`
export const DialogTitle = styled.div`
  width: 90%;
  display: flex;
  justify-content: center
`
export const DialogBoxHeader = styled.div`
  width: 100%;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
`
