import * as API from "services/API"
import { actions as searchFlightActions } from "./ducks"
// import { tripType } from "utilities"z
export const resetFlightState = () => {
  return async dispatch => {
    dispatch(searchFlightActions.resetFlightState())
  }
}

export const searchflightDepartureDate = () => {
  return async dispatch => {
    dispatch(searchFlightActions.searchFlightsProgressSearchRequest())
    try {
      const { data, success } = await API.progressListSearch()
      return dispatch(
        searchFlightActions.searchFlightsProgressSearchSuccess({
          data,
          success
        })
      )
    } catch (error) {
      return dispatch(searchFlightActions.searchFlightsProgressSearchError())
    }
  }
}

export const progressListSearch = () => {
  return async dispatch => {
    dispatch(searchFlightActions.searchFlightsProgressSearchRequest())
    try {
      const { data, success } = await API.progressListSearch()
      return dispatch(
        searchFlightActions.searchFlightsProgressSearchSuccess({
          data,
          success
        })
      )
    } catch (error) {
      return dispatch(searchFlightActions.searchFlightsProgressSearchError())
    }
  }
}

export const searchFlightsProgress = (query, page) => {
  return async dispatch => {
    let minDate
    if (query.trip_type === "oneway") {
      minDate = ""
    } else if (query.trip_type === "roundtrip") {
      minDate = query.return_date
    } else {
      minDate = query.flights[query.flights.length - 1].departure_date
    }
    dispatch(
      searchFlightActions.searchFlightsProgressRequest({
        depDateForFilters: query.departure_date
          ? query.departure_date
          : query.flights[0].departure_date,
        minDateForFilters: minDate
      })
    )
    try {
      const {
        data,
        split_view,
        success,
        pagination
      } = await API.progressSearchFlights(query, page)

      return dispatch(
        searchFlightActions.searchFlightsProgressSuccess({
          data,
          trip_type: query.trip_type,
          split_view,
          success,
          pagination
        })
      )
    } catch (error) {
      return dispatch(searchFlightActions.searchFlightsProgressError())
    }
  }
}
// @new algo of flight api call

export const saveOuterPromiseFlightsRequest = (page, query) => {
  return async dispatch => {
    let minDate
    if (query.trip_type === "oneway") {
      minDate = ""
    } else if (query.trip_type === "roundtrip") {
      minDate = query.return_date
    } else {
      minDate = query.flights[query.flights.length - 1].departure_date
    }
    dispatch(
      searchFlightActions.searchFlightsProgressRequest({
        depDateForFilters: query.departure_date
          ? query.departure_date
          : query.flights[0].departure_date,
        minDateForFilters: minDate
      })
    )
  }
}

export const saveOuterPromiseFlightsSuccess = ({
  data,
  query,
  split_view,
  success,
  pagination
}) => {
  return async dispatch => {
    dispatch(
      searchFlightActions.searchFlightsProgressSuccess({
        data,
        trip_type: query.trip_type,
        split_view,
        success,
        pagination
      })
    )
  }
}

export const saveOuterPromiseFlightsError = ({
  data,
  query,
  split_view,
  success,
  pagination
}) => {
  return async dispatch => {
    return dispatch(searchFlightActions.searchFlightsProgressError())
  }
}
// @new algo
export const searchFlightsProgressPaginationSuccess = ({
  data,
  query,
  split_view,
  success,
  pagination
}) => {
  return async dispatch => {
    dispatch(
      searchFlightActions.searchFlightsProgressPaginationSuccess({
        data,
        trip_type: query.trip_type,
        split_view,
        success,
        pagination
      })
    )
  }
}
export const searchFlightsProgressPaginationRequest = (page, query) => {
  return async dispatch => {
    let minDate
    if (query.trip_type === "oneway") {
      minDate = ""
    } else if (query.trip_type === "roundtrip") {
      minDate = query.return_date
    } else {
      minDate = query.flights[query.flights.length - 1].departure_date
    }
    dispatch(
      searchFlightActions.searchFlightsProgressPaginationRequest({
        depDateForFilters: query.departure_date
          ? query.departure_date
          : query.flights[0].departure_date,
        minDateForFilters: minDate
      })
    )
  }
}
// export const searchFlightsProgressPagination = (query, page) => {
//   return async dispatch => {
//     let minDate
//     if (query.trip_type === "oneway") {
//       minDate = ""
//     } else if (query.trip_type === "roundtrip") {
//       minDate = query.return_date
//     } else {
//       minDate = query.flights[query.flights.length - 1].departure_date
//     }
//     dispatch(
//       searchFlightActions.searchFlightsProgressPaginationRequest({
//         depDateForFilters: query.departure_date
//           ? query.departure_date
//           : query.flights[0].departure_date,
//         minDateForFilters: minDate
//       })
//     )
//     try {
//       const {
//         data,
//         split_view,
//         success,
//         pagination
//       } = await API.progressSearchFlights(query, page)
//       return dispatch(
//         searchFlightActions.searchFlightsProgressPaginationSuccess({
//           data,
//           trip_type: query.trip_type,
//           split_view,
//           success,
//           pagination
//         })
//       )
//     } catch (error) {
//       return dispatch(
//         searchFlightActions.searchFlightsProgressPaginationError()
//       )
//     }
//   }
// }

export const searchFlights = query => {
  return async dispatch => {
    let minDate
    if (query.trip_type === "oneway") {
      minDate = ""
    } else if (query.trip_type === "roundtrip") {
      minDate = query.return_date
    } else {
      minDate = query.flights[query.flights.length - 1].departure_date
    }
    dispatch(
      searchFlightActions.searchFlightsRequest({
        depDateForFilters: query.departure_date
          ? query.departure_date
          : query.flights[0].departure_date,
        minDateForFilters: minDate
      })
    )
    try {
      const { data, split_view } = await API.searchFlights(query)
      return dispatch(
        searchFlightActions.searchFlightsSuccess({
          data,
          trip_type: query.trip_type,
          split_view
        })
      )
    } catch (error) {
      return dispatch(searchFlightActions.searchFlightsError())
    }
  }
}

export const reserveFlight = query => {
  return async dispatch => {
    dispatch(searchFlightActions.reserveFlightRequest())
    try {
      // let identifier = "2718aa5c9bfd0ede89ae1bcf430fcb84"
      // let success = true
      const { identifier, success } = await API.reserveFlight(query)
      return dispatch(
        searchFlightActions.reserveFlightSuccess({ identifier, success })
      )
    } catch (error) {
      return dispatch(searchFlightActions.reserveFlightError())
    }
  }
}

export const checkBookingStatus = ({ identifier, api_token }) => {
  return async dispatch => {
    dispatch(searchFlightActions.checkBookingStatusRequest())

    try {
      // let pnr = "AEFE0"
      // let status = "success"
      // let price_mismatch
      // let price_mismatch_data
      const {
        pnr,
        status,
        price_mismatch,
        price_mismatch_data,
        redirect_pnr
      } = await API.checkBookingStatus({
        identifier,
        api_token
      })

      if (status === "success" && typeof redirect_pnr === "string") {
        window.location.href = `https://tripovy.com/bookings/${redirect_pnr}`
      }
      return dispatch(
        searchFlightActions.checkBookingStatusSuccess({
          pnr,
          status,
          price_mismatch,
          price_mismatch_data
        })
      )
    } catch (error) {
      return dispatch(searchFlightActions.checkBookingStatusError())
    }
  }
}

export const getSingleReservation = ({ pnr, api_token }) => {
  return async dispatch => {
    dispatch(searchFlightActions.getSingleReservationRequest())
    try {
      const { data } = await API.getSingleReservation({
        pnr,
        api_token
      })
      return dispatch(searchFlightActions.getSingleReservationSuccess({ data }))
    } catch (error) {
      return dispatch(searchFlightActions.getSingleReservationError())
    }
  }
}

export const searchStops = ({ stops, type }) => {
  return async dispatch => {
    dispatch(searchFlightActions.searchStops({ stops, type }))
  }
}

export const priceRange = value => {
  return async dispatch => {
    dispatch(searchFlightActions.priceRange(value))
  }
}

export const getMinMaxPriceRange = () => {
  return async dispatch => {
    dispatch(searchFlightActions.minMaxPriceRange())
  }
}

export const journeyDurationsRange = () => {
  return async dispatch => {
    dispatch(searchFlightActions.journeyDurationsRange())
  }
}
export const journeyDuration = value => {
  return async dispatch => {
    dispatch(searchFlightActions.journeyDuration(value))
  }
}

export const getAirlinesArray = () => {
  return async dispatch => {
    dispatch(searchFlightActions.getAirlinesArray())
  }
}

export const applyFilters = ({
  stops,
  journeyValue,
  airlines,
  priceValue,
  outBoundValues,
  returnSliderValues,
  onlyAirline
}) => {
  // debugger
  return async dispatch => {
    dispatch(
      searchFlightActions.applyFiltersRequest({
        stops,
        journeyValue,
        airlines,
        priceValue,
        outBoundValues,
        returnSliderValues,
        onlyAirline
      })
    )
  }
}

export const resetFilter = () => {
  return async dispatch => {
    dispatch(searchFlightActions.resetFilters())
  }
}

export const cheapestFlights = () => {
  // console.log("in cheapestFlights")
  return async dispatch => {
    dispatch(searchFlightActions.cheapestFlights())
  }
}

export const earliestFlights = () => {
  // console.log("in earliestFlights")
  return async dispatch => {
    dispatch(searchFlightActions.earliestFlights())
  }
}

export const quickestFlights = () => {
  // console.log("in questestFlights")
  return async dispatch => {
    dispatch(searchFlightActions.quickestFlights())
  }
}

export const journeyTime = value => {
  return async dispatch => {
    dispatch(searchFlightActions.outBoundJourneyTime(value))
  }
}

export const returnJourneyTime = value => {
  return async dispatch => {
    dispatch(searchFlightActions.returnJourneyTime(value))
  }
}

export const selectedFlight = ({ selectedFlight, queryDetails }) => {
  return async dispatch => {
    dispatch(
      searchFlightActions.selectedFlight({ selectedFlight, queryDetails })
    )
  }
}

export const modifySelectedFlightData = ({
  selectedPrice,
  price_mismatch_data
}) => {
  return async dispatch => {
    dispatch(
      searchFlightActions.modifySelectedFlightData({
        selectedPrice,
        price_mismatch_data
      })
    )
  }
}

export const pageRefresh = () => {
  return async dispatch => {
    dispatch(searchFlightActions.pageRefresh())
  }
}

export const modifyFareBreakDown = data => {
  return async dispatch => {
    dispatch(searchFlightActions.modifyFareBreakDown(data))
  }
}

export const reserveFlightStatusFailed = () => {
  return async dispatch => {
    dispatch(searchFlightActions.reserveFlightStatusFailed())
  }
}

export const checkBookingStatusFailed = () => {
  return async dispatch => {
    dispatch(searchFlightActions.checkBookingStatusFailed())
  }
}

export const getSingleReservationFailed = () => {
  return async dispatch => {
    dispatch(searchFlightActions.getSingleReservationFailed())
  }
}

export const totalPriceFareBreakDown = query => {
  return async dispatch => {
    dispatch(searchFlightActions.totalPriceFareBreakDownRequest())
    try {
      const data = await API.totalPriceFareBreakDown(query)
      return dispatch(searchFlightActions.totalPriceFareBreakDownSuccess(data))
    } catch (error) {
      return dispatch(searchFlightActions.totalPriceFareBreakDownError())
    }
  }
}

export const toggleOverlay = () => {
  return async dispatch => {
    dispatch(searchFlightActions.toggleOverlay())
  }
}

export const returningFilters = ({ type, query }) => {
  return async dispatch => {
    dispatch(searchFlightActions.returningFilters({ type, query }))
  }
}

export const selectedDepartingFlight = ({ departing_flight }) => {
  return async dispatch => {
    dispatch(searchFlightActions.selectedDepartingFlight({ departing_flight }))
  }
}
