import React from "react"
import { SFormGroup, ReactSelectS } from "./elements"
import { cabinData,cabinDataSelection } from "Constants"

const CabinClass = ({
  value,
  defaultClass,
  handleSelect,
  placeHolder,
  ...props
}) => {
  return (
    <SFormGroup>
      <ReactSelectS
        {...props}
        menuPlacement="auto"
        cabin
        className="cabin"
        value={value}
        onChange={value => handleSelect(value)}
        options={cabinDataSelection}
        placeholder={placeHolder}
        defaultValue={
          defaultClass ? defaultClass : { value: "Y", label: "Economy" }
        }
        // controlShouldRenderValue={false}
      />
    </SFormGroup>
  )
}

export default CabinClass
