import React, { Component } from "react"
import {
  Filter,
  Arrowleft
} from "./elements"
import BpkDatepicker from "bpk-component-datepicker"
import format from "date-fns/format"
import moment from "moment"
import { compose, pure } from "recompose"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import { withRouter } from "react-router-dom"
import { searchFlights } from "../thunks"
import {
  // customQuery,
  tripType
} from "utilities"
import {
  // FaSortAmountUp,
  FaAngleLeft,
  FaAngleRight
} from "react-icons/fa"
import { selectors } from "../ducks"
import { getStateFromProps, createQuery } from "utilities"
// import Immutable from "seamless-immutable"
const formatDate = date => format(date, "DD/MM/YYYY")
const formatDateFull = date => format(date, "dddd, Do MMMM YYYY")
const formatMonth = date => format(date, "MMMM YYYY")

const daysOfWeek = [
  {
    name: "Sunday",
    nameAbbr: "Sun",
    index: 0,
    isWeekend: true
  },
  {
    name: "Monday",
    nameAbbr: "Mon",
    index: 1,
    isWeekend: false
  },
  {
    name: "Tuesday",
    nameAbbr: "Tues",
    index: 2,
    isWeekend: false
  },
  {
    name: "wednesday",
    nameAbbr: "Wed",
    index: 3,
    isWeekend: false
  },
  {
    name: "Thursday",
    nameAbbr: "Thurs",
    index: 4,
    isWeekend: false
  },
  {
    name: "Friday",
    nameAbbr: "Fri",
    index: 5,
    isWeekend: false
  },
  {
    name: "Saturday",
    nameAbbr: "Sat",
    index: 6,
    isWeekend: true
  }
  // ...
]
class ReturnDate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedDate: props.minDateForFilters,
    }
  }

  componentDidMount = () => {
    setTimeout(() => {
      let {
        state: { selectedDate },
        props: { minDateForFilters },
      } = this
      this.setState(
        { 
          selectedDate: minDateForFilters,
        }
      )
    }, 500)
  }

  handleDateSelect = date => {
    let { minDateForFilters } = this.props
    this.setState({ selectedDate: date })
    this.props.sendDate(date)
  }



  nextDate = e => {
    if (!this.props.isSearchingFlights) {
        let { minDateForFilters } = this.props
        let new_date = moment(this.state.selectedDate, "DD-MM-YYYY").add(1, "days")
        this.setState({ selectedDate: new_date})
        const temp = new Date(new_date);
        this.props.sendDate(new_date)
        this.props.changeMaxDate(moment(new_date));
        const params = this.props.location.search.split('&');
        let day = temp.getDate();
        let month = temp.getMonth()+1 < 10 ?  temp.getMonth()+1 : temp.getMonth()+1;
        month = month < 10 ? '0'+month : month
        const departure_date = params[1].split('=')[1];
        const return_date = `${month}/${day}/${this.state.selectedDate.getFullYear()}`;
        const origin = params[3].split('=')[1];
        const destination = params[4].split('=')[1];
        const cabin_class = params[5].split('=')[1];
        const adults = params[6].split('=')[1];
        const children = params[7].split('=')[1];
        const infants = params[8].split('=')[1];
        const customDDate = params[9].split('=')[1];
        const customRDate = `${month}/${day}/${this.state.selectedDate.getFullYear()}`;
        let pathname = this.props.location.pathname === "/search" ? "/search2" : "/search";
        this.props.history.push({
          pathname,
          search: `?trip_type=roundtrip&departure_date=${departure_date}&return_date=${return_date}&origin=${origin}&destination=${destination}&cabin_class=${cabin_class}&adults=${adults}&children=${children}&infants=${infants}&customDDate=${customDDate}&customRDate=${customRDate}`
        })
    }
    }

  previousDate = () => {
    if (!this.props.isSearchingFlights) {
      const checkDate = `${this.props.checkMinDate.month}/${this.props.checkMinDate.day}/${this.props.checkMinDate.year}`;
      let { depDateForFilters, returnDateForFilters } = this.props
      let new_date = moment(this.state.selectedDate).subtract(1, "days")
      let date = moment(new_date).format("M/D/YYYY")
      let date2 = new Date(date)
      let date4 = new Date(checkDate);
      let diffDays = Math.floor(
        (Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) -
          Date.UTC(date4.getFullYear(), date4.getMonth(), date4.getDate())) /
          (1000 * 60 * 60 * 24)
      )
      const diffTime = Math.abs(date2.getTime() - date4.getTime())
      if (diffDays >= 0) {
        this.setState({ selectedDate: new_date })
        this.props.sendDate(new_date)
      }
      const temp = new Date(new_date);
      this.props.sendDate(new_date)
      this.props.changeMaxDate(moment(new_date));
      const params = this.props.location.search.split('&');
      let day = temp.getDate();
      let month = temp.getMonth()+1 < 10 ?  temp.getMonth()+1 : temp.getMonth()+1;
      month = month < 10 ? '0'+month : month
      const departure_date = params[1].split('=')[1];
      const return_date = `${month}/${day}/${temp.getFullYear()}`;
      const origin = params[3].split('=')[1];
      const destination = params[4].split('=')[1];
      const cabin_class = params[5].split('=')[1];
      const adults = params[6].split('=')[1];
      const children = params[7].split('=')[1];
      const infants = params[8].split('=')[1];
      const customDDate = params[9].split('=')[1];
      const customRDate = `${month}/${day}/${temp.getFullYear()}`;
      let pathname = this.props.location.pathname === "/search" ? "/search2" : "/search";
      this.props.history.push({
        pathname,
        search: `?trip_type=roundtrip&departure_date=${departure_date}&return_date=${return_date}&origin=${origin}&destination=${destination}&cabin_class=${cabin_class}&adults=${adults}&children=${children}&infants=${infants}&customDDate=${customDDate}&customRDate=${customRDate}`
      })
    }
  }


  render() {
    const {
      isSearchingFlights,
      depDateForFilters,
      minDateForFilters,
    } = this.props
    let trip = tripType(this.props.location.search)

    return (
      <Filter>
        <div style={{ display: "flex", width: "100%" }}>
          <Arrowleft onClick={this.previousDate} disabled={isSearchingFlights}>
            <FaAngleLeft />
          </Arrowleft>
          <BpkDatepicker
            id="datepicker"
            daysOfWeek={daysOfWeek}
            weekStartsOn={1}
            changeMonthLabel="Change month"
            closeButtonText="Close"
            title="Departure date"
            getApplicationElement={() => document.getElementById("pagewrap")}
            formatDate={formatDate}
            formatMonth={formatMonth}
            formatDateFull={formatDateFull}
            onDateSelect={this.handleDateSelect}
            date={this.state.selectedDate}
            inputProps={{
              disabled: isSearchingFlights
            }}
            // minDate={minDateForFilters}
            maxDate={minDateForFilters}
          />
          <Arrowleft onClick={this.nextDate} disabled={isSearchingFlights}>
            <FaAngleRight />
          </Arrowleft>
        </div>
      </Filter>
    )
  }
}

const mapState = () =>
  createStructuredSelector({
    isSearchingFlights: selectors.selectIsSearchingFlights(),
    depDateForFilters:  selectors.selectDepDateForFilters(),
    minDateForFilters: selectors.selectMinDateForFilters()
  })

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      searchFlights
    },
    dispatch
  )

export default compose(
  withRouter,
  component =>
    connect(
      mapState,
      mapDispatch
    )(component),
  pure
)(ReturnDate)
