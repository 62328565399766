import React, { Component } from "react"
import { Box } from "base_components/Box"
import styled from "styled-components"
import { Row, Col, Label, Input, Progress as ProgressBar } from "reactstrap"
import { ContentContainer } from "../elements"
import InfoForm from "../PassengersInfoForm"
import DetailCard from "base_components/DetailCard"
import TotalCart from "../TotalCart"
import { StyleFormGroup } from "base_components/InfoDetailForm/elements"
import { compose, pure } from "recompose"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import { withRouter } from "react-router-dom"
import {
  reserveFlight,
  checkBookingStatus,
  getSingleReservation,
  checkBookingStatusFailed,
  getSingleReservationFailed,
  reserveFlightStatusFailed,
  modifySelectedFlightData,
  // pageRefresh,
  toggleOverlay
} from "../thunks"
import { types, selectors } from "../ducks"
import {
  headerDetails,
  getCityName,
  priceItinerary,
  cabinClassArrayMulti,
  getCabinFromQueryDetails
} from "filterFunctions"
import { reserveQuery } from "utilities"
import features from "features"
import * as API from "services/API"
import Alert from "base_components/BannerAlert"
import TopSearchBar from "../TopSearchBar"
import Progress from "base_components/ProgressBar"
import LoadingOverlay from "react-loading-overlay"
import modal from "hoc/modal"
import NewPricesModal from "./NewPricesModal"
import SplitViewCart from "../TotalCart/SplitViewCart"
import PaymentUI from "../PaymentUI"
// import PaymentOptions from "base_components/PaymentOptions"
// let price_mismatch_data = {
//   mismatch_type: "both",
//   prices: [
//     { cabin_class: "economy", price: 14420, return_price: 13028, sum: 27448 },
//     { cabin_class: "economy", price: 14430, return_price: 13028, sum: 27450 }
//   ]
// }
class PassengerDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: "",
      option: "",
      message: "",
      alert: false,
      percentVal: 0,
      active: true,
      newPrices: null,
      price_mismatch: false,
      update: true,
      // isOpenOption: false,
      selectedMethod: "",
      profiles: [],
      totalPriceApiAgain: false
    }
    this.formikRef = {}
    this.currentRef = null
    this.paymentRef = null
    // this.currentRef = React.createRef()
    // this.formikRef[props.tab] = React.createRef()
  }

  // scrollToTop = () => {
  //   scroll.scrollToTop()
  // }
  scrollMe = () => {
    if (this.currentRef) {
      // window.scrollTo(0, this.currentRef.offsetTop)
      this.currentRef.scrollIntoView({
        block: "start",
        behavior: "auto",
        alignToTop: true,
        inline: "center"
      })
    }
    // debugger
  }
  scrollToPayment = () => {
    if (this.paymentRef) {
      // window.scrollTo(0, this.currentRef.offsetTop)
      this.paymentRef.scrollIntoView({
        block: "start",
        behavior: "auto",
        alignToTop: true,
        inline: "center"
      })
    }
  }

  componentDidMount() {
    this.props.toggleOverlay()
    setTimeout(() => {
      this.currentRef.scrollIntoView({
        block: "start",
        behavior: "auto",
        alignToTop: true,
        inline: "center"
      })
    }, 50)
  }

  showBannerAlert = message => {
    this.setState(
      {
        alert: true,
        message: message ? message : "Booking failed. Try again!"
      },
      () => {
        setTimeout(() => {
          this.setState({ alert: false })
        }, 3000)
      }
    )
  }
  handleContinue = () => {
    this.reserveFlight(this.state.profiles)
  }

  PaymentOptionsModalOpen = profiles => {
    let { apiToken } = this.props
    if (!apiToken) {
      this.props.showLoginModal()
    } else if (this.state.selectedMethod === "") {
      this.scrollToPayment()
    } else if (apiToken && this.state.selectedMethod) {
      if (profiles) {
        this.reserveFlight(profiles)
      } else {
        for (var key in this.formikRef) {
          if (this.formikRef.hasOwnProperty(key)) {
            this.formikRef[key].current.submitForm()
            this.submiting = this.formikRef[key].current.state.isSubmitting
            if (this.submiting === false) {
              this.scrollToFormErrors()
            }
          }
        }
      }
    }
  }

  handletWalletPayment = () => {
    let {
      walletPayment,
      apiToken
      // flightIssueTicket
    } = this.props
    walletPayment({ api_token: apiToken, pnr: this.pnr }).then(walletAction => {
      if (walletAction.type === "tripovy/WALLET_PAYMENT_SUCCESS") {
        if (walletAction.payload.success === true) {
          this.props.history.push({
            pathname: `/bookings/${this.pnr}`
          })
          // this.setState(
          //   {
          //     alert: true,
          //     message: "Reservation Successfull"
          //   },
          //   () => {
          //     setTimeout(() => {
          //       this.setState({ alert: false })
          //     }, 5000)
          //   }
          // )
          // flightIssueTicket({ api_token: apiToken, pnr: this.pnr }).then(
          //   issueAction => {
          //     // this.pending = "Issued"
          //     if (issueAction.type === "tripovy/FLIGHT_ISSUE_TICKET_SUCCESS") {

          //       // this.props.selectTicket({ ticket: flightData, change: true })
          //       // getBookingsList(apiToken)
          //       // do something here
          //       // getBookingsList(apiToken).then(resp => {
          //       // })
          //       this.props.history.push({
          //         pathname: "/home"
          //       })
          //     }
          //   }
          // )
        } else {
          // this.props.onClose()
          this.setState(
            {
              alert: true,
              message: walletAction.payload.message
            },
            () => {
              setTimeout(() => {
                this.setState({ alert: false })
              }, 5000)
            }
          )
        }
      }
    })
  }

  handleCardPayment = type => {
    let { cardPayment, apiToken } = this.props
    let { selectedMethod } = this.state
    cardPayment({
      api_token: apiToken,
      pnr: this.pnr,
      payment_type: selectedMethod
    }).then(cardAction => {
      if (cardAction.type === "tripovy/CARD_PAYMENT_SUCCESS") {
        if (cardAction.payload.success === true) {
          window.open(cardAction.payload.url, "_self")
        } else {
          this.props.onClose()
          this.setState(
            {
              alert: true,
              message: cardAction.payload.message
            },
            () => {
              setTimeout(() => {
                this.setState({ alert: false })
              }, 5000)
            }
          )
        }
      }
    })
  }

  reserveFlight = profiles => {
    // console.log("profiles =======", profiles)
    if (this.state.profiles.length === 0) {
      this.setState({
        profiles: profiles
      })
    }
    // debugger
    let {
      selectedFlightData,
      selectQueryDetails,
      reserveFlight,
      apiToken,
      checkBookingStatus,
      // identifier,
      // PNR,
      // getSingleReservation,
      // cBStatusValue,
      checkBookingStatusFailed,
      // getSingleReservationFailed,
      reserveFlightStatusFailed,
      selectDeparting_flight,
      splitView
    } = this.props
    // if (!window.asdfgh) {
    //   selectedFlightData.price_itinerary.economy.amount = 2
    //   selectedFlightData.price_itinerary.economy.fare_breakdown.total = 2
    //   selectedFlightData.price_itinerary.economy.fare_breakdown.adult.price = 2
    //   selectedFlightData.price_itinerary.economy.departing = 2
    //   window.asdfgh = 1
    // }
    // selectDeparting_flight.price_itinerary.economy.amount = 2
    // selectDeparting_flight.price_itinerary.economy.fare_breakdown.total = 2
    // selectDeparting_flight.price_itinerary.economy.fare_breakdown.adult.price = 2
    if (apiToken) {
      let percentageInterval = setInterval(() => {
        if (this.state.percentVal < 99) {
          this.setState(
            {
              percentVal: this.state.percentVal + 1
            },
            () => {
              if (this.state.percentVal === 100) {
                clearInterval(percentageInterval)
              }
            }
          )
        }
      }, 600)
      reserveFlight(
        reserveQuery({
          profiles,
          selectedFlightData,
          selectQueryDetails,
          special_message: {
            text: this.state.text,
            option: this.state.option
          },
          apiToken,
          selectDeparting_flight,
          splitView
        })
      ).then(rAction => {
        if (rAction.type === types.RESERVE_FLIGHT_SUCCESS) {
          if (rAction.payload.success === true) {
            checkBookingStatus({
              identifier: rAction.payload.identifier,
              api_token: apiToken
            }).then(cbAction => {
              if (cbAction.type === types.CHECK_BOOKING_STATUS_SUCCESS) {
                this.status = cbAction.payload.status
                this.pnr = cbAction.payload.pnr
                let t = 0
                if (this.status === "pending") {
                  let foo = setInterval(async () => {
                    if (t <= 90000 && this.status === "pending") {
                      let {
                        status,
                        pnr,
                        price_mismatch,
                        price_mismatch_data,
                        redirect_pnr
                      } = await API.checkBookingStatus({
                        identifier: rAction.payload.identifier,
                        api_token: apiToken
                      })
                      if (
                        status === "success" &&
                        typeof redirect_pnr === "string"
                      ) {
                        window.location.href = `https://tripovy.com/bookings/${redirect_pnr}`
                      }
                      this.status = status
                      this.pnr = pnr
                      this.price_mismatch = price_mismatch
                      this.price_mismatch_data = price_mismatch_data
                      t = t + 5000
                    } else if (this.status === "failed") {
                      reserveFlightStatusFailed()
                      clearInterval(foo)
                      clearInterval(percentageInterval)
                      // let {
                      //   price_mismatch,
                      //   price_mismatch_data
                      // } = cbAction.payload
                      // check for price_mismatch here
                      if (this.price_mismatch) {
                        clearInterval(percentageInterval)
                        if (
                          this.price_mismatch === true &&
                          this.price_mismatch_data === null
                        ) {
                          this.showBannerAlert(
                            "Prices may have changed. Please search again"
                          )
                        } else {
                          this.props.onOpen()
                          this.setState({ newPrices: this.price_mismatch_data })
                        }
                      } else {
                        this.showBannerAlert()
                        clearInterval(percentageInterval)
                      }
                    } else if (this.status === "success") {
                                                            clearInterval(foo)
                                                            // clearInterval(percentageInterval)
                                                            this.setState(
                                                              {
                                                                percentVal: 100
                                                              },
                                                              () => {
                                                                clearInterval(
                                                                  percentageInterval
                                                                )
                                                              }
                                                            )

                                                            this.props.toggleOverlay()
                                                            // Do payment here>>>>>>>>>>>>>>>

                                                            // this.props.history.push({
                                                            //   pathname: "/home",
                                                            //   state: { pnr: this.pnr }
                                                            // })
                                                            if (
                                                              this.state
                                                                .selectedMethod ===
                                                              "wallet"
                                                            ) {
                                                              this.handletWalletPayment()
                                                            } else {
                                                              this.handleCardPayment()
                                                            }

                                                            // getSingleReservation({
                                                            //   pnr: this.pnr,
                                                            //   api_token: apiToken
                                                            // }).then(actionGSR => {
                                                            //   clearInterval(percentageInterval)
                                                            //   this.setState(
                                                            //     { alert: true, message: "Booking Successful!" },
                                                            //     () => {
                                                            //       setTimeout(() => {
                                                            //         this.setState({ alert: false })
                                                            //       }, 3000)
                                                            //     }
                                                            //   )
                                                            // })
                                                          }
                  }, 5000)
                } else if (this.status === "failed") {
                  checkBookingStatusFailed()
                  clearInterval(percentageInterval)
                  let { price_mismatch, price_mismatch_data } = cbAction.payload

                  // check for price_mismatch here
                  if (price_mismatch) {
                    clearInterval(percentageInterval)
                    if (
                      price_mismatch === true &&
                      price_mismatch_data === null
                    ) {
                      this.showBannerAlert(
                        "Prices may have changed. Please search again"
                      )
                    } else {
                      this.props.onOpen()
                      this.setState({ newPrices: price_mismatch_data })
                    }
                  } else {
                    this.showBannerAlert()
                    clearInterval(percentageInterval)
                  }
                } else if (this.pnr) {
                  clearInterval(percentageInterval)
                  // Do payment here>>>>>>>>>>>>>>>
                  // this.props.history.push({
                  //   pathname: "/home",
                  //   state: { pnr: this.pnr }
                  // })
                  if (this.state.selectedMethod === "wallet") {
                    this.handletWalletPayment()
                  } else {
                    this.handleCardPayment()
                  }
                  // getSingleReservation({
                  //   pnr: this.pnr,
                  //   api_token: apiToken
                  // }).then(actionGSR => {
                  //   this.setState(
                  //     { alert: true, message: "Booking Successful!" },
                  //     () => {
                  //       setTimeout(() => {
                  //         this.setState({ alert: false })
                  //       }, 3000)
                  //     }
                  //   )
                  // })
                }
              } else {
                this.showBannerAlert()
                clearInterval(percentageInterval)
              }
            })
          } else {
            this.showBannerAlert()
            clearInterval(percentageInterval)
          }
        } else {
          this.showBannerAlert()
          clearInterval(percentageInterval)
        }
      })
    } else {
      this.props.showLoginModal()
    }
  }
  getOptionalServices = (type, value) => {
    this.setState({ [type]: value })
  }
  closeAlert = () => {
    this.setState({ alert: false })
  }

  savePaymentOption = (value, profiles) => {
    this.setState({ selectedMethod: value }, () => {
      this.PaymentOptionsModalOpen()
    })
    // this.setState({ selectedMethod: value })
  }

  // onClosePaymentModal = () => {
  //   if (this.state.selectedMethod == "") {
  //     this.setState({ alert: true, message: "Please select a payment option" })
  //   }
  //   this.setState({ isOpenOption: false })
  // }

  openPricesMOdal = price_mismatch_data => {
    this.setState({ price_mismatch: true })
    this.props.onOpen()
    this.setState({ newPrices: price_mismatch_data })
  }

  toggleUpdate = () => {
    this.setState({ update: false })
  }

  toggleUpdateTest = () => {
    // this.setState({ update: true })
  }

  scrollToFormErrors = () => {
    // setTimeout(() => {
    this.currentRef.scrollIntoView({
      block: "start",
      behavior: "auto",
      alignToTop: true,
      inline: "center"
    })
    // }, 50)
  }

  totalPriceApiAgainToggle = () => {
    this.setState({ totalPriceApiAgain: !this.state.totalPriceApiAgain })
  }
  closeNewPricesModal = () => {
    // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
    this.props.onClose()
    // this.props.history.goBack()
    // setTimeout(() => {
    //   this.props.history.goBack()
    // }, 200)
  }

  render() {
    let {
      selectedFlightData,
      selectQueryDetails,
      airports,
      progressValue,
      showOverlay,
      isOpen,
      onClose,
      onOpen,
      splitView
    } = this.props
    // let { isOpenOption } = this.state
    let { type, depAirport, arrAirport, shortDepDate, ppp } = headerDetails(
      selectedFlightData,
      selectQueryDetails
    )
    let departure = ""
    let arrival = ""
    airports.map(item => {
      if (item.code === depAirport) {
        departure = item.value
      } else if (item.code === arrAirport) {
        arrival = item.value
      }
    })
    let departureCity = getCityName(departure)
    let arrivalCity = getCityName(arrival)
    const { price_itinerary } = selectedFlightData
    const cabinCode = priceItinerary(price_itinerary)
    const cabinArray = cabinClassArrayMulti(cabinCode.cabin_code)
    let { cabinFromSearch } = getCabinFromQueryDetails({ selectQueryDetails })
    let cabinValueForHeader
    if (
      cabinArray[0] === "" ||
      cabinArray[0] === "N/A" ||
      cabinArray[0] === null
    ) {
      cabinValueForHeader = cabinFromSearch
    } else {
      cabinValueForHeader = cabinArray[0]
    }

    return (
      <React.Fragment>
        {/* <SrollToTop /> */}
        <StyledLoader
          active={showOverlay}
          spinner={<ProgressBar animated value={this.state.percentVal} />}
          text={`${this.state.percentVal}%`}
        >
          <TopSearchBar fromPassengerPage={false} />
          <Progress min={0} max={4} progressValue={progressValue} />
          <ContentContainer>
            <Alert
              visible={this.state.alert}
              close={this.closeAlert}
              message={this.state.message}
            />
            {/* <PaymentOptions
              isOpen={isOpenOption}
              onClose={this.onClosePaymentModal}
              // flightData={data}
              changeStatus={this.changeStatus}
              fromPassengerPage={true}
              savePaymentOption={this.savePaymentOption}
              // status={status}
            /> */}
            <Row style={{ paddingTop: "20px" }}>
              <Col md="9">
                <Box
                  heading={`${departureCity.city} to ${arrivalCity.city}, ${shortDepDate}`}
                  icon="flight"
                  flightDetails={`${type}, ${cabinValueForHeader}, ${ppp}`}
                  noBackground
                  noBoxShadow
                  cabinFromSearch={cabinFromSearch}
                >
                  <DetailCard tripType={selectQueryDetails.trip_type} />
                </Box>
                <div ref={node => (this.currentRef = node)} id="passengerForm">
                  <Box
                    heading="Enter Passenger information"
                    icon="info"
                    noBorder
                  >
                    <InfoForm
                      reserveFlight={this.PaymentOptionsModalOpen}
                      myRef={this.formikRef}
                      update={this.state.update}
                      toggleUpdate={this.toggleUpdate}
                      scrollMe={this.scrollMe}
                    />
                  </Box>
                </div>
                <Box heading="Optional Services" noBorder>
                  <OptionalServices
                    getOptionalServices={this.getOptionalServices}
                  />
                </Box>
                <div ref={node => (this.paymentRef = node)} id="paymentOption">
                  <Box heading="Choose Payment Mode" noBorder icon="Shield">
                    <PaymentUI
                      //reserveAgain={this.handleContinue}
                      savePaymentOption={this.savePaymentOption}
                      formikRef={this.formikRef}
                      update={this.state.update}
                      toggleUpdate={this.toggleUpdateTest}
                      scrollToFormErrors={this.scrollToFormErrors}
                    />
                  </Box>
                </div>
                <Box
                  heading="Review policies and Terms & Conditions"
                  icon="Shield"
                  noBorder
                >
                  <FlightDetails />
                </Box>
              </Col>
              <Col md="3">
                {splitView !== undefined && splitView === true ? (
                  <SplitViewCart
                    cabinName={cabinArray[0]}
                    currency={cabinCode.currency}
                    handleContinue={this.PaymentOptionsModalOpen}
                    formikRef={this.formikRef}
                    openPricesMOdal={this.openPricesMOdal}
                    update={this.state.update}
                    toggleUpdate={this.toggleUpdate}
                    // callTotalPriceApiAgain={this.state.callTotalPriceApiAgain}
                    // totalPriceApiAgainToggle={this.totalPriceApiAgainToggle}
                  />
                ) : (
                  <TotalCart
                    cabinName={cabinArray[0]}
                    currency={cabinCode.currency}
                    handleContinue={this.PaymentOptionsModalOpen}
                    formikRef={this.formikRef}
                    openPricesMOdal={this.openPricesMOdal}
                    update={this.state.update}
                    toggleUpdate={this.toggleUpdate}
                    totalPriceApiAgain={this.state.totalPriceApiAgain}
                    totalPriceApiAgainToggle={this.totalPriceApiAgainToggle}
                  />
                )}
              </Col>
            </Row>

            <NewPricesModal
              isOpen={isOpen}
              // onClose={onClose}
              onClose={this.closeNewPricesModal}
              onOpen={onOpen}
              newPrices={this.state.newPrices}
              reserveAgain={this.handleContinue}
              price_mismatch={this.state.price_mismatch}
              totalPriceApiAgainToggle={this.totalPriceApiAgainToggle}
            />
          </ContentContainer>
        </StyledLoader>
      </React.Fragment>
    )
  }
}

const mapState = () =>
  createStructuredSelector({
    selectedFlightData: selectors.selectSelectedFlight(),
    selectQueryDetails: selectors.selectQueryDetails(),
    airports: features.landing.selectors.selectAirportList(),
    apiToken: features.login.selectors.selectApiToken(),
    identifier: selectors.selectIdentifier(),
    PNR: selectors.selectPNR(),
    isCheckBookingStatus: selectors.selectIsCheckBookingStatus(),
    isGetSingleReservation: selectors.selectIsGetSingleReservation(),
    cBStatusValue: selectors.selectCheckBookingStatus(),
    progressValue: selectors.selectProgressValue(),
    showOverlay: selectors.selectShowOverlay(),
    splitView: selectors.selectSplitView(),
    selectDeparting_flight: selectors.selectDeparting_flight()
  })

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      reserveFlight,
      showLoginModal: features.login.actions.showLoginModal,
      checkBookingStatus,
      getSingleReservation,
      checkBookingStatusFailed,
      getSingleReservationFailed,
      reserveFlightStatusFailed,
      modifySelectedFlightData,
      toggleOverlay,
      walletPayment: features.tickets.actions.walletPayment,
      cardPayment: features.tickets.actions.cardPayment,
      flightIssueTicket: features.tickets.actions.flightIssueTicket
    },
    dispatch
  )

export default compose(
  withRouter,
  component => connect(mapState, mapDispatch)(component),
  pure,
  modal
)(PassengerDetails)

class OptionalServices extends Component {
  state = {
    option: "",
    text: ""
  }
  getOption = (type, value) => {
    this.setState({ [type]: value })
    this.props.getOptionalServices(type, value)
  }
  render() {
    return (
      <div>
        <StyleFormGroup>
          <Label for="option">Optional Message Type</Label>
          <Input
            type="select"
            className="test_browser"
            name="option"
            id="exampleSelect"
            onChange={e => this.getOption("option", e.target.value)}
          >
            <option>Select</option>
            <option value="MAAS">Meet and Assist (MAAS)</option>
            <option value="WCHR">Wheelchair (WCHR)</option>
          </Input>
        </StyleFormGroup>
        <StyleFormGroup>
          <Label for="text">Message</Label>
          <Input
            type="textarea"
            name="text"
            id="exampleText"
            placeholder="Type Message Here"
            value={this.state.text}
            onChange={e => this.getOption("text", e.target.value)}
            maxlength={40}
          />
        </StyleFormGroup>
      </div>
    )
  }
}
export const FlightDetails = () => {
  return (
    <div>
      <Detail>
        By continuing, you agree to{" "}
        <a href="https://tripovy.com/terms-and-conditions">
          Tripovy Terms &amp; Conditions
        </a>{" "}
        and <a href="https://tripovy.com/privacy-policy">Privacy Policy</a>
      </Detail>
    </div>
  )
}

export const Detail = styled.p`
  color: #4d4d4d;
  font-size: 15px;
  padding: 8px 20px;
  margin-bottom: 4px;
  font-weight: 400;
`
const StyledLoader = styled(LoadingOverlay)`
  ._loading_overlay_content {
    position: fixed;
    top: 50%;
    padding: 50px 50px 35px 50px;
    left: 50%;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    transform: translate(-50%, -50%);
    @media only screen and (maxc-width: 767px) {
      left: 10%;
    }
    .progress {
      width: 100%;
      min-width: 400px;
      border-radius: 25px;
    }
  }
`
