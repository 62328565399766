import * as API from "services/API"
import { actions as sharedActions } from "./ducks"

export const airportList = () => {
  return async dispatch => {
    dispatch(sharedActions.airportListRequest())
    try {
      let { last_modified, data } = await API.airportsList()
      return dispatch(sharedActions.airportListSuccess({ data, last_modified }))
    } catch (error) {
      return dispatch(sharedActions.airportListError())
    }
  }
}

export const setAirportsState = ({ data, last_modified }) => {
  return async dispatch => {
    dispatch(sharedActions.setAirportsState({ data, last_modified }))
  }
}

export const selectedOrigin = ({ data }) => {
  return async dispatch => {
    dispatch(sharedActions.selectedOrigin({ data }))
  }
}

export const selectedFromRoundTrip = ({ data }) => {
  return async dispatch => {
    dispatch(sharedActions.selectedFromRoundTrip({ data }))
  }
}
