import styled from "styled-components"
import React from "react"
import { FaExclamationCircle } from "react-icons/fa"

const Heading = styled.div`
  padding: 6px 9px;
  background-color: #00b4e2;
  flex: 1;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  color: #fff;
  border: 1px solid #00b4e2;
`

const Content = styled.div`
  flex: 1;
  background: ${props => (props.noBackground ? "transparent" : "#fff")};
  border: ${props => (props.noBorder ? "none" : "1px solid #00b4e2")};
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 6px 5px;
  box-shadow: ${props =>
    props.noBoxShadow ? "unset" : "0px 4px 30px -13px #000"};
`
const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 20px;
`
const FlightName = styled.div`
  font-weight: 500;
  font-size: 18px;
  svg {
    margin-right: 10px;
  }
`
const FlightDetails = styled.div`
  font-weight: 500;
  font-size: 13px;
  margin-top: 5px;
  text-transform: uppercase;
`

const iconStyle = {
  color: "#fff",
  fontSize: "18px"
}
const iconConatiner = icon => {
  if (icon === "flight") {
    return (
      <img
        src="images/plane.png"
        alt=""
        style={{ maxWidth: "16px", marginRight: "10px" }}
      />
    )
  } else if (icon === "Shield") {
    return (
      <img
        src="images/privacy.png"
        alt=""
        style={{ maxWidth: "16px", marginRight: "10px" }}
      />
    )
  } else {
    return <FaExclamationCircle />
  }
}
export const Box = ({
  heading,
  flightDetails,
  icon,
  children,
  noBorder,
  noBackground,
  noBoxShadow
}) => {
  return (
    <BoxContainer>
      <Heading>
        <FlightName>
          {" "}
          {iconConatiner(icon)}
          {heading}
        </FlightName>
        {flightDetails && <FlightDetails>{flightDetails}</FlightDetails>}
      </Heading>
      <Content
        noBorder={noBorder}
        noBackground={noBackground}
        noBoxShadow={noBoxShadow}
      >
        {children}
      </Content>
    </BoxContainer>
  )
}

export default Box
