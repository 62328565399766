import styled from "styled-components"

export const FullSlider = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 0px 40px;
  @media only screen and (max-width: 1024px) {
    padding: 0px 40px;
  }
  .slick-slider button:before {
    content: "";
    background-image: url(../images/left-arrow.png);
    background-size: contain;
    background-repeat: no-repeat;
    padding: 16px;
  }
  .slick-slider button.slick-next:before {
    content: "";
    background-image: url(../images/arrow_right.png);
    background-size: contain;
    background-repeat: no-repeat;
    padding: 16px;
  }
`
export const SliderAnchor = styled.a`
  display: block;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
`
export const SliderSlide = styled.div`
  img {
    width: 100%;
    padding: 2rem;
    margin: 0 auto;
  }
`
export const SlideText = styled.div`
  margin-top: -18px;
  margin-left: 34px;
  h3 {
    color: #000;
    font-weight: 600;
    font-size: 19px;
    margin-bottom: 3px;
  }
  p {
    font-size: 14px;
    color: #313131;
    font-weight: 400;
  }
`
export const Haeding = styled.h3`
  color: #000000;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
`
