import React, { Fragment } from "react"
import moment from "moment"
import qs from "query-string"
import * as _ from "underscore"
import { cabinData } from "Constants"
import loadMax from "lodash.max"
import orderBy from "lodash.orderby"
import {
  priceItinerary,
  getAirlinesNameCode,
  getKeyPriceItinerary,
  flightTimings
} from "filterFunctions"

export const formatedDate = date => {
  let d1 = moment(date)
  let mdate = d1.date()
  let month = d1.month() + 1
  let year = d1.year()
  return { day: mdate, month, year }
}

export const tripType = query => {
  let data = qs.parse(query)
  return data.trip_type
}

export const passengers = data => {
  return {
    adult_count: Number(data.adults),
    child_count: Number(data.children),
    infant_count: Number(data.infants)
  }
}

export const extractDepDate = query => {
  let data = qs.parse(query)
  return formatedDate(data.departure_date)
}

export const extractReturnDate = query => {
  let data = qs.parse(query)
  return formatedDate(data.customRDate)
}

export const createQuery = query => {
  let data = qs.parse(query)
  if (tripType(query) === "oneway") {
    return {
      trip_type: tripType(query),
      origin: data.origin,
      destination: data.destination,
      departure_date: formatedDate(data.customDDate),
      airlines: "all",
      cabin_class: data.cabin_class,
      passengers: passengers(data)
    }
  } else if (tripType(query) === "roundtrip") {
    return {
      trip_type: tripType(query),
      origin: data.origin,
      destination: data.destination,
      departure_date: formatedDate(data.customDDate),
      return_date: formatedDate(data.customRDate),
      airlines: "all",
      cabin_class: data.cabin_class,
      passengers: passengers(data)
    }
  } else {
    // var keys = JSON.parse(localStorage.getItem("multicitySearchKey"))
    var keys = JSON.parse(data.multicitySearchKey)

    return {
      trip_type: tripType(query),
      airlines: "all",
      passengers: passengers(data),
      cabin_class: data.cabin_class,
      flights: getMultiFlights(keys.multiCities)
    }
  }
}
export const getLocalStorage = key => {
  return JSON.parse(localStorage.getItem(key))
}
export const setLocalStorage = (values, key) => {
  if (key === "multicitySearchKey") {
    localStorage.setItem(key, JSON.stringify(values))
  }
}
export const getMultiFlights = (keys, value) => {
  let flights = []
  keys.map((item, i) =>
    flights.push({
      origin: item.origin.code ? item.origin.code : item.origin,
      destination: item.destination.code
        ? item.destination.code
        : item.destination,
      departure_date: value
        ? i === 0
          ? formatedDate(value)
          : formatedDate(item.departure_date)
        : formatedDate(item.departure_date)
    })
  )
  return flights
}

export const getCabinClass = value => {
  let itemT
  cabinData.map(item => {
    if (item.value === value) {
      return (itemT = item)
    }
  })
  return itemT
}

export const getStateFromProps = ({ props }) => {
  if (props.query) {
    let t = qs.parse(props.query)
    if (t.trip_type === "multicity") {
      // const keys = JSON.parse(localStorage.getItem("multicitySearchKey"))
      const keys = JSON.parse(t.multicitySearchKey)

      return {
        multiCities: keys.multiCities,
        cabin_class: getCabinClass(t.cabin_class),
        passengers: {
          adults: Number(keys.passengers.adults),
          infants: Number(keys.passengers.infants),
          children: Number(keys.passengers.children)
        }
      }
    } else {
      return {
        origin: t.origin,
        destination: t.destination,
        departure_date: t.customDDate,
        return_date: t.customRDate ? t.customRDate : "",
        customRDate: t.customRDate,
        customDDate: t.customDDate,
        cabin_class: getCabinClass(t.cabin_class),
        passengers: {
          adults: Number(t.adults),
          infants: Number(t.infants),
          children: Number(t.children)
        },
        multiCities: [
          {
            origin: t.origin,
            destination: t.destination,
            departure_date: t.customDDate,
            minDate: t.customDDate
          },
          {
            origin: "",
            destination: "",
            departure_date: "",
            minDate: t.customDDate
          }
        ]
      }
    }
    // if (t.trip_type === "oneway") {
    //  http://localhost:3000/search?trip_type=roundtrip&departure_date=04/20/2019&return_date=Wed%20Apr%2024%202019%2000:00:00%20GMT+0530%20(India%20Standard%20Time)&origin=LHR&destination=IXB&cabin_class=Y&adults=1&children=0&infants=0&customDDate=undefined&customRDate=04/24/2019
    // customDDate=04/19/2019&customRDate=04/23/2019
    //   return {
    //     origin: t.origin,
    //     destination: t.destination,
    //     departure_date: t.departure_date,
    //     cabin_class: getCabinClass(t.cabin_class),
    //     passengers: {
    //       adults: Number(t.adults),
    //       infants: Number(t.infants),
    //       children: Number(t.children)
    //     }
    //   }
    // } else if (t.trip_type === "roundtrip") {
    //   return {
    //     origin: t.origin,
    //     destination: t.destination,
    //     departure_date: t.departure_date,
    //     return_date: t.return_date,
    //     cabin_class: getCabinClass(t.cabin_class),
    //     passengers: {
    //       adults: Number(t.adults),
    //       infants: Number(t.infants),
    //       children: Number(t.children)
    //     }
    //   }
    // } else {
    //   let keys = getLocalStorage("multicitySearchKey")
    //   return {
    //     multiCities: keys.multiCities,
    //     cabin_class: getCabinClass(t.cabin_class),
    //     passengers: {
    //       adults: Number(keys.passengers.adults),
    //       infants: Number(keys.passengers.infants),
    //       children: Number(keys.passengers.children)
    //     }
    //   }
    // }
  } else {
    return {
      origin: "",
      destination: "",
      departure_date: "",
      return_date: "",
      cabin_class: { value: "Y", label: "Economy" },
      passengers: {
        adults: 1,
        infants: 0,
        children: 0
      },
      multiCities: [
        {
          origin: "",
          destination: "",
          departure_date: ""
        }
      ]
    }
  }
}

export const createQueryFromState = (tripType, state) => {
  if (tripType === "oneway") {
    return {
      trip_type: tripType,
      origin: state.origin,
      destination: state.destination,
      departure_date: formatedDate(state.departure_date),
      airlines: "all",
      cabin_class: state.cabin_class.value,
      passengers: passengers(state.passengers)
    }
  } else if (tripType === "roundtrip") {
    return {
      trip_type: tripType,
      origin: state.origin.code ? state.origin.code : state.origin,
      destination: state.destination.code
        ? state.destination.code
        : state.destination,
      departure_date: formatedDate(state.departure_date),
      return_date: formatedDate(state.return_date),
      airlines: "all",
      cabin_class: state.cabin_class.value,
      passengers: passengers(state.passengers)
    }
  } else {
    // var keys = JSON.parse(localStorage.getItem("multicitySearchKey"))
    var keys = JSON.parse(state.multicitySearchKey)

    return {
      trip_type: tripType,
      airlines: "all",
      passengers: passengers(state.passengers),
      cabin_class: state.cabin_class.value,
      flights: getMultiFlights(keys.multiCities)
    }
  }
}

export const multiChildState = state => {
  return {
    departure_date: state.data.departure_date,
    passengers: state.passengers,
    origin: state.data.origin,
    destination: state.data.destination
  }
}

export const customQuery = (type, query, value) => {
  let data = qs.parse(query)
  if (tripType(query) === "oneway") {
    return {
      trip_type: tripType(query),
      origin: data.origin,
      destination: data.destination,
      departure_date: formatedDate(value),
      airlines: "all",
      cabin_class: data.cabin_class,
      passengers: passengers(data)
    }
  } else if (tripType(query) === "roundtrip") {
    return {
      trip_type: tripType(query),
      origin: data.origin,
      destination: data.destination,
      departure_date: formatedDate(value),
      // return_date: formatedDate(data.return_date),
      return_date: formatedDate(data.customRDate),
      airlines: "all",
      cabin_class: data.cabin_class,
      passengers: passengers(data)
    }
  } else {
    // var keys = JSON.parse(localStorage.getItem("multicitySearchKey"))
    var keys = JSON.parse(data.multicitySearchKey)
    return {
      trip_type: tripType(query),
      airlines: "all",
      passengers: passengers(data),
      cabin_class: data.cabin_class,
      flights: getMultiFlights(keys.multiCities, value)
    }
  }
}

export const getStopsFilter = stopsObj => {
  let res = []
  let stopKeys = Object.keys(stopsObj)
  stopKeys.forEach((stp, index) => {
    if (stopsObj[stp]) {
      res.push(index)
    }
  })
  return res
}

export const timeConvert = num => {
  let hours = Math.floor(num / 60)
  let minutes = num % 60
  if (hours < 10) {
    hours = "0" + hours
  }
  if (minutes < 10) {
    minutes = "0" + minutes
  }
  if (hours === 24) {
    hours = 23
    minutes = 59
  }
  return hours + ":" + minutes
}

export const manipulateArr = (arr, type, value) => {
  let index = arr.indexOf(type)
  if (value) {
    if (index === -1) {
      arr.push(type)
    }
  } else {
    if (index !== -1) {
      arr.splice(index, 1)
    }
  }
  return arr
}

export const fakePrices = flight => {
  flight.price_itinerary.economy.amount = 1300
  flight.price_itinerary.economy.fare_breakdown.adult.price = 1300
  flight.price_itinerary.economy.fare_breakdown.total = 1300
  return flight
  // flight.price_itinerary[price_mismatch_data.prices[0].cabin_class].amount
}

export const reserveQuery = ({
  profiles,
  selectedFlightData,
  selectQueryDetails,
  special_message,
  apiToken,
  selectDeparting_flight,
  splitView
}) => {
  let newProfiles = _.map(profiles, function(o) {
    return _.omit(o, "id", "tab")
  })
  return {
    api_token: apiToken,
    trip_type: selectQueryDetails.trip_type,
    flight: splitView === true ? selectDeparting_flight : selectedFlightData,
    special_message: {
      option: special_message.option ? special_message.option : null,
      text: special_message.text ? special_message.text : null
    },
    query: selectQueryDetails,
    profiles: newProfiles,
    return_flight: splitView ? selectedFlightData : null
  }
}

export const filterData = (filter, flightsArr) => {
  // let flightsArr = Immutable(state.flightsB).asMutable({ deep: true })
  // if(action.payload.data){}
  let {
    stops,
    airlines,
    journeyValue,
    outBoundValues,
    priceValue,
    returnSliderValues
  } = filter
  // STOPS
  // debugger
  // debugger
  let filghts = flightsArr
  let filteredArr = []
  filteredArr = flightsArr.filter(flights => {
    let segment
    let departures
    let tripsLength = []
    let arrays
    let departureArr
    if (flights.trips) {
      departures = flights.trips.map(i => i.departing_flight_segments)
      arrays = departures.map((i, index) => {
        i.map(items => {
          departureArr = items.stop_airports
        })
        return departureArr
      })
      departures.map((item, index) => {
        tripsLength.push(item.length + arrays[index].length - 1)
      })

      // segment =
      //   flights.trips[0].departing_flight_segments.length +
      //   flights.trips[0].departing_flight_segments[0].stop_airports.length -
      //   1
      let maximumNum = loadMax(tripsLength)
      segment = maximumNum
    } else {
      segment =
        flights.departing_flight_segments.length +
        flights.departing_flight_segments[0].stop_airports.length -
        1
    }

    if (segment > 2) {
      segment = 2
    }

    return stops.indexOf(segment) !== -1
  })
  // Departure Times : Outbound
  if (outBoundValues !== null) {
    filteredArr = filteredArr.filter(i => {
      let departure = i.trips
        ? i.trips[0].departing_flight_segments[0]
        : i.departing_flight_segments[0]
      let { departureTime } = flightTimings(departure)
      return (
        outBoundValues[0] <= departureTime * 60 &&
        departureTime * 60 <= outBoundValues[1]
      )
    })
  }
  // Departure Times : Return
  if (returnSliderValues !== null) {
    filteredArr = filteredArr.filter(i => {
      if (i.returning_flight_segments) {
        let departure = i.trips
          ? i.trips[0].departing_flight_segments[0]
          : i.returning_flight_segments[0]
        let { departureTime } = flightTimings(departure)
        return (
          returnSliderValues[0] <= departureTime * 60 &&
          departureTime * 60 <= returnSliderValues[1]
        )
      }
    })
  }
  // Journey Duration
  if (journeyValue !== null) {
    filteredArr = filteredArr.filter(i => {
      let durationMinutes = i.departing_flight_duration
      // let departure = i.trips
      //   ? i.trips[0].departing_flight_segments
      //   : i.departing_flight_segments
      // let { durationMinutes } = getMajorFlightDetails(departure)
      return durationMinutes <= journeyValue * 60
    })
  }
  // Price Range
  if (priceValue !== null) {
    filteredArr = filteredArr.filter(i => {
      let { amount: amt } = priceItinerary(i.price_itinerary)

      return amt >= priceValue[0] && amt <= priceValue[1]
    })
  }

  // Airlines
  if (airlines !== null) {
    filteredArr = filteredArr.filter(i => {
      let departure = i.trips
        ? i.trips[0].departing_flight_segments[0]
        : i.departing_flight_segments[0]
      return (
        airlines.indexOf(
          getAirlinesNameCode(departure).code &&
            getAirlinesNameCode(departure).code
        ) !== -1
      )
    })
  }
  // also for sorting
  //
  // early flights for sorting
  //
  let earlyFlights = []
  let myFlight = filteredArr
  myFlight.map(item => {
    if (item.trips) {
      let {
        departure_date,
        departure_time
      } = item.trips[0].departing_flight_segments[0]
      let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
      let obj = { ...item, take_off_time: takeOffTime }
      earlyFlights.push(obj)
    } else {
      let { departure_date, departure_time } = item.departing_flight_segments[0]
      let takeOffTime = `${departure_date.year}-${departure_date.month}-${departure_date.day} ${departure_time.hour}:${departure_time.minute}:00`
      let obj = { ...item, take_off_time: takeOffTime }
      earlyFlights.push(obj)
    }
  })
  earlyFlights = orderBy(earlyFlights, function(o) {
    return new moment(o.take_off_time)
  })

  // cheap flights
  let cheapFlights = filteredArr
  let newArr = []
  let priceKey = getKeyPriceItinerary(
    cheapFlights.length && cheapFlights[0].price_itinerary
  )
  cheapFlights.map(items => {
    newArr.push(items.price_itinerary[priceKey].amount)
  })
  // cheapFlights.sort(function(a, b) {
  // return (
  // a.price_itinerary[priceKey].amount -
  // b.price_itinerary[priceKey].amount
  // )
  // })
  if (priceValue !== null) {
    filteredArr = filteredArr.sort(function(a, b) {
      return (
        a.price_itinerary[priceKey].amount - b.price_itinerary[priceKey].amount
      )
    })
  }
  let cheapFlight = _.min(newArr)
  // quick flights
  let quickFlights = filteredArr
  let testArray = []
  quickFlights.map(item => {
    if (item.trips) {
      let duration = 0
      item.trips.map(trips => {
        trips.departing_flight_segments.map(items => {
          duration = duration + items.flight_duration
        })
      })
      item.totalFlightDuration = duration
      testArray.push(item)
    } else {
      let duration = 0
      item.departing_flight_segments.map(subItem => {
        duration = duration + subItem.flight_duration
      })
      item.totalFlightDuration = duration
      testArray.push(item)
    }
  })
  testArray.sort(function(a, b) {
    return a.departing_flight_duration - b.departing_flight_duration
  })
  return filteredArr
}

export const sleep = ms =>
  new Promise((resolve, _reject) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
