import React, { lazy, Suspense } from "react"
const Register = lazy(() =>
  import(/*webpackChunkName: "RegisterModal" */ "../../Login/RegisterModal")
)
class index extends React.PureComponent {
  render() {
    return (
      <Suspense fallback={<p />}>
        <Register {...this.props} />
      </Suspense>
    )
  }
}

export default index
