import React, { Component, Fragment } from "react"
import { NavItem, TabContent, TabPane } from "reactstrap"
import classnames from "classnames"
import { compose, pure } from "recompose"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import { withRouter } from "react-router-dom"
import { createQuery } from "utilities"
import { RoundTripResults } from "./index"
import { selectors } from "../ducks"
import { StyleNavLink, StyledNav } from "./elements"
import Alert from "base_components/BannerAlert/index.js"
import { returningFilters, selectedDepartingFlight } from "../thunks"
class SplitView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      departingSelected: false,
      alertVisible: false,
      message: "Select departing flight first!"
    }
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  selectSplitDeparting = departing_flight => {
    this.props.selectedDepartingFlight({
      departing_flight
    })
    this.setState({ activeTab: "2", departingSelected: true })
  }

  returningFilters = type => {
    // debugger
    let { returningFilters, updateFilterComponent } = this.props
    // returningFilters({
    //   type,
    //   query: createQuery(this.props.location.search)
    // })
    updateFilterComponent(type)
  }

  selectReturning = ({ selectedFlight }) => {
    // debugger
    if (this.state.departingSelected) {
      this.props.updateProgress({ selectedFlight })
    } else {
      this.setState({ activeTab: "1", alertVisible: true })
    }
  }
  closeAlert = () => {
    this.setState({ alertVisible: false })
  }

  // departing = memoize(() => this.props.selectFlightsC.departing)
  // returning = memoize(() => this.props.selectFlightsC.returning)

  render() {
    const { activeTab } = this.state
    let { origin, destination } = createQuery(this.props.location.search)
    let {
      data,
      selectFlightsC,
      splitView,
      splitDeparting,
      splitReturning,
      updateProgress,
      selectFlights,
      updateFilterComponent,
      allAirports,
      getScrollLocations
    } = this.props
    return (
      <Fragment>
        <Alert
          message={this.state.message}
          visible={this.state.alertVisible}
          close={this.closeAlert}
        />
        <StyledNav tabs>
          <NavItem
            style={{ flex: "1" }}
            onClick={() => this.returningFilters("departing")}
          >
            <StyleNavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                this.toggle("1")
              }}
            >
              {origin} - {destination}
            </StyleNavLink>
          </NavItem>
          <NavItem
            style={{ flex: "1" }}
            onClick={() => this.returningFilters("returning")}
          >
            <StyleNavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                this.toggle("2")
              }}
            >
              {destination} - {origin}
            </StyleNavLink>
          </NavItem>
        </StyledNav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Departing
              data={splitDeparting}
              splitView={splitView}
              updateProgress={this.selectSplitDeparting}
              allAirports={allAirports}
              getScrollLocations={getScrollLocations}
              updateFilterComponent={updateFilterComponent}
            />
          </TabPane>
          <TabPane tabId="2">
            <Returning
              data={splitReturning}
              splitView={splitView}
              allAirports={allAirports}
              updateProgress={selectedFlight =>
                this.selectReturning(selectedFlight)
              }
              getScrollLocations={getScrollLocations}
              updateFilterComponent={updateFilterComponent}
            />
          </TabPane>
        </TabContent>
      </Fragment>
    )
  }
}

const mapState = () =>
  createStructuredSelector({
    selectFlightsC: selectors.selectFlightsC(),
    splitDeparting: selectors.selectSplitDeparting(),
    splitReturning: selectors.selectSplitReturning(),
    selectFlights: selectors.selectFlights()
  })

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      returningFilters,
      selectedDepartingFlight
    },
    dispatch
  )

export default compose(
  withRouter,
  component =>
    connect(
      mapState,
      mapDispatch
    )(component),
  pure
)(SplitView)

const Departing = ({ data, splitView, updateProgress, allAirports ,getScrollLocations,updateFilterComponent}) => {
  return (
    <Fragment>
      {data.length
        ? data.map((item, i) => (
            <RoundTripResults
              key={i}
              data={item}
              splitView={splitView}
              updateProgress={updateProgress}
              allAirports={allAirports}
              getScrollLocations={getScrollLocations}
              updateFilterComponent={updateFilterComponent}
            />
          ))
        : "No flights found! departing"}
    </Fragment>
  )
}

const Returning = ({ data, splitView, updateProgress, allAirports, getScrollLocations,updateFilterComponent }) => {
  return (
    <Fragment>
      {data.map((item, i) => (
        <RoundTripResults
          key={i}
          data={item}
          splitView={splitView}
          updateProgress={updateProgress}
          allAirports={allAirports}
          getScrollLocations={getScrollLocations}
          updateFilterComponent={updateFilterComponent}
        />
      ))}
    </Fragment>
  )
}
