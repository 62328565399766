import React, { Component } from "react"
import { Filter, FilterBtn, Divider } from "./elements"
// import format from "date-fns/format"
import { connect } from "react-redux"
// import Immutable from "seamless-immutable"
import { FaSortAmountUp } from "react-icons/fa"
import { createStructuredSelector } from "reselect"
import { selectors } from "../ducks"
import {
  priceItinerary,
  // flightTimings,
  // getMajorFlightDetails,
  getTotalFlightDuration
} from "filterFunctions"
import { TimeConvert24to12 } from "shared/ticketFuctions"
class Filters extends Component {
  constructor() {
    super()
    this.state = {
      selectedDate: null
    }
  }

  handleDateSelect = date => {
    this.setState({
      selectedDate: date
    })
  }

  render() {
    let {
      getBorders: { cheapFlightBorder, earlyFlightBorder, quickFlightBorder },
      flightCategories
    } = this.props
    let { cheapFlights, earlyFlights, quickFlights } = flightCategories

    let { amount, currency } = priceItinerary(
      typeof cheapFlights !== "undefined" && cheapFlights.price_itinerary
    )
    let quickAmt = priceItinerary(
      typeof quickFlights !== "undefined" && quickFlights.price_itinerary
    )
    let earlyAmt = priceItinerary(
      typeof earlyFlights !== "undefined" && earlyFlights.price_itinerary
    )
    let { totalDuration } = getTotalFlightDuration(
      typeof cheapFlights !== "undefined" && cheapFlights
    )
    let quicktotalDuration = getTotalFlightDuration(
      typeof quickFlights !== "undefined" && quickFlights
    )
    let earlytotalDuration = getTotalFlightDuration(
      typeof earlyFlights !== "undefined" && earlyFlights
    )

    // console.log(
    //   "cheapFlightBorder, earlyFlightBorder, quickFlightBorder  in filter @@@@@",
    //   cheapFlightBorder,
    //   earlyFlightBorder,
    //   quickFlightBorder
    // )

    return (
      <Filter>
        <FilterBtn
          style={{flex:1, border: cheapFlightBorder === false && "none" }}
          onClick={this.props.onCheapestPressed}
        >
          <h4>Cheapest</h4>
          <p>
            {currency} {amount}{" "}
            <span style={{ verticalAlign: "text-bottom" }}>.</span>{" "}
            {totalDuration}
          </p>
        </FilterBtn>
        <Divider />
        <FilterBtn
          onClick={this.props.onEarliestPressed}
          style={{flex:1, border: earlyFlightBorder === false && "none" }}
        >
          <h4>Earliest take-off</h4>
          <p>
            {currency} {earlyAmt.amount}{" "}
            <span style={{ verticalAlign: "text-bottom" }}>.</span>{" "}
            {earlytotalDuration.earlyTime &&
              TimeConvert24to12(earlytotalDuration.earlyTime)}
          </p>
        </FilterBtn>
        <Divider />
        <FilterBtn
          onClick={this.props.onQuickestPressed}
          style={{flex:1, border: quickFlightBorder === false && "none" }}
        >
          <h4>Quickest</h4>
          <p>
            {currency} {quickAmt.amount}{" "}
            <span style={{ verticalAlign: "text-bottom" }}>.</span>{" "}
            {quicktotalDuration.totalDuration}
          </p>
        </FilterBtn>
        {/* <Divider />
        <FilterBtn style={{ border: "none", display: "flex" }}>
          <FaSortAmountUp />
          <h4 style={{ fontSize: "14px", marginLeft: "5px" }}>Other Sort</h4>
        </FilterBtn> */}
      </Filter>
    )
  }
}

const mapState = () =>
  createStructuredSelector({
    flightCategories: selectors.selectAppliedFilters(),
    getBorders: selectors.getBorders()
  })

export default connect(mapState)(Filters)
