import _, { pick } from "underscore"
import qs from "query-string"
import moment from "moment"
import { cabinData } from "Constants"
// let state = store.getState()

export const priceItinerary = data => {
  let a = pick(
    Object.values(data)[0],
    "amount",
    "currency",
    "cabin_code",
    "fare_breakdown"
  )
  if (a.cabin_code === null) {
    a.cabin_code = { departing_segments: ["N/A"], returning_segments: ["N/A"] }
  }
  return a
}

export const getKeyPriceItinerary = data => {
  return Object.keys(data)[0]
}

export const handleLoader = called => {
  // console.log("handleLoader called", called)
  let loader = false,
    noLength = false
  if (called && called.length) {
    loader = called.some(item => item.called && !item.received)
    // console.log("loaderrrrrr", loader)
    let length = []
    called.map(item => {
      if (item.received && !item.dataLength) {
        length.push(item)
      }
    })
    if (called.length === length.length) {
      noLength = true
    }
    // noLength = called.length === length.length ? true : false
    // console.log("noLength", noLength, called.length, length)
  }
  // console.log("loader, noLength", loader, noLength)
  return { loader, noLength }
}

export const checkArrayEquality = array => {
  let array1 = array
  let array2 = [0, 1440]
  return _.isEqual(array1.sort(), array2.sort())
}

export const getDateString = date => {
  let d1 = moment(date)
  let mdate = d1.date()
  let month = d1.month() + 1
  let year = d1.year()
  let s = `${("0" + mdate).slice(-2)}/${("0" + month).slice(-2)}/${year}`
  return s
}

export let cabinClassArrayMulti = data => {
  if (data) {
    let array = []
    Object.values(data)[0].map(i => {
      if (i === "Y") {
        array.push({ name: "Economy" })
      } else if (i === "S") {
        array.push({ name: "Premium Economy" })
      } else if (i === "C") {
        array.push({ name: "Business" })
      } else if (i === "F") {
        array.push({ name: "First" })
      } else if (i === "N/A") {
        array.push({ name: "N/A" })
      }
    })

    if (Object.values(data)[1]) {
      Object.values(data)[1].map(i => {
        if (i === "Y") {
          array.push({ name: "Economy" })
        } else if (i === "S") {
          array.push({ name: "Premium Economy" })
        } else if (i === "C") {
          array.push({ name: "Business" })
        } else if (i === "F") {
          array.push({ name: "First" })
        } else if (i === "N/A") {
          array.push({ name: "N/A" })
        }
      })
    }
    return array.map(i => {
      return i.name
    })
  }
}

export const cabinClassArray = data => {
  if (data) {
    return Object.values(data)[0]
  }
}
export const getAirlineName = data => {
  let name = data.airline.airline_name
  // console.log("name @@@@@@@@", name)
  return name
}

export const getAirlinesNameCode = data => {
  let code = pick(data.airline, "code")
  let name = data.airline.airline_name
  let obj = {}
  obj.name = name
  obj.code = code.code
  return obj
}

export const getMyCabinClass = (index, cabinClassArr) => {
  if (cabinClassArr) {
    let label
    cabinData.map(item => {
      if (item.value == cabinClassArr[index]) {
        return (label = item.label)
      }
    })
    return label
  }
}
export const stopAirports = data => {
  let stopAirports = data.stop_airports
  let t = []
  let s
  if (stopAirports.length) {
    stopAirports.map(item => t.push(item.airport_code))
    s = t.join(",")
  }
  return s
}

export const getFlightCardDataMulti = (data, airportsList) => {
  if (data) {
    let arrivalCity = airportsList.find(
      item => item.code === data.arrival_airport.code
    )
    let depCity = airportsList.find(
      item => item.code === data.departure_airport.code
    )
    // console.log("data ----------- multi", data)
    // console.log("arrivalCity ------- multi", arrivalCity)
    // console.log("depcity ------------ multi", depCity)
    return {
      arivalAirport: data.arrival_airport.code,
      depAirport: data.departure_airport.code,
      stops: data.stop_airports.length ? data.stop_airports.length : null,
      stopAirports: stopAirports(data),
      cabin: data.cabin,
      airlineCode: data.airline.code,
      cabinNew: data.cabin,
      arrivalCity: arrivalCity.value,
      departCity: depCity.value
    }
  }
}

export const getFlightCardData = (data, airportsList) => {
  if (data) {
    let arrivalCity = airportsList.find(
      item => item.code === data.arrival_airport.code
    )
    let depCity = airportsList.find(
      item => item.code === data.departure_airport.code
    )
    // console.log("data -----------", data)
    // console.log("arrivalCity -------", arrivalCity)
    // console.log("depcity ------------", depCity)
    return {
      arivalAirport: data.arrival_airport.code,
      depAirport: data.departure_airport.code,
      stops: data.stop_airports.length ? data.stop_airports.length : null,
      stopAirports: stopAirports(data),
      airlineCode: data.airline.code,
      cabinNew: data.cabin,
      arrivalCity: arrivalCity.value,
      departCity: depCity.value
    }
  }
}

export const getFlightReturnTime = data => {
  let returningTime = data.departure_time.hour
  return { returningTime }
}

export const flightTimings = data => {
  let testDepDate =
    data.departure_date.year +
    "/" +
    data.departure_date.month +
    "/" +
    data.departure_date.day
  var dt = moment(testDepDate, "YYYY-MM-DD HH:mm:ss")
  let departureTime = data.departure_time.hour
  let depDate = dt.format("ddd") + "," + dt.format("D") + " " + dt.format("MMM")
  let endTime = data.arrival_time.hour + ":" + data.arrival_time.minute
  let startTime = data.departure_time.hour + ":" + data.departure_time.minute
  let arival = moment(endTime, "H:mm")
  let dep = moment(startTime, "H:mm")
  let arivalTime = moment(endTime, "H:mm").format("H:mm")
  let depTime = moment(startTime, "H:mm").format("H:mm")
  let airlineCode = data.airline.code
  let airlineNum = data.airline.flight_number
  let flightNumber = `${airlineCode}-${airlineNum}`
  // let d = arival.diff(dep, "minute")
  let aircraft = data.aircraft
  let duration =
    Math.floor(data.flight_duration / 60) +
    "h" +
    " " +
    (data.flight_duration % 60) +
    "m"
  let is_flight_duration_null = data.flight_duration === null ? true : false
  return {
    arivalTime,
    depTime,
    duration,
    depDate,
    departureTime,
    flightNumber,
    aircraft,
    is_flight_duration_null
  }
}

export const baggageAllowance = data => {
  if (data) {
    return pick(
      Object.values(data)[0]["departing"],
      "weight",
      "unit",
      "passenger_type",
      "pieces"
    )
  } else {
    let weight, unit, passenger_type, pieces
    return { weight, unit, passenger_type, pieces }
  }
}

export const createBaggageString = item => {
  let { weight, unit, passenger_type, pieces } = item
  let str
  if (unit === "kg") {
    str = `${weight}${unit} per ${passenger_type}`
  } else if (unit === "piece(s)") {
    str = `${pieces} ${unit} per ${passenger_type}`
  } else str = "N/A"
  return str
}

export const baggageAllowanceBreakdowns = ({ baggage_allowance, tripType }) => {
  let { breakdowns } = baggage_allowance || { breakdowns: null }
  let departBagArr = []
  let returnBagArr = []
  if (breakdowns) {
    let { adult, child, infant } = breakdowns
    if (adult && adult.length === 1) {
      departBagArr.push(createBaggageString(adult[0]))
    }
    if (child && child.length === 1) {
      departBagArr.push(createBaggageString(child[0]))
    }
    if (infant && infant.length === 1) {
      departBagArr.push(createBaggageString(infant[0]))
    }
    if (adult && adult.length === 2) {
      departBagArr.push(createBaggageString(adult[0]))
      returnBagArr.push(createBaggageString(adult[1]))
    }
    if (child && child.length === 2) {
      departBagArr.push(createBaggageString(child[0]))
      returnBagArr.push(createBaggageString(child[1]))
    }
    if (infant && infant.length === 2) {
      departBagArr.push(createBaggageString(infant[0]))
      returnBagArr.push(createBaggageString(infant[1]))
    }
  }
  return {
    departBagArr: departBagArr.join(", "),
    returnBagArr: returnBagArr.join(", ")
  }
}

export const multicityBaggageAllowance = ({ baggage_allowance, trips }) => {
  let { breakdowns } = baggage_allowance || { breakdowns: null }
  let multiBagArr = []
  trips.forEach(item => multiBagArr.push([]))
  if (breakdowns) {
    let { adult, child, infant } = breakdowns
    trips.map((item, index) => {
      if (adult && adult.length) {
        adult.map((aItem, aIndex) => {
          if (index === aIndex) {
            multiBagArr[aIndex].push(createBaggageString(aItem))
          }
        })
      }
      if (child && child.length) {
        child.map((aItem, aIndex) => {
          if (index === aIndex) {
            multiBagArr[aIndex].push(createBaggageString(aItem))
          }
        })
      }
      if (infant && infant.length) {
        infant.map((aItem, aIndex) => {
          if (index === aIndex) {
            multiBagArr[aIndex].push(createBaggageString(aItem))
          }
        })
      }
    })
  }
  return {
    multiBagArr
  }
}
export const getMajorFlightDetails = (data, allAirports) => {
  if (data !== undefined) {
    // get departure details
    let startTime =
      data[0].departure_time.hour + ":" + data[0].departure_time.minute
    let depTime = moment(startTime, "HH:mm").format("HH:mm")
    let depAirport = data[0].departure_airport.code
    // get arrival details
    let endTime =
      data[data.length - 1].arrival_time.hour +
      ":" +
      data[data.length - 1].arrival_time.minute
    let arrTime = moment(endTime, "H:mm").format("H:mm")
    let arrAirport = data[data.length - 1].arrival_airport.code
    let arrivalCity =
      allAirports && allAirports.find(item => item.code === arrAirport)
    let depCity =
      allAirports && allAirports.find(item => item.code === depAirport)
    // get flight duration
    let d = moment(startTime, "H:mm")
    let a = moment(endTime, "H:mm")
    let duration = a.diff(d, "minute")
    let flightDuration =
      Math.floor(duration / 60) + "h" + " " + (duration % 60) + "m"
    // get #stops
    let s = 0
    data.map(item => {
      if (item.stop_airports.length) {
        s = s + item.stop_airports.length
      }
    })
    let stopInt = s + data.length - 1

    // stop airports codes
    let sA = []
    if (data.length > 1) {
      data.map(item => {
        sA.push(item.arrival_airport.code)
        if (item.stop_airports.length) {
          item.stop_airports.map(item2 => sA.push(item2.airport_code))
        }
      })
      sA.pop()
    } else {
      data.map(item => {
        if (item.stop_airports.length) {
          item.stop_airports.map(item2 => sA.push(item2.airport_code))
        }
      })
    }
    let sAValue = sA.join(",")

    let airlineName = data[0].airline.airline_name
    let tDuration = 0
    data.map(d => {
      tDuration = tDuration + d.flight_duration
    })
    let totalDuration =
      Math.floor(tDuration / 60) + "h" + " " + (tDuration % 60) + "m"
    let shortDepDate =
      ("0" + data[0].departure_date.day).slice(-2) +
      "/" +
      ("0" + data[0].departure_date.month).slice(-2)
    let depDate = getFormatedDepDate(data[0].departure_date)
    let airlineCode = data[0].airline.code
    return {
      depTime,
      depAirport,
      arrTime,
      arrAirport,
      flightDuration,
      stopInt,
      sAValue,
      airlineName,
      totalDuration,
      durationMinutes: tDuration,
      shortDepDate,
      depDate,
      airlineCode,
      arrivalCity: arrivalCity && arrivalCity.value,
      depCity: depCity && depCity.value
    }
  } else {
    return {
      depTime: "",
      depAirport: "",
      arrTime: "",
      arrAirport: "",
      flightDuration: "",
      stopInt: "",
      sAValue: "",
      airlineName: "",
      totalDuration: "",
      durationMinutes: "",
      shortDepDate: "",
      depDate: "",
      airlineCode: ""
    }
  }
}

export const getCityName = string => {
  let indexOfComma = string.indexOf(",")
  let city = indexOfComma > -1 ? string.slice(0, indexOfComma) : string
  return { city }
}

export const getStopAirportsData = (data, airportsList) => {
  let d = []
  if (data.stop_airports.length) {
    // debugger
    data.stop_airports.map(i => {
      d.push({
        depAirport: data.departure_airport.code,
        arivalAirport: i.airport_code,
        depTime: getFormatedTime(data.departure_time),
        arrTime: getFormatedTime(i.arrival_time),
        duration: getStopsDurations(i.elapsed_time),
        depDate: getFormatedDepDate(data.departure_date),
        stayAirportData: { transitTimeD: null, transitAirport: null }
      })
      d.push({
        depAirport: i.airport_code,
        arivalAirport: data.arrival_airport.code,
        arrTime: getFormatedTime(data.arrival_time),
        depTime: getFormatedTime(i.departure_time),
        duration: getStopsDurations(data.flight_duration - i.elapsed_time),
        depDate: getFormatedDepDate(i.departure_date),
        stop_duration: i.stop_duration,
        stayAirportData: getTransitDurationTextStopAirports(
          i.stop_duration,
          i.airport_code,
          airportsList
        )
      })
    })
  }
  return d
}

export const getStopAirportsDataImproved = (data, airportsList) => {
  let d = []

  if (
    data.stop_airports.length > 0 &&
    !data.stop_airports.find(a => !a.departure_time)
  ) {
    let arrivalCity = airportsList.find(
      item => item.code === data.arrival_airport.code
    )
    let depCity = airportsList.find(
      item => item.code === data.departure_airport.code
    )
    let length = data.stop_airports.length
    data.stop_airports.map((item, i) => {
      let arrivalCityName = airportsList.find(i => i.code === item.airport_code)
      let departCityName = airportsList.find(i => i.code === item.airport_code)
      // console.log("item -------", item)
      if (i === 0) {
        d.push({
          depAirport: data.departure_airport.code,
          arivalAirport: item.airport_code,
          depTime: getFormatedTime(data.departure_time),
          arrTime: getFormatedTime(item.arrival_time),
          duration: getStopsDurations(item.stop_duration),
          depDate: getFormatedDepDate(data.departure_date),
          stayAirportData: { transitTimeD: null, transitAirport: null },
          arrivalCity: arrivalCityName.value,
          departCity: depCity.value
        })
        if (length === 1) {
          d.push({
            depAirport: item.airport_code,
            arivalAirport: data.arrival_airport.code,
            arrTime: getFormatedTime(data.arrival_time),
            depTime: getFormatedTime(item.departure_time),
            duration: getStopsDurations(item.stop_duration),
            depDate: getFormatedDepDate(item.departure_date),
            stop_duration: item.stop_duration,
            stayAirportData: { transitTimeD: getStopsDurations(item.stop_duration), transitAirport: item.airport_code },
            arrivalCity: arrivalCity.value,
            departCity: departCityName.value
          })
        }
      } else if (length > 1 && i < length - 1 && i > 0) {
        let departingCityName = airportsList.find(
          item => item.code === data.stop_airports[i - 1].airport_code
        )
        d.push({
          depAirport: data.stop_airports[i - 1].airport_code,
          arivalAirport: item.airport_code,
          depTime: getFormatedTime(data.stop_airports[i - 1].departure_time),
          arrTime: getFormatedTime(item.arrival_time),
          duration: getStopsDurations(item.elapsed_time),
          depDate: getFormatedDepDate(data.stop_airports[i - 1].departure_date),
          stop_duration: item.stop_duration,
          stayAirportData: getTransitDurationTextStopAirports(
            item.stop_duration,
            item.airport_code,
            airportsList
          ),
          arrivalCity: arrivalCityName.value,
          departCity: departingCityName.value
        })
      } else {
        let departingCityName = airportsList.find(
          item => item.code === data.stop_airports[i - 1].airport_code
        )
        d.push({
          depAirport: data.stop_airports[i - 1].airport_code,
          arivalAirport: item.airport_code,
          depTime: getFormatedTime(data.stop_airports[i - 1].departure_time),
          arrTime: getFormatedTime(item.arrival_time),
          duration: getStopsDurations(data.elapsed_time),
          depDate: getFormatedDepDate(data.stop_airports[i - 1].departure_date),
          stop_duration: item.stop_duration,
          stayAirportData: getTransitDurationTextStopAirports(
            item.stop_duration,
            item.airport_code,
            airportsList
          ),
          arrivalCity: arrivalCityName.value,
          departCity: departingCityName.value
        })

        if (i === length - 1) {
          d.push({
            depAirport: item.airport_code,
            arivalAirport: data.arrival_airport.code,
            arrTime: getFormatedTime(data.arrival_time),
            depTime: getFormatedTime(item.departure_time),
            duration: getStopsDurations(
              data.flight_duration - item.elapsed_time
            ),
            depDate: getFormatedDepDate(item.departure_date),
            stop_duration: item.stop_duration,
            stayAirportData: getTransitDurationTextStopAirports(
              item.stop_duration,
              item.airport_code,
              airportsList
            ),
            arrivalCity: arrivalCity.value,
            departCity: departCityName.value
          })
        }
      }
    })
  }
  else
  {
    d.push({
      depAirport: data.departure_airport.code,
      arivalAirport: data.arrival_airport.code,
      arrTime: getFormatedTime(data.arrival_time),
      depTime: getFormatedTime(data.departure_time),
      depDate: getFormatedDepDate(data.departure_date),
      stayAirportData:{transitTimeD:null , transitAirport:data.departure_airport.code},
      arrivalCity: "",
      departCity: "",
      duration: getStopsDurations(data.flight_duration),
    })
  }
  return d
}

const flightsDetail = flight => {
  let flightsTempArr = []
  flight.map(item => {
    let obj = {}
    // If there is data inside stop airports array of flight segments
    if (
      item.stop_airports &&
      item.stop_airports.length > 0 &&
      !item.stop_airports.find(a => !a.departure_time)
    ) {
      item.stop_airports.map((subItem, index) => {
        if (index === 0) {
          obj = {
            dep_Airport: item.departure_airport.code,
            arrival_Airport: subItem.airport_code,
            dep_Time: getFormatedTime(item.departure_time),
            arr_Time: getFormatedTime(subItem.arrival_time),
            duration: 0,
            dep_Date: getFormatedDepDate(item.departure_date),
            airline: item.airline.airline_name,
            // airlineLogo: `${logoBaseUrl}/${item.airline.code}`,
            flight: item,
            cabinClass: item.cabin,
            transit_duration: subItem.stop_duration
          }
          flightsTempArr.push(obj)

          if (item.stop_airports.length === 1) {
            obj = {
              dep_Airport: subItem.airport_code,
              arrival_Airport: item.departure_airport.code,
              dep_Time: getFormatedTime(subItem.arrival_time),
              arr_Time: getFormatedTime(item.departure_time),
              duration: 0,
              dep_Date: getFormatedDepDate(subItem.departure_date),
              airline: item.airline.airline_name,
              // airlineLogo: `${logoBaseUrl}/${item.airline.code}`,
              flight: item,
              cabinClass: item.cabin,
              transit_duration: subItem.stop_duration
            }
            flightsTempArr.push(obj)
          }
        } else if (
          item.stop_airports.length > 1 &&
          index < item.stop_airports.length - 1 &&
          index > 0
        ) {
          obj = {
            dep_Airport: item.stop_airports[index - 1].airport_code,
            arrival_Airport: subItem.airport_code,
            dep_Time: getFormatedTime(
              item.stop_airports[index - 1].departure_time
            ),
            arr_Time: getFormatedTime(subItem.arrival_time),
            duration: getStopsDurations(subItem.elapsed_time),
            dep_Date: getFormatedDepDate(
              item.stop_airports[index - 1].departure_date
            ),
            airline: item.airline.airline_name,
            // airlineLogo: `${logoBaseUrl}/${item.airline.code}`,
            flight: item,
            cabinClass: item.cabin,
            // cabinClass: getCabinClass(item.cabinClass ? item.cabinClass : null),
            transit_duration: subItem.stop_duration
          }
          flightsTempArr.push(obj)
        } else {
          obj = {
            dep_Airport: item.stop_airports[index - 1].airport_code,
            arrival_Airport: item.arrival_airport.code,
            dep_Time: getFormatedTime(subItem.departure_time),
            arr_Time: getFormatedTime(item.arrival_time),
            duration: getStopsDurations(subItem.elapsed_time),
            dep_Date: getFormatedDepDate(subItem.departure_date),
            airline: item.airline.airline_name,
            // airlineLogo: `${logoBaseUrl}/${item.airline.code}`,
            flight: item,
            cabinClass: item.cabin,
            // cabinClass: getCabinClass(item.cabinClass ? item.cabinClass : null),
            transit_duration: subItem.stop_duration
          }
          flightsTempArr.push(obj)
        }
      })
    } else {
      obj = {
        dep_Airport: item.departure_airport.code,
        arrival_Airport: item.arrival_airport.code,
        dep_Time: getFormatedTime(item.departure_time),
        arr_Time: getFormatedTime(item.arrival_time),
        duration: getStopsDurations(item.flight_duration),
        dep_Date: getFormatedDepDate(item.departure_date),
        airline: item.airline.airline_name,
        // airlineLogo: `${logoBaseUrl}/${item.airline.code}`,
        flight: item,
        cabinClass: item.cabin,
        transit_duration: item.transit_duration
      }
      flightsTempArr.push(obj)
    }
  })
  return flightsTempArr
}

export const getStopsDurations = value => {
  return Math.floor(value / 60) + "h" + " " + (value % 60) + "m"
}

export const getFormatedTime = data => {
  let testTime = data.hour + ":" + data.minute
  let time = moment(testTime, "H:mm").format("H:mm")
  return time
}

export const getFormatedDepDate = date => {
  let testDate = date.year + "/" + date.month + "/" + date.day
  let dt = moment(testDate, "YYYY-MM-DD HH:mm:ss")
  let finalDate =
    dt.format("ddd") + "," + dt.format("D") + " " + dt.format("MMM")
  return finalDate
}

export const getDuration = (dt, at) => {
  let startTime = dt.hour + ":" + dt.minute
  let endTime = at.hour + ":" + at.minute
  let arival = moment(endTime, "H:mm")
  let dep = moment(startTime, "H:mm")
  let duration = arival.diff(dep, "minute")
  let flightDuration =
    Math.floor(duration / 60) + "h" + " " + (duration % 60) + "m"
  return flightDuration
}

export const getMultiCabinClass = ({
  flightSegmentsIndex,
  tripIndex,
  cabinClassArr
}) => {
  let label
  let myIndex = flightSegmentsIndex + tripIndex
  cabinData.map(item => {
    if (item.value === cabinClassArr[cabinClassArr.length - 1]) {
      label = item.label
    }
  })
  return label
}

export const getMomentDate = date => {
  if (date) {
    let tt = date.day + "/" + date.month + "/" + date.year
    let dt = moment(tt, "DD/MM/YYYY")
    return new Date(dt)
  }
}

// get total duration for sorting buttons
export const getTotalFlightDuration = data => {
  let totalDuration
  let earlyTime
  if (data) {
    if (data.trips) {
      let tDuration = 0
      // data.trips.map(trip => {
      //   trip.departing_flight_segments.map(t => {
      //     tDuration = tDuration + t.flight_duration
      //   })
      // })
      totalDuration =
        Math.floor(data.departing_flight_duration / 60) +
        "h" +
        " " +
        (data.departing_flight_duration % 60) +
        "m"
      let hour = data.trips[0].departing_flight_segments[0].departure_time.hour
      let minutes =
        data.trips[0].departing_flight_segments[0].departure_time.minute
      earlyTime = ("0" + hour).slice(-2) + ":" + ("0" + minutes).slice(-2)
    } else {
      // let tDuration = 0
      // data.departing_flight_segments.map(t => {
      //   tDuration = tDuration + t.flight_duration
      // })
      totalDuration =
        Math.floor(data.departing_flight_duration / 60) +
        "h" +
        " " +
        (data.departing_flight_duration % 60) +
        "m"
      let hour = data.departing_flight_segments[0].departure_time.hour
      let minutes = data.departing_flight_segments[0].departure_time.minute
      earlyTime = ("0" + hour).slice(-2) + ":" + ("0" + minutes).slice(-2)
    }
  }
  return { totalDuration, earlyTime }
}
// function time (d){
//   return Math.floor(d / 60) +
//       "h" +
//       " " +
//       (d % 60) +
//       "m"
//   }

export const getFareBreakdown = data => {
  let { adult, child, infant, total } = data
  return {
    adult,
    child,
    infant,
    total: total === null ? 0 : typeof total === "object" ? total.total : total
  }
}
export const headerDetails = (data, query) => {
  let { passengers } = query
  let type
  if (data.type === "oneway") {
    type = "ONE-WAY"
  } else if (data.type === "roundtrip") {
    type = "ROUND-TRIP"
  } else {
    type = "MULTI-CITY"
  }
  let adult_count = passengers.adult_count > 0 ? passengers.adult_count : null,
    child_count = passengers.child_count > 0 ? passengers.child_count : null,
    infant_count = passengers.infant_count > 0 ? passengers.infant_count : null

  let {
    depAirport,
    arrAirport,
    shortDepDate,
    stopInt,
    depTime
  } = getMajorFlightDetails(
    data.trips
      ? data.trips[0].departing_flight_segments
      : data.departing_flight_segments
  )
  let { weight, passenger_type, unit } = baggageAllowance(
    data.baggage_allowance
  )
  let psngr = []

  if (adult_count) {
    if (adult_count >= 1) {
      psngr.push(`${adult_count} ADULTS`)
    } else {
      psngr.push(`${adult_count} ADULT`)
    }
  }
  if (child_count) {
    if (child_count >= 1) {
      psngr.push(`${child_count} CHILDREN`)
    } else {
      psngr.push(`${child_count} CHILD`)
    }
  }
  if (infant_count) {
    if (infant_count >= 1) {
      psngr.push(`${infant_count} INFANTS`)
    } else {
      psngr.push(`${infant_count} INFANT`)
    }
  }

  let testDepDate
  if (data.trips) {
    testDepDate =
      data.trips[0].departing_flight_segments[0].departure_date.year +
      "/" +
      data.trips[0].departing_flight_segments[0].departure_date.month +
      "/" +
      data.trips[0].departing_flight_segments[0].departure_date.day
  } else {
    testDepDate =
      data.departing_flight_segments[0].departure_date.year +
      "/" +
      data.departing_flight_segments[0].departure_date.month +
      "/" +
      data.departing_flight_segments[0].departure_date.day
  }
  var dt = moment(testDepDate, "YYYY-MM-DD HH:mm:ss")
  let depDate = dt.format("ddd") + "," + dt.format("D") + " " + dt.format("MMM")
  let priceItineraryKey = getKeyPriceItinerary(data.price_itinerary)
  let { adult, child, infant, total } = getFareBreakdown(
    data.price_itinerary[priceItineraryKey].fare_breakdown
  )

  let { tripTypeName } = tripTypeNames(data.type)
  let { totalPassengerCount } = totalPassengers({
    adult_count,
    child_count,
    infant_count
  })
  return {
    type,
    depAirport,
    arrAirport,
    shortDepDate,
    ppp: psngr.join(", "),
    depDate,
    stopInt,
    depTime,
    adult,
    child,
    infant,
    total,
    tripTypeName,
    totalPassengerCount
  }
}

export const dobsConstraints = (type, selectedFlight) => {
  let startDate = new Date()
  let endDate = new Date()
  let depDateT
  if (selectedFlight.trips) {
    let { trips } = selectedFlight

    depDateT =
      trips[trips.length - 1].departing_flight_segments[
        trips[trips.length - 1].departing_flight_segments.length - 1
      ].departure_date
  } else {
    let { departing_flight_segments } = selectedFlight
    depDateT =
      departing_flight_segments[departing_flight_segments.length - 1]
        .departure_date
  }

  let depDateTT = depDateT.month + "/" + depDateT.day + "/" + depDateT.year
  let depDate = moment(depDateTT, "MM-DD-YYYY")
  if (type === "infant") {
    endDate = moment(depDate).toDate()
    startDate = moment(depDate)
      .subtract(2, "years")
      .add(1, "days")
      .toDate()
  }
  if (type === "child") {
    endDate = moment(depDate)
      .subtract(2, "years")
      .toDate()
    startDate = moment(depDate)
      .subtract(12, "years")
      .add(1, "days")
      .toDate()
  }

  if (type === "adult") {
    endDate = moment(depDate)
      .subtract(12, "years")
      .toDate()
    startDate = moment("01/01/1900").toDate()
  }
  return { startDate, endDate }
}

export const getFlightDepDate = d => {
  let dobj
  if (d.trips) {
    let { trips } = d
    dobj =
      trips[trips.length - 1].departing_flight_segments[
        trips[trips.length - 1].departing_flight_segments.length - 1
      ].departure_date
  } else {
    let { departing_flight_segments } = d
    dobj =
      departing_flight_segments[departing_flight_segments.length - 1]
        .departure_date
  }
  let dString = `${("0" + dobj.day).slice(-2)}/${("0" + dobj.month).slice(
    -2
  )}//${dobj.year}`
  let mdate = moment(dString, "DD-MM-YYYY").add(1, "days")
  return mdate
}

export const tripTypeNames = type => {
  let tripTypeName = ""
  if (type === "oneway") {
    tripTypeName = "Oneway"
  } else if (type === "roundtrip") {
    tripTypeName = "Round Trip"
  } else {
    tripTypeName = "Multicity"
  }
  return { tripTypeName }
}

export const totalPassengers = data => {
  let { adult_count, child_count, infant_count } = data

  let totalPassengers = []

  if (adult_count) {
    if (adult_count > 1) {
      totalPassengers.push(`${adult_count} Adults`)
    } else {
      totalPassengers.push(`${adult_count} Adult`)
    }
  }
  if (child_count) {
    if (child_count > 1) {
      totalPassengers.push(`${child_count} Children`)
    } else {
      totalPassengers.push(`${child_count} Child`)
    }
  }
  if (infant_count) {
    if (infant_count > 1) {
      totalPassengers.push(`${infant_count} Infants`)
    } else {
      totalPassengers.push(`${infant_count} Infant`)
    }
  }
  return { totalPassengerCount: totalPassengers.join(", ") }
}

export const getPassengersPriceAndTax = (array, types) => {
  if (types === "Adult") {
    array[0].type = array[0].quantity >= 2 ? "Adults" : "Adult"
  }
  if (types === "Adults&Child") {
    array[0].type = array[0].quantity >= 2 ? "Adults" : "Adult"
    array[1].type = array[1].quantity >= 2 ? "Children" : "Child"
  }
  if (types === "Adult&Infant") {
    array[0].type = array[0].quantity >= 2 ? "Adults" : "Adult"
    array[1].type = array[1].quantity >= 2 ? "Infants" : "Infant"
  }
  if (types === "All") {
    array[0].type = array[0].quantity >= 2 ? "Adults" : "Adult"
    array[1].type = array[1].quantity >= 2 ? "Children" : "Child"
    array[2].type = array[2].quantity >= 2 ? "Infants" : "Infant"
  }

  return { totalPassengerData: array }
}

export const getNewFlightDuration = (dtime, rtime) => {
  let DTime = Math.floor(dtime / 60) + "h" + " " + (dtime % 60) + "m"

  let RTime
  if (rtime) {
    RTime = Math.floor(rtime / 60) + "h" + " " + (rtime % 60) + "m"
  }

  return {
    DTime,
    RTime
  }
}

export const getTransitDurationText = data => {
  let { transit_duration, departure_airport } = data
  let transitTime = null,
    transitAirportCode = null
  if (transit_duration) {
    transitTime =
      Math.floor(transit_duration / 60) +
      "h" +
      " " +
      (transit_duration % 60) +
      "m"
    transitAirportCode = departure_airport.code
  }
  return { transitTime, transitAirportCode }
}

export const getTransitDurationTextStopAirports = (
  stop_duration,
  airport_code,
  airportsList
) => {
  let transitAirport = null
  airportsList.map(item => {
    if (item.code === airport_code) {
      transitAirport = item.value
    }
  })
  let transitTimeD = null,
    transitAirportCode = null
  if (stop_duration) {
    transitTimeD =
      Math.floor(stop_duration / 60) + "h" + " " + (stop_duration % 60) + "m"
    transitAirport = `${transitAirport} (${airport_code})`
  }
  return { transitTimeD, transitAirport }
}

export const getCabinFromSearchQuery = data => {
  let keys = qs.parse(data)
  let cabin
  cabinData.map(item => {
    if (item.value === keys.cabin_class) {
      cabin = item.label
    }
  })
  return { cabinFromSearch: cabin }
}

export const getCabinFromQueryDetails = ({ selectQueryDetails }) => {
  let { cabin_class } = selectQueryDetails
  let cabin
  cabinData.map(item => {
    if (item.value === cabin_class) {
      cabin = item.label
    }
  })
  return { cabinFromSearch: cabin }
}
