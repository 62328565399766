import React, { Component } from "react"
import { Modal, ModalBody, ModalFooter } from "reactstrap"
import styled from "styled-components"
import { compose, pure } from "recompose"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import { withRouter } from "react-router-dom"
import { selectors } from "../ducks"
import { modifySelectedFlightData } from "../thunks"
import { priceItinerary } from "filterFunctions"
import * as _ from "underscore"
import { IoMdCheckmarkCircleOutline } from "react-icons/io"

class NewPricesModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedPrice:
        props.newPrices && props.newPrices.prices && props.newPrices.prices[0]
      // values[0]
    }
  }

  handleOk = data => {
    let {
      modifySelectedFlightData,
      newPrices,
      totalPriceApiAgainToggle
    } = this.props
    modifySelectedFlightData({
      selectedPrice: this.state.selectedPrice,
      price_mismatch_data: newPrices
    }).then(noAction => {
      if (this.props.price_mismatch === true) {
        // improvement: calling total price api again
        totalPriceApiAgainToggle()
        this.props.onClose()
      } else {
        this.props.reserveAgain()
        this.props.onClose()
      }
    })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.newPrices !== this.props.newPrices) {
      this.setState({ selectedPrice: this.props.newPrices.prices[0] })
    }
  }

  handleSelectedPrice = selectedPrice => {
    this.setState({ selectedPrice })
  }

  handleToggle = () => {
    let { modifySelectedFlightData, newPrices, onClose } = this.props
    if (this.props.price_mismatch === true) {
      modifySelectedFlightData({
        selectedPrice: this.state.selectedPrice,
        price_mismatch_data: newPrices
      })
    }
    onClose()
  }
  render() {
    let { newPrices, isOpen, onClose, selectedFlightData, history } = this.props
    let { prices } = newPrices || {}
    let { currency } = priceItinerary(selectedFlightData.price_itinerary)
    return (
      <Modal
        isOpen={isOpen}
        toggle={this.handleToggle}
        className={this.props.className}
        backdrop={"static"}
        fade={false}
      >
        <ModalBody>
          <Heading>
            The price you selected previously is no longer available. Please
            select new fare below
          </Heading>
          {prices
            ? prices.length
              ? prices.map((item, i) => (
                  <PricesList
                    data={item}
                    key={i}
                    handleSelectedPrice={this.handleSelectedPrice}
                    currency={currency}
                    selectedPrice={this.state.selectedPrice}
                  />
                ))
              : null
            : null}
          <ModalFooter
            style={{
              borderTop: "unset",
              fontSize: "14px",
              paddingRight: "0",
              paddingBottom: "0px"
            }}
          >
            <Buttons
              onClick={() => {
                onClose()
                history.goBack()
              }}
            >
              CANCEL
            </Buttons>{" "}
            <Buttons style={{ marginLeft: "5px" }} onClick={this.handleOk}>
              OK
            </Buttons>
          </ModalFooter>
        </ModalBody>
      </Modal>
    )
  }
}

const mapState = () =>
  createStructuredSelector({
    selectedFlightData: selectors.selectSelectedFlight()
  })

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      modifySelectedFlightData
    },
    dispatch
  )

export default compose(
  withRouter,
  component =>
    connect(
      mapState,
      mapDispatch
    )(component),
  pure
)(NewPricesModal)
export const PricesList = ({
  data,
  handleSelectedPrice,
  currency,
  selectedPrice
}) => {
  let active = _.isEqual(data, selectedPrice)
  return (
    <PriceListItem onClick={() => handleSelectedPrice(data)}>
      <Cabin>{data.cabin_class}</Cabin>
      <Prize>
        {currency} {data.sum}
      </Prize>
      {active ? (
        <IoMdCheckmarkCircleOutline
          style={{
            color: "#388694",
            fontSize: "27px",
            marginLeft: "7px",
            position: "absolute",
            right: "0px"
          }}
        />
      ) : null}
    </PriceListItem>
  )
}

export const PriceListItem = styled.div`
  border-bottom: 1px solid #cccc;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 8px 15px;
  // margin-top: 5px;
  position: relative;
  cursor: pointer;
  &:hover {
    background: #cccc;
  }
`

const Cabin = styled.div`
  color: #333;
  font-size: 18px;
  text-transform: capitalize;
`
const Prize = styled.div`
  color: #333;
  margin-right: 25px;
`

const Heading = styled.h4`
  font-size: 18px;
  color: #333;
  font-weight: 400;
  margin-bottom: 25px;
`
const Buttons = styled.span`
  color: rgb(56, 134, 148);
  font-weight: 500;
  margin-top: 10px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  width: 75px;
  text-align: center;
  &:hover {
    background: rgb(56, 134, 148);
    color: #fff;
  }
`
