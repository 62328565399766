import styled from "styled-components"
export const BigCard = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 30px 10px;
  margin-top: ${props => (props.noMargin ? "0px" : "10px")};
  display: flex;
  justify-content: space-between;
  border-bottom: ${p =>
    p.transitTimeD === "null" ? "1px dashed #e6e6e6" : "none"};
  padding-left: 1.5rem;
  position: relative;
  padding-right: 4rem;
  &:nth-last-of-type(1) {
    border: none;
  }
  @media only screen and (min-width: 200px) and (max-width: 1024px) {
    width: 100%;
    position: relative;
  }
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    padding-left: 1rem;
    padding-right: 0rem;
    padding-top: 28px;
  }
`
export const MainCard = styled.div`
  background-color: transparent;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: -20px;
  position: relative;
  border: 1px solid #8ccee7;
`
export const CardImg = styled.div`
  // margin-right: 20px;
  text-align: center;
  @media only screen and (min-width: 200px) and (max-width: 1024px) {
    position: absolute;
    left: 11px;
    top: 5px;
  }
  img {
    @media only screen and (min-width: 200px) and (max-width: 1024px) {
      display: none;
    }
  }
  p {
    color: #000;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 0px;
    font-weight: 500;
    width: 80px;
    // margin: 0 auto;
    // text-transform: capitalize;
    line-height: 14px;
    @media only screen and (min-width: 200px) and (max-width: 1024px) {
      margin-top: 0px;
      width: 100%;
      text-aling: left;
    }
    @media only screen and (min-width: 200px) and (max-width: 767px) {
      width: 100%;
    }
  }
`
export const Fromtext = styled.div`
  width: 100%;
  // max-width: 80px;
  text-align: center;
  margin-right: 10px;
  @media only screen and (min-width: 1401px) and (max-width: 2560px) {
    max-width: 100%;
  }
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    margin-right: 0px;
  }
  h4 {
    color: #343434;
    font-weight: 600;
    margin-bottom: 3px;
    font-size: 14px;
    @media only screen and (min-width: 200px) and (max-width: 767px) {
      font-size: 16px;
    }
  }
  h5 {
    font-weight: 400;
    margin-bottom: 0px;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @media only screen and (min-width: 200px) and (max-width: 767px) {
      font-size: 12px;
    }
  }
`
export const DirectFlight = styled.div`
  width: 100%;
  max-width: 200px;
  text-align: center;
  // margin-right: 20px;
  h4 {
    color: #343434;
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 19px;
    @media only screen and (min-width: 200px) and (max-width: 1024px) {
      font-size: 16px;
    }
  }
`
export const Flightdetail = styled.p`
  color: #939297;
  text-transform: uppercase;
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 0px;
  position: absolute;
  right: 20px;
  word-break: break-all;
  width: 65px;
  text-align: center;
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    position: absolute;
    bottom: 20px;
    width: unset;
  }
`

export const AirCraftName = styled.div`
  color: #939297;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 11px;
  cursor: pointer;
  bottom: 5px;
  position: absolute;
  right: 20px;
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    position: absolute;
    bottom: 5px;
    margin-bottom: 0px;
  }
`
export const Detail = styled.div`
  border-bottom: 1px solid #eee;
  padding: 0px 10px;
  padding-top: 10px;
`
export const DetailText = styled.h4`
  color: #2f2f2f;
  text-transform: uppercase;
  font-size: 23px;
  margin-bottom: 0px;
  border-bottom: 2px solid #47b0e5;
  padding-bottom: 6px;
  width: 60px;
`
export const Date = styled.h4`
  color: #2f2f2f;
  font-size: 12px;
  margin-bottom: 0px;
  margin-right: 0px;
  min-width: 80px;
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    font-size: 12px;
  }
`

export const DetailBottomText = styled.p`
  color: #939297;
  font-size: 14px;
  margin-bottom: 0px;
  text-align: right;
  padding-right: 15px;
  margin-top: 10px;
  // padding-top: 2px;
  span {
    color: #00b4e2;
    border-radius: 50%;
    border: 1px solid #00b4e2;
    padding: 2px;
    margin-right: 4px;
  }
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    font-size: 13px;
  }
`
export const CardTopHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  p {
    margin-bottom: 0px;
    margin-top: 20px;
  }
`
