import * as API from "services/API"
import { actions as loginActions } from "./ducks"
// import { push } from "react-router-redux"

export const login = formData => {
  return async dispatch => {
    dispatch(loginActions.loginRequest())
    try {
      const data = await API.login(formData)
      return dispatch(loginActions.loginSuccess({ data }))
    } catch (error) {
      return dispatch(loginActions.loginError())
    }
  }
}

export const register = formData => {
  return async dispatch => {
    dispatch(loginActions.registerRequest())
    try {
      const data = await API.register(formData)
      return dispatch(loginActions.registerSuccess({ data }))
    } catch (error) {
      return dispatch(loginActions.registerError())
    }
  }
}

export const dummySignOut = () => {
  return async dispatch => {
    dispatch(loginActions.logoutRequest())
    // dispatch(push("/"))
  }
}

export const showLoginModal = () => {
  return async dispatch => {
    dispatch(loginActions.showLoginModal())
  }
}

export const showRegisterModal = () => {
  return async dispatch => {
    dispatch(loginActions.showRegisterModal())
  }
}

export const activeLoginModal = () => {
  return async dispatch => {
    dispatch(loginActions.activeLoginModal())
  }
}

export const setPreviousPath = data => {
  return async dispatch => {
    dispatch(loginActions.socialLoginSuccess({ data }))
  }
}

export const disableLoginModal = () => {
  return async dispatch => {
    dispatch(loginActions.disableLoginModal())
  }
}

export const toggleIsLogging = () => {
  return async dispatch => {
    dispatch(loginActions.toggleIsLogging())
  }
}

export const forgetPassword = formData => {
  return async dispatch => {
    dispatch(loginActions.forgetPasswordRequest())
    try {
      const { success, message } = await API.forgetPassword(formData)
      if (success) {
        return dispatch(loginActions.forgetPasswordSuccess({ message }))
      } else {
        return dispatch(loginActions.forgetPasswordError({ message }))
      }
    } catch (error) {
      return dispatch(loginActions.forgetPasswordError())
    }
  }
}

export const SocialLogin = datas => {
  return async dispatch => {
    // dispatch(loginActions.loginSuccess({ data }))
    let data = {
      profile: {
        api_token: datas.api_token,
        email: datas.email,
        id: datas.id,
        name: datas.name,
        phone: datas.phone,
        title: datas.title
      },
      wallet: datas.wallet
    }
    try {
      return dispatch(
        loginActions.loginSuccess({ data: { data: data, success: true } })
      )
    } catch (error) {
      return dispatch(loginActions.loginError())
    }
  }
}

export const editProfile = values => {
  return async dispatch => {
    dispatch(loginActions.updateProfileRequest())
    try {
      const { success, profile, errors, message } = await API.editProfile(
        values
      )

      if (success) {
        return dispatch(loginActions.updateProfileSuccess({ profile }))
      } else {
        return dispatch(loginActions.updateProfileError({ errors, message }))
      }
    } catch (error) {
      return dispatch(loginActions.updateProfileError({}))
    }
  }
}

export const updatePassword = values => {
  return async dispatch => {
    dispatch(loginActions.updatePasswordRequest())
    try {
      const { success, errors, message } = await API.changePassword(values)
      if (success) {
        return dispatch(
          loginActions.updatePasswordSuccess({ success, message })
        )
      } else {
        return dispatch(loginActions.updatePasswordError({ errors, message }))
      }
    } catch (error) {
      return dispatch(loginActions.updatePasswordError({}))
    }
  }
}
