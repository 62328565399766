// import * as _ from "underscore"
import moment from "moment"
import { isEqual, pick } from "underscore"
import store from "../store"
import { getCityName } from "filterFunctions"
import { cabinData } from "Constants"
let state = store.getState()
export const getDateFromObj = departure_date => {
  if (!departure_date) return ""
  let dString =
    departure_date.year + "/" + departure_date.month + "/" + departure_date.day
  let dt = moment(dString, "YYYY-MM-DD HH:mm:ss")
  let depDate =
    dt.format("ddd") +
    ", " +
    dt.format("D") +
    " " +
    dt.format("MMM") +
    " " +
    dt.format("YYYY")
  return depDate
}

export const getDatefromObjShort = departure_date => {
  if (!departure_date) return ""
  let dString =
    departure_date.year + "/" + departure_date.month + "/" + departure_date.day
  let dt = moment(dString, "YYYY-MM-DD HH:mm:ss")
  let depDate =
    dt.format("D") + " " + dt.format("MMM") + ", " + dt.format("YYYY")
  return depDate
}

export const getDateFromMoment = date => {
  if (!date) return ""
  let dt = moment(date, "YYYY-MM-DD HH:mm:ss")
  let momentDate =
    dt.format("ddd") +
    ", " +
    dt.format("D") +
    " " +
    dt.format("MMM") +
    " " +
    dt.format("YYYY")
  return momentDate
}
export const listItemData = (data, selectedTicket) => {
  let {
    search_request,
    trip_type,
    pnr,
    flight_details,
    is_expired,
    is_issued,
    paid
  } = data
  let depDate
  let airportString
  let { departure_date, destination, origin, flights } = search_request
  if (trip_type === "oneway") {
    depDate = getDateFromObj(departure_date)
    airportString = origin + "-" + destination
  } else if (trip_type === "roundtrip") {
    depDate = getDateFromObj(departure_date)
    origin = flight_details.departing_flight_segments[0].departure_airport.code
    destination =
      flight_details.returning_flight_segments[0].departure_airport.code
    airportString = origin + "-" + destination + "-" + origin
  } else {
    // for multicity
    depDate = getDateFromObj(
      flight_details.trips[0].departing_flight_segments[0].departure_date
    )
    let fArr = []
    flights.map(item => {
      fArr.push(`${item.origin}-${item.destination}`)
    })
    airportString = fArr.join(", ")
  }
  let status =
    is_expired === true
      ? "Expired"
      : paid === 0
      ? "Unpaid"
      : paid === 1 && is_issued === 0
      ? "Pending"
      : "Complete"

  let active = isEqual(data, selectedTicket)

  return {
    depDate,
    airportString,
    pnr,
    status,
    active
  }
}

export const getAirlineDetails = item => {
  let { airline } = item
  let airlineString = airline.code + "-" + airline.flight_number
  let airlineName = airline.airline_name
  // AirlinesCodes.map(item => {
  //   if (item.code === airline.code) {
  //     airlineName = item.name
  //   } else {
  //     airlineName = ""
  //   }
  // })
  return { airlineString, airlineName }
}

export const getBriefDetails = data => {
  // let { trip_type, flight_details, tickets, pnr_type } = data
  let depDate,
    arrivaldate,
    depPortCode,
    arrivalPortCode,
    depPortName,
    arrivalPortName,
    depDateFull,
    depTime,
    arrivalTime,
    stopInt,
    duration,
    cabinClass

  // if (type === "oneway") {
  depDate = getDatefromObjShort(data[0].departure_date)
  arrivaldate = getDateFromObj(data[data.length - 1].arrival_date)
  depPortCode = data[0].departure_airport.code
  arrivalPortCode = data[data.length - 1].arrival_airport.code
  depDateFull = getDateFromObj(data[0].departure_date)
  let startTime =
    data[0].departure_time.hour + ":" + data[0].departure_time.minute
  depTime = moment(startTime, "HH:mm").format("HH:mm")

  let endTime =
    data[data.length - 1].arrival_time.hour +
    ":" +
    data[data.length - 1].arrival_time.minute
  arrivalTime = moment(endTime, "H:mm").format("H:mm")

  let s = 0
  data.map(item => {
    if (item.stop_airports.length) {
      s = s + item.stop_airports.length
    }
  })
  stopInt = s + data.length - 1
  let d = 0
  data.map(item => {
    d = d + item.flight_duration
  })

  duration = Math.floor(d / 60) + "h" + " " + (d % 60) + "m"

  let departure = ""
  let arrival = ""
  state.landing.airportList.map(item => {
    if (item.code === depPortCode) {
      departure = item.value
    } else if (item.code === arrivalPortCode) {
      arrival = item.value
    }
  })
  depPortName = getCityName(departure)
  arrivalPortName = getCityName(arrival)
  let { airlineString, airlineName } = getAirlineDetails(data[0])

  return {
    depDate,
    arrivaldate,
    depPortCode,
    arrivalPortCode,
    depPortName: depPortName.city,
    arrivalPortName: arrivalPortName.city,
    depDateFull,
    depTime,
    arrivalTime,
    stopInt,
    duration,
    airlineString,
    airlineName
  }
}
export const selectedTicketData = data => {
  let { updated_at, pnr, is_expired, is_issued, paid } = data
  let bookingdate = getDateFromMoment(updated_at.date)
  let status =
    is_expired === true
      ? "Expired"
      : paid === 0
      ? "Unpaid"
      : paid === 1 && is_issued === 0
      ? "Pending"
      : "Complete"
  return {
    bookingId: pnr,
    bookingdate,
    status
  }
}

export const getPassengerData = data => {
  let {
    flight_details: { baggage_allowance, price_itinerary }
  } = data

  let { fare_breakdown } = pick(
    Object.values(price_itinerary)[0],
    "amount",
    "currency",
    "cabin_code",
    "fare_breakdown"
  )

  let priceObj = {}
  if (fare_breakdown) {
    let { adult, child, infant } = fare_breakdown
    if (adult !== null) {
      priceObj["adult"] = fare_breakdown.adult.price
      priceObj["main-adult"] = fare_breakdown.adult.price
    }
    if (child !== null) {
      priceObj["child"] = fare_breakdown.child.price
    }
    if (infant !== null) {
      priceObj["infant"] = fare_breakdown.infant.price
    }
  }
  let baggageObj = {}
  if (baggage_allowance) {
    let { breakdowns } = baggage_allowance
    if (breakdowns) {
      let { adult, child, infant } = breakdowns
      if (adult && adult.length) {
        baggageObj["adult"] = createBaggageString(adult[0])
        baggageObj["main-adult"] = createBaggageString(adult[0])
      }
      if (child && child.length) {
        baggageObj["child"] = createBaggageString(child[0])
      }
      if (infant && infant.length) {
        baggageObj["infant"] = createBaggageString(infant[0])
      }
    }
  }
  return {
    priceObj,
    baggageObj
  }
}

export const getBaggageObj = ({ data }) => {
  let departBagObj = {},
    returnBagObj = {}
  let {
    flight_details: { baggage_allowance }
  } = data
  if (baggage_allowance) {
    let { breakdowns } = baggage_allowance

    if (breakdowns) {
      let { adult, child, infant } = breakdowns
      if (adult && adult.length === 1) {
        departBagObj["adult"] = createBaggageString(adult[0])
        departBagObj["main-adult"] = createBaggageString(adult[0])
      }
      if (child && child.length === 1) {
        departBagObj["child"] = createBaggageString(child[0])
      }
      if (infant && infant.length === 1) {
        departBagObj["infant"] = createBaggageString(infant[0])
      }
      if (adult && adult.length === 2) {
        departBagObj["adult"] = createBaggageString(adult[0])
        departBagObj["main-adult"] = createBaggageString(adult[0])
        returnBagObj["adult"] = createBaggageString(adult[1])
        returnBagObj["main-adult"] = createBaggageString(adult[1])
      }
      if (child && child.length === 2) {
        departBagObj["child"] = createBaggageString(child[0])
        returnBagObj["child"] = createBaggageString(child[1])
      }
      if (infant && infant.length === 2) {
        departBagObj["infant"] = createBaggageString(infant[0])
        returnBagObj["infant"] = createBaggageString(infant[1])
      }
    }
  }
  return {
    departBagObj,
    returnBagObj
  }
}

export const getBaggageObjMulti = ({ data }) => {
  let {
    flight_details: { baggage_allowance, trips }
  } = data

  let breakdowns = baggage_allowance && baggage_allowance.breakdowns

  let multiBagArr = []
  trips.forEach(item => multiBagArr.push({}))
  if (breakdowns) {
    let { adult, child, infant } = breakdowns
    trips.map((item, index) => {
      if (adult && adult.length) {
        adult.map((aItem, aIndex) => {
          if (index === aIndex) {
            multiBagArr[aIndex]["adult"] = createBaggageString(aItem)
            multiBagArr[aIndex]["main-adult"] = createBaggageString(aItem)
          }
        })
      }
      if (child && child.length) {
        child.map((aItem, aIndex) => {
          if (index === aIndex) {
            multiBagArr[aIndex]["child"] = createBaggageString(aItem)
          }
        })
      }
      if (infant && infant.length) {
        infant.map((aItem, aIndex) => {
          if (index === aIndex) {
            multiBagArr[aIndex]["infant"] = createBaggageString(aItem)
          }
        })
      }
    })
  }
  return {
    multiBagArr
  }
}

export const createBaggageString = item => {
  let { weight, unit, passenger_type, pieces } = item
  let str
  if (unit === "kg") {
    str = `${weight}${unit}`
  } else {
    str = `${pieces} ${unit}`
  }
  return str
}

export const getPriceDetails = data => {
  let { fare_breakdown, amount, currency } = pick(
    Object.values(data.flight_details.price_itinerary)[0],
    "amount",
    "currency",
    "cabin_code",
    "fare_breakdown"
  )
  let { search_request, issuer } = data
  let {
    passengers: { adult_count, child_count, infant_count }
  } = search_request
  let pricesArr = []
  let fareTotal
  if (fare_breakdown) {
    let { adult, child, infant, total } = fare_breakdown
    if (adult_count && adult !== null) {
      pricesArr.push({ type: "Adult", data: adult })
    }
    if (child_count && child !== null) {
      pricesArr.push({ type: "Child", data: child })
    }
    if (infant_count && infant !== null) {
      pricesArr.push({ type: "Infant", data: infant })
    }
    fareTotal = total
  }
  return {
    pricesArr,
    amount,
    currency,
    fareTotal,
    issuerName: issuer.name
  }
}

export const getCabinClassName = cabin_code => {
  let cabinName
  cabinData.map(item => {
    if (item.value === cabin_code) {
      cabinName = item.label
    }
  })
  return cabinName
}

export const getFormatedTime = data => {
  if (!data) return ""
  let testTime = data.hour + ":" + data.minute
  let time = moment(testTime, "H:mm").format("H:mm")
  return time
}

export const flightDetails = ({ data }) => {
  let depDate,
    arrivaldate,
    depPortCode,
    arrivalPortCode,
    depPortName,
    arrivalPortName,
    depDateFull,
    depTime,
    arrivalTime,
    stopInt,
    duration

  // if (type === "oneway") {
  depDate = getDatefromObjShort(data.departure_date)
  arrivaldate = getDateFromObj(data.arrival_date)
  depPortCode = data.departure_airport.code
  arrivalPortCode = data.arrival_airport.code
  depDateFull = getDateFromObj(data.departure_date)
  let startTime = data.departure_time.hour + ":" + data.departure_time.minute
  depTime = moment(startTime, "HH:mm").format("HH:mm")
  let endTime = data.arrival_time.hour + ":" + data.arrival_time.minute
  arrivalTime = moment(endTime, "H:mm").format("H:mm")

  // duration = Math.floor(d / 60) + "h" + " " + (d % 60) + "m"

  let departure = ""
  let arrival = ""
  state.landing.airportList.map(item => {
    if (item.code === depPortCode) {
      departure = item.value
    } else if (item.code === arrivalPortCode) {
      arrival = item.value
    }
  })
  depPortName = getCityName(departure)
  arrivalPortName = getCityName(arrival)
  let { airlineString, airlineName } = getAirlineDetails(data)

  return {
    depDate,
    arrivaldate,
    depPortCode,
    arrivalPortCode,
    depPortName: depPortName.city,
    arrivalPortName: arrivalPortName.city,
    depDateFull,
    depTime,
    arrivalTime,
    stopInt,
    duration: "20hr",
    airlineString,
    airlineName
  }
}
export const getAirportName = code => {
  let a
  state.landing.airportList.map(item => {
    if (item.code === code) {
      a = item.value
    }
  })
  if (!a) return ""
  let indexOfComma = a.indexOf(",")
  let city = indexOfComma > -1 ? a.slice(0, indexOfComma) : a
  return city
}
export const dateTime = data => {
  let s
  let startTime = data.departure_time.hour + ":" + data.departure_time.minute
  s = moment(startTime, "HH:mm").format("HH:mm")
  return s
}
export const getStopAirportsData = (data, airportsList) => {
  let d = []
  if (data.stop_airports.length) {
    data.stop_airports.map(i => {
      d.push({
        depPortCode: data.departure_airport.code,
        arrivalPortCode: i.airport_code,
        depTime: getFormatedTime(data.departure_time),
        arrivalTime: getFormatedTime(i.arrival_time),
        depPortName: getAirportName(data.departure_airport.code),
        arrivalPortName: getAirportName(i.airport_code),
        depDate: getDateFromObj(data.departure_date),
        arrivaldate: getDateFromObj(i.arrival_date)
      })
      d.push({
        depPortCode: i.airport_code,
        depPortName: getAirportName(i.airport_code),
        arrivalPortCode: data.arrival_airport.code,
        arrivalTime: getFormatedTime(data.arrival_time),
        depTime: getFormatedTime(i.departure_time),
        arrivalPortName: getAirportName(data.arrival_airport.code),
        arrivaldate: getDateFromObj(data.arrival_date),
        depDate: getDateFromObj(i.departure_date)
      })
    })
  }
  return d
}

export const getStopAirportsDataImproved = (data, airportsList) => {
  let d = []
  if (data.stop_airports.length) {
    let length = data.stop_airports.length
    data.stop_airports.map((item, i) => {
      if (i === 0) {
        d.push({
          depPortCode: data.departure_airport.code,
          arrivalPortCode: item.airport_code,
          depTime: getFormatedTime(data.departure_time),
          arrivalTime: getFormatedTime(item.arrival_time),
          depPortName: getAirportName(data.departure_airport.code),
          arrivalPortName: getAirportName(item.airport_code),
          depDate: getDateFromObj(data.departure_date),
          arrivaldate: getDateFromObj(item.arrival_date)
        })
        if (length === 1) {
          d.push({
            depPortCode: item.airport_code,
            arrivalPortCode: data.arrival_airport.code,
            depTime: getFormatedTime(item.departure_time),
            arrivalTime: getFormatedTime(data.arrival_time),
            depPortName: getAirportName(item.airport_code),
            arrivalPortName: getAirportName(data.arrival_airport.code),
            depDate: getDateFromObj(item.departure_date),
            arrivaldate: getDateFromObj(data.arrival_date)
          })
        }
      } else if (length > 1 && i < length - 1 && i > 0) {
        d.push({
          depPortCode: data.stop_airports[i - 1].airport_code,
          arrivalPortCode: item.airport_code,
          depTime: getFormatedTime(data.stop_airports[i - 1].departure_time),
          arrivalTime: getFormatedTime(item.arrival_time),
          depPortName: getAirportName(data.stop_airports[i - 1].airport_code),
          arrivalPortName: getAirportName(item.airport_code),
          depDate: getDateFromObj(data.stop_airports[i - 1].departure_date),
          arrivaldate: getDateFromObj(item.arrival_date)
        })
      } else {
        d.push({
          depPortCode: data.stop_airports[i - 1].airport_code,
          arrivalPortCode: item.airport_code,
          depTime: getFormatedTime(data.stop_airports[i - 1].departure_time),
          arrivalTime: getFormatedTime(item.arrival_time),
          depPortName: getAirportName(data.stop_airports[i - 1].airport_code),
          arrivalPortName: getAirportName(item.airport_code),
          depDate: getDateFromObj(data.stop_airports[i - 1].departure_date),
          arrivaldate: getDateFromObj(item.arrival_date)
        })

        if (i === length - 1) {
          d.push({
            depPortCode: item.airport_code,
            arrivalPortCode: data.arrival_airport.code,
            depTime: getFormatedTime(item.departure_time),
            arrivalTime: getFormatedTime(data.arrival_time),
            depPortName: getAirportName(item.airport_code),
            arrivalPortName: getAirportName(data.arrival_airport.code),
            depDate: getDateFromObj(item.departure_date),
            arrivaldate: getDateFromObj(data.arrival_date)
          })
        }
      }
    })
  }
  return d
}

export const getAirlineCode = data => {
  let airlineCode = data.airline.code
  return { airlineCode }
}

export const getTotalFlightDuration = data => {
  let {
    flight_details: { departing_flight_duration, returning_flight_duration }
  } = data
  let dfd =
    Math.floor(departing_flight_duration / 60) +
    "h" +
    " " +
    (departing_flight_duration % 60) +
    "m"
  let rfd =
    Math.floor(returning_flight_duration / 60) +
    "h" +
    " " +
    (returning_flight_duration % 60) +
    "m"
  return {
    dfd,
    rfd
  }
}

export const multiDuration = data => {
  let {
    flight_details: { trips }
  } = data
  let arr = []
  trips.map(i => {
    let a =
      Math.floor(i.departing_flight_duration / 60) +
      "h" +
      " " +
      (i.departing_flight_duration % 60) +
      "m"
    arr.push(a)
  })
  return arr
}

export const TimeConvert24to12 = time => {
  // Check correct time format and split into components
  time = time.split(":")

  if (time.length > 1) {
    time[2] = time[1]
    time[1] = ":"
    time[4] = +time[0] < 12 ? " AM" : " PM" // Set AM/PM
    time[0] = +time[0] % 12 || 12 // Adjust hours
  }
  time[0] = ("0" + time[0]).slice(-2)
  return time.join("") // return adjusted time or original string
}
