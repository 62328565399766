import { FaPlaneDeparture, FaHotel, FaTrash } from "react-icons/fa"
import { GiHandBag } from "react-icons/gi"
import React from "react"

export const LuggageIcon = () => {
  return (
    <span style={{}}>
      <GiHandBag />
    </span>
  )
}

export const FaPlaneDepartureIcon = () => {
  return (
    <span style={{ marginRight: "8px" }}>
      <FaPlaneDeparture style={{ marginTop: "-5px" }} />
    </span>
  )
}

export const FaHotelIcon = () => {
  return (
    <span style={{ marginRight: "8px" }}>
      <FaHotel style={{ marginTop: "-5px" }} />
    </span>
  )
}

export const FaDeleteIcon = props => {
  return (
    <span style={{ marginRight: "8px" }}>
      <FaTrash
        style={{ marginTop: "-5px", color: "#dc3545", cursor: "pointer" }}
        onClick={props.onClick}
      />
    </span>
  )
}
