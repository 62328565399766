var _ = (function() {
  var i = String.fromCharCode
  var o = {
    decompressFromUTF16: function(i) {
      if (i == null) return ""
      if (i == "") return null
      return o._decompress(i.length, 16384, function(o) {
        return i.charCodeAt(o) - 32
      })
    },
    decompress: function(i) {
      if (i == null) return ""
      if (i == "") return null
      return o._decompress(i.length, 32768, function(o) {
        return i.charCodeAt(o)
      })
    },
    _decompress: function(o, n, t) {
      var e = [],
        r,
        s = 4,
        p = 4,
        a = 3,
        l = "",
        u = [],
        h,
        c,
        f,
        d,
        v,
        w,
        m,
        x = { val: t(0), position: n, index: 1 }
      for (h = 0; h < 3; h += 1) {
        e[h] = h
      }
      f = 0
      v = Math.pow(2, 2)
      w = 1
      while (w != v) {
        d = x.val & x.position
        x.position >>= 1
        if (x.position == 0) {
          x.position = n
          x.val = t(x.index++)
        }
        f |= (d > 0 ? 1 : 0) * w
        w <<= 1
      }
      switch ((r = f)) {
        case 0:
          f = 0
          v = Math.pow(2, 8)
          w = 1
          while (w != v) {
            d = x.val & x.position
            x.position >>= 1
            if (x.position == 0) {
              x.position = n
              x.val = t(x.index++)
            }
            f |= (d > 0 ? 1 : 0) * w
            w <<= 1
          }
          m = i(f)
          break
        case 1:
          f = 0
          v = Math.pow(2, 16)
          w = 1
          while (w != v) {
            d = x.val & x.position
            x.position >>= 1
            if (x.position == 0) {
              x.position = n
              x.val = t(x.index++)
            }
            f |= (d > 0 ? 1 : 0) * w
            w <<= 1
          }
          m = i(f)
          break
        case 2:
          return ""
      }
      e[3] = m
      c = m
      u.push(m)
      while (true) {
        if (x.index > o) {
          return ""
        }
        f = 0
        v = Math.pow(2, a)
        w = 1
        while (w != v) {
          d = x.val & x.position
          x.position >>= 1
          if (x.position == 0) {
            x.position = n
            x.val = t(x.index++)
          }
          f |= (d > 0 ? 1 : 0) * w
          w <<= 1
        }
        switch ((m = f)) {
          case 0:
            f = 0
            v = Math.pow(2, 8)
            w = 1
            while (w != v) {
              d = x.val & x.position
              x.position >>= 1
              if (x.position == 0) {
                x.position = n
                x.val = t(x.index++)
              }
              f |= (d > 0 ? 1 : 0) * w
              w <<= 1
            }
            e[p++] = i(f)
            m = p - 1
            s--
            break
          case 1:
            f = 0
            v = Math.pow(2, 16)
            w = 1
            while (w != v) {
              d = x.val & x.position
              x.position >>= 1
              if (x.position == 0) {
                x.position = n
                x.val = t(x.index++)
              }
              f |= (d > 0 ? 1 : 0) * w
              w <<= 1
            }
            e[p++] = i(f)
            m = p - 1
            s--
            break
          case 2:
            return u.join("")
        }
        if (s == 0) {
          s = Math.pow(2, a)
          a++
        }
        if (e[m]) {
          l = e[m]
        } else {
          if (m === p) {
            l = c + c.charAt(0)
          } else {
            return null
          }
        }
        u.push(l)
        e[p++] = c + l.charAt(0)
        s--
        c = l
        if (s == 0) {
          s = Math.pow(2, a)
          a++
        }
      }
    }
  }
  return o
})()
module.exports = _.decompressFromUTF16
