import React from "react"
import BpkProgress from "bpk-component-progress"
import { ProgressBartext, ProgressBars } from "./elements"

const Progress = ({ min, max, progressValue }) => (
  <ProgressBars>
    <ProgressBartext>
      <h4>Select Flight</h4>
      <h4>passanger info</h4>
      <h4>Payment</h4>
      <h4>ticket</h4>
    </ProgressBartext>
    <BpkProgress
      stepped
      min={min}
      max={max}
      value={progressValue}
      aria-label="Checkout"
      getValueText={(value, min, max) => `Step ${value} of ${max}`}
      style={{ marginTop: "10px" }}
    />
  </ProgressBars>
)

export default Progress
