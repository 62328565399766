import React from "react"
import BpkInput, { withOpenEvents } from "bpk-component-input"
import BpkPopover from "bpk-component-popover"

import InputNumberChange from "../InputNumberChange"

const EnhancedInput = withOpenEvents(BpkInput)

class InputPassengersNumber extends React.Component {
  static defaultProps = {
    inputName: "passengers",
    inputValue: "",
    closeButtonText: "Close"
  }

  state = { isOpen: false }

  onOpen = () => {
    if (!this.props.rowIndex) {
      this.setState({ isOpen: true })
    }
  }

  onClose = () => {
    this.setState({ isOpen: false })
  }

  getPassengersQtyText = (...args) => {
    const total = args.reduce((a, b) => a + b)
    return `${total} Passenger${total === 1 ? "" : "s"}`
  }

  getMaxPassengers = () => {
    const { initialValue } = this.props
    let maxPassengers = {
      adults: 6,
      children: 6,
      infants: 6
    }

    // The sum of Adults & Children can't be more than 9
    if (initialValue.adults + initialValue.children >= 9) {
      maxPassengers.adults = initialValue.adults
      maxPassengers.children = initialValue.children
    }

    // The number of Infants can't be more than the number of Adults
    if (initialValue.infants >= initialValue.adults) {
      maxPassengers.infants = initialValue.infants
    }

    return maxPassengers
  }

  render() {
    const {
      inputName,
      closeButtonText,
      loading,
      onChange,
      initialValue,
      rowIndex
    } = this.props

    if (initialValue.adults < initialValue.infants) {
      initialValue.infants = initialValue.adults
    }

    const maxPassengers = this.getMaxPassengers()
    const disabled = rowIndex > 0 || loading
    return (
      <BpkPopover
        portalStyle={{}}
        id="popover"
        target={
          <EnhancedInput
            id="input"
            name={inputName}
            value={this.getPassengersQtyText(
              initialValue.adults,
              initialValue.children,
              initialValue.infants
            )}
            isOpen={this.state.isOpen}
            onOpen={this.onOpen}
            onChange={() => null}
            className="form-input"
            disabled={disabled}
          />
        }
        onClose={this.onClose}
        isOpen={this.state.isOpen}
        label="Popover"
        closeButtonText={closeButtonText}
      >
        <div className="drop__inner">
          <h5>Select passengers</h5>
          <div>
            <InputNumberChange
              rowIndex={rowIndex}
              label="Adults"
              description="12+ years"
              onChange={onChange}
              max={maxPassengers.adults}
              min={1}
              initialValue={initialValue.adults}
            />
            <InputNumberChange
              rowIndex={rowIndex}
              label="Children"
              description="2-12 years"
              onChange={onChange}
              max={maxPassengers.children}
              initialValue={initialValue.children}
            />
            <InputNumberChange
              rowIndex={rowIndex}
              label="Infants"
              description="0-24 months"
              onChange={onChange}
              max={maxPassengers.infants}
              initialValue={initialValue.infants}
            />
          </div>
        </div>
      </BpkPopover>
    )
  }
}

export default InputPassengersNumber
