import styled from "styled-components"
import { FormGroup } from "reactstrap"
export const StyleFormGroup = styled(FormGroup)`
  flex: 1;
  padding: 0px 15px;
  label {
    color: #353535;
    font-size: 15px;
  }
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    padding: 12px 6px;
    border-radius: 0px;
    border: 1px solid #eaeaea;
    &:focus {
      outline: none;
      box-shadow: unset;
    }
  }
  .form-control {
    padding: 12px 6px;
    border-radius: 0px;
    border: 1px solid #eaeaea;
    height: unset !important;
    &:focus {
      outline: none;
      box-shadow: unset;
    }
  }
  .custom-control-label {
    margin-top: 3px;
    color: #545454;
    &:before {
      background: transparent;
      border: 1px solid #787878;
    }
  }

  .test_browser {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url(../images/drop-down-arrow.png);
    background-repeat: no-repeat;
    background-position: right 6px center;
    background-size: 8px;
  }
  &.date-of-birth {
    @media screen and (min-width: 200px) and (max-width: 767px) {
      max-width: 100% !important;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 420px) {
    .country-list {
      width: 210px;
    }
  }
`

export const FlexContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  @media screen and (min-width: 200px) and (max-width: 767px) {
    display: block;
  }
`
export const Divider = styled.div`
  border-bottom: 1px solid #eaeaea;
  margin: 20px 0px;
`
export const Heading = styled.h3`
  color: #323232;
  font-size: 20px;
`
export const FormBtn = styled.button`
  background-color: #ff7b47;
  color: #fff;
  font-weight: 600;
  border: none;
  padding: 11px 60px;
  border-radius: 5px;
`
export const InvalidFeedback = styled.div`
  display: ${props => (props.invalid === true ? "block" : "none")};
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`
export const DepartureDate = styled.div`
  input[type="text"] {
    // border: none;
    // border-radius: 0px;
    // padding: 0px 32px;
    // background-image: url(../images/date.png);
    // background-repeat: no-repeat;
    // background-position: center left 10px;
    // height: 100%;
    min-height: 50px;
    // background-size: 15px;
    // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    // width: 100%;
    font-family: "Roboto", sans-serif !important;
    padding: 12px 6px;
    border-radius: 0px;
    border: 1px solid #eaeaea;
  }
`
