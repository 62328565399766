import React from "react"
import BpkDialog from "bpk-component-dialog"
import styled from "styled-components"
import { IoMdClose } from "react-icons/io"

const ImageAlert = ({ visible, close, image }) => {
  return (
    <div id="dialog-container">
      <BpkDialog
        closeLabel="Close dialog"
        linkColor="yellow"
        id="my-dialog"
        className="my-classname"
        isOpen={visible}
        dismissible={false}
        contentClassName="p-0 rounded"
        onClose={close}
        getApplicationElement={() => document.getElementById("pagewrap")}
        renderTarget={() => document.getElementById("dialog-container")}
      >
        <DialogBox>
          <CloseButton onClick={close}>
            <IoMdClose color="black" size={20} />
          </CloseButton>
          <AlertImg src={image} alt="alert-img" />
        </DialogBox>
      </BpkDialog>
    </div>
  )
}

export default ImageAlert

export const DialogBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const CloseButton = styled.button`
  outline: none;
  text-transform: none;
  background-color: #181c386b;
  border-width: inherit;
  border-radius: 4;
  position: absolute;
  top: -13px;
  right: -15px;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: none;
  }
`
export const DialogTitle = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
`
export const DialogBoxHeader = styled.div`
  width: 100%;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
`
export const AlertImg = styled.img`
  width: 100%;
`
