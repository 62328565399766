// filghts types
export const ONE_WAY = "oneway"
export const ROUND_TRIP = "roundtrip"
export const MULTI_CITY = "multicity"

export const AirlinesCodes = [
  {
    code: "BS",
    name: "US-BANGLA AIRLINES"
  },
  {
    code: "RX",
    name: "REGENT AIRLINES"
  },
  {
    code: "VQ",
    name: "NOVOAIR"
  },
  {
    code: "AI",
    name: "AIR INDIA LTD"
  },
  {
    code: "CX",
    name: "CATHAY PACIFIC AIRWAYS"
  },
  {
    code: "CZ",
    name: "CHINA SOUTHERN AIRLINES"
  },
  {
    code: "EK",
    name: "EMIRATES"
  },
  {
    code: "EY",
    name: "ETIHAD AIRWAYS"
  },
  {
    code: "GF",
    name: "GULF AIR"
  },
  {
    code: "KU",
    name: "KUWAIT AIRWAYS CORP"
  },
  {
    code: "MH",
    name: "MALAYSIA AIRLINES"
  },
  {
    code: "MU",
    name: "CHINA EASTERN AIRLINES"
  },
  {
    code: "OD",
    name: "MALINDO AIR"
  },
  {
    code: "PG",
    name: "BANGKOK AIRWAYS"
  },
  {
    code: "QR",
    name: "QATAR AIRWAYS"
  },
  {
    code: "SL",
    name: "THAI LION"
  },
  {
    code: "SQ",
    name: "SINGAPORE AIRLINES"
  },
  {
    code: "TG",
    name: "THAI AIRWAYS INTL LTD"
  },
  {
    code: "TK",
    name: "TURKISH AIRLINES"
  },
  {
    code: "UL",
    name: "SRILANKAN AIRLINES"
  },
  {
    code: "9W",
    name: "JET AIRWAYS"
  },
  {
    code: "BG",
    name: "BIMAN BANGLADESH AIRLINES"
  }
]

export let dates = [
  {
    type: "day",
    placeholder: "DD"
  },
  {
    type: "month",
    placeholder: "MM"
  },
  {
    type: "year",
    placeholder: "YYYY"
  }
]

export const daysOfWeek = [
  {
    label: "Sunday",
    nameAbbr: "Sun",
    index: 0,
    isWeekend: true
  },
  {
    label: "Monday",
    nameAbbr: "Mon",
    index: 1,
    isWeekend: false
  },
  {
    label: "Tuesday",
    nameAbbr: "Tues",
    index: 2,
    isWeekend: false
  },
  {
    label: "wednesday",
    nameAbbr: "Wed",
    index: 3,
    isWeekend: false
  },
  {
    label: "Thursday",
    nameAbbr: "Thurs",
    index: 4,
    isWeekend: false
  },
  {
    label: "Friday",
    nameAbbr: "Fri",
    index: 5,
    isWeekend: false
  },
  {
    label: "Saturday",
    nameAbbr: "Sat",
    index: 6,
    isWeekend: true
  }
  // ...
]

export const cabinData = [
  { value: "Y", label: "Economy" },
  { value: "S", label: "Premium Economy" },
  { value: "C", label: "Business" },
  { value: "F", label: "First" },
  { value: "N/A", label: "" }
]
export const cabinDataSelection = [
  { value: "Y", label: "Economy" },
  { value: "S", label: "Premium Economy" },
  { value: "C", label: "Business" },
  { value: "F", label: "First" }
]
export const namePrefix = {
  adult: [
    { value: "1", label: "Mr" },
    { value: "2", label: "Mrs" },
    { value: "3", label: "Ms" }
  ],
  child: [{ value: "1", label: "Mstr" }, { value: "2", label: "Miss" }],
  infant: [{ value: "1", label: "Mstr" }, { value: "2", label: "Miss" }]
}

export const PassportCountry = [
  { label: "Afghanistan", value: "AFG" },
  { label: "Albania", value: "ALB" },
  { label: "Algeria", value: "DZA" },
  { label: "American Samoa", value: "ASM" },
  { label: "Andorra", value: "AND" },
  { label: "Angola", value: "AGO" },
  { label: "Anguilla", value: "AIA" },
  { label: "Antarctica", value: "ATA" },
  { label: "Antigua and Barbuda", value: "ATG" },
  { label: "Argentina", value: "ARG" },
  { label: "Armenia", value: "ARM" },
  { label: "Aruba", value: "ABW" },
  { label: "Australia", value: "AUS" },
  { label: "Austria", value: "AUT" },
  { label: "Azerbaijan", value: "AZE" },
  { label: "Bahamas", value: "BHS" },
  { label: "Bahrain", value: "BHR" },
  { label: "Bangladesh", value: "BGD" },
  { label: "Barbados", value: "BRB" },
  { label: "Belarus", value: "BLR" },
  { label: "Belgium", value: "BEL" },
  { label: "Belize", value: "BLZ" },
  { label: "Benin", value: "BEN" },
  { label: "Bermuda", value: "BMU" },
  { label: "Bhutan", value: "BTN" },
  { label: "Bolivia (Plurinational State of)", value: "BOL" },
  { label: "Bonaire, Sint Eustatius and Saba", value: "BES" },
  { label: "Bosnia and Herzegovina", value: "BIH" },
  { label: "Botswana", value: "BWA" },
  { label: "Bouvet Island", value: "BVT" },
  { label: "Brazil", value: "BRA" },
  { label: "British Indian Ocean Territory", value: "IOT" },
  { label: "Brunei Darussalam", value: "BRN" },
  { label: "Bulgaria", value: "BGR" },
  { label: "Burkina Faso", value: "BFA" },
  { label: "Burundi", value: "BDI" },
  { label: "Cabo Verde", value: "CPV" },
  { label: "Cambodia", value: "KHM" },
  { label: "Cameroon", value: "CMR" },
  { label: "Canada", value: "CAN" },
  { label: "Cayman Islands", value: "CYM" },
  { label: "Central African Republic", value: "CAF" },
  { label: "Chad", value: "TCD" },
  { label: "Chile", value: "CHL" },
  { label: "China", value: "CHN" },
  { label: "Christmas Island", value: "CXR" },
  { label: "Cocos (Keeling) Islands", value: "CCK" },
  { label: "Colombia", value: "COL" },
  { label: "Comoros", value: "COM" },
  { label: "Congo (the Democratic Republic of the)", value: "COD" },
  { label: "Congo", value: "COG" },
  { label: "Cook Islands", value: "COK" },
  { label: "Costa Rica", value: "CRI" },
  { label: "Croatia", value: "HRV" },
  { label: "Cuba", value: "CUB" },
  { label: "Curaçao", value: "CUW" },
  { label: "Cyprus", value: "CYP" },
  { label: "Czechia", value: "CZE" },
  { label: "Côte d'Ivoire", value: "CIV" },
  { label: "Denmark", value: "DNK" },
  { label: "Djibouti", value: "DJI" },
  { label: "Dominica", value: "DMA" },
  { label: "Dominican Republic", value: "DOM" },
  { label: "Ecuador", value: "ECU" },
  { label: "Egypt", value: "EGY" },
  { label: "El Salvador", value: "SLV" },
  { label: "Equatorial Guinea", value: "GNQ" },
  { label: "Eritrea", value: "ERI" },
  { label: "Estonia", value: "EST" },
  { label: "Eswatini", value: "SWZ" },
  { label: "Ethiopia", value: "ETH" },
  { label: "Falkland Islands [Malvinas]", value: "FLK" },
  { label: "Faroe Islands", value: "FRO" },
  { label: "Fiji", value: "FJI" },
  { label: "Finland", value: "FIN" },
  { label: "France", value: "FRA" },
  { label: "French Guiana", value: "GUF" },
  { label: "French Polynesia", value: "PYF" },
  { label: "French Southern Territories", value: "ATF" },
  { label: "Gabon", value: "GAB" },
  { label: "Gambia", value: "GMB" },
  { label: "Georgia", value: "GEO" },
  { label: "Germany", value: "DEU" },
  { label: "Ghana", value: "GHA" },
  { label: "Gibraltar", value: "GIB" },
  { label: "Greece", value: "GRC" },
  { label: "Greenland", value: "GRL" },
  { label: "Grenada", value: "GRD" },
  { label: "Guadeloupe", value: "GLP" },
  { label: "Guam", value: "GUM" },
  { label: "Guatemala", value: "GTM" },
  { label: "Guernsey", value: "GGY" },
  { label: "Guinea", value: "GIN" },
  { label: "Guinea-Bissau", value: "GNB" },
  { label: "Guyana", value: "GUY" },
  { label: "Haiti", value: "HTI" },
  { label: "Heard Island and McDonald Islands", value: "HMD" },
  { label: "Holy See", value: "VAT" },
  { label: "Honduras", value: "HND" },
  { label: "Hong Kong", value: "HKG" },
  { label: "Hungary", value: "HUN" },
  { label: "Iceland", value: "ISL" },
  { label: "India", value: "IND" },
  { label: "Indonesia", value: "IDN" },
  { label: "Iran (Islamic Republic of)", value: "IRN" },
  { label: "Iraq", value: "IRQ" },
  { label: "Ireland", value: "IRL" },
  { label: "Isle of Man", value: "IMN" },
  { label: "Israel", value: "ISR" },
  { label: "Italy", value: "ITA" },
  { label: "Jamaica", value: "JAM" },
  { label: "Japan", value: "JPN" },
  { label: "Jersey", value: "JEY" },
  { label: "Jordan", value: "JOR" },
  { label: "Kazakhstan", value: "KAZ" },
  { label: "Kenya", value: "KEN" },
  { label: "Kiribati", value: "KIR" },
  { label: "Korea (the Democratic People's Republic of)", value: "PRK" },
  { label: "Korea (the Republic of)", value: "KOR" },
  { label: "Kuwait", value: "KWT" },
  { label: "Kyrgyzstan", value: "KGZ" },
  { label: "Lao People's Democratic Republic", value: "LAO" },
  { label: "Latvia", value: "LVA" },
  { label: "Lebanon", value: "LBN" },
  { label: "Lesotho", value: "LSO" },
  { label: "Liberia", value: "LBR" },
  { label: "Libya", value: "LBY" },
  { label: "Liechtenstein", value: "LIE" },
  { label: "Lithuania", value: "LTU" },
  { label: "Luxembourg", value: "LUX" },
  { label: "Macao", value: "MAC" },
  { label: "Macedonia (the former Yugoslav Republic of)", value: "MKD" },
  { label: "Madagascar", value: "MDG" },
  { label: "Malawi", value: "MWI" },
  { label: "Malaysia", value: "MYS" },
  { label: "Maldives", value: "MDV" },
  { label: "Mali", value: "MLI" },
  { label: "Malta", value: "MLT" },
  { label: "Marshall Islands", value: "MHL" },
  { label: "Martinique", value: "MTQ" },
  { label: "Mauritania", value: "MRT" },
  { label: "Mauritius", value: "MUS" },
  { label: "Mayotte", value: "MYT" },
  { label: "Mexico", value: "MEX" },
  { label: "Micronesia (Federated States of)", value: "FSM" },
  { label: "Moldova (the Republic of)", value: "MDA" },
  { label: "Monaco", value: "MCO" },
  { label: "Mongolia", value: "MNG" },
  { label: "Montenegro", value: "MNE" },
  { label: "Montserrat", value: "MSR" },
  { label: "Morocco", value: "MAR" },
  { label: "Mozambique", value: "MOZ" },
  { label: "Myanmar", value: "MMR" },
  { label: "Namibia", value: "NAM" },
  { label: "Nauru", value: "NRU" },
  { label: "Nepal", value: "NPL" },
  { label: "Netherlands", value: "NLD" },
  { label: "New Caledonia", value: "NCL" },
  { label: "New Zealand", value: "NZL" },
  { label: "Nicaragua", value: "NIC" },
  { label: "Niger", value: "NER" },
  { label: "Nigeria", value: "NGA" },
  { label: "Niue", value: "NIU" },
  { label: "Norfolk Island", value: "NFK" },
  { label: "Northern Mariana Islands", value: "MNP" },
  { label: "Norway", value: "NOR" },
  { label: "Oman", value: "OMN" },
  { label: "Pakistan", value: "PAK" },
  { label: "Palau", value: "PLW" },
  { label: "Palestine, State of", value: "PSE" },
  { label: "Panama", value: "PAN" },
  { label: "Papua New Guinea", value: "PNG" },
  { label: "Paraguay", value: "PRY" },
  { label: "Peru", value: "PER" },
  { label: "Philippines", value: "PHL" },
  { label: "Pitcairn", value: "PCN" },
  { label: "Poland", value: "POL" },
  { label: "Portugal", value: "PRT" },
  { label: "Puerto Rico", value: "PRI" },
  { label: "Qatar", value: "QAT" },
  { label: "Romania", value: "ROU" },
  { label: "Russian Federation", value: "RUS" },
  { label: "Rwanda", value: "RWA" },
  { label: "Réunion", value: "REU" },
  { label: "Saint Barthélemy", value: "BLM" },
  { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SHN" },
  { label: "Saint Kitts and Nevis", value: "KNA" },
  { label: "Saint Lucia", value: "LCA" },
  { label: "Saint Martin (French part)", value: "MAF" },
  { label: "Saint Pierre and Miquelon", value: "SPM" },
  { label: "Saint Vincent and the Grenadines", value: "VCT" },
  { label: "Samoa", value: "WSM" },
  { label: "San Marino", value: "SMR" },
  { label: "Sao Tome and Principe", value: "STP" },
  { label: "Saudi Arabia", value: "SAU" },
  { label: "Senegal", value: "SEN" },
  { label: "Serbia", value: "SRB" },
  { label: "Seychelles", value: "SYC" },
  { label: "Sierra Leone", value: "SLE" },
  { label: "Singapore", value: "SGP" },
  { label: "Sint Maarten (Dutch part)", value: "SXM" },
  { label: "Slovakia", value: "SVK" },
  { label: "Slovenia", value: "SVN" },
  { label: "Solomon Islands", value: "SLB" },
  { label: "Somalia", value: "SOM" },
  { label: "South Africa", value: "ZAF" },
  { label: "South Georgia and the South Sandwich Islands", value: "SGS" },
  { label: "South Sudan", value: "SSD" },
  { label: "Spain", value: "ESP" },
  { label: "Sri Lanka", value: "LKA" },
  { label: "Sudan", value: "SDN" },
  { label: "Suriname", value: "SUR" },
  { label: "Svalbard and Jan Mayen", value: "SJM" },
  { label: "Sweden", value: "SWE" },
  { label: "Switzerland", value: "CHE" },
  { label: "Syrian Arab Republic", value: "SYR" },
  { label: "Taiwan (Province of China)", value: "TWN" },
  { label: "Tajikistan", value: "TJK" },
  { label: "Tanzania, United Republic of", value: "TZA" },
  { label: "Thailand", value: "THA" },
  { label: "Timor-Leste", value: "TLS" },
  { label: "Togo", value: "TGO" },
  { label: "Tokelau", value: "TKL" },
  { label: "Tonga", value: "TON" },
  { label: "Trinidad and Tobago", value: "TTO" },
  { label: "Tunisia", value: "TUN" },
  { label: "Turkey", value: "TUR" },
  { label: "Turkmenistan", value: "TKM" },
  { label: "Turks and Caicos Islands", value: "TCA" },
  { label: "Tuvalu", value: "TUV" },
  { label: "Uganda", value: "UGA" },
  { label: "Ukraine", value: "UKR" },
  { label: "United Arab Emirates", value: "ARE" },
  { label: "United Kingdom", value: "GBR" },
  { label: "United States Minor Outlying Islands", value: "UMI" },
  { label: "United States of America", value: "USA" },
  { label: "Uruguay", value: "URY" },
  { label: "Uzbekistan", value: "UZB" },
  { label: "Vanuatu", value: "VUT" },
  { label: "Venezuela (Bolivarian Republic of)", value: "VEN" },
  { label: "Viet Nam", value: "VNM" },
  { label: "Virgin Islands (British)", value: "VGB" },
  { label: "Virgin Islands (U.S.)", value: "VIR" },
  { label: "Wallis and Futuna", value: "WLF" },
  { label: "Western Sahara", value: "ESH" },
  { label: "Yemen", value: "YEM" },
  { label: "Zambia", value: "ZMB" },
  { label: "Zimbabwe", value: "ZWE" },
  { label: "Åland Islands", value: "ALA" }
]

export const CountryCodes = [
  { value: "US", label: "United States" },
  { value: "GB", label: "United Kingdom" },
  { value: "CA", label: "Canada" },
  { value: "AF", label: "Afghanistan" },
  { value: "AX", label: "Aland Islands" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarctica" },
  { value: "AG", label: "Antigua And Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AN", label: "Netherlands Antilles" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia And Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet Island" },
  { value: "BR", label: "Brazil" },
  { value: "IO", label: "British Indian Ocean Territory" },
  { value: "BN", label: "Brunei Darussalam" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CA", label: "Canada" },
  { value: "CV", label: "Cape Verde" },
  { value: "KY", label: "Cayman Islands" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CX", label: "Christmas Island" },
  { value: "CC", label: "Cocos (Keeling) Islands" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CG", label: "Congo" },
  { value: "CD", label: "Congo, Democratic Republic" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "Cote D'Ivoire" },
  { value: "HR", label: "Croatia" },
  { value: "CU", label: "Cuba" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands (Malvinas)" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "TF", label: "French Southern Territories" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernsey" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HM", label: "Heard Island & Mcdonald Islands" },
  { value: "VA", label: "Holy See (Vatican City State)" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran, Islamic Republic Of" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IM", label: "Isle Of Man" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KR", label: "Korea" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Lao People's Democratic Republic" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libyan Arab Jamahiriya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macao" },
  { value: "MK", label: "Macedonia" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia, Federated States Of" },
  { value: "MD", label: "Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "AN", label: "Netherlands Antilles" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palestinian Territory, Occupied" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "Reunion" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russian Federation" },
  { value: "RW", label: "Rwanda" },
  { value: "BL", label: "Saint Barthelemy" },
  { value: "SH", label: "Saint Helena" },
  { value: "KN", label: "Saint Kitts And Nevis" },
  { value: "LC", label: "Saint Lucia" },
  { value: "MF", label: "Saint Martin" },
  { value: "PM", label: "Saint Pierre And Miquelon" },
  { value: "VC", label: "Saint Vincent And Grenadines" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "Sao Tome And Principe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "GS", label: "South Georgia And Sandwich Isl." },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard And Jan Mayen" },
  { value: "SZ", label: "Swaziland" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syrian Arab Republic" },
  { value: "TW", label: "Taiwan" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania" },
  { value: "TH", label: "Thailand" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad And Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks And Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States" },
  { value: "UM", label: "United States Outlying Islands" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Viet Nam" },
  { value: "VG", label: "Virgin Islands, British" },
  { value: "VI", label: "Virgin Islands, U.S." },
  { value: "WF", label: "Wallis And Futuna" },
  { value: "EH", label: "Western Sahara" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" }
]

export const CountriesNew = [
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "United States of America", label: "United States of America" },
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Aland Islands", label: "Aland Islands" },
  { value: "Albania", label: "Albania" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "Algeria", label: "Algeria" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Antarctica", label: "Antarctica" },
  { value: "Antigua &amp; Barbuda", label: "Antigua &amp; Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  {
    value: "Bonaire, Sint Eustatius &amp; Saba",
    label: "Bonaire, Sint Eustatius &amp; Saba"
  },
  { value: "Bosnia &amp; Herzegovina", label: "Bosnia &amp; Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Bouvet Island", label: "Bouvet Island" },
  { value: "Brazil", label: "Brazil" },
  {
    value: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory"
  },
  { value: "Brunei Darussalam", label: "Brunei Darussalam" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Cape verde", label: "Cape verde" },
  { value: "Cayman Islands", label: "Cayman Islands" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Christmas Island", label: "Christmas Island" },
  { value: "Cocos (Keeling)  Islands", label: "Cocos (Keeling) Islands" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  {
    value: "Congo Democratic Republic of The",
    label: "Congo Democratic Republic of The"
  },
  { value: "Cook Islands", label: "Cook Islands" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Cote D'Ivoire", label: "Cote D'Ivoire" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Curacao", label: "Curacao" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "East Timor", label: "East Timor" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Falkland Islands", label: "Falkland Islands" },
  { value: "Faroe Islands", label: "Faroe Islands" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "French Guiana", label: "French Guiana" },
  { value: "French Polynesia", label: "French Polynesia" },
  {
    value: "French Southern Territories",
    label: "French Southern Territories"
  },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Greece", label: "Greece" },
  { value: "Greenland", label: "Greenland" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Guam", label: "Guam" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guernsey", label: "Guernsey" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  {
    value: "Heard &amp; McDonald Islands",
    label: "Heard &amp; McDonald Islands"
  },
  { value: "Honduras", label: "Honduras" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Isle of Man", label: "Isle of Man" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jersey", label: "Jersey" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  {
    value: "Korea Democratic People's Republic of",
    label: "Korea Democratic People's Republic of"
  },
  { value: "Korea Republic of", label: "Korea Republic of" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  {
    value: "Lao People's Democratic Republic",
    label: "Lao People's Democratic Republic"
  },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Macau", label: "Macau" },
  { value: "Macedonia", label: "Macedonia" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Martinique", label: "Martinique" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mayotte", label: "Mayotte" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "Netherlands Antilles", label: "Netherlands Antilles" },
  { value: "New Caledonia", label: "New Caledonia" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Niue", label: "Niue" },
  { value: "Norfolk Island", label: "Norfolk Island" },
  { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { value: "Norway", label: "Norway" },
  {
    value: "Occupied Palestinian Territory",
    label: "Occupied Palestinian Territory"
  },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Pitcairn Island", label: "Pitcairn Island" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Qatar", label: "Qatar" },
  { value: "Reunion", label: "Reunion" },
  { value: "Romania", label: "Romania" },
  { value: "Russian Federation", label: "Russian Federation" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saint BarthÃ©lemy", label: "Saint BarthÃ©lemy" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Saint Martin (French)", label: "Saint Martin (French)" },
  {
    value: "Saint Vincent &amp; the Grenadines",
    label: "Saint Vincent &amp; the Grenadines"
  },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome &amp; Principe", label: "Sao Tome &amp; Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Sint Maarten (Dutch)", label: "Sint Maarten (Dutch)" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  {
    value: "South Georgia &amp; Sandwich Islands",
    label: "South Georgia &amp; Sandwich Islands"
  },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain &amp; Canary Islands", label: "Spain &amp; Canary Islands" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "St. Helena", label: "St. Helena" },
  { value: "St. Kitts &amp; Nevis", label: "St. Kitts &amp; Nevis" },
  { value: "St. Pierre &amp; Miquelon", label: "St. Pierre &amp; Miquelon" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  {
    value: "Svalbard &amp; Jan Mayen Island",
    label: "Svalbard &amp; Jan Mayen Island"
  },
  { value: "Swaziland", label: "Swaziland" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tokelau", label: "Tokelau" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad &amp; Tobago", label: "Trinidad &amp; Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Turks &amp; Caicos Islands", label: "Turks &amp; Caicos Islands" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "US Minor Outlying Islands", label: "US Minor Outlying Islands" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Vatican City", label: "Vatican City" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Virgin Islands (British)", label: "Virgin Islands (British)" },
  { value: "Virgin Islands (US)", label: "Virgin Islands (US)" },
  {
    value: "Wallis &amp; Futuna Islands",
    label: "Wallis &amp; Futuna Islands"
  },
  { value: "Western Sahara", label: "Western Sahara" },
  { value: "Western Samoa", label: "Western Samoa" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" }
]

export const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  variableWidth: false,
  centerMode: false,
  autoplay: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 3000,
      settings: {
        centerMode: false,
        slidesToShow: 3
      }
    },
    {
      breakpoint: 1500,
      settings: {
        centerMode: false,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 1200,
      settings: {
        centerMode: false,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        centerMode: false,
        slidesToShow: 1
      }
    }
  ]
}
