import reducer, { selectors, types } from "./ducks"
import Loadable from "base_components/Loadable"
import * as actions from "./thunks"
const pages = {
  Login: Loadable({
    loader: () => import("./LoginModal")
  }),
  Register: Loadable({
    loader: () => import("./RegisterModal")
  })
}
export { pages, reducer, selectors, types, actions }
