import styled from "styled-components"
import {
  BpkAccordion,
  BpkAccordionItem,
  withAccordionItemState
} from "bpk-component-accordion"
import { CustomInput } from "reactstrap"
const StatefulAccordionItem = withAccordionItemState(BpkAccordionItem)

export const SBpkAccordionItem = styled(StatefulAccordionItem)`
  width: 100%;
  padding: 0px 0px;
  margin-bottom: 15px;
  button {
    background-color: #00b4e2;
    color: #fff;
    padding: 9px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: 600;
    svg {
      fill: #fff;
    }
    &:focus {
      outline: none;
    }
  }
`
export const AccordionBody = styled.div`
  background-color: #fff;
  margin-top: -6px;
  padding: 9px 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`
export const CustomInputs = styled(CustomInput)`
  display: flex;
  flex-wrap: wrap;
  padding-left: 35px;
  margin-bottom: 15px;
  .MainText {
    font-weight: 500;
    display: block;
    width: 100%;
    text-transform: capitalize;
    font-size: 15px;
    color: #4f4e60;
  }
  .subtext {
    font-weight: 300;
    display: flex;
    // width: 100%;
    text-transform: capitalize;
    font-size: 14px;
    margin-top: -4px;
  }
  label {
    &:before {
      border-radius: 0 !important;
      background-color: transparent;
      border: 1px solid #c3c3c3;
      margin-right: 20px;
    }
  }
  .only {
  //   // display: ${p => (p.only === "yes" ? "flex" : "none")};
    margin-left: 5px;
    font-size: 14px;
    text-transform: lowercase;
    font-weight: normal;
    display:none;
    cursor: pointer;
    text-decoration: underline;
  }
  &:hover {
    .only {
      display: inline-block;
    }
  }
`
export const RangeText = styled.h4`
  background-color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 16px;
  color: #4f4e60;
  margin-bottom: 0px;
`
export const RangeTime = styled.h4`
  background-color: #fff;
  font-weight: 400;
  // text-transform: capitalize;
  font-size: 14px;
  color: #4f4e60;
  margin-bottom: 5px;
`
