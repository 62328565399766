// import React, { lazy, Suspense } from "react"
// import { InputsLoaders } from "../Loaders"
// const DatePicker = lazy(() => import("./DatePicker"))
// class index extends React.PureComponent {
//   render() {
//     return (
//       <Suspense fallback={InputsLoaders()}>
//         <DatePicker {...this.props} />
//       </Suspense>
//     )
//   }
// }
import DatePicker from "./DatePicker"
export default DatePicker
