import React, { Component, Fragment } from "react"
import {
  BigCard,
  CardImg,
  Fromtext,
  DirectFlight,
  Flightdetail,
  MainCard,
  Detail,
  DetailText,
  Date,
  DetailBottomText,
  AirCraftName
} from "./elements"
import {
  getAirlineName,
  flightTimings,
  getFlightCardData,
  // baggageAllowance,
  // getStopAirportsData,
  getTransitDurationText,
  baggageAllowanceBreakdowns,
  getCabinFromSearchQuery,
  getStopAirportsDataImproved
} from "filterFunctions"
import { ChangePlanesUI } from "base_components/ChangePlanesUI"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { LuggageIcon } from "base_components/Icons"
import features from "features"
import { withRouter } from "react-router-dom"
import { FaTimes } from "react-icons/fa"
import { TimeConvert24to12 } from "shared/ticketFuctions"

class CardDetail extends Component {
  render() {
    let { data, hideDetails, cabinClassArr, airports } = this.props
    let { baggage_allowance, departing_flight_segments } = data

    let { departBagArr } = baggageAllowanceBreakdowns({
      baggage_allowance,
      tripType: "oneway"
    })
    let { cabinFromSearch } = getCabinFromSearchQuery(
      this.props.location.search
    )
    // departing_flight_segments.stop_airports = stop_airports
    return (
      <MainCard>
        <Detail>
          <DetailText>Details</DetailText>
          <FaTimes style={style} onClick={() => hideDetails()} />
        </Detail>
        {departing_flight_segments.map((datat, i) => (
          <BigCardT
            key={i}
            data={datat}
            majorData={data}
            index={i}
            cabinClassArr={cabinClassArr}
            airports={airports}
            cabinFromSearch={cabinFromSearch}
          />
        ))}
        <DetailBottomText title="Baggage allowance">
          <LuggageIcon />
          {departBagArr}
        </DetailBottomText>
      </MainCard>
    )
  }
}

const mapState = () =>
  createStructuredSelector({
    airports: features.landing.selectors.selectAirportList()
  })

export default connect(mapState)(withRouter(CardDetail))
// export default CardDetail

export const BigCardT = ({
  data,
  majorData,
  index,
  cabinClassArr,
  airports,
  cabinFromSearch
}) => {
  let {
    arivalTime,
    depTime,
    duration,
    depDate,
    flightNumber,
    aircraft,
    is_flight_duration_null
  } = flightTimings(data)
  let {
    arivalAirport,
    depAirport,
    stops,
    stopAirports,
    airlineCode,
    cabinNew,
    arrivalCity,
    departCity
  } = getFlightCardData(data, airports)
  let d = getStopAirportsDataImproved(data, airports)
  let { transitTime, transitAirportCode } = getTransitDurationText(data)
  let transitAirportName
  airports.map(item => {
    if (item.code === transitAirportCode) {
      transitAirportName = item.value
    }
  })

  return stops ? (
    <Fragment>
      {d.map((d, i) => {
        let { transitTimeD, transitAirport } = d.stayAirportData
        let transitAirportName
        airports.map(item => {
          if (item.code === d.depAirport) {
            transitAirportName = item.value
          }
        })
        return (
          <div style={{ backgroundColor: "#fff" }} key={i}>
            {transitTimeD !== null || transitAirport !== null  ? (
            <div style={{marginTop:"10px"}}>
              <ChangePlanesUI
                transitAirportName={`${transitAirportName} (${transitAirport})`}
                transitTime={transitTimeD}
              />
            </div>
            ) : null}
            <BigCard noMargin key={i} transitTimeD={transitTimeD}>
              <Date>
                {d.depDate}
                <p style={{ marginTop: "20px", fontSize: "15px" }}>
                  {flightNumber}
                </p>
              </Date>
              <CardImg>
                <img
                  src={`${process.env.REACT_APP_API_URL}v2/flight/airlines/logos/${airlineCode}`}
                  alt="N/A"
                />
                <p>{getAirlineName(data)}</p>
              </CardImg>
              <Fromtext title={`${d.departCity} (${d.depAirport})`}>
                <h4>{TimeConvert24to12(d.depTime)}</h4>
                <h5>
                  {d.departCity} <br /> ({d.depAirport})
                </h5>
              </Fromtext>
              <DirectFlight>
                <img
                  src="images/air.png"
                  style={{ width: "100%" }}
                  alt="plane"
                />
                <h4>{d.duration}</h4>
              </DirectFlight>
              <Fromtext title={`${d.arrivalCity} (${d.arivalAirport})`}>
                <h4>{TimeConvert24to12(d.arrTime)}</h4>
                <h5>
                  {d.arrivalCity} <br /> ({d.arivalAirport})
                </h5>
              </Fromtext>
              <Flightdetail>
                {cabinNew
                  ? cabinNew.replace("_", " ")
                  : cabinFromSearch.replace("_", " ")}
                {/* {getMyCabinClass(index, cabinClassArr)} */}
              </Flightdetail>
              <AirCraftName>
                <img
                  src="images/listicon.png"
                  style={{ width: "15px" }}
                  alt="icon"
                />
                <div style={{ alignItems: "flex-end", marginLeft: "3px" }}>
                  {aircraft}
                </div>
              </AirCraftName>
            </BigCard>
          </div>
        )
      })}
    </Fragment>
  ) : (
    <div style={{ backgroundColor: "#fff" }}>
      {transitTime !== null ? (
        <ChangePlanesUI
          transitAirportName={`${transitAirportName} (${transitAirportCode})`}
          transitTime={transitTime}
        />
      ) : null}
      <BigCard noMargin transitTimeD={transitTime}>
        <Date>
          {depDate}
          <p style={{ marginTop: "20px", fontSize: "15px" }}>{flightNumber}</p>
        </Date>

        <CardImg>
          <img
            src={`${process.env.REACT_APP_API_URL}v2/flight/airlines/logos/${airlineCode}`}
            alt="N/A"
          />
          <p>{getAirlineName(data)}</p>
        </CardImg>
        <Fromtext title={`${departCity} (${depAirport})`}>
          <h4>{TimeConvert24to12(depTime)}</h4>
          <h5>
            {departCity} <br /> ({depAirport})
          </h5>
        </Fromtext>
        <DirectFlight>
          <img src="images/air.png" style={{ width: "100%" }} alt="plane" />
          <h4>{is_flight_duration_null ? null : duration}</h4>
        </DirectFlight>
        <Fromtext title={`${arrivalCity} (${arivalAirport})`}>
          <h4>{TimeConvert24to12(arivalTime)}</h4>
          <h5>
            {arrivalCity} <br /> ({arivalAirport})
          </h5>
        </Fromtext>
        <Flightdetail>
          {cabinNew
            ? cabinNew.replace("_", " ")
            : cabinFromSearch.replace("_", " ")}
          {/* {getMyCabinClass(index, cabinClassArr)} */}
        </Flightdetail>
        <AirCraftName>
          <img src="images/listicon.png" style={{ width: "15px" }} alt="icon" />
          <div style={{ alignItems: "flex-end", marginLeft: "3px" }}>
            {aircraft}
          </div>
        </AirCraftName>
      </BigCard>
    </div>
  )
}

const style = {
  position: "absolute",
  right: "17px",
  top: "17px",
  color: "#fff",
  cursor: "pointer",
  background: "#939297",
  borderRadius: "50%",
  height: "20px",
  width: "20px",
  fontSize: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "2px"
}
