import React, { Fragment } from "react"
import BpkNudger from "bpk-component-nudger"
import styled from "styled-components"
class InputNumberChange extends React.Component {
  static defaultProps = {
    decreaseButtonLabel: "Decrease",
    increaseButtonLabel: "Increase",
    min: 0,
    max: 6,
    label: "Select number"
  }

  state = {
    value: 0
  }

  componentDidMount() {
    if (this.props.initialValue) {
      this.handleChange(this.props.initialValue)
    }
  }

  handleChange = value => {
    const type = this.props.label.toLowerCase()
    this.setState(
      { value },
      this.props.onChange(type, value, this.props.rowIndex)
    )
  }

  render() {
    const {
      decreaseButtonLabel,
      increaseButtonLabel,
      min,
      max,
      label,
      description
    } = this.props
    return (
      <Fragment>
        <label style={{ fontWeight: "bold", marginBottom: "6px" }}>
          {label}
        </label>
        <MyContainer className="flex-inline flex-inline--verticalCenter">
          <BpkNudger
            id="my-nudger"
            min={min}
            max={max}
            value={this.state.value}
            onChange={this.handleChange}
            decreaseButtonLabel={decreaseButtonLabel}
            increaseButtonLabel={increaseButtonLabel}
          />
          <p style={{ marginLeft: "0.5em", marginTop: "5px" }}>{description}</p>
        </MyContainer>
      </Fragment>
    )
  }
}

export default InputNumberChange

export const MyContainer = styled.div`
  display: flex;
  justify-content: unset;
  margin-bottom: 10px;
  > div {
    display: flex;
    flex: 1;
    justify-content: space-between;
    width: 100%;
    max-width: 110px;
    margin-right: 20px;
    button {
      border-radius: 50%;
      height: 40px;
      width: 40px;
      &:focus {
        outline: none;
      }
    }
    input:focus {
      outline: none;
    }
  }
`
