import styled from "styled-components"
import { Navbar, NavItem, NavLink, Nav, NavbarToggler } from "reactstrap"

export const StyleNavbar = styled(Navbar)`
  padding: 0px 3em !important;
  border-bottom: 1px solid #fff;
  z-index: 1;
  @media screen and (min-width: 200px) and (max-width: 991px) {
    justify-content: space-between;
    padding: 0px 2em !important;
  }
  @media screen and (min-width: 992px) and (max-width: 1024px) {
    padding: 0px 1em !important;
  }
  @media screen and (min-width: 371px) and (max-width: 420px) {
    padding: 0px 2em !important;
  }
  @media screen and (min-width: 200px) and (max-width: 370px) {
    padding: 0px 1em !important;
  }
  .Mobileset {
    // @media only screen and (min-width: 600px) and (max-width: 991px) {
    //   display: flex;
    //   flex-direction: row;
    // }
    @media screen and (min-width: 200px) and (max-width: 991px) {
      width: 100%;
      text-align: right;
      flex-direction: row;
      justify-content: space-between;
      height: unset !important;
      margin-bottom: 20px;
      order: 0;

    flex-wrap: wrap;
    }
  }
  .Mobileset1 {
    @media only screen and (min-width: 600px) and (max-width: 991px) {
      display: flex;
      flex-direction: row;
    }
    @media screen and (min-width: 200px) and (max-width: 767px) {
      width: 100%;
      // max-width: 200px;
      text-align: right;
      flex-direction: row;
      justify-content: space-between;
      height: unset !important;
      order: 1;
    }
    }
  }
`

export const MobileRightWrap = styled.div`
  // display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  display: none;
  @media only screen and (max-width: 991px) {
    display: flex;
    margin-top: 6px;
  }
`
export const TabsMobileWrap = styled.div`
  // display: flex;
  align-items: center;
  justify-content: flex-end;
  display: none;
  width: 100%;
  @media only screen and (max-width: 991px) {
    display: flex;
    margin-top: 10px;
  }
  ul {
    justify-content: center !important;
    width: 100% !important;
    height: unset !important;
  }
`
export const MobileNone = styled.div`
  @media only screen and (max-width: 991px) {
    display: none;
  }
`

export const StyleNavbarToggler = styled(NavbarToggler)`
  border: none;
  padding-right: 0px;
  margin-top: -9px;
  &:focus {
    outline: none;
  }
`

export const MobileNav = styled.div`
  width: 100%;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  align-items: center;
  @media screen and (min-width: 200px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`
export const SNavlink = styled(NavLink)`
  color: #fff !important;
  font-size: 20px;
  letter-spacing: 0.1em;
  font-weight: bold;
  margin-right: 20px;

  background-color: ${props =>
    props.active === true ? "#fff" : "transparent"};
  border-top-left-radius: ${props => (props.active ? "7px" : "0px")};
  border-top-right-radius: ${props => (props.active ? "7px" : "0px")};
  padding: 6px 18px !important;
  .fa {
    padding-right: 8px;
  }
  @media screen and (min-width: 1100px) and (max-width: 1400px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 991px) and (max-width: 1160px) {
    display: flex;
    font-size: 16px;
    margin-right: 9px;
  }
  @media screen and (min-width: 200px) and (max-width: 990px) {
    margin-right: 10px;
    font-size: 17px;
  }
  &.active {
    color: #3ab1e4 !important;
    background-color: #fff;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 6px 18px !important;
  }
`
export const SButton = styled.button`
  color: ${props => (props.signin ? "#3ab1e4 !important" : "#fff !important")};
  font-size: 16px;
  letter-spacing: 0.1em;
  font-weight: bold;
  margin-right: 15px;
  cursor: pointer;
  background-color: ${props => (props.signin ? "#fff" : "transparent")};
  border-radius: 2px;
  border: none;
  padding: 6px 18px !important;
  &:focus {
    outline: none;
  }

  @media screen and (min-width: 1100px) and (max-width: 1400px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 991px) and (max-width: 1160px) {
    font-size: 14px;
  }
  // @media screen and (min-width: 451px) and (max-width: 767px) {
  //   margin-right: 0px;
  //   font-size: 14px;
  //   padding: 10px !important;
  // }
  @media screen and (min-width: 200px) and (max-width: 991px) {
    margin-right: 0px;
    font-size: 14px;
    padding: 10px !important;
    display: block;
    width: 100%;
    text-align: center;
    color: #3ab1e4 !important;
    background-color: #fff;
    margin-top: 15px;
  }
`
export const SNavlinkbtn = styled(NavLink)`
  color: ${props => (props.active ? "#3ab1e4 !important" : "#fff !important")};
  font-size: 16px;
  letter-spacing: 0.1em;
  font-weight: bold;
  margin-right: 15px;
  cursor: pointer;
  background-color: ${props => (props.active ? "#fff" : "transparent")};
  border-radius: 2px;
  .fa {
    padding-right: 8px;
  }
  @media screen and (min-width: 1100px) and (max-width: 1400px) {
    font-size: 14px;
  }
  @media screen and (min-width: 451px) and (max-width: 767px) {
    margin-right: 0px;
    font-size: 14px;
    padding: 10px !important;
  }
  @media screen and (min-width: 200px) and (max-width: 450px) {
    margin-right: 0px;
    font-size: 14px;
    padding: 10px !important;
    display: block;
    width: 100%;
    text-align: center;
    color: #3ab1e4 !important;
    background-color: #fff;
    margin-top: 15px;
  }
`
export const StyleNav = styled(Nav)`
  @media screen and (min-width: 200px) and (max-width: 550px) {
    display: block;
  }
`
export const NavItems = styled(NavItem)`
  display: flex;
  align-items: center;
  &.headerLinks {
    @media screen and (min-width: 200px) and (max-width: 767px) {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }
  &.mobileLinks {
    display: none;
    @media screen and (min-width: 200px) and (max-width: 767px) {
      display: block;
    }
  }
  &.webLinks {
    display: flex;
    @media screen and (min-width: 200px) and (max-width: 991px) {
      display: none;
    }
  }
  &#mydiv,
  &#mydiv1 {
    @media screen and (min-width: 551px) and (max-width: 991px) {
      flex: 1;
      padding: 0px 10px;
    }
  }
`

export const MobileSocial = styled.div`
  display: none;
  @media only screen and (max-width: 991px) {
    display: flex;
  }
`
export const MobileSocialButton = styled.a`
  text-align: center;
  margin-right: 20px;
  text-decoration: none !important;
  p {
    font-size: 15px;
    margin: 0px;
    color: #ffffff;
    margin-top: 1px;
  }
`

export const SelectVNavItems = styled(NavItem)`
  margin-right: 10px;
`
export const SocialButton = styled.a`
  background-color: #ffffff;
  width: 143px;
  height: 50px;
  padding: 10px 19px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  box-shadow: 0px 0px 7px -5px #000;
  cursor: pointer;
  text-decoration: none !important;
  @media only screen and (min-width: 991px) and (max-width: 1160px) {
    width: 120px;
    padding: 10px 8px;
    margin-right: 10px;
  }
`

export const SocialIcon = styled.div``
export const SocialText = styled.div`
  margin-left: 8px;
  p {
    margin: 0px;
    font-size: 9px;
    color: #abafb8;
  }
  h4 {
    margin: 0px;
    font-size: 13px;
    color: #000000;
  }
`

//   .currencySelector {
//     background: transparent;
//     color: #fff;
//     padding-left: 9px;
//     border: solid 1px #fff;
//     border-radius: 0px;
//     &:focus {
//       outline: unset;
//     }
//     option {
//       color: #000;
//     }
//   }
// `
