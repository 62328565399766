import ReactSelect from "react-select"
import styled from "styled-components"
import { FormGroup } from "reactstrap"

export const ReactSelectS = styled(ReactSelect)`
  border: none;
  margin-top: ${props => (props.top ? "0px" : "0px")};
  > div:nth-of-type(1) {
    height: 100%;
    min-height: ${props => props.height};
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    background-image: ${props =>
      props.cabin ? "url(../images/cabin.png)" : "url(../images/user.png)"};
    background-repeat: no-repeat;
    background-position: center left 10px;
    padding: 0px 30px;
    font-size: ${props => (props.fontSize ? props.fontSize : "20px")};
    background-size: 15px;
    border-radius: 0px;
    padding-right: 4px;
    border: none;
    @media only screen and (max-width: 767px) {
      font-size: 14px !important;
    }
  }
  .css-dvua67-singleValue {
    color: #524c61;
  }
`

export const SFormGroup = styled(FormGroup)`
  flex: 1;
  border: none;
  margin-bottom: 0px !important;
`
