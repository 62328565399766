import React, { Component } from "react"
import { TabContent, TabPane, NavItem } from "reactstrap"
import classnames from "classnames"
import { StyleNav, TabLinks } from "./elements"
import DetailForm from "../DetailForm"
import { compose, pure } from "recompose"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  passengerWOPassValidate,
  passengerFormValidate,
  otherPassengerValidate,
  otherPassengerWOPassValidate
} from "shared/validations"
import { createStructuredSelector } from "reselect"
import { withRouter } from "react-router-dom"
import {} from "../thunks"
import * as _ from "underscore"
import { selectors } from "../ducks"

class InfoForm extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: "0",
      tabsArr: [],
      totalTabs: [],
      names: [],
      profiles: [],
      callMe: false,
      errors: {}
      // update: true
    }
  }

  componentDidMount = () => {
    let { queryData } = this.props
    let { passengers } = queryData
    let { adult_count, child_count, infant_count } = passengers
    let ArrayOfAdults = []
    for (let i = 0; i < adult_count; i++) {
      ArrayOfAdults.push({
        id: Math.floor(Math.random() * 10000),
        type: "adult"
      })
    }
    let ArrayOfChilds = []
    for (let i = 0; i < child_count; i++) {
      ArrayOfChilds.push({
        id: Math.floor(Math.random() * 10000),
        type: "child"
      })
    }
    let ArrayOfInfants = []
    for (let i = 0; i < infant_count; i++) {
      ArrayOfInfants.push({
        id: Math.floor(Math.random() * 10000),
        type: "infant"
      })
    }
    let concattedArr = ArrayOfAdults.concat(ArrayOfChilds).concat(
      ArrayOfInfants
    )
    this.setState({ totalTabs: concattedArr, profiles: concattedArr })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  nextHandler = tab => {
    // e.preventDefault()
    this.setState({ activeTab: tab })
  }

  getAllFormData = data => {
    let { profiles } = this.state
    let index = profiles.findIndex(item => item.id === data.id)
    profiles[index] = data
    this.setState({ profiles })
  }

  submitMyForm = () => {
    let { profiles } = this.state
    let errorTabs = []
    let valid = false
    profiles.map((item, index) => {
      let test = _.values(item).some(x => x === "")
      if (test) {
        errorTabs.push(Number(item.tab) - 1)
      }
      if (errorTabs.length) {
        this.setState({ activeTab: errorTabs[0].toString() })
      }
    })
  }

  mapErrors = ({ inner }) => {
    let obj = {}
    if (inner.length) {
      for (let i of inner) obj[i.path] = i.message
    }
    return obj
  }

  // toggleUpdate = () => {
  //   this.setState({ update: false })
  // }

  callToFormik = async (values, tab) => {
    let { selectedFlight } = this.props
    let passportRequired = selectedFlight.passport_required
    let validation
    let { totalTabs, profiles } = this.state
    let errorTabs = []
    let errors
    if (values) {
      if (tab == totalTabs.length) {
        profiles.map(async (item, index) => {
          if (index) {
            validation = passportRequired
              ? otherPassengerValidate
              : otherPassengerWOPassValidate
          } else {
            validation = passportRequired
              ? passengerFormValidate
              : passengerWOPassValidate
          }
          if (!passportRequired) {
            delete item.passport_number
            delete item.passport_nationality
            delete item.passport_expiry_date
            delete item.passport_country_of_issue
          }
          try {
            errors = await validation.isValid(item, { abortEarly: false })
          } catch (e) {
            errors = e
          }
          // let errorObj = this.mapErrors(errors)
          if (!errors) {
            // // // errorTabs.push(Number(item.tab) - 1)
            errorTabs.push({ tab: index })
          }
          if (errorTabs.length) {
            this.setState({
              activeTab: errorTabs[0].tab.toString(),
              errors: true,
              callMe: true
            })
          }

          if (index === profiles.length - 1 && errorTabs.length === 0) {
            profiles.map(item => {
              if (item.country_code) {
                if (item.country_code.indexOf("+") === 0) {
                  item.country_code = item.country_code.slice(1)
                }
              }
            })
            this.props.toggleUpdate()
            this.props.reserveFlight(profiles)
            // this.props.handleContinue(profiles)
          }
        })
      } else {
        this.nextHandler(tab)
      }
    }
  }

  render() {
    let { totalTabs, profiles } = this.state
    return (
      <div style={{ margin: "-4px" }}>
        <StyleNav tabs>
          {this.state.totalTabs.map((item, index) => (
            <NavItem key={index}>
              <TabLinks
                key={index}
                className={classnames({
                  active: this.state.activeTab === index.toString()
                })}
                onClick={() => {
                  this.toggle(index.toString())
                }}
              >
                Passenger {index + 1}- {item.type.toUpperCase()}
              </TabLinks>
            </NavItem>
          ))}
        </StyleNav>
        <TabContent
          activeTab={this.state.activeTab}
          style={{ padding: "15px 30px" }}
        >
          {this.state.totalTabs.map((item, index) => (
            <TabPane tabId={index.toString()} key={index}>
              <DetailForm
                id={item.id}
                type={item.type}
                nextHandler={this.nextHandler}
                tab={(index + 1).toString()}
                idx={index}
                getAllFormData={this.getAllFormData}
                tabsLength={totalTabs.length}
                submitMyForm={this.submitMyForm}
                callToFormik={this.callToFormik}
                errors={this.state.errors}
                update={this.props.update}
                toggleUpdate={this.props.toggleUpdate}
                myRef={this.props.myRef}
                scrollMe={this.props.scrollMe}
              />
            </TabPane>
          ))}
        </TabContent>
      </div>
    )
  }
}

const mapState = () =>
  createStructuredSelector({
    queryData: selectors.selectQueryDetails(),
    selectedFlight: selectors.selectSelectedFlight()
  })

const mapDispatch = dispatch => bindActionCreators({}, dispatch)

export default compose(
  withRouter,
  component =>
    connect(
      mapState,
      mapDispatch
    )(component),
  pure
)(InfoForm)
