import React from "react"
import STYLES from "./App.scss"
import { PersistGate } from "redux-persist/integration/react"
import { Provider } from "react-redux"
import store, { persistor } from "./store"
import Routes from "./routes"

const c = className => STYLES[className] || "UNKNOWN"
const App = () => (
  <div className={c("App")}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  </div>
)

export default App
