import React from "react"
import { Formik, Form, Field } from "formik"
import styled from "styled-components"
import { Input } from "reactstrap"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import { emailSchema } from "shared/validations"
import Loading from "base_components/Loading"
const EmailModal = ({
  isOpen,
  onClose,
  className,
  onSubmit,
  isResendingEmail,
  showLoader,
  isForget
}) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} className={className && className}>
      <ModalHeader style={{ borderBottom: "none" }} toggle={onClose}>
        Please enter your email
      </ModalHeader>
      {showLoader && isForget ? (
        <CustomLoading>
          <Loading />
        </CustomLoading>
      ) : null}
      <ModalBody style={{ paddingTop: "0px" }}>
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            email: ""
          }}
          validationSchema={emailSchema}
          render={({ errors, touched }) => (
            <Form>
              <Input
                type="email"
                name="email"
                placeholder="Email"
                tag={Field}
                invalid={errors.email && touched.email}
                style={{ margin: "30px 0px 50px 0px" }}
              />
              <FormButton color={isResendingEmail}>Submit</FormButton>
            </Form>
          )}
        />
      </ModalBody>
    </Modal>
  )
}

export default EmailModal

const FormButton = styled.button`
  width: 100%;
  padding: 7px 10px;
  background-color: ${props => (props.color ? "#eee" : "#f47c48")};
  border: none;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 3px;
`
const CustomLoading = styled.div`
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  // margin: -14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  .orbit-spinner {
    z-index: 9999;
  }
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #000;
    opacity: 0.1;
    z-index: 9999;
    border-radius: 10px;
  }
`
