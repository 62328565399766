import styled from "styled-components"
export const BigCard = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 20px 10px;
  padding-bottom: 10px;
`
export const InnerCard = styled.div`
  width: 100%;
  border-radius: 0px;
  display: flex;
  padding: 20px 8px;
  border-bottom: ${props =>
    props.transitTimeD === "null" ? "1px dashed #e6e6e6" : "none"};

  @media only screen and (min-width: 200px) and (max-width: 1024px) {
    width: 100%;
    position: relative;
    padding-bottom: 70px;
    padding-top: 30px;
  }
`
export const MainCard = styled.div`
  padding: 15px;
`
export const CardImg = styled.div`
  margin-right: 20px;
  text-align: center;
  @media only screen and (min-width: 200px) and (max-width: 1024px) {
    position: absolute;
    left: 11px;
    top: 0px;
  }
  img {
    // width: 100%;
    @media only screen and (min-width: 200px) and (max-width: 1024px) {
      display: none;
    }
  }
  p {
    color: #000;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0px;
    width: 100px;
    line-height: 14px;
    // text-transform: capitalize;
    @media only screen and (min-width: 200px) and (max-width: 1024px) {
      margin-top: 0px;
      position: absolute;
      top: 8px;
      text-align: left;
      width: 200px;
    }
  }
`
export const Fromtext = styled.div`
  width: 100%;
  // max-width: 73px;
  max-width: 86px;
  text-align: center;
  margin-right: 20px;
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    margin-right: 0px;
  }
  h4 {
    color: #343434;
    font-weight: 600;
    margin-bottom: 3px;
    font-size: 20px;
    @media only screen and (min-width: 200px) and (max-width: 767px) {
      font-size: 16px;
    }
  }
  h5 {
    font-weight: 400;
    margin-bottom: 0px;
    font-size: 14px;
    @media only screen and (min-width: 200px) and (max-width: 767px) {
      font-size: 12px;
    }
  }
`
export const DirectFlight = styled.div`
  width: 100%;
  max-width: 200px;
  text-align: center;
  margin-right: 20px;
  h4 {
    color: #343434;
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 20px;
    @media only screen and (min-width: 200px) and (max-width: 1024px) {
      font-size: 16px;
    }
  }
`
export const Flightdetail = styled.div`
  color: #313131;
  font-size: 14px;
  cursor: pointer;
  flex: 1;
  text-align: right;
  line-height: 19px;
  margin-bottom: 0px;
  // text-transform: capitalize;
  span {
    border-radius: 50%;
    border: 1px solid #313131;
    padding: 2px;
    margin: 4px;
  }
  @media only screen and (min-width: 200px) and (max-width: 1024px) {
    position: absolute;
    bottom: -18px;
    right: 14px;
  }
`
export const Detail = styled.div`
  border-bottom: 1px solid #eee;
  padding: 0px 10px;
`
export const DetailText = styled.h4`
  color: #46aee4;
  font-size: 20px;
  margin-bottom: 15px;
`
export const Date = styled.h4`
  color: #2f2f2f;
  font-size: 16px;
  margin-bottom: 0px;
  padding-left: 12px;
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    font-size: 14px;
  }
`
