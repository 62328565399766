import reducer, { selectors, types } from "./ducks"
import * as actions from "./thunks"
import Loadable from "base_components/Loadable"

const pages = {
  Results: Loadable({
    loader: () => import("./SearchResultsPage")
  }),
  Passengers: Loadable({
    loader: () => import("./PassengerDetails")
  })
}
export { pages, reducer, selectors, types, actions }
