import React, { Component } from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import BpkPopover from "bpk-component-popover"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import {
  login,
  SocialLogin,
  showRegisterModal,
  dummySignOut,
  toggleIsLogging
} from "../thunks"
import { selectors, types } from "../ducks"
import query from "querystring"
// import Loading from "base_components/Loading"
class CustomerLogin extends Component {
  state = {
    alert: false,
    message: "",
    previousRoute: {}
  }
  componentDidMount() {
    // this.props.toggleIsLogging()
    let data = query.parse(this.props.location.search)
    const base64String = data["?data"]

    let json = atob(decodeURIComponent(base64String).replace(/#.*/g, ""))
    json = JSON.parse(json)

    this.props.SocialLogin(json).then(action => {
      if (action.type === types.LOGIN_SUCCESS) {
        if (action.payload.success) {
          const { history } = this.props
          if (
            this.props.dataS.search === "" &&
            this.props.dataS.pathname === "/"
          ) {
            history.push("/")
            // this.props.onClose()
          } else {
            history.push(
              `${this.props.dataS.pathname}${this.props.dataS.search}`
            )
          }
        } else {
          this.setState(
            { alert: true, message: action.payload.message },
            () => {
              setTimeout(() => {
                this.setState({ alert: false })
              }, 3000)
            }
          )
          // this.props.onClose()
          this.props.logout()
        }
      } else {
        // this.setState(
        //   { alert: true, message: "Login failed. Try again!" },
        //   () => {
        //     setTimeout(() => {
        //       this.setState({ alert: false })
        //     }, 3000)
        //   }
        // )
        // this.props.onClose()
        // this.props.logout()
      }
    })
  }
  // closeAlert = () => {
  //   this.setState({ alert: false })
  // }
  render() {
    // let { emailModal } = this.state

    return <div>klkl</div>
  }
}

const mapToState = () =>
  createStructuredSelector({
    isLogging: selectors.selectIsLogging(),
    loggedUser: selectors.selectLoggedUser(),
    dataS: selectors.selectIsSocial()
  })

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      login,
      SocialLogin,
      logout: dummySignOut,
      showRegisterModal,
      toggleIsLogging
    },
    dispatch
  )

export default connect(
  mapToState,
  mapDispatch
)(withRouter(CustomerLogin))
// export default LoginPopover

const BpkPopovers = styled(BpkPopover)`
  border-radius: 0px !important;
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    width: 380px !important;
  }
  footer {
    padding: 0px;
    text-align: right;
    box-shadow: unset;
    display: none;
  }
`
