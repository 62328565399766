import React, { lazy, Suspense } from "react"
const Login = lazy(() =>
  import(/* webpackChunkName: "LoginModal" */ "../../Login/LoginModal")
)
class index extends React.PureComponent {
  render() {
    return (
      <Suspense fallback={<p />}>
        <Login {...this.props} />
      </Suspense>
    )
  }
}

export default index
