import styled from "styled-components"

export const ContactContainer = styled.div`
  background-color: white;
`

export const UserContactForm = styled.div`
  width: 85vw;
  margin: auto;
  margin-top: 20px;
`
export const CenteredDiv = styled.div`
  margin: auto;
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);

  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
