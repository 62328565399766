// import React from "react"
// import { InputsLoaders } from "../Loaders"
// const CabinClass = React.lazy(() => import("./CabinClass"))

// class index extends React.PureComponent {
//   render() {
//     return (
//       <React.Suspense fallback={InputsLoaders()}>
//         <CabinClass {...this.props} />
//       </React.Suspense>
//     )
//   }
// }
import CabinClass from "./CabinClass"
export default CabinClass
