import { fetchService } from "./API"

export const reserveFlight = query => {
  if (query.return_flight === null) {
    delete query.return_flight
  }
  // query.flight.price_itinerary.economy.amount = 1234;
  // query.flight.price_itinerary.economy.departing = 1234;
  // query.flight.price_itinerary.economy.returning = 1234

  // query.flight.price_itinerary.economy.fare_breakdown.adult.price = 1234;
  // query.flight.price_itinerary.economy.fare_breakdown.total = 1234;

  // query.return_flight.price_itinerary.economy.amount = 2750
  // query.return_flight.price_itinerary.economy.fare_breakdown.adult.price = 2750;
  // query.return_flight.price_itinerary.economy.fare_breakdown.total = 2750;

  let profileData = query.profiles
  let currentUserEmail = localStorage.getItem("currentUserEmail")
  // let storageData = JSON.parse(localStorage.getItem(currentUserEmail))
  // let passangers = {
  //   adult: [],
  //   child: [],
  //   infant: []
  // }

  profileData.forEach((data, i) => {
    let storageData = JSON.parse(localStorage.getItem(currentUserEmail))
    if (data.type === "adult") {
      i = 0
      if (storageData === null) {
        let user = {
          id: i,
          user: data
        }
        let passangers = {
          adult: [user],
          child: [],
          infant: []
        }
        localStorage.setItem(currentUserEmail, JSON.stringify(passangers))
      } else {
        let id
        if (storageData.adult.length > 0) {
          id = storageData.adult.slice(-1)[0].id + 1
        } else {
          id = storageData.adult.length
        }
        let user = {
          id: id,
          user: data
        }
        if (storageData.adult.length === 0) {
          let passangers = {
            adult: [user],
            child: storageData.child,
            infant: storageData.infant
          }
          localStorage.setItem(currentUserEmail, JSON.stringify(passangers))
        } else {
          if (
            JSON.stringify(storageData.adult).includes(JSON.stringify(data))
          ) {
            let passangers = {
              adult: [...storageData.adult],
              child: storageData.child,
              infant: storageData.infant
            }
            localStorage.setItem(currentUserEmail, JSON.stringify(passangers))
          } else {
            if (storageData.adult.length >= 20) {
              storageData.adult.shift()
            }
            let passangers = {
              adult: [...storageData.adult, user],
              child: storageData.child,
              infant: storageData.infant
            }
            localStorage.setItem(currentUserEmail, JSON.stringify(passangers))
          }
        }
      }
    } else if (data.type === "child") {
      i = 0
      if (storageData === null) {
        let user = {
          id: i,
          user: data
        }
        let passangers = {
          adult: storageData.adult,
          child: [user],
          infant: storageData.infant
        }
        localStorage.setItem(currentUserEmail, JSON.stringify(passangers))
      } else {
        let id
        if (storageData.child.length > 0) {
          id = storageData.child.slice(-1)[0].id + 1
        } else {
          id = storageData.child.length
        }
        let user = {
          id: id,
          user: data
        }

        if (storageData.child.length === 0) {
          let passangers = {
            adult: storageData.adult,
            child: [user],
            infant: storageData.infant
          }
          localStorage.setItem(currentUserEmail, JSON.stringify(passangers))
        } else {
          if (
            JSON.stringify(storageData.child).includes(JSON.stringify(data))
          ) {
            let passangers = {
              adult: storageData.adult,
              child: [...storageData.child],
              infant: storageData.infant
            }
            localStorage.setItem(currentUserEmail, JSON.stringify(passangers))
          } else {
            if (storageData.child.length >= 5) {
              storageData.child.shift()
            }
            let passangers = {
              adult: storageData.adult,
              child: [...storageData.child, user],
              infant: storageData.infant
            }
            localStorage.setItem(currentUserEmail, JSON.stringify(passangers))
          }
        }
      }
    } else if (data.type === "infant") {
      i = 0
      if (storageData === null) {
        let user = {
          id: i,
          user: data
        }
        let passangers = {
          adult: storageData.adult,
          child: storageData.child,
          infant: [user]
        }
        localStorage.setItem(currentUserEmail, JSON.stringify(passangers))
      } else {
        let id
        if (storageData.infant.length > 0) {
          id = storageData.infant.slice(-1)[0].id + 1
        } else {
          id = storageData.infant.length
        }
        let user = {
          id: id,
          user: data
        }

        if (storageData.infant.length === 0) {
          let passangers = {
            adult: storageData.adult,
            child: storageData.child,
            infant: [user]
          }
          localStorage.setItem(currentUserEmail, JSON.stringify(passangers))
        } else {
          if (
            JSON.stringify(storageData.infant).includes(JSON.stringify(data))
          ) {
            let passangers = {
              adult: storageData.adult,
              child: storageData.child,
              infant: [...storageData.infant]
            }
            localStorage.setItem(currentUserEmail, JSON.stringify(passangers))
          } else {
            if (storageData.infant.length >= 5) {
              storageData.infant.shift()
            }
            let passangers = {
              adult: storageData.adult,
              child: storageData.child,
              infant: [...storageData.infant, user]
            }
            localStorage.setItem(currentUserEmail, JSON.stringify(passangers))
          }
        }
      }
    }
  })
  // localStorage.setItem(currentUserEmail, JSON.stringify(passangers))
  console.log(query)
  return fetchService({ method: "POST", url: "v2/flight/reserve", body: query })
}

export const checkBookingStatus = ({ api_token, identifier }) => {
  return fetchService({
    method: "POST",
    url: "v2/flight/checkbookingstatus",
    body: { api_token, identifier }
  })
}

export const getSingleReservation = ({ pnr, api_token }) => {
  return fetchService({
    method: "POST",
    url: `v2/flight/bookings/${pnr}`,
    body: { api_token }
  })
}
