import React, { Fragment } from "react"
import {
  BigCard,
  CardImg,
  Fromtext,
  DirectFlight,
  Flightdetail,
  MainCard,
  Detail,
  DetailText,
  Date,
  InnerCard
} from "./elements"
import { connect } from "react-redux"
import {
  getAirlineName,
  flightTimings,
  getStopAirportsData,
  priceItinerary,
  cabinClassArrayMulti,
  getFlightCardDataMulti,
  getTransitDurationText,
  baggageAllowanceBreakdowns,
  multicityBaggageAllowance,
  getCabinFromQueryDetails,
  getStopAirportsDataImproved
} from "filterFunctions"
import features from "features"
import { ChangePlanesUI } from "base_components/ChangePlanesUI"
import { createStructuredSelector } from "reselect"
import { LuggageIcon } from "base_components/Icons"
import { TimeConvert24to12 } from "shared/ticketFuctions"

class DetailCard extends React.Component {
  state = {
    array: []
  }
  componentDidMount = () => {
    let { details } = this.props
    let { cabin_code } = priceItinerary(details.price_itinerary)
    let cabinClassArr = cabinClassArrayMulti(cabin_code)
    let myArr = []
    if (details.trips) {
      details.trips.map(items => {
        myArr.push(items.departing_flight_segments)
      })

      myArr.map(i => {
        i.map(item => {
          item.cabin = "Test"
        })
      })
      let newArr = [].concat.apply([], myArr)

      newArr.map((items, indexes) => {
        cabinClassArr &&
          cabinClassArr.map((item, index) => {
            if (indexes === index) {
              items.cabin = item
            }
          })
      })
      this.setState({ array: newArr })
    } else if (details.type === "roundtrip") {
      let array = []
      let departing_arr = details.departing_flight_segments
      let returning_arr = details.returning_flight_segments
      array.push(departing_arr, returning_arr)

      let newArr = [].concat.apply([], array)
      newArr.map((items, indexes) => {
        cabinClassArr &&
          cabinClassArr.map((item, index) => {
            if (indexes === index) {
              items.cabin = item
            }
          })
      })
      this.setState({ array: newArr })
    } else {
      let newArr = details.departing_flight_segments
      newArr.map((items, indexes) => {
        cabinClassArr &&
          cabinClassArr.map((item, index) => {
            if (indexes === index) {
              items.cabin = item
            }
          })
      })
      this.setState({ array: newArr })
    }
  }

  render() {
    let {
      details,
      tripType,
      splitView,
      split_departing_flight,
      airports,
      selectQueryDetails
    } = this.props
    let { amount, currency, cabin_code } = priceItinerary(
      details.price_itinerary
    )
    // debugger
    let cabinClassArr = cabinClassArrayMulti(cabin_code)
    let cabinClassArrSplit
    if (splitView === true) {
      let { cabin_code: cabin_code_split } = priceItinerary(
        split_departing_flight.price_itinerary
      )
      // debugger
      cabinClassArrSplit = cabinClassArrayMulti(cabin_code_split)
    }

    let { cabinFromSearch } = getCabinFromQueryDetails({ selectQueryDetails })
    if (tripType === "oneway") {
      return (
        <OnewayDetails
          data={details}
          cabinClassArr={cabinClassArr}
          airports={airports}
          cabinFromSearch={cabinFromSearch}
        />
      )
    } else if (tripType === "roundtrip") {
      if (splitView === undefined) {
        return (
          <RoundripDetails
            data={details}
            cabinClassArr={cabinClassArr}
            airports={airports}
            cabinFromSearch={cabinFromSearch}
          />
        )
      } else {
        return (
          <RoundripDetailsSplit
            data={details}
            cabinClassArr={cabinClassArr}
            cabinClassArrSplit={cabinClassArrSplit}
            split_departing_flight={split_departing_flight}
            airports={airports}
            cabinFromSearch={cabinFromSearch}
          />
        )
      }
    } else {
      return (
        <MulticityDetails
          data={details}
          cabinClassArr={cabinClassArr}
          airports={airports}
          cabinFromSearch={cabinFromSearch}
        />
      )
    }
  }
}
const mapState = () =>
  createStructuredSelector({
    details: features.searchResult.selectors.selectSelectedFlightB(),
    selectQueryDetails: features.searchResult.selectors.selectQueryDetails(),
    splitView: features.searchResult.selectors.selectSplitView(),
    split_departing_flight: features.searchResult.selectors.selectDeparting_flight(),
    airports: features.landing.selectors.selectAirportList()
  })

export default connect(mapState)(DetailCard)
export const OnewayDetails = ({
  data,
  cabinClassArr,
  airports,
  cabinFromSearch
}) => {
  let { baggage_allowance } = data
  let { departBagArr } = baggageAllowanceBreakdowns({
    baggage_allowance,
    tripType: "oneway"
  })
  return (
    <MainCard id="details_card_passenger">
      <Detail>
        <DetailText>Departing flight</DetailText>
      </Detail>
      {data.departing_flight_segments.map((datat, i) => {
        return (
          <BigCardT
            key={i}
            data={datat}
            majorData={data}
            index={i}
            cabinClassArr={cabinClassArr}
            airports={airports}
            baggage={departBagArr}
            cabinFromSearch={cabinFromSearch}
          />
        )
      })}
    </MainCard>
  )
}

export const RoundripDetails = ({
  data,
  cabinClassArr,
  airports,
  cabinFromSearch
}) => {
  let { baggage_allowance } = data
  let { departBagArr, returnBagArr } = baggageAllowanceBreakdowns({
    baggage_allowance,
    tripType: "oneway"
  })
  return (
    <MainCard>
      <Detail>
        <DetailText>Departing flight</DetailText>
      </Detail>
      {data.departing_flight_segments.map((datat, i) => {
        return (
          <BigCardT
            data={datat}
            majorData={data}
            index={i}
            cabinClassArr={cabinClassArr}
            airports={airports}
            baggage={departBagArr}
            cabinFromSearch={cabinFromSearch}
          />
        )
      })}
      <Detail style={{ paddingTop: "15px" }}>
        <DetailText>Returning flight</DetailText>
      </Detail>
      {data.returning_flight_segments.map((datat, i) => {
        return (
          <BigCardT
            data={datat}
            majorData={data}
            index={i}
            cabinClassArr={cabinClassArr}
            airports={airports}
            baggage={returnBagArr}
            cabinFromSearch={cabinFromSearch}
          />
        )
      })}
    </MainCard>
  )
}

export const RoundripDetailsSplit = ({
  split_departing_flight,
  data,
  cabinClassArr,
  cabinClassArrSplit,
  airports,
  cabinFromSearch
}) => {
  let { baggage_allowance } = data
  let { departBagArr } = baggageAllowanceBreakdowns({
    baggage_allowance
  })

  let { baggage_allowance: baggage_allowanceS } = split_departing_flight
  let { departBagArr: departBagArrS } = baggageAllowanceBreakdowns({
    baggage_allowance: baggage_allowanceS
  })
  return (
    <MainCard>
      <Detail>
        <DetailText>Departing flight</DetailText>
      </Detail>
      {split_departing_flight.departing_flight_segments.map((datat, i) => {
        return (
          <BigCardT
            data={datat}
            majorData={split_departing_flight}
            index={i}
            cabinClassArr={cabinClassArrSplit}
            airports={airports}
            baggage={departBagArrS}
            cabinFromSearch={cabinFromSearch}
          />
        )
      })}
      <Detail>
        <DetailText>Returning flight</DetailText>
      </Detail>
      {data.departing_flight_segments.map((datat, i) => {
        return (
          <BigCardT
            data={datat}
            majorData={data}
            index={i}
            cabinClassArr={cabinClassArr}
            airports={airports}
            baggage={departBagArr}
            cabinFromSearch={cabinFromSearch}
          />
        )
      })}
    </MainCard>
  )
}

export const MulticityDetails = ({
  data,
  cabinClassArr,
  airports,
  cabinFromSearch
}) => {
  let { baggage_allowance, trips } = data
  let { multiBagArr } = multicityBaggageAllowance({
    baggage_allowance,
    trips
  })
  return (
    <MainCard>
      {data.trips.map((datat, i) => (
        <BigCardMulti
          data={datat}
          majorData={data}
          tripIndex={i}
          key={i}
          cabinClassArr={cabinClassArr}
          airports={airports}
          baggage={multiBagArr[i]}
          cabinFromSearch={cabinFromSearch}
        />
      ))}
    </MainCard>
  )
}

export const BigCardT = ({
  data,
  majorData,
  index,
  cabinClassArr,
  airports,
  baggage,
  cabinFromSearch
}) => {
  let {
    arivalTime,
    depTime,
    duration,
    depDate,
    is_flight_duration_null
  } = flightTimings(data)
  let {
    arivalAirport,
    depAirport,
    stops,
    stopAirports,
    cabin,
    airlineCode,
    cabinNew,
    arrivalCity,
    departCity
  } = getFlightCardDataMulti(data, airports)
  let d = getStopAirportsDataImproved(data, airports)
  // let baggage = baggageAllowance(majorData.baggage_allowance)
  // const { weight, unit, passenger_type } = baggage
  let { transitTime, transitAirportCode } = getTransitDurationText(data)
  let transitAirportName
  airports.map(item => {
    if (item.code === transitAirportCode) {
      transitAirportName = item.value
    }
  })
  return stops ? (
    <div style={{ borderRadius: "3px", background: "#fff" }}>
      {d.map((item, i) => {
        let { transitTimeD, transitAirport } = item.stayAirportData
        return (
          <Fragment>
            {transitTimeD !== null ? (
              <ChangePlanesUI
                transitAirportName={transitAirport}
                transitTime={transitTimeD}
              />
            ) : null}
            <BigCard>
              <Date>{item.depDate}</Date>
              <InnerCard className={transitTimeD} transitTimeD={transitTimeD}>
                <CardImg>
                  <img
                    src={`${process.env.REACT_APP_API_URL}v2/flight/airlines/logos/${airlineCode}`}
                    alt="N/A"
                  />
                  <p>{getAirlineName(data)}</p>
                </CardImg>
                <Fromtext>
                  <h4>{TimeConvert24to12(item.depTime)}</h4>
                  <h5>
                    {item.departCity} ({item.depAirport})
                  </h5>
                </Fromtext>
                <DirectFlight>
                  <img
                    src="images/air.png"
                    style={{ width: "100%" }}
                    alt="plane"
                  />
                  {/* <h4>{item.duration}</h4> */}
                </DirectFlight>
                <Fromtext>
                  <h4>{TimeConvert24to12(item.arrTime)}</h4>
                  <h5>
                    {item.arrivalCity} ({item.arivalAirport})
                  </h5>
                </Fromtext>
                <Flightdetail>
                  {cabinNew === "" || cabinNew === "N/A"
                    ? cabinFromSearch
                    : cabinNew}
                  <br />
                  Aircraft:{" "}
                  {data.aircraft ? data.aircraft.toLowerCase() : "N/A"}
                  <br />
                  <p title="Baggage allowance">
                    <LuggageIcon />
                    {baggage}
                  </p>
                </Flightdetail>
              </InnerCard>
            </BigCard>
          </Fragment>
        )
      })}
    </div>
  ) : (
    <div style={{ background: "#fff" }}>
      {transitTime !== null ? (
        <ChangePlanesUI
          transitAirportName={`${transitAirportName} (${transitAirportCode})`}
          transitTime={transitTime}
        />
      ) : null}
      <BigCard>
        <Date>{depDate}</Date>
        <InnerCard transitTimeD={transitTime}>
          <CardImg>
            <img
              src={`${process.env.REACT_APP_API_URL}v2/flight/airlines/logos/${airlineCode}`}
              alt="N/A"
            />
            <p>{getAirlineName(data)}</p>
          </CardImg>
          <Fromtext>
            <h4>{TimeConvert24to12(depTime)}</h4>
            <h5>
              {departCity} <br />({depAirport})
            </h5>
          </Fromtext>
          <DirectFlight>
            <img src="images/air.png" style={{ width: "100%" }} alt="plane" />
            <h4>{is_flight_duration_null ? null : duration}</h4>
          </DirectFlight>
          <Fromtext>
            <h4>{TimeConvert24to12(arivalTime)}</h4>
            <h5>
              {arrivalCity} <br /> ({arivalAirport})
            </h5>
          </Fromtext>
          <Flightdetail>
            {cabinNew === "" || cabinNew === "N/A" ? cabinFromSearch : cabinNew}
            <br />
            Aircraft: {data.aircraft ? data.aircraft : "N/A"}
            <br />
            <p title="Baggage allowance">
              <LuggageIcon />
              {baggage}
            </p>
          </Flightdetail>
        </InnerCard>
      </BigCard>
    </div>
  )
}

export const BigCardMulti = ({
  data,
  cabinClassArr,
  tripIndex,
  majorData,
  airports,
  baggage,
  cabinFromSearch
}) => {
  return (
    <Fragment>
      <Detail>
        <DetailText> Flight {tripIndex + 1}</DetailText>
      </Detail>
      {data.departing_flight_segments.map((dt, i) => (
        <BigCardT
          key={i}
          data={dt}
          majorData={majorData}
          index={i}
          cabinClassArr={cabinClassArr}
          airports={airports}
          baggage={baggage}
          cabinFromSearch={cabinFromSearch}
        />
      ))}
    </Fragment>
  )
}
