import styled from "styled-components"

export const FullContainer = styled.div`
  //   background-image: url(../images/comingSoon.png);
  //   height: 100vh;
  //   background-size: 100% 100%;
  //   background-position: bottom;
  //   @media only screen and (max-width: 767px) {
  //     background-attachment: fixed;
  //     position: fixed;
  //     overflow: auto;
  //     background-size: 102% 100%;
  //     width: 100%;
  //     background-size: cover;
  //     background-position: right bottom;
  //     padding-bottom: 3rem;
  //   }
  //
`
