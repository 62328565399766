import React from "react"
import { FullContainer } from "./elements"
import Header from "scenes/Header/Header"
import { FullBack } from "../../Landing/LandingPage/elements"
class CommingSoon extends React.Component {
  render() {
    return (
      <FullBack hotel={"2"}>
        <Header />
        <FullContainer></FullContainer>
      </FullBack>
    )
  }
}

export default CommingSoon
