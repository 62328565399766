import React, { Component } from "react"
import { Collapse, NavbarToggler, Nav } from "reactstrap"
import { withRouter } from "react-router-dom"
import features from "features"
import { compose, pure } from "recompose"
import {
  StyleNavbar,
  // Navlogo,
  SNavlink,
  NavItems,
  MobileNav,
  StyleNav,
  SButton,
  SocialButton,
  SocialIcon,
  SocialText,
  MobileSocial,
  MobileSocialButton,
  MobileRightWrap,
  StyleNavbarToggler,
  TabsMobileWrap,
  MobileNone
} from "../elements"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
// const Login = lazy(() => import("../../Login/LoginModal"))
// const Register = lazy(() => import("../../Login/RegisterModal"))
import Login from "../Login"
import Register from "../Register"
import Logo from "base_components/TripovyLogo"
// import CurrencySelector from "base_components/CurrencySelector"
import { FaPlaneDepartureIcon, FaHotelIcon } from "base_components/Icons"
import EmailModal from "../../Tickets/TicketsTab/EmailModal"
import Alert from "base_components/BannerAlert"
import classnames from "classnames"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      currency: "",
      popOverLogin: false,
      popOverRegister: false,
      emailModal: false,
      alert: false,
      message: "",
      activeTab: "1"
    }
  }
  componentDidMount = () => {
    const {
      activeLoginModal,
      disableLoginModal,
      location: { search, pathname },
      user
    } = this.props
    if (search.includes("?redirect=") && pathname === "/" && user === null) {
      activeLoginModal()
    } else {
      disableLoginModal()
    }
  }
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  toggleEmailModal = () => {
    // debugger
    this.setState({ emailModal: false })
  }

  displayLogin = () => {
    this.props.showLoginModal()
  }

  displayRegister = () => {
    this.props.showRegisterModal()
  }

  dummySignOut = () => {
    this.props.dummySignOut()
    if (this.props.location.pathname === "/home") {
      this.props.history.push("/")
    }
  }
  onClickForgotPassword = () => {
    this.setState({ emailModal: !this.state.emailModal })
    this.props.showLoginModal()
  }

  callForgetPassword = email => {
    this.props.forgetPassword(email).then(action => {
      if (action.type === "tripovy/FORGET_PASSWORD_SUCCESS") {
        this.setState(
          {
            emailModal: false,
            alert: true,
            message: action.payload.message
          },
          () => {
            setTimeout(() => {
              this.setState({ alert: false })
            }, 3000)
          }
        )
      } else {
        this.setState(
          { alert: true, message: action.payload.message, emailModal: false },
          () => {
            setTimeout(() => {
              this.setState({ alert: false })
            }, 3000)
          }
        )
      }
    })
  }

  closeAlert = () => {
    this.setState({ alert: false })
  }
  toggleTab = tab => {
    this.setState({ activeTab: tab })
    this.props.toggle(tab)
  }

  render() {
    let {
      user,
      loginState,
      registerState,
      isForget,
      location: { pathname }
    } = this.props
    return (
      <StyleNavbar light expand="lg" className="nav-main">
        <Alert
          visible={this.state.alert}
          close={this.closeAlert}
          message={this.state.message}
        />
        <Logo history={this.props.history} />

        <NavItems
          // className="headerLinks"
          className="mobileLinks"
        >
          {/* <a href="https://itunes.apple.com/ae/app/tripovy/id1437289545">
            <img
              src="images/app-store-icon-2.png"
              alt=""
              className="paly-store"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.tripovy.app">
            <img
              src="images/google-play-store-icon-2.png"
              alt=""
              style={{ marginLeft: "20px" }}
              className="paly-store"
            />
          </a> */}
        </NavItems>
        <MobileRightWrap>
          <MobileSocial>
            <MobileSocialButton href="https://play.google.com/store/apps/details?id=com.tripovy.app">
              <img src="/images/mobile-playstore.png" alt="" />
              <p>Android</p>
            </MobileSocialButton>
            <MobileSocialButton href="https://itunes.apple.com/ae/app/tripovy/id1437289545">
              <img src="/images/apple-icon-mobile.png" alt="" />
              <p>IOS</p>
            </MobileSocialButton>
          </MobileSocial>
          <StyleNavbarToggler onClick={this.toggle}>
            <img src="/images/toggle-icon.png" alt="" />
          </StyleNavbarToggler>
        </MobileRightWrap>

        <Collapse isOpen={this.state.isOpen} navbar className="Responsive">
          <MobileNav>
            <MobileNone>
              <Nav style={navHeight} className="flex_end Mobileset1" navbar tab>
                <NavItems>
                  <SNavlink
                    href="#"
                    className={classnames({
                      active: pathname === "/hotels" ? false : true
                    })}
                    onClick={() => {
                      // this.toggleTab("1")
                      if (pathname === "/hotels") {
                        this.props.history.goBack()
                      }
                    }}
                  >
                    <FaPlaneDepartureIcon />
                    Flight
                  </SNavlink>
                </NavItems>
                <NavItems>
                  <SNavlink
                    href="#"
                    className={classnames({
                      active: pathname === "/hotels" ? true : false
                    })}
                    onClick={() => {
                      // this.toggleTab("2")
                      if (pathname !== "/hotels") {
                        this.props.history.push("/hotels")
                      }
                    }}
                  >
                    <FaHotelIcon />
                    Hotel
                  </SNavlink>
                </NavItems>
              </Nav>
            </MobileNone>
            <StyleNav className="ml-auto Mobileset" navbar>
              {/* <SelectVNavItems>
                <CurrencySelector />
              </SelectVNavItems> */}
              <NavItems
                // className="headerLinks"
                className="webLinks"
              >
                {/* <a href="https://itunes.apple.com/ae/app/tripovy/id1437289545">
                  <img
                    src="images/app-store2.png"
                    alt=""
                    className="paly-store"
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.tripovy.app">
                  <img
                    src="images/gplay2.png"
                    alt=""
                    style={{ marginLeft: "20px" }}
                    className="paly-store"
                  />
                </a> */}
                <SocialButton href="https://play.google.com/store/apps/details?id=com.tripovy.app">
                  <SocialIcon>
                    <img src="/images/play-store-icon.png" alt="" />
                  </SocialIcon>
                  <SocialText>
                    <p>ANDROID APP ON</p>
                    <h4>Google Play</h4>
                  </SocialText>
                </SocialButton>
                <SocialButton href="https://itunes.apple.com/ae/app/tripovy/id1437289545">
                  <SocialIcon>
                    <img src="/images/apple-icon.png" alt="" />
                  </SocialIcon>
                  <SocialText>
                    <p>IOS APP ON</p>
                    <h4>App Store</h4>
                  </SocialText>
                </SocialButton>
              </NavItems>
              <NavItems id="mydiv">
                {user !== null ? (
                  <SButton onClick={this.dummySignOut}>SIGN OUT</SButton>
                ) : (
                  <SButton onClick={this.displayLogin}>SIGN IN</SButton>
                )}
                <Login
                  onClose={this.displayLogin}
                  isOpen={loginState}
                  forgotPassword={this.onClickForgotPassword}
                />
              </NavItems>
              <NavItems id="mydiv1">
                {user === null ? (
                  <SButton signin onClick={this.displayRegister}>
                    CREATE ACCOUNT
                  </SButton>
                ) : (
                  <SButton
                    signin
                    onClick={() => this.props.history.push("/home")}
                  >
                    MY DASHBOARD
                  </SButton>
                )}

                <Register
                  onClose={this.displayRegister}
                  isOpen={registerState}
                />
              </NavItems>
            </StyleNav>
          </MobileNav>
        </Collapse>
        <TabsMobileWrap>
          <Nav style={navHeight} className="flex_end Mobileset1" navbar tab>
            <NavItems>
              <SNavlink
                href="#"
                className={classnames({
                  active: pathname === "/hotels" ? false : true
                })}
                onClick={() => {
                  // this.toggleTab("1")
                  if (pathname === "/hotels") {
                    this.props.history.goBack()
                  }
                }}
              >
                <FaPlaneDepartureIcon />
                Flight
              </SNavlink>
            </NavItems>
            <NavItems>
              <SNavlink
                href="#"
                className={classnames({
                  active: pathname === "/hotels" ? true : false
                })}
                onClick={() => {
                  // this.toggleTab("2")
                  if (pathname !== "/hotels") {
                    this.props.history.push("/hotels")
                  }
                }}
              >
                <FaHotelIcon />
                Hotel
              </SNavlink>
            </NavItems>
          </Nav>
        </TabsMobileWrap>
        <EmailModal
          showLoader={true}
          isForget={isForget}
          onSubmit={this.callForgetPassword}
          isOpen={this.state.emailModal}
          onClose={this.toggleEmailModal}
        />
      </StyleNavbar>
    )
  }
}

const mapToState = () =>
  createStructuredSelector({
    user: features.login.selectors.selectLoggedUser(),
    loginState: features.login.selectors.selectLoginState(),
    registerState: features.login.selectors.selectRegisterState(),
    isForget: features.login.selectors.selectIsForget()
  })

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      dummySignOut: features.login.actions.dummySignOut,
      showLoginModal: features.login.actions.showLoginModal,
      showRegisterModal: features.login.actions.showRegisterModal,
      activeLoginModal: features.login.actions.activeLoginModal,
      disableLoginModal: features.login.actions.disableLoginModal,
      forgetPassword: features.login.actions.forgetPassword
    },
    dispatch
  )

export default compose(
  withRouter,
  component =>
    connect(
      mapToState,
      mapDispatch
    )(component),
  pure
)(Header)

const navHeight = {
  height: "88px",
  WebkitAlignItems: "flex-end",
  alignItems: "flex-end"
}
