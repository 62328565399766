import React, { Component, useState, Fragment } from "react"
import {
  Label,
  Input,
  Button,
  FormFeedback,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
// import DatePickers from "react-datepicker"
import {
  StyleFormGroup,
  FlexContainer,
  Divider,
  Heading,
  FormBtn,
  InvalidFeedback,
  DepartureDate
} from "./elements"
import DatePicker from "base_components/DatePicker"
import {
  // PassportCountry,
  namePrefix,
  CountriesNew,
  CountryCodes,
  dates
} from "Constants"
import ReactPhoneInput from "react-phone-input-2"
import { Field, Form, Formik } from "formik"
import { passengerInfoFormSchema } from "shared/validations"
import {
  dobsConstraints,
  getFlightDepDate,
  getDateString
} from "filterFunctions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { compose, pure } from "recompose"
import { createStructuredSelector } from "reselect"
import { withRouter } from "react-router-dom"
import { selectors } from "../ducks"
import features from "features"
import moment from "moment"
import { FaDeleteIcon } from "base_components/Icons"
import { cloneDeep } from "lodash"
import { isThisSecond } from "date-fns"
// import ManualDatePicker from "base_components/ManualDatePicker"
// import "./phoneInput.css"
const re = /^[0-9\b]+$/
const getInitialValues = (id, type, passportExpDateString, tab) => {
  if (type === "adult") {
    if (tab == 1) {
      return {
        tab: tab,
        type: type,
        id: id,
        title: "Mr",
        first_name: "",
        last_name: "",
        birth_date: "",
        email: "",
        country_code: "+880",
        phone: "",
        passport_number: "",
        passport_country_of_issue: "BD",
        passport_nationality: "BD",
        country: "Bangladesh",
        passport_expiry_date: passportExpDateString
      }
    } else {
      return {
        tab: tab,
        type: type,
        id: id,
        title: "Mr",
        first_name: "",
        last_name: "",
        birth_date: "",
        passport_number: "",
        passport_country_of_issue: "BD",
        passport_nationality: "BD",
        country: "Bangladesh",
        passport_expiry_date: passportExpDateString
      }
    }
  } else {
    return {
      tab: tab,
      type: type,
      id: id,
      title: "Mstr",
      first_name: "",
      last_name: "",
      birth_date: "",
      passport_number: "",
      passport_country_of_issue: "BD",
      passport_nationality: "BD",
      country: "Bangladesh",
      passport_expiry_date: passportExpDateString
    }
  }
}

const SavedUser = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState)
  const [selectedUser, setSelectedUser] = useState("Select")
  const handleSelect = item => {
    setSelectedUser(item.user.first_name + " " + item.user.last_name)
    props.getCurrentUser(item.id, item.user.type, props.setFieldValue)
  }
  let currentUsersList = props.currentUsers
  let currentUserEmail = localStorage.getItem("currentUserEmail")
  let storageData = JSON.parse(localStorage.getItem(currentUserEmail))

  if (props.currentUsers !== storageData[props.type]) {
    currentUsersList = storageData[props.type]
    if (props.type === "adult" && currentUsersList.length >= 20) {
      let length = currentUsersList.length
      currentUsersList.splice(0, length - 20)
      const passengerDetail = {
        adult: [...currentUsersList],
        child: [...storageData.child],
        infant: [...storageData.infant]
      }

      localStorage.setItem(currentUserEmail, JSON.stringify(passengerDetail))
    }
    if (props.type === "child" && currentUsersList.length >= 5) {
      let length = currentUsersList.length
      currentUsersList.splice(0, length - 5)
      const passengerDetail = {
        adult: [...storageData.adult],
        child: [...currentUsersList],
        infant: [...storageData.infant]
      }

      localStorage.setItem(currentUserEmail, JSON.stringify(passengerDetail))
    }
    if (props.type === "infant" && currentUsersList.length >= 5) {
      let length = currentUsersList.length
      currentUsersList.splice(0, length - 5)
      const passengerDetail = {
        adult: [...storageData.adult],
        child: [...storageData.child],
        infant: [...currentUsersList]
      }
      localStorage.setItem(currentUserEmail, JSON.stringify(passengerDetail))
    }
  }
  return currentUsersList.length > 0 ? (
    <FlexContainer>
      <StyleFormGroup style={{ width: "100%", maxWidth: "50%" }}>
        <Label for="user ">
          <b>Select From Previously Saved Users</b>
        </Label>

        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            className="test_browser w-100"
            style={{
              backgroundColor: "transparent",
              color: "black",
              height: "50px",
              outline: "none",
              borderRadius: "0px"
            }}
          >
            <span className="float-left">{selectedUser}</span>
          </DropdownToggle>
          <DropdownMenu
            className="w-100 "
            style={{ color: "black", borderRadius: "0px" }}
          >
            {currentUsersList.map((item, index) => (
              <div className="d-flex">
                <DropdownItem
                  key={index}
                  onClick={e => handleSelect(item)}
                  style={{ borderRadius: "0px" }}
                >
                  {item.user.first_name + " " + item.user.last_name}
                </DropdownItem>
                <div className="d-flex align-items-center justify-content-center">
                  <FaDeleteIcon
                    className="d-inline btn btn-danger m-2"
                    onClick={e => props.deleteCurrentUser(item)}
                  />
                </div>
              </div>
            ))}
          </DropdownMenu>
        </Dropdown>
      </StyleFormGroup>
    </FlexContainer>
  ) : (
    ""
  )
}
class DetailForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      country_code: "880",
      autoFocus: false,
      update: true,
      dateString: "",
      passportExpiryDate: "",
      currentUsers: [],
      passport_nationality: "BD",
      title: "",
      passport_country_of_issue: "BD",
      country: "Bangladesh"
    }
    this.formikRef = this.props.myRef
    this.formikRef[props.tab] = React.createRef()
  }

  componentDidUpdate() {
    if (this.props.update) {
      this.formikRef[this.props.tab].current.submitForm()
      this.props.toggleUpdate()
    }
  }

  componentDidMount() {
    document.body.scrollTop = 0
    this.props.scrollMe()
    let currentUserEmail = localStorage.getItem("currentUserEmail")
    let storageData = JSON.parse(localStorage.getItem(currentUserEmail))
    if (storageData !== null) {
      this.setState({
        currentUsers: storageData
      })
    }
  }
  handleOnChange = (value, setFieldValue) => {
    this.setState({
      country_code:value
    })
    setFieldValue("country_code", value)
  }
  getFormvalues = values => {
    let { nextHandler, tab, tabsLength, submitMyForm } = this.props
    if (values) {
      if (tab == tabsLength) {
        submitMyForm()
      } else {
        nextHandler(tab)
      }
    }
  }

  getTitle = (e, key, setFieldValue) => {
    if (typeof e === "string") {
      if (key === "passport_nationality") {
        this.setState({
          passport_nationality: e
        })
        setFieldValue(key, e)
      }
      if (key === "title") {
        this.setState({
          title: e
        })
        setFieldValue(key, e)
      }
      if (key === "passport_country_of_issue") {
        this.setState({
          passport_country_of_issue: e
        })
        setFieldValue(key, e)
      }
      if (key === "country") {
        this.setState({
          country: e
        })
        setFieldValue(key, e)
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value
      })
      setFieldValue(key, e.target.value)
    }
  }
  deleteCurrentUser = item => {
    let type = item.user.type
    let id = item.id
    let currentUserEmail = localStorage.getItem("currentUserEmail")
    const usersList = cloneDeep(
      JSON.parse(localStorage.getItem(currentUserEmail))
    )

    usersList[type] = usersList[type].filter(item => item.id !== id)
    this.setState(
      {
        currentUsers: usersList
      },
      () => {
        localStorage.setItem(
          currentUserEmail,
          JSON.stringify(this.state.currentUsers)
        )
      }
    )
  }
  getCurrentUser = (e, type, setFieldValue) => {
    let id = e
    if (type === "adult") {
      let user = this.state.currentUsers.adult.filter(
        data => data.id === parseInt(id)
      )
      setFieldValue("first_name", user[0].user.first_name)
      setFieldValue("last_name", user[0].user.last_name)
      setFieldValue("email", user[0].user.email)
      setFieldValue("phone", user[0].user.phone)
      setFieldValue("title", user[0].user.title)
      setFieldValue("country_code", user[0].user.country_code)
      setFieldValue("country", user[0].user.country)
      this.getTitle(user[0].user.title, "title", setFieldValue)
      this.getTitle(user[0].user.country, "country", setFieldValue)

      if (
        user[0].user.passport_country_of_issue &&
        user[0].user.passport_expiry_date &&
        user[0].user.passport_nationality &&
        user[0].user.passport_number
      ) {
        setFieldValue("passport_number", user[0].user.passport_number)
        this.getTitle(
          user[0].user.passport_nationality,
          "passport_nationality",
          setFieldValue
        )
        this.getTitle(
          user[0].user.passport_country_of_issue,
          "passport_country_of_issue",
          setFieldValue
        )
        const date = moment(
          user[0].user.passport_expiry_date,
          "DD/MM/YYYY"
        ).format("MM/DD/YYYY")
        this.getDOB("passport_expiry_date", new Date(date), setFieldValue)
        this.setState({
          passportExpiryDate: user[0].user.passport_expiry_date
        })
      } else {
        setFieldValue("passport_number", "")
        this.setState({
          passportExpiryDate: "",
          passport_nationality: "BD",
          passport_country_of_issue: "BD"
        })
      }
      if (user[0].user.birth_date !== "") {
        const date = moment(user[0].user.birth_date, "DD/MM/YYYY").format(
          "MM/DD/YYYY"
        )
        this.getDOB("birth_date", new Date(date), setFieldValue)
      } else {
        this.setState({
          dateString: ""
        })
      }
      this.deleteCurrentUser(user[0])
    }
    if (type === "child") {
      let user = this.state.currentUsers.child.filter(
        data => data.id === parseInt(id)
      )

      setFieldValue("first_name", user[0].user.first_name)
      setFieldValue("last_name", user[0].user.last_name)
      setFieldValue("title", user[0].user.title)
      setFieldValue("country", user[0].user.country)

      this.getTitle(user[0].user.country, "country", setFieldValue)

      this.getTitle(user[0].user.title, "title", setFieldValue)
      const date = moment(user[0].user.birth_date, "DD/MM/YYYY").format(
        "MM/DD/YYYY"
      )
      this.getDOB("birth_date", new Date(date), setFieldValue)

      if (
        user[0].user.passport_country_of_issue &&
        user[0].user.passport_expiry_date &&
        user[0].user.passport_nationality &&
        user[0].user.passport_number
      ) {
        setFieldValue("passport_number", user[0].user.passport_number)
        this.getTitle(
          user[0].user.passport_nationality,
          "passport_nationality",
          setFieldValue
        )
        this.getTitle(
          user[0].user.passport_country_of_issue,
          "passport_country_of_issue",
          setFieldValue
        )
        const date = moment(
          user[0].user.passport_expiry_date,
          "DD/MM/YYYY"
        ).format("MM/DD/YYYY")
        this.getDOB("passport_expiry_date", new Date(date), setFieldValue)

        this.setState({
          passportExpiryDate: user[0].user.passport_expiry_date
        })
      } else {
        setFieldValue("passport_number", "")
        this.setState({
          passportExpiryDate: "",
          passport_nationality: "BD",
          passport_country_of_issue: "BD"
        })
      }
      this.deleteCurrentUser(user[0])
    }
    if (type === "infant") {
      let user = this.state.currentUsers.infant.filter(
        data => data.id === parseInt(id)
      )

      setFieldValue("first_name", user[0].user.first_name)
      setFieldValue("last_name", user[0].user.last_name)
      setFieldValue("title", user[0].user.title)
      setFieldValue("country", user[0].user.country)
      setFieldValue("birth_date", user[0].user.birth_date)
      this.getTitle(user[0].user.country, "country", setFieldValue)
      this.getTitle(user[0].user.title, "title", setFieldValue)

      const date = moment(user[0].user.birth_date, "DD/MM/YYYY").format(
        "MM/DD/YYYY"
      )
      this.getDOB("birth_date", new Date(date), setFieldValue)
      if (
        user[0].user.passport_country_of_issue &&
        user[0].user.passport_expiry_date &&
        user[0].user.passport_nationality &&
        user[0].user.passport_number
      ) {
        setFieldValue("passport_number", user[0].user.passport_number)
        this.getTitle(
          user[0].user.passport_nationality,
          "passport_nationality",
          setFieldValue
        )
        this.getTitle(
          user[0].user.passport_country_of_issue,
          "passport_country_of_issue",
          setFieldValue
        )
        const date = moment(
          user[0].user.passport_expiry_date,
          "DD/MM/YYYY"
        ).format("MM/DD/YYYY")
        this.getDOB("passport_expiry_date", new Date(date), setFieldValue)

        this.setState({
          passportExpiryDate: user[0].user.passport_expiry_date
        })
      } else {
        setFieldValue("passport_number", "")
        this.setState({
          passportExpiryDate: "",
          passport_nationality: "BD",
          passport_country_of_issue: "BD"
        })
      }
      this.deleteCurrentUser(user[0])
    }
  }

  getDOB = (key, date, setFieldValue) => {
    if (date !== undefined) {
      let d = getDateString(date)
      setFieldValue(key, d)
      if (key === "birth_date") {
        this.setState({ dateString: d })
      } else if (key === "passport_expiry_date") {
        this.setState({ passportExpiryDate: d })
      }
    }
  }

  getPhone = (e, setFieldValue) => {
    let { value } = e.target
    const { country_code } = this.state
    if (country_code == "880") {
      value = value.replace(/^0+/, "")
      value = value.replace(/[^0-9]/g, "")
      try {
        setFieldValue("phone", value.substring(0, 10))
      } catch (e) {}
    } else {
      setFieldValue("phone", e.target.value)
    }
}

  componentWillUnmount() {
    window.scrollTo(0, 0)
  }

  render = () => {
    let {
      tab,
      type,
      queryData,
      selectedFlight,
      id,
      tabsLength,
      apiToken,
      idx
    } = this.props
    const { currentUsers, country, country_code } = this.state
    let adultLength = 0
    let childLength = 0
    let infantLength = 0
    if (currentUsers.length !== 0) {
      adultLength = currentUsers.adult.length
      childLength = currentUsers.child.length
      infantLength = currentUsers.infant.length
    }
    let passportExpDate = getFlightDepDate(selectedFlight)
    let passportExpDateString = getDateString(passportExpDate)

    let { passport_required } = selectedFlight
    return (
      <Formik
        initialValues={getInitialValues(id, type, passportExpDateString, tab)}
        validationSchema={passengerInfoFormSchema(
          type,
          selectedFlight.passport_required,
          idx,
          this.state.country_code
        )}
        onSubmit={(values, { setSubmitting }) => {
          this.props.callToFormik(values, tab)
        }}
        ref={this.formikRef[tab]}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, touched, setFieldValue, values }) => {
          return (
            <Form onChange={this.props.getAllFormData(values)}>
              {type === "adult" && adultLength > 0 ? (
                <SavedUser
                  currentUsers={currentUsers.adult}
                  type="adult"
                  deleteCurrentUser={this.deleteCurrentUser}
                  getCurrentUser={this.getCurrentUser}
                  setFieldValue={setFieldValue}
                />
              ) : null}
              {type === "child" && childLength > 0 ? (
                <SavedUser
                  currentUsers={currentUsers.child}
                  type="child"
                  deleteCurrentUser={this.deleteCurrentUser}
                  getCurrentUser={this.getCurrentUser}
                  setFieldValue={setFieldValue}
                />
              ) : null}
              {type === "infant" && infantLength > 0 ? (
                <SavedUser
                  currentUsers={currentUsers.infant}
                  type="infant"
                  deleteCurrentUser={this.deleteCurrentUser}
                  getCurrentUser={this.getCurrentUser}
                  setFieldValue={setFieldValue}
                />
              ) : null}

              <FlexContainer>
                <StyleFormGroup>
                  <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <div>
                      <Label for="title">Title</Label>
                      <Input
                        type="select"
                        name="title"
                        value={this.state.title}
                        className="test_browser"
                        id="titleSelect"
                        onChange={e => this.getTitle(e, "title", setFieldValue)}
                        style={{ width: "80px", marginRight: "10px" }}
                      >
                        {namePrefix[`${type}`].map((item, index) => (
                          <option key={index}>{item.label}</option>
                        ))}
                      </Input>
                    </div>
                    <div style={{ width: "100%" }}>
                      <Label for="first_name">First Name</Label>
                      <Input
                        tag={Field}
                        type="text"
                        name="first_name"
                        id="first_name"
                        placeholder=""
                        invalid={errors.first_name && touched.first_name}
                      />
                      <FormFeedback>{errors.first_name}</FormFeedback>
                    </div>
                  </div>
                </StyleFormGroup>
                <StyleFormGroup>
                  <Label for="last_name">Last Name</Label>
                  <Input
                    tag={Field}
                    type="text"
                    name="last_name"
                    id="last_name"
                    placeholder=""
                    invalid={errors.last_name && touched.last_name}
                  />
                  <FormFeedback>{errors.last_name}</FormFeedback>
                </StyleFormGroup>
              </FlexContainer>
              <FlexContainer>
                {type === "adult" ? (
                  !passport_required || passport_required !== true ? null : (
                    <StyleFormGroup
                      id="pagewrap"
                      style={{ width: "100%", maxWidth: "50%" }}
                    >
                      <Label for="exampleEmail">Date Of Birth</Label>
                      <CustomDOB
                        className=""
                        dateStr={this.state.dateString}
                        placeholder="DD-MM-YY"
                        name="birth_date"
                        onChange={(birth_date, date) => {
                          return this.getDOB(birth_date, date, setFieldValue)
                        }}
                        type={type}
                        depDate={selectedFlight}
                      />
                      <InvalidFeedback
                        invalid={errors.birth_date && touched.birth_date}
                      >
                        {errors.birth_date}
                      </InvalidFeedback>
                    </StyleFormGroup>
                  )
                ) : (
                  <StyleFormGroup
                    id="pagewrap"
                    style={{ width: "100%", maxWidth: "50%" }}
                  >
                    <Label for="exampleEmail">Date Of Birth</Label>
                    <CustomDOB
                      className=""
                      dateStr={this.state.dateString}
                      placeholder="DD-MM-YY"
                      name="birth_date"
                      onChange={(birth_date, date) =>
                        this.getDOB(birth_date, date, setFieldValue)
                      }
                      type={type}
                      depDate={selectedFlight}
                    />
                    <InvalidFeedback
                      invalid={errors.birth_date && touched.birth_date}
                    >
                      {errors.birth_date}
                    </InvalidFeedback>
                  </StyleFormGroup>
                )}

                <StyleFormGroup
                  className="date-of-birth"
                  style={{ width: "100%", maxWidth: "50%" }}
                >
                  <Label for="country">Country</Label>
                  <Input
                    className="test_browser"
                    type="select"
                    value={this.state.country}
                    name="country"
                    id="country"
                    onChange={e => this.getTitle(e, "country", setFieldValue)}
                    invalid={errors.country && touched.country}
                    defaultValue={"Bangladesh"}
                  >
                    <option>Select</option>
                    {CountriesNew.map((item, index) => (
                      <option
                        key={index}
                        value={item.value}
                        // selected={item.label === "Bangladesh" ? true : false}
                      >
                        {item.label}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>{errors.country}</FormFeedback>
                </StyleFormGroup>
              </FlexContainer>
              <FlexContainer>
                {type === "adult" && idx === 0 ? (
                  <StyleFormGroup>
                    <Label for="exampleEmail">Phone Number</Label>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                      <ReactPhoneInput
                        defaultCountry={"bd"}
                        value={values.country_code}
                        onChange={value =>
                          this.handleOnChange(value, setFieldValue)
                        }
                        inputStyle={{
                          width: "90px",
                          borderRadius: "0px",
                          border: "1px solid #eaeaea",
                          cursor: "default",
                          paddingLeft: "41px"
                        }}
                        dropdownStyle={{ backgroundColor: "#fff" }}
                        buttonStyle={{
                          backgroundColor: "#fff",
                          border: "1px solid #eaeaea",
                          borderRight: "none"
                        }}
                        containerStyle={{
                          width: "100%",
                          maxWidth: "90px",
                          marginRight: "10px"
                        }}
                        // disableDropdown={false}
                        countryCodeEditable={false}
                        autoFormat={false}
                        inputExtraProps={{
                          disabled: true,
                          title: values.country_code
                        }}
                      />
                      <div style={{ width: "100%" }}>
                        <Input
                          type="tel"
                          name="phone"
                          id="phone"
                          // value={phone}
                          onChange={e => this.getPhone(e, setFieldValue)}
                          placeholder={
                            country === "Bangladesh" && country_code === "880"
                              ? "17XXXXXXXX"
                              : "Phone # *"
                          }
                          tag={Field}
                          invalid={errors.phone && touched.phone}
                        />
                        <FormFeedback>{errors.phone}</FormFeedback>
                      </div>
                    </div>
                  </StyleFormGroup>
                ) : null}
                {type === "adult" && idx === 0 ? (
                  <StyleFormGroup>
                    <Label for="email">Email Address</Label>
                    <Input
                      tag={Field}
                      type="email"
                      name="email"
                      id="email"
                      placeholder=""
                      invalid={errors.email && touched.email}
                    />
                    <FormFeedback>{errors.email}</FormFeedback>
                  </StyleFormGroup>
                ) : null}
              </FlexContainer>
              {selectedFlight.passport_required ? (
                <Fragment>
                  <Divider />
                  <Heading>Passport Details</Heading>
                  <FlexContainer>
                    <StyleFormGroup>
                      <Label for="passport_number">Passport Number</Label>
                      <Input
                        type="text"
                        name="passport_number"
                        id="passport_number"
                        placeholder=""
                        tag={Field}
                        invalid={
                          errors.passport_number && touched.passport_number
                        }
                      />
                      <FormFeedback>{errors.passport_number}</FormFeedback>
                    </StyleFormGroup>
                    <StyleFormGroup>
                      <Label for="passport_nationality">
                        Passport Nationality
                      </Label>
                      <Input
                        type="select"
                        value={this.state.passport_nationality}
                        name="passport_nationality"
                        id="passport_nationality"
                        className="test_browser"
                        onChange={e =>
                          this.getTitle(
                            e,
                            "passport_nationality",
                            setFieldValue
                          )
                        }
                        invalid={
                          errors.passport_nationality &&
                          touched.passport_nationality
                        }
                        // value="Bangladesh"
                      >
                        <option>Select</option>
                        {CountryCodes.map((item, index) => {
                          return (
                            <option
                              key={index}
                              value={item.value}
                              selected={
                                item.label === "Bangladesh" ? true : false
                              }
                            >
                              {item.label}
                            </option>
                          )
                        })}
                      </Input>
                      <FormFeedback>{errors.passport_nationality}</FormFeedback>
                    </StyleFormGroup>
                  </FlexContainer>
                  <FlexContainer>
                    <StyleFormGroup>
                      <Label for="exampleEmail">Passport Expiration Date</Label>
                      <PassportExpDate
                        placeholder="DD-MM-YY"
                        onChange={(passport_expiry_date, date) =>
                          this.getDOB(passport_expiry_date, date, setFieldValue)
                        }
                        type={type}
                        depDate={selectedFlight}
                        expDate={this.state.passportExpiryDate}
                      />
                    </StyleFormGroup>
                    <StyleFormGroup>
                      <Label for="passport_country_of_issue">
                        Passport Country of Issue
                      </Label>
                      <Input
                        type="select"
                        className="test_browser"
                        value={this.state.passport_country_of_issue}
                        name="passport_country_of_issue"
                        id="passport_country_of_issue"
                        onChange={e =>
                          this.getTitle(
                            e,
                            "passport_country_of_issue",
                            setFieldValue
                          )
                        }
                        // defaultValue={"Bangladesh"}
                      >
                        <option>Select</option>
                        {CountryCodes.map((item, index) => (
                          <option
                            key={index}
                            value={item.value}
                            selected={
                              item.label === "Bangladesh" ? true : false
                            }
                          >
                            {item.label}
                          </option>
                        ))}
                      </Input>
                    </StyleFormGroup>
                  </FlexContainer>
                </Fragment>
              ) : null}
              <Divider />
              <Heading>Rewards Programme</Heading>
              <FlexContainer>
                <StyleFormGroup>
                  <Label for="frequent_flyer_number">
                    Frequent Flyer Number (Optional)
                  </Label>
                  <Input
                    type="text"
                    name="frequent_flyer_number"
                    id="frequent_flyer_number"
                    placeholder=""
                  />
                </StyleFormGroup>
              </FlexContainer>
              {tab == tabsLength ? (
                apiToken ? (
                  <FormBtn>Submit</FormBtn>
                ) : (
                  <FormBtn>Login</FormBtn>
                )
              ) : (
                <FormBtn>Next</FormBtn>
              )}
            </Form>
          )
        }}
      </Formik>
    )
  }
}

const mapState = () =>
  createStructuredSelector({
    queryData: selectors.selectQueryDetails(),
    selectedFlight: selectors.selectSelectedFlight(),
    apiToken: features.login.selectors.selectApiToken()
  })

const mapDispatch = dispatch => bindActionCreators({}, dispatch)

export default compose(
  withRouter,
  component => connect(mapState, mapDispatch)(component),
  pure
)(DetailForm)

const CustomDOB = props => {
  // let [selectedDate, setDate] = useState()
  let birthDate = null
  if (props.dateStr !== "") {
    birthDate = new Date(moment(props.dateStr, "DD/MM/YYYY"))
  }

  let { startDate, endDate } = dobsConstraints(props.type, props.depDate)
  return (
    <Fragment>
      <DepartureDate
        // ref={props.customDateRef}
        // style={{ height: 0, width: 0 }}
        id="pagewrap"
      >
        <DatePicker
          date={birthDate}
          passengerForm={true}
          handleChange={date => {
            props.onChange("birth_date", date)
            return (birthDate = date)
          }}
          minDate={startDate}
          initiallyFocusedDate={new Date()}
          maxDate={endDate}
          {...props}
        />
        {/* <input
          ref={props.customDateRef}
          required
          // value="2019-02-02"
          min={start}
          max={end}
          // value={selectedDate}
          onChange={date =>
            setDate(() => {
              props.onChange("birth_date", date)
              return (selectedDate = date)
            })
          }
          type="date"
          // {...props}
        ></input> */}

        {/* <DatePickers
          // style={{ height: 0, width: 0, border: 0 }}
          selected={new Date("01-02-2004")}
          onChange={date => {
            console.log("my date ---------", date)
          }}
          customInput={
            <Inputs
              dateStr={props.dateStr}
              onChangeDate={date =>
                console.log("date ------", date.target.value)
              }
              onChangeMonth={month =>
                console.log("month ------", month.target.value)
              }
              onChangeYear={year =>
                console.log("year ----------", year.target.value)
              }
            />
          }
          dateFormat="dd/MM/yyyy"
          // placeholderText={placeholder ? placeholder : "DD / MM / YYYY"}
          // ref={props.customDateRef}
          // id="datepicker"
          {...props}
          minDate={new Date(startDate)}
          maxDate={new Date(endDate)}
          showYearDropdown
          yearDropdownItemNumber={15}
        /> */}
        {/* <ManualDatePicker
          criteria="birth_date"
          onChange={props.onChange}
          minDate={new Date(startDate)}
          maxDate={new Date(endDate)}
        /> */}
      </DepartureDate>
    </Fragment>
  )
}

const PassportExpDate = props => {
  let dString = getFlightDepDate(props.depDate)
  // let [expDate, setExpDate] = useState(new Date(dString))
  let passportExpiryDate = null
  if (props.expDate === "") {
    passportExpiryDate = new Date(dString)
  } else {
    passportExpiryDate = new Date(moment(props.expDate, "DD/MM/YYYY"))
  }

  let testMaxDate = moment(dString).add(10, "years")
  return (
    <DepartureDate id="pagewrap">
      <DatePicker
        date={passportExpiryDate}
        handleChange={date => {
          props.onChange("passport_expiry_date", date)
          return (passportExpiryDate = date)
        }}
        minDate={new Date(dString)}
        maxDate={testMaxDate}
      />
      {/* <ManualDatePicker
        criteria="passport_expiry_date"
        onChange={props.onChange}
        minDate={new Date(dString)}
        maxDate={new Date(testMaxDate)}
      /> */}
    </DepartureDate>
  )
}
