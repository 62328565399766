import styled from "styled-components"
export const TopCart = styled.div`
  background-color: #fff;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px -2px 22px -13px #000;
`
export const InnerHeading = styled.h3`
  margin-bottom: 0px;
  color: #1e1e1e;
  font-size: 18px;
`
export const SmallHeading = styled.p`
  margin-bottom: 20px;
  color: #454545;
  font-size: 14px;
`
export const FlexContainer = styled.div`
  display: flex;
`
export const Date = styled.div`
  flex: 1;
  h4 {
    color: #313131;
    font-size: 17px;
    margin-bottom: 0px;
  }
  p {
    color: #8d8b98;
    font-size: 13px;
    margin-bottom: 0px;
  }
`
export const Time = styled.div`
  flex: 1;
  text-align: right;
  h4 {
    color: #313131;
    font-size: 17px;
    margin-bottom: 0px;
  }
  p {
    color: #8d8b98;
    font-size: 13px;
    margin-bottom: 0px;
  }
`
export const Charges = styled.h4`
  color: #313131;
  font-size: 17px;
  margin-bottom: 0px;
  text-transform: uppercase;
  padding: 12px 23px;
  background-color: #e4e2e3;
`
export const BottomCart = styled.div`
  background-color: #fff;
  padding: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 4px 22px -13px #000;
`
export const Person = styled.h4`
  color: #3d3d3d;
  font-size: 17px;
  margin-bottom: 0px;
  font-weight: 400;
`
export const Divider = styled.div`
  border-bottom: 1px solid #f1f1f1;
  margin: 20px 0px;
`
export const Total = styled.h4`
  color: #000;
  font-size: 17px;
  margin-bottom: 0px;
  font-weight: bold;
`
export const CardBtn = styled.button`
  background-color: #ff7b47;
  color: #fff;
  font-weight: 600;
  border: none;
  padding: 8px 0px;
  width: 100%;
  text-transform: uppercase;
  margin-top: 20px;
`
export const StickyCart = styled.div`
  position: sticky;
  top: 20px;
  right: 0px;
  @media only screen and (max-width: 767px) {
    position: unset;
  }
`
