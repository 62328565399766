import styled from "styled-components"

export const ProgressBartext = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
  h4 {
    font-size: 15px;
    flex: 1;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 0px;
  }
`
export const ProgressBars = styled.div`
  background-color: #fff;
  padding: 20px 9rem;
  margin-top: 0px;
  padding-top: 15px;
  @media only screen and (min-width: 200px) and (max-width: 1024px) {
    padding: 20px 2rem;
  }
`
