import React from "react"
import ContentLoader from "react-content-loader"

export const SearchResultLoader = props => {
  return (
    <ContentLoader
      ariaLabel="Searching all available flights..."
      type="list"
      height={1200}
      width={700}
      speed={2}
      primaryColor="rgb(128,128,128, 0.1)"
      secondaryColor="rgb(128,128,128, 0.2)"
      // primaryOpacity="0.1"
      {...props}
    >
      <rect x="0" y="0" rx="8" ry="8" width="455" height="80" />
      <rect x="455" y="0" rx="8" ry="8" width="245" height="80" />
      {/* <rect
        x="560"
        y="90"
        rx="10"
        ry="10"
        width="30"
        height="30"
        stroke="red"
        fill="black"
        stroke-width="5"
      /> */}
      {/* second box */}
      <rect x="0" y="95" rx="8" ry="8" width="455" height="80" />
      <rect x="455" y="95" rx="8" ry="8" width="245" height="80" />
      {/* third */}
      <rect x="0" y="190" rx="8" ry="8" width="455" height="80" />
      <rect x="455" y="190" rx="8" ry="8" width="245" height="80" />
      {/* fourth */}
      <rect x="0" y="285" rx="8" ry="8" width="455" height="80" />
      <rect x="455" y="285" rx="8" ry="8" width="245" height="80" />
      {/* fifth */}
      <rect x="0" y="380" rx="8" ry="8" width="455" height="80" />
      <rect x="455" y="380" rx="8" ry="8" width="245" height="80" />
    </ContentLoader>
  )
}

export const LandingPageLoader = props => {
  return (
    <ContentLoader
      type="list"
      height={1200}
      width={700}
      speed={2}
      primaryColor="rgb(128,128,128, 0.1)"
      secondaryColor="rgb(128,128,128, 0.2)"
      // primaryOpacity="0.1"
      {...props}
    >
      <rect x="0" y="150" rx="8" ry="8" width="800" height="200" />
      <rect x="0" y="380" rx="8" ry="8" width="400" height="200" />
      <rect x="420" y="380" rx="8" ry="8" width="400" height="200" />
      {/* <rect x="400" y="400" rx="8" ry="8" width="400" height="200" /> */}
      {/* <rect x="455" y="0" rx="8" ry="8" width="245" height="80" /> */}

      {/* second box */}
      {/* <rect x="" y="95" rx="8" ry="8" width="455" height="80" /> */}
      {/* <rect x="455" y="95" rx="8" ry="8" width="245" height="80" /> */}
    </ContentLoader>
  )
}

export const SliderLoader = props => {
  return (
    <ContentLoader
      speed={2}
      primaryColor="rgb(128,128,128, 0.1)"
      secondaryColor="rgb(128,128,128, 0.2)"
      // primaryOpacity="0.1"
      {...props}
    >
      <rect x="0" y="0" width="130" height="100" />
      <rect x="140" y="0" width="130" height="100" />
      <rect x="280" y="0" width="130" height="100" />
    </ContentLoader>
  )
}

export const DealsLoader = props => {
  return (
    <ContentLoader
      // type="list"
      height={400}
      // width={700}
      speed={2}
      primaryColor="rgb(128,128,128, 0.1)"
      secondaryColor="rgb(128,128,128, 0.2)"
      // primaryOpacity="0.1"
      {...props}
    >
      <rect x="5" y="20" width="800" height="400" />
    </ContentLoader>
  )
}

export const LandingTabsLoader = props => {
  return (
    <ContentLoader
      type="list"
      height={1000}
      // width={700}
      speed={2}
      primaryColor="rgb(128,128,128, 0.1)"
      secondaryColor="rgb(128,128,128, 0.2)"
      // primaryOpacity="0.1"
      {...props}
    >
      <rect x="5" y="60" width="800" height="60" />
      <rect x="5" y="130" width="800" height="60" />
      <rect x="5" y="200" width="800" height="60" />
      <rect x="5" y="270" width="800" height="60" />
    </ContentLoader>
  )
}

export const InputsLoaders = props => {
  return (
    <ContentLoader
      // type="list"
      // height={100}
      // width={700}
      speed={2}
      primaryColor="rgb(128,128,128, 0.1)"
      secondaryColor="rgb(128,128,128, 0.2)"
      // primaryOpacity="0.1"
      {...props}
    >
      <rect x="5" y="60" width="800" height="120" />
    </ContentLoader>
  )
}

export const InputsLoadersHalf = props => {
  return (
    <ContentLoader
      // type="list"
      // height={100}
      // width={700}
      speed={2}
      primaryColor="rgb(128,128,128, 0.1)"
      secondaryColor="rgb(128,128,128, 0.2)"
      // primaryOpacity="0.1"
      {...props}
    >
      <rect x="5" y="60" width="399" height="120" />
    </ContentLoader>
  )
}

export const SmallRectangle = props => {
  return (
    <ContentLoader
      // type="list"
      // height={300}
      // width={700}
      speed={2}
      primaryColor="rgb(128,128,128, 0.1)"
      secondaryColor="rgb(128,128,128, 0.2)"
      // primaryOpacity="0.1"
      {...props}
    >
      <rect x="0" y="0" width="100" height="100" />
    </ContentLoader>
  )
}

export const TicketsLoader = props => {
  return (
    <ContentLoader
      type="list"
      // height={300}
      // width={700}
      speed={2}
      primaryColor="rgb(128,128,128, 0.1)"
      secondaryColor="rgb(128,128,128, 0.2)"
      // primaryOpacity="0.1"
      {...props}
    >
      <rect x="10" y="10" rx="4" ry="4" width="110" height="20" />
      <rect x="10" y="32" rx="4" ry="4" width="110" height="20" />
      <rect x="10" y="54" rx="4" ry="4" width="110" height="20" />
      <rect x="130" y="10" rx="4" ry="4" width="250" height="65" />
      {/* <rect x="10" y="10" rx="8" ry="8" width="100" height="200" />
      <rect x="10" y="10" rx="8" ry="8" width="100" height="200" />
      <rect x="10" y="10" rx="8" ry="8" width="100" height="200" /> */}
      {/* <rect x="120" y="0" rx="8" ry="8" width="245" height="80" /> */}
    </ContentLoader>
  )
}

export const Test = props => {
  return (
    <ContentLoader
      type="list"
      // height={300}
      // width={700}
      speed={2}
      primaryColor="rgb(128,128,128, 0.1)"
      secondaryColor="rgb(128,128,128, 0.2)"
      // primaryOpacity="0.1"
      {...props}
    >
      {/* <rect x="10" y="10" rx="4" ry="4" width="110" height="20" />
      <rect x="10" y="32" rx="4" ry="4" width="110" height="20" />
      <rect x="10" y="54" rx="4" ry="4" width="110" height="20" />
      <rect x="130" y="10" rx="4" ry="4" width="200" height="60" /> */}

      {/* <rect x="10" y="10" rx="8" ry="8" width="100" height="200" />
      <rect x="10" y="10" rx="8" ry="8" width="100" height="200" />
      <rect x="10" y="10" rx="8" ry="8" width="100" height="200" /> */}
      {/* <rect x="120" y="0" rx="8" ry="8" width="245" height="80" /> */}
    </ContentLoader>
  )
}

export const PaginationResultLoader = props => {
  return (
    <ContentLoader
      ariaLabel="Bringing more flights results..."
      type="list"
      height={100}
      width={700}
      speed={2}
      primaryColor="rgb(128,128,128, 0.1)"
      secondaryColor="rgb(128,128,128, 0.2)"
      {...props}
    >
      <rect x="0" y="0" rx="8" ry="8" width="455" height="80" />
      <rect x="455" y="0" rx="8" ry="8" width="245" height="80" />
    </ContentLoader>
  )
}
