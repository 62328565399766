import { fetchService } from "./API"

export const searchFlights = query => {
  return fetchService({ method: "POST", url: "v2/flight/search", body: query })
}
export const progressListSearch = () => {
  return fetchService({ method: "GET", url: "v2/flight/progsearchlist" })
}

export const progressSearchFlights = async (query, page) => {
  // console.log("calling for venderrr ", query.progress)
  return fetchService({
    method: "POST",
    url: `v2/flight/progsearch/${page}`,
    body: query
  })
}

export const totalPriceFareBreakDown = query => {
  return fetchService({
    method: "POST",
    url: "v2/flight/totalprice",
    body: query
  })
}
