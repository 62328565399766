import styled from "styled-components"
export const Filter = styled.div`
  display: flex;
  margin-top: 10px;
  background-color: #fff;
  padding: 0px;
  padding-bottom: 0px;
  align-items: center;
  #datepicker {
    max-width: 100%;
    height: 4rem;
    text-align: center;
    &:focus {
      outline: none;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1500px) {
      height: 3.5rem;
    }
    @media only screen and (min-width: 200px) and (max-width: 767px) {
      max-width: 100%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 10px !important;
  }
  @media only screen and (min-width: 200px) and (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 10px;
    //     width: 100%;
    // max-width: 200px;
    margin: 0 auto;
    flex: 1;
  }
`
export const Arrowleft = styled.span`
  background-color: ${props => (props.disabled ? "#93c8e2" : "#47b2e6")};
  color: #fff;
  padding: 8px 2px;
  cursor: ${props => (props.disabled === true ? null : "pointer")};
  display: flex;
  align-items: center;
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    margin-bottom: 0px;
  }
`
