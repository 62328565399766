import styled from "styled-components";

export const ModalCustome = styled.div`
  padding: 0px;
`;
export const HeaderModal = styled.div`
  border-bottom: 2px solid #390d00;
  text-align: center;
  padding-bottom: 18px;
`;
export const BodyModal = styled.div`
  padding: 0px 20px !important;
`;
export const SidebarModal = styled.div`
`;
