import React from "react"
import styled from "styled-components"

export const ResetFilterBtn = ({ resetFilters }) => (
  <ResetFilter onClick={resetFilters}>
    <h4>Reset Filters</h4>
  </ResetFilter>
)
export const Result = ({ props, loading, receivedSome, received }) => (
  <FilterResul>
    <h4>
      {receivedSome && props > 0
        ? `${props} Flights`
        : props === 0 && received
        ? "No Flights"
        : "Loading..."}
    </h4>
  </FilterResul>
)

export const ResetFilter = styled.div`
  position: relative;
  display: block;
  height: 100%;
  h4 {
    color: #ee464c;
    font-size: 13px;
    position: absolute;
    right: 0;
    bottom: 0px;
    margin-bottom: 0px;
    cursor: pointer;
  }
  @media only screen and (min-width: 200px) and (max-width: 1024px) {
    display: none;
  }
`
export const FilterResul = styled.div`
  position: relative;
  display: block;
  height: 100%;
  h4 {
    color: #676470;
    font-size: 11px;
    position: absolute;
    left: 0;
    bottom: 0px;
    margin-bottom: 0px;
    cursor: pointer;
  }
  @media only screen and (min-width: 200px) and (max-width: 1024px) {
    display: none;
  }
`
