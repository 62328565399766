import React, { lazy, Suspense } from "react"
import { Row, Col, TabContent, TabPane } from "reactstrap"
import { compose } from "recompose"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import { airportList } from "../thunks"
import { selectors } from ".."
import Test from "scenes/Airports"
import features from "features"
import { Main, Full, BackWhite, FullTabs, FullBack } from "./elements"
// import ErrorBoundary from "routes/ErrorBoundry"
import Header from "scenes/Header/Header"
import { progressListSearch as progressArrayApi } from "services/SearchFlights"
import * as localForage from "localforage"
import * as API from "services/API"
import Alert from "../../../base_components/BannerAlert"
import ImageAlert from "../../../base_components/BannerImageAlert"
// import Hotel from "../../Hotel"
// import CommingSoon from "scenes/Hotel/CommingSoon"
// import { LandingPageLoader } from "base_components/Loaders"
const Award = lazy(() => import(/*  webpackChunkName: "Award" */ "../Award"))
const DealSlider = lazy(() =>
  import(/*webpackPrefetch: true, webpackChunkName: "DealSlider" */ "../DealSlider")
)
const TabSection = lazy(() =>
  import(/*webpackPrefetch: true, webpackChunkName: "TabsSection" */ "../TabsSection")
)
const TravelTalk = lazy(() =>
  import(/*webpackPrefetch: 7, webpackChunkName: "TravelTalk" */ "../TravelTalk")
)
const QrSection = lazy(() =>
  import(/* webpackPrefetch: 8, webpackChunkName: "QrSection" */ "../QrSection")
)
const LeftContainer = lazy(() =>
  import(/* webpackPrefetch: 10, webpackChunkName: "LeftContianer" */ "../LeftContianer")
)
const RightContainer = lazy(() =>
  import(/* webpackPrefetch: 9, webpackChunkName: "RightContianer" */ "../RightContianer")
)
// import Award from "../Award"
// import DealSlider from "../DealSlider"
// import TabSection from "../TabsSection"
// import TravelTalk from "../TravelTalk"
// import QrSection from "../QrSection"
// import RightContainer from "../RightContianer"
// import LeftContainer from "../LeftContianer"
// const Header = lazy(() => import("../../Header/Header"))
class Landing extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      alertModel: undefined,
      displayAlert: false
    }
  }
  componentDidMount() {
    API.fetchGlobalService({
      method: "GET",
      url: process.env.REACT_APP_NOTICE_API_URL
    })
      .then(res => {
        this.setState({
          displayAlert: true,
          alertModel: res
        })
      })
      .catch(err => {
        console.log("err---", err)
      })

    let { airportList, setAirportsState } = this.props
    // console.time("Time taken by API")
    Test().then(storage => {
      let { data, last_modified } = storage
      setAirportsState({
        data,
        last_modified
      }).then(() => airportList())
    })
    // console.timeEnd("Time taken by API")
    progressArrayApi().then(data => {
      if (data.success) {
        localForage.setItem("progressResult", data.data)
      }
    })
  }
  toggle = tab => {
    // console.log(tab)
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  closeAlert = () => {
    this.setState({
      displayAlert: false
    })
  }

  render() {
    
    // console.time("Time taken by API")
    // console.timeEnd("Time taken by API")
    return (
      // <ErrorBoundary>
      <React.Fragment>
        <FullBack hotel={this.state.activeTab}>
          <Header toggle={this.toggle} />
          <Suspense fallback={<p />}>
            <FullTabs>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane
                  className={this.state.activeTab == "1" ? "active" : null}
                  tabId="1"
                >
                  <Main>
                    <Full>
                      <Row style={{ margin: "0px", marginTop: "-55px" }}>
                        <Col sm="7">
                          <LeftContainer />
                        </Col>
                        <Col
                          sm="5"
                          //  style={{ paddingLeft: "30px" }}
                        >
                          <RightContainer />
                        </Col>
                      </Row>
                    </Full>
                  </Main>
                  <BackWhite>
                    <Award />
                    <DealSlider />
                    <TabSection />
                    <TravelTalk />
                    <QrSection />
                  </BackWhite>
                </TabPane>
                <TabPane
                  className={this.state.activeTab == "2" ? "active" : null}
                  tabId="2"
                >
                  {/* <Hotel /> */}
                  {/* <CommingSoon /> */}
                </TabPane>
              </TabContent>
            </FullTabs>
          </Suspense>
        </FullBack>
        {this.state.alertModel && ( 
          this.state.alertModel.description &&
          this.state.alertModel.description !== "" &&
          this.state.alertModel.title &&
          this.state.alertModel.title !== "" ? (
          <Alert
            visible={this.state.displayAlert}
            close={this.closeAlert}
            message={this.state.alertModel.description}
            title={this.state.alertModel.title}
          />
        ) : this.state.alertModel.image_url  && 
            <ImageAlert 
            visible={this.state.displayAlert}
            close={this.closeAlert}
            image={this.state.alertModel.image_url}
          />
          
        )}
      </React.Fragment>
      // </ErrorBoundary>
    )
  }
}

const mapToState = () =>
  createStructuredSelector({
    selectAirportList: selectors.selectAirportList(),
    selectIsLogging: features.login.selectors.selectIsLogging()
  })

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      airportList,
      setAirportsState: features.landing.actions.setAirportsState
    },
    dispatch
  )

export default compose(component =>
  connect(
    mapToState,
    mapDispatch
  )(component)
)(Landing)
