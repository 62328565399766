import styled from "styled-components"
import { Nav, NavLink, Button, FormGroup, Input } from "reactstrap"
export const StyledNav = styled(Nav)`
  border-bottom: none !important;
  padding: 0px 3rem;
  margin-bottom: 0px !important;
  background-color: #3ab1e4;
  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    margin-left: 2rem;
  }
  @media screen and (min-width: 200px) and (max-width: 767px) {
    padding: 0px 1rem;
    margin-left: 0px;
    justify-content: center;
  }
`
export const NavLinks = styled(NavLink)`
  &:hover {
    border: 1px solid transparent !important;
  }
  &.active {
    background-color: #fff !important;
    border: none !important;
    color: #3ab1e4 !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  svg {
    margin-right: 4px;
    font-size: 20px;
  }
  font-size: 19px;
  color: #fff !important;
  font-weight: 400;
  padding: 12px 12px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: -1px;
  margin-right: 10px;
  @media screen and (min-width: 1100px) and (max-width: 1400px) {
    font-size: 19px;
  }
`
